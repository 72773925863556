import {Directive, ElementRef, OnInit, Input} from '@angular/core';

declare var $: any;

@Directive({
    selector: '[saUiDatepicker]'
})
export class UiDatepickerDirective implements OnInit {

    @Input() saUiDatepicker: any;
    @Input() onSelectCallbacks: Function[] = [];

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        let saUiDatepicker = this.saUiDatepicker || {};
        let element = $(this.el.nativeElement);

        if (saUiDatepicker.minRestrict) {
            this.onSelectCallbacks.push((selectedDate) => {
                $(saUiDatepicker.minRestrict).datepicker('option', 'minDate', selectedDate);
            });
        }
        if (saUiDatepicker.maxRestrict) {
            this.onSelectCallbacks.push((selectedDate) => {
                $(saUiDatepicker.maxRestrict).datepicker('option', 'maxDate', selectedDate);
            });
        }

        //Let others know about changes to the data field
        this.onSelectCallbacks.push((selectedDate) => {
            element.triggerHandler("change");

            let form = element.closest('form');

            if (typeof form.bootstrapValidator == 'function') {
                try {
                    form.bootstrapValidator('revalidateField', element);
                } catch (e) {
                    console.warn(e.message)
                }
            }
        });

        let options = $.extend({
            prevText: '<i class="fa fa-chevron-left"></i>',
            nextText: '<i class="fa fa-chevron-right"></i>',
            onSelect: (selectedDate) => {
                this.onSelectCallbacks.forEach((callback) => {
                    callback.call(callback, selectedDate)
                })
            }
        }, saUiDatepicker);

        element.datepicker(options);
    }
}
