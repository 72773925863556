import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import {ConsultaView} from '../../shared/consulta.classes';
import {PatientView} from '../../../+paciente/paciente.classes';
import {MedicoView} from '../../../+doctor/doctor.classes';
import {DoctorService} from '../../../+doctor/doctor.service';
import {PacienteService} from '../../../+paciente/paciente.service';
import {Subscription} from 'rxjs/Subscription';
import {ConsultaService} from '../../shared/consulta.service';
import {NotificationService} from '../../../shared/utils/notification.service';
import {MedicinesService} from "../../../shared/services/medicines.service";
import {RecipesService} from "../../../shared/services/recipes.service";
import {Recipe} from "../../../shared/classes/recipe.classes";
import {
    Medicine,
    MedicinePresentation,
    MedicineProduct,
    MedicineSubstance
} from "../../../shared/classes/medicine.classes";
import {I18nPipe} from "../../../shared/i18n";
import {PageRequest} from "../../../common/http/page.request";
import {CapitalizePipe} from "../../../shared/utils/capitalize.pipe";
import {NgForm} from "@angular/forms";
import {ReportsService} from '../../../shared/services/reports.service';
import {ShareDataService} from '../../../+paciente/+detalle/consulta-actual/share-data.service';

@Component({
    selector: 'app-plan-receta',
    templateUrl: './plan-receta.component.html',
    styleUrls: ['./plan-receta.component.css']
})
export class PlanRecetaComponent implements OnInit, OnDestroy {
    @ViewChild('frmMedicamento') private formMedicamento: NgForm;
    medicamentos: Medicine[] = [];
    medicamento: Medicine = new Medicine();
    timePeriod: string = 'Dias';
    private isEditing: boolean = false;
    consulta: ConsultaView = new ConsultaView();
    paciente: PatientView = new PatientView();
    medico: MedicoView = new MedicoView();
    fullNameMedico: string = '';
    domicilioPaciente: string = '';
    cargando: boolean = false;
    trySubmitForm: boolean = false;
    canEdit: boolean = true;
    private subscription: Subscription = new Subscription();

    @ViewChild('dialogSearchMedicamento') private dialogSearchMedicamentoEl: ElementRef;
    dialogMedicamentoConfig = {
        autoOpen: false,
        closeText: '',
        draggable: false,
        resizable: false,
        modal: true,
        width: 600,
        classes: {
            'ui-dialog-titlebar': 'no-border',
        },
        position: {my: 'left top', at: 'left top', of: $('#denominacionGenerica')},
        title: this.i18nPipe.transform('Search substance')
    };
    medicineSubstancesList: MedicineSubstance[] = [];
    medicineProductsList: MedicineProduct[] = [];
    searchMedicamento: string;
    loadingMedicinesList: boolean = false;
    receta: Recipe = new Recipe();
    private timeoutSearchMedicamento: number;
    private pageSearchFilterList: PageRequest = new PageRequest();
    selectedMedicineSubstance: MedicineSubstance;
    private isLastFromFilterList: boolean = false;
    showModal: boolean = false;
    medicamentoBandera:boolean = false; //ACARMONA20200807 agrego bandera para saber que tipo de seleccionde medicamento tendra el doctor

    constructor(public bsModalRef: BsModalRef,
                private doctorService: DoctorService,
                private pacienteService: PacienteService,
                private consultaService: ConsultaService,
                private medicinesService: MedicinesService,
                private recipesService: RecipesService,
                private notificationService: NotificationService,
                private i18nPipe: I18nPipe,
                private capitalizePipe: CapitalizePipe,
                private reportsService: ReportsService,
                private dataService: ShareDataService) {
    }

    ngOnInit(): void {
        this.receta.detalleRecetaViewList = [];

        this.consultaService.getMapEstatusConsulta()
            .subscribe((mapEstatusConsulta) => this.canEdit = this.consulta.idEstadoConsulta == mapEstatusConsulta.get('ConsultaEnTurno'));

        this.receta.diagnosticoPaciente = `${this.consulta.listaPadecimiento && this.consulta.listaPadecimiento.length && this.consulta.listaPadecimiento[0].catalogKey} 
                                        ${this.consulta.listaPadecimiento && this.consulta.listaPadecimiento.length && this.consulta.listaPadecimiento[0].nombrePadecimiento}`;
        this.receta.sello = '';
        this.receta.cadenaOriginal = '';
        this.receta.consultaId = this.consulta.idConsulta;
        this.receta.activo= true;

        if (!(this.medico.idMedico && this.medico.especialidadViewList.length && this.medico.especialidadViewList[0].cedula.length)) {
            if (this.consulta && this.consulta.idMedico) {
                this.subscription.add(this.doctorService.getById(this.consulta.idMedico, true).subscribe(m => {
                    this.medico = m;
                    this.fullNameMedico = `${m.nombre} ${m.apellidoPaterno} ${m.apellidoMaterno}`.trim();
                    this.llenarDetallesMedico();
                }));
            }
        } else {
            this.fullNameMedico = `${this.medico.nombre} ${this.medico.apellidoPaterno} ${this.medico.apellidoMaterno}`.trim();
            this.llenarDetallesMedico();
        }

        if (!(this.paciente && this.paciente.idPaciente)) {
            if (this.consulta && this.consulta.idPaciente) {
                this.subscription.add(this.pacienteService.getById(this.consulta.idPaciente, false).subscribe(p => {
                    if (p && p.idPaciente) {
                        this.paciente = p;

                        this.receta.curpPaciente = this.paciente.curp || '';
                        this.receta.curpPaciente = (this.receta.curpPaciente.length && this.receta.curpPaciente) || '.';
                        this.receta.nombrePaciente = this.consulta.nombrePaciente.trim();
                        this.receta.sexoPaciente = this.paciente.sexo;
                        this.receta.numeroExpediente = this.paciente.numeroExpediente;
                        this.receta.pacienteId = this.paciente.idPaciente;
                        this.receta.edadPaciente = Math.abs(new Date(Date.now() - new Date(this.paciente.fechaNacimiento).getTime()).getUTCFullYear() - 1970);

                        if (this.paciente && this.paciente.domicilioViewList && this.paciente.domicilioViewList.length) {
                            const _address = this.paciente.domicilioViewList[0];
                            this.domicilioPaciente = `${_address.domicilio}
                                                    ${_address.colonia ? 'Colonia: ' + _address.colonia : ''}
                                                    ${_address.municipio} 
                                                    ${_address.estado} 
                                                    ${this.paciente.telefonoCelular ? 'Teléfono: ' + this.paciente.telefonoCelular : this.paciente.telefonoLocal ? 'Teléfono: ' + this.paciente.telefonoLocal : ''}`.trim();
                            const _pAddress = this.paciente.domicilioViewList[0];

                            this.receta.domicilioViewList.push(
                                {
                                    municipio: _pAddress.municipio,
                                    colonia: _pAddress.colonia,
                                    cp: _pAddress.cp,
                                    estado: _pAddress.estado,
                                    localidad: _pAddress.localidad,
                                    pais: _pAddress.pais,
                                    numeroExterior: null,
                                    calle: null,
                                    numeroInterior: null,
                                    referencia: null,
                                    email: this.paciente.email,
                                    tipoUsuario: false,
                                    personaId: this.paciente.idPaciente
                                }
                            );
                        }
                    }
                }));
            }
        }
        // this.getReceta();
    }
    onKeypressEvent(event: any){

        console.log(event.target.value);
        return false;
     }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    llenarDetallesMedico(): void {
        this.receta.cedulaMedico = this.medico.especialidadViewList &&
            this.medico.especialidadViewList.length &&
            this.medico.especialidadViewList[0].cedula || '';
        this.receta.especialidadMedico = this.medico.especialidadViewList &&
            this.medico.especialidadViewList.length &&
            this.medico.especialidadViewList[0].especialidad || '';
        this.receta.universidadMedico = this.medico.especialidadViewList &&
            this.medico.especialidadViewList.length &&
            this.medico.especialidadViewList[0].universidad || '';
        this.receta.medicoId = this.medico.idMedico;
        this.receta.nombreMedico = this.fullNameMedico;
    }

    downloadDocument() {
        this.cargando = true;
        var idGroup = Number(localStorage.getItem('id_select_group')); //GGR20200622 agrego idGrupo seleccionado
        this.reportsService.getRecipeNote(this.consulta.idConsulta, idGroup)
            .take(1)
            .subscribe((base64File) => {
                this.cargando = false;
                let fileName = `Receta-${this.consulta.nombrePaciente}`;
                fileName.toString().trim();
                let url = `data:application/pdf;base64,${base64File}`;
                fetch(url)
                    .then(res => res.blob())
                    .then(function (blob) {
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveBlob(blob, fileName);
                        } else {
                            var elem = window.document.createElement('a');
                            elem.href = window.URL.createObjectURL(blob);
                            elem.download = fileName;
                            document.body.appendChild(elem);
                            elem.click();
                            document.body.removeChild(elem);
                        }
                    });
            }, () => this.cargando = false, () => this.cargando = false);
    }

    editarMedicamento(indexMedicine: number): void {
        if (!this.canEdit) {
            return;
        }

        if (this.isEditing) {
            this.agregarMedicamento(true);
        }

        this.medicamento = this.medicamentos[indexMedicine];
        let timePeriodSplit = this.medicamento.periodo.split(" ");
        this.medicamento.periodo = timePeriodSplit[0];
        if (timePeriodSplit.length > 1) {
            this.timePeriod = timePeriodSplit[1];
        }
        this.medicamentos.splice(indexMedicine, 1);
        this.isEditing = true;
    }

    agregarMedicamento(isValid: boolean): void {
        this.trySubmitForm = true;
        console.log(this.medicamento);
       // C:\Users\Antonio\Documents\AngularProd
        if (!this.canEdit || !isValid) {
            return;
        }
        if ( 
            !this.medicamento.denominacionGenerica ||
            !this.medicamento.frecuencia ||
            !this.medicamento.periodo ||
            !this.medicamento.denominacionGenerica ||
            !this.medicamento.frecuencia || !this.medicamento.periodo) {
            return;
        }
        this.medicamento.denominacionDistintiva = '';
        this.medicamento.periodo += ` ${this.timePeriod}`;
        this.medicamento.cantidad = 0;
        this.receta.detalleRecetaViewList.push(this.medicamento);
        // validar si sera POST o PUT
        this.cargando = true;
        if (this.receta && this.receta.detalleRecetaViewList.length > 0 && this.receta.idReceta || this.receta && !this.receta.detalleRecetaViewList.length && this.receta.idReceta) {
            this.recipesService.update(this.receta, this.receta.idReceta)
                .subscribe((recetaView) => {
                    this.cargando = false;
                    this.receta = recetaView;
                }, () => {
                    this.receta.detalleRecetaViewList.pop();
                    this.cargando = false;
                }, () => this.cargando = false);
        } else {
            this.recipesService.create(this.receta)
                .subscribe((recetaView) => {
                    this.receta = recetaView;
                    this.cargando = false;
                }, () => {
                    this.cargando = false;
                    this.receta.detalleRecetaViewList.pop();
                }, () => this.cargando = false);
        }
        // this.medicamentos = [...this.medicamentos, this.medicamento];
        this.isEditing = false;
        this.medicamento = new Medicine();

        this.trySubmitForm = false;
        Object.keys(this.formMedicamento.controls).forEach((key) => {
            this.formMedicamento.form.get(key).markAsPristine();
            this.formMedicamento.form.get(key).markAsUntouched();
        });
    }

    eliminarDetalle(index: number): void {
        if (!this.canEdit) {
            return;
        }
        const medicina: Medicine = Object.assign({}, this.medicamento);
        this.receta.detalleRecetaViewList.splice(index, 1);
        // si queremos hacer un update tenemos que asegurarnos que aun hay detalles en la receta
        if (this.receta.detalleRecetaViewList.length) {
            this.cargando = true;
            this.recipesService.update(this.receta, this.receta.idReceta)
                .subscribe((recetaView) => {
                    this.cargando = false;
                    this.receta = recetaView;
                }, () => {
                    this.cargando = false;
                    this.receta.detalleRecetaViewList.push(medicina);
                });
        }
    }

    resetTimePeriod(): void {
        if (!this.timePeriod) {
            this.timePeriod = this.i18nPipe.transform("Days");
        }
    }

// metodo bye


    get dialogSearchMedicamento(): any {
        if (!this.dialogSearchMedicamentoEl) {
            return null;
        }

        return $(this.dialogSearchMedicamentoEl.nativeElement);
    }

    openDialogMedicamento(event: MouseEvent): void {
        if (this.dialogSearchMedicamento) {
            this.medicineSubstancesList = [];
            this.medicineProductsList = [];
            delete this.selectedMedicineSubstance;
            delete this.searchMedicamento;

            if (event) {
                this.dialogSearchMedicamento.dialog('option', 'position', {
                    my: 'left top',
                    at: 'left top',
                    of: event.target
                });
            }

            this.dialogSearchMedicamento.dialog('open');
        }
    }

    buscarMedicamento(reset: boolean = false, more: boolean = false): void {
        clearTimeout(this.timeoutSearchMedicamento);

        if (more) {
            this.pageSearchFilterList.page++;
        }

        if (reset) {
            this.pageSearchFilterList.page = 0;
            this.medicineProductsList = [];
            this.isLastFromFilterList = false;
        }

        if (this.searchMedicamento && this.searchMedicamento.length > 2) {
            this.timeoutSearchMedicamento = window.setTimeout(() => {
                delete this.selectedMedicineSubstance;
                this.medicineProductsList = [];
                this.loadingMedicinesList = true;

                this.medicinesService.search({textoBusqueda: this.searchMedicamento})
                    .subscribe((medicineSearch) => {
                        this.medicineSubstancesList = [];
                        this.medicineSubstancesList.push(...medicineSearch.Substances);
                        this.isLastFromFilterList = true;
                        this.loadingMedicinesList = false;
                    }, () => this.loadingMedicinesList = false);
            }, 500);
        }
    }

    onListaMedicamentosScroll(event: MouseEvent): void {
        if (!this.isLastFromFilterList) {
            if ((<HTMLElement>event.target).offsetHeight + (<HTMLElement>event.target).scrollTop >= (<HTMLElement>event.target).scrollHeight) {
                this.buscarMedicamento(false, true);
            }
        }
    }

    selectMedicineSubstance(substance: MedicineSubstance): void {
        this.selectedMedicineSubstance = substance;
        this.loadingMedicinesList = true;
        this.medicinesService.getBySustancia(substance.ActiveSubstanceId)
            .subscribe((medicineProducts) => {
                this.medicineProductsList = [];
                this.medicineProductsList.push(...medicineProducts);
                this.medicineSubstancesList = [];
                this.loadingMedicinesList = false;
            }, () => this.loadingMedicinesList = false);
    }

    selectMedicamento(medicineProduct: MedicineProduct, medicinePresentation: MedicinePresentation): void {
        this.medicamento.denominacionGenerica = medicineProduct.Brand;
        this.medicamento.unidad = medicineProduct.PharmaForm && medicineProduct.PharmaForm.length ? medicineProduct.PharmaForm : '';
        this.medicamento.presentacion = medicinePresentation.Presentation;
        this.medicamento.substancias = medicineProduct.Substances;

        this.searchMedicamento = "";

        if (this.dialogSearchMedicamento) {
            this.dialogSearchMedicamento.dialog('close');
        }
    }

    getPresentationSubstances(medicine: Medicine): string {
        if (medicine) {
            let presentation: string = "";
            if (medicine.presentacion) {
                presentation = `<div>${medicine.presentacion}</div>`;
            }

            let substances: string = "";
            if (medicine.substancias) {
                substances = medicine.substancias.map((substance) => this.capitalizePipe.transform(substance.Description)).join(", ");

                if (substances) {
                    substances = `<div class="substances">${substances}</div>`;
                }
            }

            return presentation + substances;
        }

        return "";
    }

    RecetaViewTieneDetalles(): void {
        if (this.receta && this.receta.detalleRecetaViewList && this.receta.detalleRecetaViewList.length) {
            this.dataService.sendParameter();
            this.bsModalRef.hide();
        } else {
            this.showModal = true;
            (<any>$('#modalInfo')).modal('show');
        }
    }

    cancelaFormato(): void {
        // QUIERE DECIR QUE YA EXISTE EN BACK, SINO SOLO CERRAMOS EL MODAL
        if (this.receta && this.receta.idReceta) {
            this.cargando = true;
            this.recipesService.delete(this.receta.idReceta).subscribe(() => {
                this.cargando = false;
                this.bsModalRef.hide();
            }, () => this.cargando = false, () => this.cargando = false);
        } else {
            this.bsModalRef.hide();
        }
    }

    noAgregar(): void {
        this.showModal = false;
        (<any>$('#modalInfo')).modal('hide');
        this.cancelaFormato();
    }


    /**
     * INICIO ACARMONA20200807 Funcion que hace el cambio de input en el llenado de medicamentos
     * @param values 
     */
    fieldsChange(values:any): void {
        this.medicamentoBandera = !this.medicamentoBandera;
    }
    //FIN ACARMONA20200807
}
