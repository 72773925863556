import {Injectable} from '@angular/core';
import {CustomHttp} from '../../common/http/custom.http';
import {Http} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import {RequestConfig} from '../rest/rest.request';
import {RequestMethod, Response} from '@angular/http';
import {CatNacionalidadesView} from "../classes/catalog.classes";
export class EstadosView {
    key: string;
    nombre: string;
}

export class PaisesView {
    id: string;
    nombre: string;
}

export class MunicipiosView {
    key: string;
    nombre: string;
    efe_key: string;
}

export class LocalidadesView {
    key: string;
    nombre: string;
    efe_key: string;
    mun_key: string;
}

export class CodigosPostalesView {
    key: string;
    asenta: string;
    tasenta: string;
    efe_key: string;
    mun_key: string;
    cd_key: string;
}


@Injectable()
export class ComunService {

    private GET_CATALOGO_NACIONALIDADES_ALL = new RequestConfig('get-nacionalidades-all', 'application/json', true, RequestMethod.Get, '/catalogo-nacionalidades/findAlll');

    constructor(private http: CustomHttp, private _http: Http) {
    }

    getCatConvivencia() {
        return [
            {nombre: 'Social'},
            {nombre: '1-3 veces por semana'},
            {nombre: 'Más de 3 veces por semana'},
            {nombre: 'Diariamente'}
        ];
    }
 
    getPaises(): PaisesView[] {
        return [
            /*{'id': '004', 'nombre': 'AFGANISTAN'},
            {'id': '008', 'nombre': 'ALBANIA'},
            {'id': '010', 'nombre': 'ANTARTIDA'},
            {'id': '012', 'nombre': 'ARGELIA'},
            {'id': '016', 'nombre': 'SAMOA AMERICANA'},
            {'id': '020', 'nombre': 'ANDORRA'},
            {'id': '024', 'nombre': 'ANGOLA'},
            {'id': '028', 'nombre': 'ANTIGUA Y BARBUDA'},
            {'id': '031', 'nombre': 'AZERBAIYAN'},
            {'id': '032', 'nombre': 'ARGENTINA'},
            {'id': '036', 'nombre': 'AUSTRALIA'},
            {'id': '040', 'nombre': 'AUSTRIA'},
            {'id': '044', 'nombre': 'BAHAMAS (LAS)'},
            {'id': '048', 'nombre': 'BAREIN'},
            {'id': '050', 'nombre': 'BANGLADES'},
            {'id': '051', 'nombre': 'ARMENIA'},
            {'id': '052', 'nombre': 'BARBADOS'},
            {'id': '056', 'nombre': 'BELGICA'},
            {'id': '060', 'nombre': 'BERMUDAS'},
            {'id': '064', 'nombre': 'BUTAN'},
            {'id': '068', 'nombre': 'BOLIVIA, ESTADO PLURINACIONAL DE'},
            {'id': '070', 'nombre': 'BOSNIA Y HERZEGOVINA'},
            {'id': '072', 'nombre': 'BOTSUANA'},
            {'id': '074', 'nombre': 'ISLA BOUVET'},
            {'id': '076', 'nombre': 'BRASIL'},
            {'id': '084', 'nombre': 'BELICE'},
            {'id': '086', 'nombre': 'TERRITORIO BRITANICO DEL OCEANO INDICO (EL)'},
            {'id': '090', 'nombre': 'ISLAS SALOMON (LAS)'},
            {'id': '092', 'nombre': 'ISLAS VIRGENES (BRITANICAS)'},
            {'id': '096', 'nombre': 'BRUNEI DARUSSALAM'},
            {'id': '100', 'nombre': 'BULGARIA'},
            {'id': '104', 'nombre': 'MYANMAR'},
            {'id': '108', 'nombre': 'BURUNDI'},
            {'id': '112', 'nombre': 'BIELORRUSIA'},
            {'id': '116', 'nombre': 'CAMBOYA'},
            {'id': '120', 'nombre': 'CAMERUN'},
            {'id': '124', 'nombre': 'CANADA'},
            {'id': '132', 'nombre': 'CABO VERDE'},
            {'id': '136', 'nombre': 'ISLAS CAIMAN (LAS)'},
            {'id': '140', 'nombre': 'REPUBLICA CENTROAFRICANA (LA)'},
            {'id': '144', 'nombre': 'SRI LANKA'},
            {'id': '148', 'nombre': 'CHAD'},
            {'id': '152', 'nombre': 'CHILE'},
            {'id': '156', 'nombre': 'CHINA'},
            {'id': '158', 'nombre': 'TAIWAN (PROVINCIA DE CHINA)'},
            {'id': '162', 'nombre': 'ISLA DE NAVIDAD'},
            {'id': '166', 'nombre': 'ISLAS COCOS (KEELING)'},
            {'id': '170', 'nombre': 'COLOMBIA'},
            {'id': '174', 'nombre': 'COMORAS'},
            {'id': '175', 'nombre': 'MAYOTTE'},
            {'id': '178', 'nombre': 'CONGO'},
            {'id': '180', 'nombre': 'CONGO (LA REPUBLICA DEMOCRATICA DEL)'},
            {'id': '184', 'nombre': 'ISLAS COOK (LAS)'},
            {'id': '188', 'nombre': 'COSTA RICA'},
            {'id': '191', 'nombre': 'CROACIA'},
            {'id': '192', 'nombre': 'CUBA'},
            {'id': '196', 'nombre': 'CHIPRE'},
            {'id': '203', 'nombre': 'REPUBLICA CHECA (LA)'},
            {'id': '204', 'nombre': 'BENIN'},
            {'id': '208', 'nombre': 'DINAMARCA'},
            {'id': '212', 'nombre': 'DOMINICA'},
            {'id': '214', 'nombre': 'REPUBLICA DOMINICANA (LA)'},
            {'id': '218', 'nombre': 'ECUADOR'},
            {'id': '222', 'nombre': 'EL SALVADOR'},
            {'id': '226', 'nombre': 'GUINEA ECUATORIAL'},
            {'id': '231', 'nombre': 'ETIOPIA'},
            {'id': '232', 'nombre': 'ERITREA'},
            {'id': '233', 'nombre': 'ESTONIA'},
            {'id': '234', 'nombre': 'ISLAS FEROE (LAS)'},
            {'id': '238', 'nombre': 'ISLAS MALVINAS [FALKLAND] (LAS)'},
            {'id': '239', 'nombre': 'GEORGIA DEL SUR Y LAS ISLAS SANDWICH DEL SUR'},
            {'id': '242', 'nombre': 'FIYI'},
            {'id': '246', 'nombre': 'FINLANDIA'},
            {'id': '248', 'nombre': 'ISLAS ÅLAND'},
            {'id': '250', 'nombre': 'FRANCIA'},
            {'id': '254', 'nombre': 'GUAYANA FRANCESA'},
            {'id': '258', 'nombre': 'POLINESIA FRANCESA'},
            {'id': '260', 'nombre': 'TERRITORIOS AUSTRALES FRANCESES (LOS)'},
            {'id': '262', 'nombre': 'YIBUTI'},
            {'id': '266', 'nombre': 'GABON'},
            {'id': '268', 'nombre': 'GEORGIA'},
            {'id': '270', 'nombre': 'GAMBIA (LA)'},
            {'id': '275', 'nombre': 'PALESTINA, ESTADO DE'},
            {'id': '276', 'nombre': 'ALEMANIA'},
            {'id': '288', 'nombre': 'GHANA'},
            {'id': '292', 'nombre': 'GIBRALTAR'},
            {'id': '296', 'nombre': 'KIRIBATI'},
            {'id': '300', 'nombre': 'GRECIA'},
            {'id': '304', 'nombre': 'GROENLANDIA'},
            {'id': '308', 'nombre': 'GRANADA'},
            {'id': '312', 'nombre': 'GUADALUPE'},
            {'id': '316', 'nombre': 'GUAM'},
            {'id': '320', 'nombre': 'GUATEMALA'},
            {'id': '324', 'nombre': 'GUINEA'},
            {'id': '328', 'nombre': 'GUYANA'},
            {'id': '332', 'nombre': 'HAITI'},
            {'id': '334', 'nombre': 'ISLA HEARD E ISLAS MCDONALD'},
            {'id': '336', 'nombre': 'SANTA SEDE[ESTADO DE LA CIUDAD DEL VATICANO] (LA)'},
            {'id': '340', 'nombre': 'HONDURAS'},
            {'id': '344', 'nombre': 'HONG KONG'},
            {'id': '348', 'nombre': 'HUNGRIA'},
            {'id': '352', 'nombre': 'ISLANDIA'},
            {'id': '356', 'nombre': 'INDIA'},
            {'id': '360', 'nombre': 'INDONESIA'},
            {'id': '364', 'nombre': 'IRAN (LA REPUBLICA ISLAMICA DE)'},
            {'id': '368', 'nombre': 'IRAK'},
            {'id': '372', 'nombre': 'IRLANDA'},
            {'id': '376', 'nombre': 'ISRAEL'},
            {'id': '380', 'nombre': 'ITALIA'},
            {'id': '384', 'nombre': 'COTE D IVOIRE'},
            {'id': '388', 'nombre': 'JAMAICA'},
            {'id': '392', 'nombre': 'JAPON'},
            {'id': '398', 'nombre': 'KAZAJISTAN'},
            {'id': '400', 'nombre': 'JORDANIA'},
            {'id': '404', 'nombre': 'KENIA'},
            {'id': '408', 'nombre': 'COREA (LA REPUBLICA DEMOCRATICA POPULAR DE)'},
            {'id': '410', 'nombre': 'COREA (LA REPUBLICA DE)'},
            {'id': '414', 'nombre': 'KUWAIT'},
            {'id': '417', 'nombre': 'KIRGUISTAN'},
            {'id': '418', 'nombre': 'LAO, (LA) REPUBLICA DEMOCRATICA POPULAR'},
            {'id': '422', 'nombre': 'LIBANO'},
            {'id': '426', 'nombre': 'LESOTO'},
            {'id': '428', 'nombre': 'LETONIA'},
            {'id': '430', 'nombre': 'LIBERIA'},
            {'id': '434', 'nombre': 'LIBIA'},
            {'id': '438', 'nombre': 'LIECHTENSTEIN'},
            {'id': '440', 'nombre': 'LITUANIA'},
            {'id': '442', 'nombre': 'LUXEMBURGO'},
            {'id': '446', 'nombre': 'MACAO'},
            {'id': '450', 'nombre': 'MADAGASCAR'},
            {'id': '454', 'nombre': 'MALAUI'},
            {'id': '458', 'nombre': 'MALASIA'},
            {'id': '462', 'nombre': 'MALDIVAS'},
            {'id': '466', 'nombre': 'MALI'},
            {'id': '470', 'nombre': 'MALTA'},
            {'id': '474', 'nombre': 'MARTINICA'},
            {'id': '478', 'nombre': 'MAURITANIA'},
            {'id': '480', 'nombre': 'MAURICIO'},
            {'id': '484', 'nombre': 'MEXICO'},
            {'id': '492', 'nombre': 'MONACO'},
            {'id': '496', 'nombre': 'MONGOLIA'},
            {'id': '498', 'nombre': 'MOLDAVIA (LA REPUBLICA DE)'},
            {'id': '499', 'nombre': 'MONTENEGRO'},
            {'id': '500', 'nombre': 'MONTSERRAT'},
            {'id': '504', 'nombre': 'MARRUECOS'},
            {'id': '508', 'nombre': 'MOZAMBIQUE'},
            {'id': '512', 'nombre': 'OMAN'},
            {'id': '516', 'nombre': 'NAMIBIA'},
            {'id': '520', 'nombre': 'NAURU'},
            {'id': '524', 'nombre': 'NEPAL'},
            {'id': '528', 'nombre': 'PAISES BAJOS (LOS)'},
            {'id': '531', 'nombre': 'CURAÇAO'},
            {'id': '533', 'nombre': 'ARUBA'},
            {'id': '534', 'nombre': 'SINT MAARTEN (PARTE HOLANDESA)'},
            {'id': '535', 'nombre': 'BONAIRE, SAN EUSTAQUIO Y SABA'},
            {'id': '540', 'nombre': 'NUEVA CALEDONIA'},
            {'id': '548', 'nombre': 'VANUATU'},
            {'id': '554', 'nombre': 'NUEVA ZELANDA'},
            {'id': '558', 'nombre': 'NICARAGUA'},
            {'id': '562', 'nombre': 'NIGER (EL)'},
            {'id': '566', 'nombre': 'NIGERIA'},
            {'id': '570', 'nombre': 'NIUE'},
            {'id': '574', 'nombre': 'ISLA NORFOLK'},
            {'id': '578', 'nombre': 'NORUEGA'},
            {'id': '580', 'nombre': 'ISLAS MARIANAS DEL NORTE (LAS)'},
            {'id': '581', 'nombre': 'ISLAS DE ULTRAMAR MENORES DE ESTADOS UNIDOS (LAS)'},
            {'id': '583', 'nombre': 'MICRONESIA (LOS ESTADOS FEDERADOS DE)'},
            {'id': '584', 'nombre': 'ISLAS MARSHALL (LAS)'},
            {'id': '585', 'nombre': 'PALAOS'},
            {'id': '586', 'nombre': 'PAKISTAN'},
            {'id': '591', 'nombre': 'PANAMA'},
            {'id': '598', 'nombre': 'PAPUA NUEVA GUINEA'},
            {'id': '600', 'nombre': 'PARAGUAY'},
            {'id': '604', 'nombre': 'PERU'},
            {'id': '608', 'nombre': 'FILIPINAS (LAS)'},
            {'id': '612', 'nombre': 'PITCAIRN'},
            {'id': '616', 'nombre': 'POLONIA'},
            {'id': '620', 'nombre': 'PORTUGAL'},
            {'id': '624', 'nombre': 'GUINEA-BISAU'},
            {'id': '626', 'nombre': 'TIMOR-LESTE'},
            {'id': '630', 'nombre': 'PUERTO RICO'},
            {'id': '634', 'nombre': 'CATAR'},
            {'id': '638', 'nombre': 'REUNION'},
            {'id': '642', 'nombre': 'RUMANIA'},
            {'id': '643', 'nombre': 'RUSIA, (LA) FEDERACION DE'},
            {'id': '646', 'nombre': 'RUANDA'},
            {'id': '652', 'nombre': 'SAN BARTOLOME'},
            {'id': '654', 'nombre': 'SANTA HELENA, ASCENSION Y TRISTAN DE ACUÑA'},
            {'id': '659', 'nombre': 'SAN CRISTOBAL Y NIEVES'},
            {'id': '660', 'nombre': 'ANGUILA'},
            {'id': '662', 'nombre': 'SANTA LUCIA'},
            {'id': '663', 'nombre': 'SAN MARTIN (PARTE FRANCESA)'},
            {'id': '666', 'nombre': 'SAN PEDRO Y MIQUELON'},
            {'id': '670', 'nombre': 'SAN VICENTE Y LAS GRANADINAS'},
            {'id': '674', 'nombre': 'SAN MARINO'},
            {'id': '678', 'nombre': 'SANTO TOME Y PRINCIPE'},
            {'id': '682', 'nombre': 'ARABIA SAUDITA'},
            {'id': '686', 'nombre': 'SENEGAL'},
            {'id': '688', 'nombre': 'SERBIA'},
            {'id': '690', 'nombre': 'SEYCHELLES'},
            {'id': '694', 'nombre': 'SIERRA LEONA'},
            {'id': '702', 'nombre': 'SINGAPUR'},
            {'id': '703', 'nombre': 'ESLOVAQUIA'},
            {'id': '704', 'nombre': 'VIET NAM'},
            {'id': '705', 'nombre': 'ESLOVENIA'},
            {'id': '706', 'nombre': 'SOMALIA'},
            {'id': '710', 'nombre': 'SUDAFRICA'},
            {'id': '716', 'nombre': 'ZIMBABUE'},
            {'id': '724', 'nombre': 'ESPAÑA'},
            {'id': '728', 'nombre': 'SUDAN DEL SUR'},
            {'id': '729', 'nombre': 'SUDAN (EL)'},
            {'id': '732', 'nombre': 'SAHARA OCCIDENTAL'},
            {'id': '740', 'nombre': 'SURINAM'},
            {'id': '744', 'nombre': 'SVALBARD Y JAN MAYEN'},
            {'id': '748', 'nombre': 'SUAZILANDIA'},
            {'id': '752', 'nombre': 'SUECIA'},
            {'id': '756', 'nombre': 'SUIZA'},
            {'id': '760', 'nombre': 'SIRIA, (LA) REPUBLICA ARABE'},
            {'id': '762', 'nombre': 'TAYIKISTAN'},
            {'id': '764', 'nombre': 'TAILANDIA'},
            {'id': '768', 'nombre': 'TOGO'},
            {'id': '772', 'nombre': 'TOKELAU'},
            {'id': '776', 'nombre': 'TONGA'},
            {'id': '780', 'nombre': 'TRINIDAD Y TOBAGO'},
            {'id': '784', 'nombre': 'EMIRATOS ARABES UNIDOS (LOS)'},
            {'id': '788', 'nombre': 'TUNEZ'},
            {'id': '792', 'nombre': 'TURQUIA'},
            {'id': '795', 'nombre': 'TURKMENISTAN'},
            {'id': '796', 'nombre': 'ISLAS TURCAS Y CAICOS (LAS)'},
            {'id': '798', 'nombre': 'TUVALU'},
            {'id': '800', 'nombre': 'UGANDA'},
            {'id': '804', 'nombre': 'UCRANIA'},
            {'id': '807', 'nombre': 'MACEDONIA (LA ANTIGUA REPUBLICA YUGOSLAVA DE)'},
            {'id': '818', 'nombre': 'EGIPTO'},
            {'id': '826', 'nombre': 'REINO UNIDO (EL)'},
            {'id': '831', 'nombre': 'GUERNSEY'},
            {'id': '832', 'nombre': 'JERSEY'},
            {'id': '833', 'nombre': 'ISLA DE MAN'},
            {'id': '834', 'nombre': 'TANZANIA, REPUBLICA UNIDA DE'},
            {'id': '840', 'nombre': 'ESTADOS UNIDOS (LOS)'},
            {'id': '850', 'nombre': 'ISLAS VIRGENES (EE.UU.)'},
            {'id': '854', 'nombre': 'BURKINA FASO'},
            {'id': '858', 'nombre': 'URUGUAY'},
            {'id': '860', 'nombre': 'UZBEKISTAN'},
            {'id': '862', 'nombre': 'VENEZUELA, REPUBLICA BOLIVARIANA DE'},
            {'id': '876', 'nombre': 'WALLIS Y FUTUNA'},
            {'id': '882', 'nombre': 'SAMOA'},
            {'id': '887', 'nombre': 'YEMEN'},
            {'id': '894', 'nombre': 'ZAMBIA'}*/
        ];
    }

    getEstados(): EstadosView[] {
        return [
            /*{'key': '01', 'nombre': 'AGUASCALIENTES'},
            {'key': '03', 'nombre': 'BAJA CALIFORNIA SUR'},
            {'key': '04', 'nombre': 'CAMPECHE'},
            {'key': '05', 'nombre': 'COAHUILA DE ZARAGOZA'},
            {'key': '06', 'nombre': 'COLIMA'},
            {'key': '07', 'nombre': 'CHIAPAS'},
            {'key': '08', 'nombre': 'CHIHUAHUA'},
            {'key': '09', 'nombre': 'CIUDAD DE MÉXICO'},
            {'key': '10', 'nombre': 'DURANGO'},
            {'key': '11', 'nombre': 'GUANAJUATO'},
            {'key': '12', 'nombre': 'GUERRERO'},
            {'key': '13', 'nombre': 'HIDALGO'},
            {'key': '14', 'nombre': 'JALISCO'},
            {'key': '15', 'nombre': 'MÉXICO'},
            {'key': '16', 'nombre': 'MICHOACÁN DE OCAMPO'},
            {'key': '17', 'nombre': 'MORELOS'},
            {'key': '18', 'nombre': 'NAYARIT'},
            {'key': '19', 'nombre': 'NUEVO LEÓN'},
            {'key': '20', 'nombre': 'OAXACA'},
            {'key': '21', 'nombre': 'PUEBLA'},
            {'key': '22', 'nombre': 'QUERÉTARO'},
            {'key': '23', 'nombre': 'QUINTANA ROO'},
            {'key': '24', 'nombre': 'SAN LUIS POTOSÍ'},
            {'key': '25', 'nombre': 'SINALOA'},
            {'key': '26', 'nombre': 'SONORA'},
            {'key': '27', 'nombre': 'TABASCO'},
            {'key': '28', 'nombre': 'TAMAULIPAS'},
            {'key': '29', 'nombre': 'TLAXCALA'},
            {'key': '30', 'nombre': 'VERACRUZ DE IGNACIO DE LA LLAVE'},
            {'key': '31', 'nombre': 'YUCATÁN'},
            {'key': '32', 'nombre': 'ZACATECAS'},
            {'key': '36', 'nombre': 'ESTADOS UNIDOS MEXICANOS'},
            {'key': '97', 'nombre': 'NO APLICA'},
            {'key': '98', 'nombre': 'SE IGNORA'},
            {'key': '99', 'nombre': 'NO ESPECIFICADO'},*/
        ]
    }

    getMunicipios(): MunicipiosView[] {
        return [
            /*{'key': '001', 'nombre': 'AGUASCALIENTES', 'efe_key': '01'},
            {'key': '002', 'nombre': 'ASIENTOS', 'efe_key': '01'},
            {'key': '003', 'nombre': 'CALVILLO', 'efe_key': '01'},
            {'key': '004', 'nombre': 'COSÍO', 'efe_key': '01'},
            {'key': '005', 'nombre': 'JESÚS MARÍA', 'efe_key': '01'},
            {'key': '006', 'nombre': 'PABELLÓN DE ARTEAGA', 'efe_key': '01'},
            {'key': '007', 'nombre': 'RINCÓN DE ROMOS', 'efe_key': '01'},
            {'key': '008', 'nombre': 'SAN JOSÉ DE GRACIA', 'efe_key': '01'},
            {'key': '009', 'nombre': 'TEPEZALÁ', 'efe_key': '01'},
            {'key': '010', 'nombre': 'EL LLANO', 'efe_key': '01'},
            {'key': '011', 'nombre': 'SAN FRANCISCO DE LOS ROMO', 'efe_key': '01'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '01'},
            {'key': '001', 'nombre': 'ENSENADA', 'efe_key': '02'},
            {'key': '002', 'nombre': 'MEXICALI', 'efe_key': '02'},
            {'key': '003', 'nombre': 'TECATE', 'efe_key': '02'},
            {'key': '004', 'nombre': 'TIJUANA', 'efe_key': '02'},
            {'key': '005', 'nombre': 'PLAYAS DE ROSARITO', 'efe_key': '02'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '02'},
            {'key': '001', 'nombre': 'COMONDÚ', 'efe_key': '03'},
            {'key': '002', 'nombre': 'MULEGÉ', 'efe_key': '03'},
            {'key': '003', 'nombre': 'LA PAZ', 'efe_key': '03'},
            {'key': '008', 'nombre': 'LOS CABOS', 'efe_key': '03'},
            {'key': '009', 'nombre': 'LORETO', 'efe_key': '03'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '03'},
            {'key': '001', 'nombre': 'CALKINÍ', 'efe_key': '04'},
            {'key': '002', 'nombre': 'CAMPECHE', 'efe_key': '04'},
            {'key': '003', 'nombre': 'CARMEN', 'efe_key': '04'},
            {'key': '004', 'nombre': 'CHAMPOTÓN', 'efe_key': '04'},
            {'key': '005', 'nombre': 'HECELCHAKÁN', 'efe_key': '04'},
            {'key': '006', 'nombre': 'HOPELCHÉN', 'efe_key': '04'},
            {'key': '007', 'nombre': 'PALIZADA', 'efe_key': '04'},
            {'key': '008', 'nombre': 'TENABO', 'efe_key': '04'},
            {'key': '009', 'nombre': 'ESCÁRCEGA', 'efe_key': '04'},
            {'key': '010', 'nombre': 'CALAKMUL', 'efe_key': '04'},
            {'key': '011', 'nombre': 'CANDELARIA', 'efe_key': '04'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '04'},
            {'key': '001', 'nombre': 'ABASOLO', 'efe_key': '05'},
            {'key': '002', 'nombre': 'ACUÑA', 'efe_key': '05'},
            {'key': '003', 'nombre': 'ALLENDE', 'efe_key': '05'},
            {'key': '004', 'nombre': 'ARTEAGA', 'efe_key': '05'},
            {'key': '005', 'nombre': 'CANDELA', 'efe_key': '05'},
            {'key': '006', 'nombre': 'CASTAÑOS', 'efe_key': '05'},
            {'key': '007', 'nombre': 'CUATRO CIÉNEGAS', 'efe_key': '05'},
            {'key': '008', 'nombre': 'ESCOBEDO', 'efe_key': '05'},
            {'key': '009', 'nombre': 'FRANCISCO I. MADERO', 'efe_key': '05'},
            {'key': '010', 'nombre': 'FRONTERA', 'efe_key': '05'},
            {'key': '011', 'nombre': 'GENERAL CEPEDA', 'efe_key': '05'},
            {'key': '012', 'nombre': 'GUERRERO', 'efe_key': '05'},
            {'key': '013', 'nombre': 'HIDALGO', 'efe_key': '05'},
            {'key': '014', 'nombre': 'JIMÉNEZ', 'efe_key': '05'},
            {'key': '015', 'nombre': 'JUÁREZ', 'efe_key': '05'},
            {'key': '016', 'nombre': 'LAMADRID', 'efe_key': '05'},
            {'key': '017', 'nombre': 'MATAMOROS', 'efe_key': '05'},
            {'key': '018', 'nombre': 'MONCLOVA', 'efe_key': '05'},
            {'key': '019', 'nombre': 'MORELOS', 'efe_key': '05'},
            {'key': '020', 'nombre': 'MÚZQUIZ', 'efe_key': '05'},
            {'key': '021', 'nombre': 'NADADORES', 'efe_key': '05'},
            {'key': '022', 'nombre': 'NAVA', 'efe_key': '05'},
            {'key': '023', 'nombre': 'OCAMPO', 'efe_key': '05'},
            {'key': '024', 'nombre': 'PARRAS', 'efe_key': '05'},
            {'key': '025', 'nombre': 'PIEDRAS NEGRAS', 'efe_key': '05'},
            {'key': '026', 'nombre': 'PROGRESO', 'efe_key': '05'},
            {'key': '027', 'nombre': 'RAMOS ARIZPE', 'efe_key': '05'},
            {'key': '028', 'nombre': 'SABINAS', 'efe_key': '05'},
            {'key': '029', 'nombre': 'SACRAMENTO', 'efe_key': '05'},
            {'key': '030', 'nombre': 'SALTILLO', 'efe_key': '05'},
            {'key': '031', 'nombre': 'SAN BUENAVENTURA', 'efe_key': '05'},
            {'key': '032', 'nombre': 'SAN JUAN DE SABINAS', 'efe_key': '05'},
            {'key': '033', 'nombre': 'SAN PEDRO', 'efe_key': '05'},
            {'key': '034', 'nombre': 'SIERRA MOJADA', 'efe_key': '05'},
            {'key': '035', 'nombre': 'TORREÓN', 'efe_key': '05'},
            {'key': '036', 'nombre': 'VIESCA', 'efe_key': '05'},
            {'key': '037', 'nombre': 'VILLA UNIÓN', 'efe_key': '05'},
            {'key': '038', 'nombre': 'ZARAGOZA', 'efe_key': '05'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '05'},
            {'key': '001', 'nombre': 'ARMERÍA', 'efe_key': '06'},
            {'key': '002', 'nombre': 'COLIMA', 'efe_key': '06'},
            {'key': '003', 'nombre': 'COMALA', 'efe_key': '06'},
            {'key': '004', 'nombre': 'COQUIMATLÁN', 'efe_key': '06'},
            {'key': '005', 'nombre': 'CUAUHTÉMOC', 'efe_key': '06'},
            {'key': '006', 'nombre': 'IXTLAHUACÁN', 'efe_key': '06'},
            {'key': '007', 'nombre': 'MANZANILLO', 'efe_key': '06'},
            {'key': '008', 'nombre': 'MINATITLÁN', 'efe_key': '06'},
            {'key': '009', 'nombre': 'TECOMÁN', 'efe_key': '06'},
            {'key': '010', 'nombre': 'VILLA DE ÁLVAREZ', 'efe_key': '06'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '06'},
            {'key': '001', 'nombre': 'ACACOYAGUA', 'efe_key': '07'},
            {'key': '002', 'nombre': 'ACALA', 'efe_key': '07'},
            {'key': '003', 'nombre': 'ACAPETAHUA', 'efe_key': '07'},
            {'key': '004', 'nombre': 'ALTAMIRANO', 'efe_key': '07'},
            {'key': '005', 'nombre': 'AMATÁN', 'efe_key': '07'},
            {'key': '006', 'nombre': 'AMATENANGO DE LA FRONTERA', 'efe_key': '07'},
            {'key': '007', 'nombre': 'AMATENANGO DEL VALLE', 'efe_key': '07'},
            {'key': '008', 'nombre': 'ANGEL ALBINO CORZO', 'efe_key': '07'},
            {'key': '009', 'nombre': 'ARRIAGA', 'efe_key': '07'},
            {'key': '010', 'nombre': 'BEJUCAL DE OCAMPO', 'efe_key': '07'},
            {'key': '011', 'nombre': 'BELLA VISTA', 'efe_key': '07'},
            {'key': '012', 'nombre': 'BERRIOZÁBAL', 'efe_key': '07'},
            {'key': '013', 'nombre': 'BOCHIL', 'efe_key': '07'},
            {'key': '014', 'nombre': 'EL BOSQUE', 'efe_key': '07'},
            {'key': '015', 'nombre': 'CACAHOATÁN', 'efe_key': '07'},
            {'key': '016', 'nombre': 'CATAZAJÁ', 'efe_key': '07'},
            {'key': '017', 'nombre': 'CINTALAPA', 'efe_key': '07'},
            {'key': '018', 'nombre': 'COAPILLA', 'efe_key': '07'},
            {'key': '019', 'nombre': 'COMITÁN DE DOMÍNGUEZ', 'efe_key': '07'},
            {'key': '020', 'nombre': 'LA CONCORDIA', 'efe_key': '07'},
            {'key': '021', 'nombre': 'COPAINALÁ', 'efe_key': '07'},
            {'key': '022', 'nombre': 'CHALCHIHUITÁN', 'efe_key': '07'},
            {'key': '023', 'nombre': 'CHAMULA', 'efe_key': '07'},
            {'key': '024', 'nombre': 'CHANAL', 'efe_key': '07'},
            {'key': '025', 'nombre': 'CHAPULTENANGO', 'efe_key': '07'},
            {'key': '026', 'nombre': 'CHENALHÓ', 'efe_key': '07'},
            {'key': '027', 'nombre': 'CHIAPA DE CORZO', 'efe_key': '07'},
            {'key': '028', 'nombre': 'CHIAPILLA', 'efe_key': '07'},
            {'key': '029', 'nombre': 'CHICOASÉN', 'efe_key': '07'},
            {'key': '030', 'nombre': 'CHICOMUSELO', 'efe_key': '07'},
            {'key': '031', 'nombre': 'CHILÓN', 'efe_key': '07'},
            {'key': '032', 'nombre': 'ESCUINTLA', 'efe_key': '07'},
            {'key': '033', 'nombre': 'FRANCISCO LEÓN', 'efe_key': '07'},
            {'key': '034', 'nombre': 'FRONTERA COMALAPA', 'efe_key': '07'},
            {'key': '035', 'nombre': 'FRONTERA HIDALGO', 'efe_key': '07'},
            {'key': '036', 'nombre': 'LA GRANDEZA', 'efe_key': '07'},
            {'key': '037', 'nombre': 'HUEHUETÁN', 'efe_key': '07'},
            {'key': '038', 'nombre': 'HUIXTÁN', 'efe_key': '07'},
            {'key': '039', 'nombre': 'HUITIUPÁN', 'efe_key': '07'},
            {'key': '040', 'nombre': 'HUIXTLA', 'efe_key': '07'},
            {'key': '041', 'nombre': 'LA INDEPENDENCIA', 'efe_key': '07'},
            {'key': '042', 'nombre': 'IXHUATÁN', 'efe_key': '07'},
            {'key': '043', 'nombre': 'IXTACOMITÁN', 'efe_key': '07'},
            {'key': '044', 'nombre': 'IXTAPA', 'efe_key': '07'},
            {'key': '045', 'nombre': 'IXTAPANGAJOYA', 'efe_key': '07'},
            {'key': '046', 'nombre': 'JIQUIPILAS', 'efe_key': '07'},
            {'key': '047', 'nombre': 'JITOTOL', 'efe_key': '07'},
            {'key': '048', 'nombre': 'JUÁREZ', 'efe_key': '07'},
            {'key': '049', 'nombre': 'LARRÁINZAR', 'efe_key': '07'},
            {'key': '050', 'nombre': 'LA LIBERTAD', 'efe_key': '07'},
            {'key': '051', 'nombre': 'MAPASTEPEC', 'efe_key': '07'},
            {'key': '052', 'nombre': 'LAS MARGARITAS', 'efe_key': '07'},
            {'key': '053', 'nombre': 'MAZAPA DE MADERO', 'efe_key': '07'},
            {'key': '054', 'nombre': 'MAZATÁN', 'efe_key': '07'},
            {'key': '055', 'nombre': 'METAPA', 'efe_key': '07'},
            {'key': '056', 'nombre': 'MITONTIC', 'efe_key': '07'},
            {'key': '057', 'nombre': 'MOTOZINTLA', 'efe_key': '07'},
            {'key': '058', 'nombre': 'NICOLÁS RUÍZ', 'efe_key': '07'},
            {'key': '059', 'nombre': 'OCOSINGO', 'efe_key': '07'},
            {'key': '060', 'nombre': 'OCOTEPEC', 'efe_key': '07'},
            {'key': '061', 'nombre': 'OCOZOCOAUTLA DE ESPINOSA', 'efe_key': '07'},
            {'key': '062', 'nombre': 'OSTUACÁN', 'efe_key': '07'},
            {'key': '063', 'nombre': 'OSUMACINTA', 'efe_key': '07'},
            {'key': '064', 'nombre': 'OXCHUC', 'efe_key': '07'},
            {'key': '065', 'nombre': 'PALENQUE', 'efe_key': '07'},
            {'key': '066', 'nombre': 'PANTELHÓ', 'efe_key': '07'},
            {'key': '067', 'nombre': 'PANTEPEC', 'efe_key': '07'},
            {'key': '068', 'nombre': 'PICHUCALCO', 'efe_key': '07'},
            {'key': '069', 'nombre': 'PIJIJIAPAN', 'efe_key': '07'},
            {'key': '070', 'nombre': 'EL PORVENIR', 'efe_key': '07'},
            {'key': '071', 'nombre': 'VILLA COMALTITLÁN', 'efe_key': '07'},
            {'key': '072', 'nombre': 'PUEBLO NUEVO SOLISTAHUACÁN', 'efe_key': '07'},
            {'key': '073', 'nombre': 'RAYÓN', 'efe_key': '07'},
            {'key': '074', 'nombre': 'REFORMA', 'efe_key': '07'},
            {'key': '075', 'nombre': 'LAS ROSAS', 'efe_key': '07'},
            {'key': '076', 'nombre': 'SABANILLA', 'efe_key': '07'},
            {'key': '077', 'nombre': 'SALTO DE AGUA', 'efe_key': '07'},
            {'key': '078', 'nombre': 'SAN CRISTÓBAL DE LAS CASAS', 'efe_key': '07'},
            {'key': '079', 'nombre': 'SAN FERNANDO', 'efe_key': '07'},
            {'key': '080', 'nombre': 'SILTEPEC', 'efe_key': '07'},
            {'key': '081', 'nombre': 'SIMOJOVEL', 'efe_key': '07'},
            {'key': '082', 'nombre': 'SITALÁ', 'efe_key': '07'},
            {'key': '083', 'nombre': 'SOCOLTENANGO', 'efe_key': '07'},
            {'key': '084', 'nombre': 'SOLOSUCHIAPA', 'efe_key': '07'},
            {'key': '085', 'nombre': 'SOYALÓ', 'efe_key': '07'},
            {'key': '086', 'nombre': 'SUCHIAPA', 'efe_key': '07'},
            {'key': '087', 'nombre': 'SUCHIATE', 'efe_key': '07'},
            {'key': '088', 'nombre': 'SUNUAPA', 'efe_key': '07'},
            {'key': '089', 'nombre': 'TAPACHULA', 'efe_key': '07'},
            {'key': '090', 'nombre': 'TAPALAPA', 'efe_key': '07'},
            {'key': '091', 'nombre': 'TAPILULA', 'efe_key': '07'},
            {'key': '092', 'nombre': 'TECPATÁN', 'efe_key': '07'},
            {'key': '093', 'nombre': 'TENEJAPA', 'efe_key': '07'},
            {'key': '094', 'nombre': 'TEOPISCA', 'efe_key': '07'},
            {'key': '096', 'nombre': 'TILA', 'efe_key': '07'},
            {'key': '097', 'nombre': 'TONALÁ', 'efe_key': '07'},
            {'key': '098', 'nombre': 'TOTOLAPA', 'efe_key': '07'},
            {'key': '099', 'nombre': 'LA TRINITARIA', 'efe_key': '07'},
            {'key': '100', 'nombre': 'TUMBALÁ', 'efe_key': '07'},
            {'key': '101', 'nombre': 'TUXTLA GUTIÉRREZ', 'efe_key': '07'},
            {'key': '102', 'nombre': 'TUXTLA CHICO', 'efe_key': '07'},
            {'key': '103', 'nombre': 'TUZANTÁN', 'efe_key': '07'},
            {'key': '104', 'nombre': 'TZIMOL', 'efe_key': '07'},
            {'key': '105', 'nombre': 'UNIÓN JUÁREZ', 'efe_key': '07'},
            {'key': '106', 'nombre': 'VENUSTIANO CARRANZA', 'efe_key': '07'},
            {'key': '107', 'nombre': 'VILLA CORZO', 'efe_key': '07'},
            {'key': '108', 'nombre': 'VILLAFLORES', 'efe_key': '07'},
            {'key': '109', 'nombre': 'YAJALÓN', 'efe_key': '07'},
            {'key': '110', 'nombre': 'SAN LUCAS', 'efe_key': '07'},
            {'key': '111', 'nombre': 'ZINACANTÁN', 'efe_key': '07'},
            {'key': '112', 'nombre': 'SAN JUAN CANCUC', 'efe_key': '07'},
            {'key': '113', 'nombre': 'ALDAMA', 'efe_key': '07'},
            {'key': '114', 'nombre': 'BENEMÉRITO DE LAS AMÉRICAS', 'efe_key': '07'},
            {'key': '115', 'nombre': 'MARAVILLA TENEJAPA', 'efe_key': '07'},
            {'key': '116', 'nombre': 'MARQUÉS DE COMILLAS', 'efe_key': '07'},
            {'key': '117', 'nombre': 'MONTECRISTO DE GUERRERO', 'efe_key': '07'},
            {'key': '118', 'nombre': 'SAN ANDRÉS DURAZNAL', 'efe_key': '07'},
            {'key': '119', 'nombre': 'SANTIAGO EL PINAR', 'efe_key': '07'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '07'},
            {'key': '001', 'nombre': 'AHUMADA', 'efe_key': '08'},
            {'key': '002', 'nombre': 'ALDAMA', 'efe_key': '08'},
            {'key': '003', 'nombre': 'ALLENDE', 'efe_key': '08'},
            {'key': '004', 'nombre': 'AQUILES SERDÁN', 'efe_key': '08'},
            {'key': '005', 'nombre': 'ASCENSIÓN', 'efe_key': '08'},
            {'key': '006', 'nombre': 'BACHÍNIVA', 'efe_key': '08'},
            {'key': '007', 'nombre': 'BALLEZA', 'efe_key': '08'},
            {'key': '008', 'nombre': 'BATOPILAS', 'efe_key': '08'},
            {'key': '009', 'nombre': 'BOCOYNA', 'efe_key': '08'},
            {'key': '010', 'nombre': 'BUENAVENTURA', 'efe_key': '08'},
            {'key': '011', 'nombre': 'CAMARGO', 'efe_key': '08'},
            {'key': '012', 'nombre': 'CARICHÍ', 'efe_key': '08'},
            {'key': '013', 'nombre': 'CASAS GRANDES', 'efe_key': '08'},
            {'key': '014', 'nombre': 'CORONADO', 'efe_key': '08'},
            {'key': '015', 'nombre': 'COYAME DEL SOTOL', 'efe_key': '08'},
            {'key': '016', 'nombre': 'LA CRUZ', 'efe_key': '08'},
            {'key': '017', 'nombre': 'CUAUHTÉMOC', 'efe_key': '08'},
            {'key': '018', 'nombre': 'CUSIHUIRIACHI', 'efe_key': '08'},
            {'key': '019', 'nombre': 'CHIHUAHUA', 'efe_key': '08'},
            {'key': '020', 'nombre': 'CHÍNIPAS', 'efe_key': '08'},
            {'key': '021', 'nombre': 'DELICIAS', 'efe_key': '08'},
            {'key': '022', 'nombre': 'DR. BELISARIO DOMÍNGUEZ', 'efe_key': '08'},
            {'key': '023', 'nombre': 'GALEANA', 'efe_key': '08'},
            {'key': '024', 'nombre': 'SANTA ISABEL', 'efe_key': '08'},
            {'key': '025', 'nombre': 'GÓMEZ FARÍAS', 'efe_key': '08'},
            {'key': '026', 'nombre': 'GRAN MORELOS', 'efe_key': '08'},
            {'key': '027', 'nombre': 'GUACHOCHI', 'efe_key': '08'},
            {'key': '028', 'nombre': 'GUADALUPE', 'efe_key': '08'},
            {'key': '029', 'nombre': 'GUADALUPE Y CALVO', 'efe_key': '08'},
            {'key': '030', 'nombre': 'GUAZAPARES', 'efe_key': '08'},
            {'key': '031', 'nombre': 'GUERRERO', 'efe_key': '08'},
            {'key': '032', 'nombre': 'HIDALGO DEL PARRAL', 'efe_key': '08'},
            {'key': '033', 'nombre': 'HUEJOTITÁN', 'efe_key': '08'},
            {'key': '034', 'nombre': 'IGNACIO ZARAGOZA', 'efe_key': '08'},
            {'key': '035', 'nombre': 'JANOS', 'efe_key': '08'},
            {'key': '036', 'nombre': 'JIMÉNEZ', 'efe_key': '08'},
            {'key': '037', 'nombre': 'JUÁREZ', 'efe_key': '08'},
            {'key': '038', 'nombre': 'JULIMES', 'efe_key': '08'},
            {'key': '039', 'nombre': 'LÓPEZ', 'efe_key': '08'},
            {'key': '040', 'nombre': 'MADERA', 'efe_key': '08'},
            {'key': '041', 'nombre': 'MAGUARICHI', 'efe_key': '08'},
            {'key': '042', 'nombre': 'MANUEL BENAVIDES', 'efe_key': '08'},
            {'key': '043', 'nombre': 'MATACHÍ', 'efe_key': '08'},
            {'key': '044', 'nombre': 'MATAMOROS', 'efe_key': '08'},
            {'key': '045', 'nombre': 'MEOQUI', 'efe_key': '08'},
            {'key': '046', 'nombre': 'MORELOS', 'efe_key': '08'},
            {'key': '047', 'nombre': 'MORIS', 'efe_key': '08'},
            {'key': '048', 'nombre': 'NAMIQUIPA', 'efe_key': '08'},
            {'key': '049', 'nombre': 'NONOAVA', 'efe_key': '08'},
            {'key': '050', 'nombre': 'NUEVO CASAS GRANDES', 'efe_key': '08'},
            {'key': '051', 'nombre': 'OCAMPO', 'efe_key': '08'},
            {'key': '052', 'nombre': 'OJINAGA', 'efe_key': '08'},
            {'key': '053', 'nombre': 'PRAXEDIS G. GUERRERO', 'efe_key': '08'},
            {'key': '054', 'nombre': 'RIVA PALACIO', 'efe_key': '08'},
            {'key': '055', 'nombre': 'ROSALES', 'efe_key': '08'},
            {'key': '056', 'nombre': 'ROSARIO', 'efe_key': '08'},
            {'key': '057', 'nombre': 'SAN FRANCISCO DE BORJA', 'efe_key': '08'},
            {'key': '058', 'nombre': 'SAN FRANCISCO DE CONCHOS', 'efe_key': '08'},
            {'key': '059', 'nombre': 'SAN FRANCISCO DEL ORO', 'efe_key': '08'},
            {'key': '060', 'nombre': 'SANTA BÁRBARA', 'efe_key': '08'},
            {'key': '061', 'nombre': 'SATEVÓ', 'efe_key': '08'},
            {'key': '062', 'nombre': 'SAUCILLO', 'efe_key': '08'},
            {'key': '063', 'nombre': 'TEMÓSACHIC', 'efe_key': '08'},
            {'key': '064', 'nombre': 'EL TULE', 'efe_key': '08'},
            {'key': '065', 'nombre': 'URIQUE', 'efe_key': '08'},
            {'key': '066', 'nombre': 'URUACHI', 'efe_key': '08'},
            {'key': '067', 'nombre': 'VALLE DE ZARAGOZA', 'efe_key': '08'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '08'},
            {'key': '002', 'nombre': 'AZCAPOTZALCO', 'efe_key': '09'},
            {'key': '003', 'nombre': 'COYOACÁN', 'efe_key': '09'},
            {'key': '004', 'nombre': 'CUAJIMALPA DE MORELOS', 'efe_key': '09'},
            {'key': '005', 'nombre': 'GUSTAVO A. MADERO', 'efe_key': '09'},
            {'key': '006', 'nombre': 'IZTACALCO', 'efe_key': '09'},
            {'key': '007', 'nombre': 'IZTAPALAPA', 'efe_key': '09'},
            {'key': '008', 'nombre': 'LA MAGDALENA CONTRERAS', 'efe_key': '09'},
            {'key': '009', 'nombre': 'MILPA ALTA', 'efe_key': '09'},
            {'key': '010', 'nombre': 'ÁLVARO OBREGÓN', 'efe_key': '09'},
            {'key': '011', 'nombre': 'TLÁHUAC', 'efe_key': '09'},
            {'key': '012', 'nombre': 'TLALPAN', 'efe_key': '09'},
            {'key': '013', 'nombre': 'XOCHIMILCO', 'efe_key': '09'},
            {'key': '014', 'nombre': 'BENITO JUÁREZ', 'efe_key': '09'},
            {'key': '015', 'nombre': 'CUAUHTÉMOC', 'efe_key': '09'},
            {'key': '016', 'nombre': 'MIGUEL HIDALGO', 'efe_key': '09'},
            {'key': '017', 'nombre': 'VENUSTIANO CARRANZA', 'efe_key': '09'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '09'},
            {'key': '001', 'nombre': 'CANATLÁN', 'efe_key': '10'},
            {'key': '002', 'nombre': 'CANELAS', 'efe_key': '10'},
            {'key': '003', 'nombre': 'CONETO DE COMONFORT', 'efe_key': '10'},
            {'key': '004', 'nombre': 'CUENCAMÉ', 'efe_key': '10'},
            {'key': '005', 'nombre': 'DURANGO', 'efe_key': '10'},
            {'key': '006', 'nombre': 'GENERAL SIMÓN BOLÍVAR', 'efe_key': '10'},
            {'key': '007', 'nombre': 'GÓMEZ PALACIO', 'efe_key': '10'},
            {'key': '008', 'nombre': 'GUADALUPE VICTORIA', 'efe_key': '10'},
            {'key': '009', 'nombre': 'GUANACEVÍ', 'efe_key': '10'},
            {'key': '010', 'nombre': 'HIDALGO', 'efe_key': '10'},
            {'key': '011', 'nombre': 'INDÉ', 'efe_key': '10'},
            {'key': '012', 'nombre': 'LERDO', 'efe_key': '10'},
            {'key': '013', 'nombre': 'MAPIMÍ', 'efe_key': '10'},
            {'key': '014', 'nombre': 'MEZQUITAL', 'efe_key': '10'},
            {'key': '015', 'nombre': 'NAZAS', 'efe_key': '10'},
            {'key': '016', 'nombre': 'NOMBRE DE DIOS', 'efe_key': '10'},
            {'key': '017', 'nombre': 'OCAMPO', 'efe_key': '10'},
            {'key': '018', 'nombre': 'EL ORO', 'efe_key': '10'},
            {'key': '019', 'nombre': 'OTÁEZ', 'efe_key': '10'},
            {'key': '020', 'nombre': 'PÁNUCO DE CORONADO', 'efe_key': '10'},
            {'key': '021', 'nombre': 'PEÑÓN BLANCO', 'efe_key': '10'},
            {'key': '022', 'nombre': 'POANAS', 'efe_key': '10'},
            {'key': '023', 'nombre': 'PUEBLO NUEVO', 'efe_key': '10'},
            {'key': '024', 'nombre': 'RODEO', 'efe_key': '10'},
            {'key': '025', 'nombre': 'SAN BERNARDO', 'efe_key': '10'},
            {'key': '026', 'nombre': 'SAN DIMAS', 'efe_key': '10'},
            {'key': '027', 'nombre': 'SAN JUAN DE GUADALUPE', 'efe_key': '10'},
            {'key': '028', 'nombre': 'SAN JUAN DEL RÍO', 'efe_key': '10'},
            {'key': '029', 'nombre': 'SAN LUIS DEL CORDERO', 'efe_key': '10'},
            {'key': '030', 'nombre': 'SAN PEDRO DEL GALLO', 'efe_key': '10'},
            {'key': '031', 'nombre': 'SANTA CLARA', 'efe_key': '10'},
            {'key': '032', 'nombre': 'SANTIAGO PAPASQUIARO', 'efe_key': '10'},
            {'key': '033', 'nombre': 'SÚCHIL', 'efe_key': '10'},
            {'key': '034', 'nombre': 'TAMAZULA', 'efe_key': '10'},
            {'key': '035', 'nombre': 'TEPEHUANES', 'efe_key': '10'},
            {'key': '036', 'nombre': 'TLAHUALILO', 'efe_key': '10'},
            {'key': '037', 'nombre': 'TOPIA', 'efe_key': '10'},
            {'key': '038', 'nombre': 'VICENTE GUERRERO', 'efe_key': '10'},
            {'key': '039', 'nombre': 'NUEVO IDEAL', 'efe_key': '10'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '10'},
            {'key': '001', 'nombre': 'ABASOLO', 'efe_key': '11'},
            {'key': '002', 'nombre': 'ACÁMBARO', 'efe_key': '11'},
            {'key': '003', 'nombre': 'SAN MIGUEL DE ALLENDE', 'efe_key': '11'},
            {'key': '004', 'nombre': 'APASEO EL ALTO', 'efe_key': '11'},
            {'key': '005', 'nombre': 'APASEO EL GRANDE', 'efe_key': '11'},
            {'key': '006', 'nombre': 'ATARJEA', 'efe_key': '11'},
            {'key': '007', 'nombre': 'CELAYA', 'efe_key': '11'},
            {'key': '008', 'nombre': 'MANUEL DOBLADO', 'efe_key': '11'},
            {'key': '009', 'nombre': 'COMONFORT', 'efe_key': '11'},
            {'key': '010', 'nombre': 'CORONEO', 'efe_key': '11'},
            {'key': '011', 'nombre': 'CORTAZAR', 'efe_key': '11'},
            {'key': '012', 'nombre': 'CUERÁMARO', 'efe_key': '11'},
            {'key': '013', 'nombre': 'DOCTOR MORA', 'efe_key': '11'},
            {'key': '014', 'nombre': 'DOLORES HIDALGO CUNA DE LA INDEPENDENCIA NACIONAL', 'efe_key': '11'},
            {'key': '015', 'nombre': 'GUANAJUATO', 'efe_key': '11'},
            {'key': '016', 'nombre': 'HUANÍMARO', 'efe_key': '11'},
            {'key': '017', 'nombre': 'IRAPUATO', 'efe_key': '11'},
            {'key': '018', 'nombre': 'JARAL DEL PROGRESO', 'efe_key': '11'},
            {'key': '019', 'nombre': 'JERÉCUARO', 'efe_key': '11'},
            {'key': '020', 'nombre': 'LEÓN', 'efe_key': '11'},
            {'key': '021', 'nombre': 'MOROLEÓN', 'efe_key': '11'},
            {'key': '022', 'nombre': 'OCAMPO', 'efe_key': '11'},
            {'key': '023', 'nombre': 'PÉNJAMO', 'efe_key': '11'},
            {'key': '024', 'nombre': 'PUEBLO NUEVO', 'efe_key': '11'},
            {'key': '025', 'nombre': 'PURÍSIMA DEL RINCÓN', 'efe_key': '11'},
            {'key': '026', 'nombre': 'ROMITA', 'efe_key': '11'},
            {'key': '027', 'nombre': 'SALAMANCA', 'efe_key': '11'},
            {'key': '028', 'nombre': 'SALVATIERRA', 'efe_key': '11'},
            {'key': '029', 'nombre': 'SAN DIEGO DE LA UNIÓN', 'efe_key': '11'},
            {'key': '030', 'nombre': 'SAN FELIPE', 'efe_key': '11'},
            {'key': '031', 'nombre': 'SAN FRANCISCO DEL RINCÓN', 'efe_key': '11'},
            {'key': '032', 'nombre': 'SAN JOSÉ ITURBIDE', 'efe_key': '11'},
            {'key': '033', 'nombre': 'SAN LUIS DE LA PAZ', 'efe_key': '11'},
            {'key': '034', 'nombre': 'SANTA CATARINA', 'efe_key': '11'},
            {'key': '035', 'nombre': 'SANTA CRUZ DE JUVENTINO ROSAS', 'efe_key': '11'},
            {'key': '036', 'nombre': 'SANTIAGO MARAVATÍO', 'efe_key': '11'},
            {'key': '037', 'nombre': 'SILAO DE LA VICTORIA', 'efe_key': '11'},
            {'key': '038', 'nombre': 'TARANDACUAO', 'efe_key': '11'},
            {'key': '039', 'nombre': 'TARIMORO', 'efe_key': '11'},
            {'key': '040', 'nombre': 'TIERRA BLANCA', 'efe_key': '11'},
            {'key': '041', 'nombre': 'URIANGATO', 'efe_key': '11'},
            {'key': '042', 'nombre': 'VALLE DE SANTIAGO', 'efe_key': '11'},
            {'key': '043', 'nombre': 'VICTORIA', 'efe_key': '11'},
            {'key': '044', 'nombre': 'VILLAGRÁN', 'efe_key': '11'},
            {'key': '045', 'nombre': 'XICHÚ', 'efe_key': '11'},
            {'key': '046', 'nombre': 'YURIRIA', 'efe_key': '11'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '11'},
            {'key': '001', 'nombre': 'ACAPULCO DE JUÁREZ', 'efe_key': '12'},
            {'key': '002', 'nombre': 'AHUACUOTZINGO', 'efe_key': '12'},
            {'key': '003', 'nombre': 'AJUCHITLÁN DEL PROGRESO', 'efe_key': '12'},
            {'key': '004', 'nombre': 'ALCOZAUCA DE GUERRERO', 'efe_key': '12'},
            {'key': '005', 'nombre': 'ALPOYECA', 'efe_key': '12'},
            {'key': '006', 'nombre': 'APAXTLA', 'efe_key': '12'},
            {'key': '007', 'nombre': 'ARCELIA', 'efe_key': '12'},
            {'key': '008', 'nombre': 'ATENANGO DEL RÍO', 'efe_key': '12'},
            {'key': '009', 'nombre': 'ATLAMAJALCINGO DEL MONTE', 'efe_key': '12'},
            {'key': '010', 'nombre': 'ATLIXTAC', 'efe_key': '12'},
            {'key': '011', 'nombre': 'ATOYAC DE ÁLVAREZ', 'efe_key': '12'},
            {'key': '012', 'nombre': 'AYUTLA DE LOS LIBRES', 'efe_key': '12'},
            {'key': '013', 'nombre': 'AZOYÚ', 'efe_key': '12'},
            {'key': '014', 'nombre': 'BENITO JUÁREZ', 'efe_key': '12'},
            {'key': '015', 'nombre': 'BUENAVISTA DE CUÉLLAR', 'efe_key': '12'},
            {'key': '016', 'nombre': 'COAHUAYUTLA DE JOSÉ MARÍA IZAZAGA', 'efe_key': '12'},
            {'key': '017', 'nombre': 'COCULA', 'efe_key': '12'},
            {'key': '018', 'nombre': 'COPALA', 'efe_key': '12'},
            {'key': '019', 'nombre': 'COPALILLO', 'efe_key': '12'},
            {'key': '020', 'nombre': 'COPANATOYAC', 'efe_key': '12'},
            {'key': '021', 'nombre': 'COYUCA DE BENÍTEZ', 'efe_key': '12'},
            {'key': '022', 'nombre': 'COYUCA DE CATALÁN', 'efe_key': '12'},
            {'key': '023', 'nombre': 'CUAJINICUILAPA', 'efe_key': '12'},
            {'key': '024', 'nombre': 'CUALÁC', 'efe_key': '12'},
            {'key': '025', 'nombre': 'CUAUTEPEC', 'efe_key': '12'},
            {'key': '026', 'nombre': 'CUETZALA DEL PROGRESO', 'efe_key': '12'},
            {'key': '027', 'nombre': 'CUTZAMALA DE PINZÓN', 'efe_key': '12'},
            {'key': '028', 'nombre': 'CHILAPA DE ÁLVAREZ', 'efe_key': '12'},
            {'key': '029', 'nombre': 'CHILPANCINGO DE LOS BRAVO', 'efe_key': '12'},
            {'key': '030', 'nombre': 'FLORENCIO VILLARREAL', 'efe_key': '12'},
            {'key': '031', 'nombre': 'GENERAL CANUTO A. NERI', 'efe_key': '12'},
            {'key': '032', 'nombre': 'GENERAL HELIODORO CASTILLO', 'efe_key': '12'},
            {'key': '033', 'nombre': 'HUAMUXTITLÁN', 'efe_key': '12'},
            {'key': '034', 'nombre': 'HUITZUCO DE LOS FIGUEROA', 'efe_key': '12'},
            {'key': '035', 'nombre': 'IGUALA DE LA INDEPENDENCIA', 'efe_key': '12'},
            {'key': '036', 'nombre': 'IGUALAPA', 'efe_key': '12'},
            {'key': '037', 'nombre': 'IXCATEOPAN DE CUAUHTÉMOC', 'efe_key': '12'},
            {'key': '038', 'nombre': 'ZIHUATANEJO DE AZUETA', 'efe_key': '12'},
            {'key': '039', 'nombre': 'JUAN R. ESCUDERO', 'efe_key': '12'},
            {'key': '040', 'nombre': 'LEONARDO BRAVO', 'efe_key': '12'},
            {'key': '041', 'nombre': 'MALINALTEPEC', 'efe_key': '12'},
            {'key': '042', 'nombre': 'MÁRTIR DE CUILAPAN', 'efe_key': '12'},
            {'key': '043', 'nombre': 'METLATÓNOC', 'efe_key': '12'},
            {'key': '044', 'nombre': 'MOCHITLÁN', 'efe_key': '12'},
            {'key': '045', 'nombre': 'OLINALÁ', 'efe_key': '12'},
            {'key': '046', 'nombre': 'OMETEPEC', 'efe_key': '12'},
            {'key': '047', 'nombre': 'PEDRO ASCENCIO ALQUISIRAS', 'efe_key': '12'},
            {'key': '048', 'nombre': 'PETATLÁN', 'efe_key': '12'},
            {'key': '049', 'nombre': 'PILCAYA', 'efe_key': '12'},
            {'key': '050', 'nombre': 'PUNGARABATO', 'efe_key': '12'},
            {'key': '051', 'nombre': 'QUECHULTENANGO', 'efe_key': '12'},
            {'key': '052', 'nombre': 'SAN LUIS ACATLÁN', 'efe_key': '12'},
            {'key': '053', 'nombre': 'SAN MARCOS', 'efe_key': '12'},
            {'key': '054', 'nombre': 'SAN MIGUEL TOTOLAPAN', 'efe_key': '12'},
            {'key': '055', 'nombre': 'TAXCO DE ALARCÓN', 'efe_key': '12'},
            {'key': '056', 'nombre': 'TECOANAPA', 'efe_key': '12'},
            {'key': '057', 'nombre': 'TÉCPAN DE GALEANA', 'efe_key': '12'},
            {'key': '058', 'nombre': 'TELOLOAPAN', 'efe_key': '12'},
            {'key': '059', 'nombre': 'TEPECOACUILCO DE TRUJANO', 'efe_key': '12'},
            {'key': '060', 'nombre': 'TETIPAC', 'efe_key': '12'},
            {'key': '061', 'nombre': 'TIXTLA DE GUERRERO', 'efe_key': '12'},
            {'key': '062', 'nombre': 'TLACOACHISTLAHUACA', 'efe_key': '12'},
            {'key': '063', 'nombre': 'TLACOAPA', 'efe_key': '12'},
            {'key': '064', 'nombre': 'TLALCHAPA', 'efe_key': '12'},
            {'key': '065', 'nombre': 'TLALIXTAQUILLA DE MALDONADO', 'efe_key': '12'},
            {'key': '066', 'nombre': 'TLAPA DE COMONFORT', 'efe_key': '12'},
            {'key': '067', 'nombre': 'TLAPEHUALA', 'efe_key': '12'},
            {'key': '068', 'nombre': 'LA UNIÓN DE ISIDORO MONTES DE OCA', 'efe_key': '12'},
            {'key': '069', 'nombre': 'XALPATLÁHUAC', 'efe_key': '12'},
            {'key': '070', 'nombre': 'XOCHIHUEHUETLÁN', 'efe_key': '12'},
            {'key': '071', 'nombre': 'XOCHISTLAHUACA', 'efe_key': '12'},
            {'key': '072', 'nombre': 'ZAPOTITLÁN TABLAS', 'efe_key': '12'},
            {'key': '073', 'nombre': 'ZIRÁNDARO', 'efe_key': '12'},
            {'key': '074', 'nombre': 'ZITLALA', 'efe_key': '12'},
            {'key': '075', 'nombre': 'EDUARDO NERI', 'efe_key': '12'},
            {'key': '076', 'nombre': 'ACATEPEC', 'efe_key': '12'},
            {'key': '077', 'nombre': 'MARQUELIA', 'efe_key': '12'},
            {'key': '078', 'nombre': 'COCHOAPA EL GRANDE', 'efe_key': '12'},
            {'key': '079', 'nombre': 'JOSÉ JOAQUÍN DE HERRERA', 'efe_key': '12'},
            {'key': '080', 'nombre': 'JUCHITÁN', 'efe_key': '12'},
            {'key': '081', 'nombre': 'ILIATENCO', 'efe_key': '12'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '12'},
            {'key': '001', 'nombre': 'ACATLÁN', 'efe_key': '13'},
            {'key': '002', 'nombre': 'ACAXOCHITLÁN', 'efe_key': '13'},
            {'key': '003', 'nombre': 'ACTOPAN', 'efe_key': '13'},
            {'key': '004', 'nombre': 'AGUA BLANCA DE ITURBIDE', 'efe_key': '13'},
            {'key': '005', 'nombre': 'AJACUBA', 'efe_key': '13'},
            {'key': '006', 'nombre': 'ALFAJAYUCAN', 'efe_key': '13'},
            {'key': '007', 'nombre': 'ALMOLOYA', 'efe_key': '13'},
            {'key': '008', 'nombre': 'APAN', 'efe_key': '13'},
            {'key': '009', 'nombre': 'EL ARENAL', 'efe_key': '13'},
            {'key': '010', 'nombre': 'ATITALAQUIA', 'efe_key': '13'},
            {'key': '011', 'nombre': 'ATLAPEXCO', 'efe_key': '13'},
            {'key': '012', 'nombre': 'ATOTONILCO EL GRANDE', 'efe_key': '13'},
            {'key': '013', 'nombre': 'ATOTONILCO DE TULA', 'efe_key': '13'},
            {'key': '014', 'nombre': 'CALNALI', 'efe_key': '13'},
            {'key': '015', 'nombre': 'CARDONAL', 'efe_key': '13'},
            {'key': '016', 'nombre': 'CUAUTEPEC DE HINOJOSA', 'efe_key': '13'},
            {'key': '017', 'nombre': 'CHAPANTONGO', 'efe_key': '13'},
            {'key': '018', 'nombre': 'CHAPULHUACÁN', 'efe_key': '13'},
            {'key': '019', 'nombre': 'CHILCUAUTLA', 'efe_key': '13'},
            {'key': '020', 'nombre': 'ELOXOCHITLÁN', 'efe_key': '13'},
            {'key': '021', 'nombre': 'EMILIANO ZAPATA', 'efe_key': '13'},
            {'key': '022', 'nombre': 'EPAZOYUCAN', 'efe_key': '13'},
            {'key': '023', 'nombre': 'FRANCISCO I. MADERO', 'efe_key': '13'},
            {'key': '024', 'nombre': 'HUASCA DE OCAMPO', 'efe_key': '13'},
            {'key': '025', 'nombre': 'HUAUTLA', 'efe_key': '13'},
            {'key': '026', 'nombre': 'HUAZALINGO', 'efe_key': '13'},
            {'key': '027', 'nombre': 'HUEHUETLA', 'efe_key': '13'},
            {'key': '028', 'nombre': 'HUEJUTLA DE REYES', 'efe_key': '13'},
            {'key': '029', 'nombre': 'HUICHAPAN', 'efe_key': '13'},
            {'key': '030', 'nombre': 'IXMIQUILPAN', 'efe_key': '13'},
            {'key': '031', 'nombre': 'JACALA DE LEDEZMA', 'efe_key': '13'},
            {'key': '032', 'nombre': 'JALTOCÁN', 'efe_key': '13'},
            {'key': '033', 'nombre': 'JUÁREZ HIDALGO', 'efe_key': '13'},
            {'key': '034', 'nombre': 'LOLOTLA', 'efe_key': '13'},
            {'key': '035', 'nombre': 'METEPEC', 'efe_key': '13'},
            {'key': '036', 'nombre': 'SAN AGUSTÍN METZQUITITLÁN', 'efe_key': '13'},
            {'key': '037', 'nombre': 'METZTITLÁN', 'efe_key': '13'},
            {'key': '038', 'nombre': 'MINERAL DEL CHICO', 'efe_key': '13'},
            {'key': '039', 'nombre': 'MINERAL DEL MONTE', 'efe_key': '13'},
            {'key': '040', 'nombre': 'LA MISIÓN', 'efe_key': '13'},
            {'key': '041', 'nombre': 'MIXQUIAHUALA DE JUÁREZ', 'efe_key': '13'},
            {'key': '042', 'nombre': 'MOLANGO DE ESCAMILLA', 'efe_key': '13'},
            {'key': '043', 'nombre': 'NICOLÁS FLORES', 'efe_key': '13'},
            {'key': '044', 'nombre': 'NOPALA DE VILLAGRÁN', 'efe_key': '13'},
            {'key': '045', 'nombre': 'OMITLÁN DE JUÁREZ', 'efe_key': '13'},
            {'key': '046', 'nombre': 'SAN FELIPE ORIZATLÁN', 'efe_key': '13'},
            {'key': '047', 'nombre': 'PACULA', 'efe_key': '13'},
            {'key': '048', 'nombre': 'PACHUCA DE SOTO', 'efe_key': '13'},
            {'key': '049', 'nombre': 'PISAFLORES', 'efe_key': '13'},
            {'key': '050', 'nombre': 'PROGRESO DE OBREGÓN', 'efe_key': '13'},
            {'key': '051', 'nombre': 'MINERAL DE LA REFORMA', 'efe_key': '13'},
            {'key': '052', 'nombre': 'SAN AGUSTÍN TLAXIACA', 'efe_key': '13'},
            {'key': '053', 'nombre': 'SAN BARTOLO TUTOTEPEC', 'efe_key': '13'},
            {'key': '054', 'nombre': 'SAN SALVADOR', 'efe_key': '13'},
            {'key': '055', 'nombre': 'SANTIAGO DE ANAYA', 'efe_key': '13'},
            {'key': '056', 'nombre': 'SANTIAGO TULANTEPEC DE LUGO GUERRERO', 'efe_key': '13'},
            {'key': '057', 'nombre': 'SINGUILUCAN', 'efe_key': '13'},
            {'key': '058', 'nombre': 'TASQUILLO', 'efe_key': '13'},
            {'key': '059', 'nombre': 'TECOZAUTLA', 'efe_key': '13'},
            {'key': '060', 'nombre': 'TENANGO DE DORIA', 'efe_key': '13'},
            {'key': '061', 'nombre': 'TEPEAPULCO', 'efe_key': '13'},
            {'key': '062', 'nombre': 'TEPEHUACÁN DE GUERRERO', 'efe_key': '13'},
            {'key': '063', 'nombre': 'TEPEJI DEL RÍO DE OCAMPO', 'efe_key': '13'},
            {'key': '064', 'nombre': 'TEPETITLÁN', 'efe_key': '13'},
            {'key': '065', 'nombre': 'TETEPANGO', 'efe_key': '13'},
            {'key': '066', 'nombre': 'VILLA DE TEZONTEPEC', 'efe_key': '13'},
            {'key': '067', 'nombre': 'TEZONTEPEC DE ALDAMA', 'efe_key': '13'},
            {'key': '068', 'nombre': 'TIANGUISTENGO', 'efe_key': '13'},
            {'key': '069', 'nombre': 'TIZAYUCA', 'efe_key': '13'},
            {'key': '070', 'nombre': 'TLAHUELILPAN', 'efe_key': '13'},
            {'key': '071', 'nombre': 'TLAHUILTEPA', 'efe_key': '13'},
            {'key': '072', 'nombre': 'TLANALAPA', 'efe_key': '13'},
            {'key': '073', 'nombre': 'TLANCHINOL', 'efe_key': '13'},
            {'key': '074', 'nombre': 'TLAXCOAPAN', 'efe_key': '13'},
            {'key': '075', 'nombre': 'TOLCAYUCA', 'efe_key': '13'},
            {'key': '076', 'nombre': 'TULA DE ALLENDE', 'efe_key': '13'},
            {'key': '077', 'nombre': 'TULANCINGO DE BRAVO', 'efe_key': '13'},
            {'key': '078', 'nombre': 'XOCHIATIPAN', 'efe_key': '13'},
            {'key': '079', 'nombre': 'XOCHICOATLÁN', 'efe_key': '13'},
            {'key': '080', 'nombre': 'YAHUALICA', 'efe_key': '13'},
            {'key': '081', 'nombre': 'ZACUALTIPÁN DE ÁNGELES', 'efe_key': '13'},
            {'key': '082', 'nombre': 'ZAPOTLÁN DE JUÁREZ', 'efe_key': '13'},
            {'key': '083', 'nombre': 'ZEMPOALA', 'efe_key': '13'},
            {'key': '084', 'nombre': 'ZIMAPÁN', 'efe_key': '13'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '13'},
            {'key': '001', 'nombre': 'ACATIC', 'efe_key': '14'},
            {'key': '002', 'nombre': 'ACATLÁN DE JUÁREZ', 'efe_key': '14'},
            {'key': '003', 'nombre': 'AHUALULCO DE MERCADO', 'efe_key': '14'},
            {'key': '004', 'nombre': 'AMACUECA', 'efe_key': '14'},
            {'key': '005', 'nombre': 'AMATITÁN', 'efe_key': '14'},
            {'key': '006', 'nombre': 'AMECA', 'efe_key': '14'},
            {'key': '007', 'nombre': 'SAN JUANITO DE ESCOBEDO', 'efe_key': '14'},
            {'key': '008', 'nombre': 'ARANDAS', 'efe_key': '14'},
            {'key': '009', 'nombre': 'EL ARENAL', 'efe_key': '14'},
            {'key': '010', 'nombre': 'ATEMAJAC DE BRIZUELA', 'efe_key': '14'},
            {'key': '011', 'nombre': 'ATENGO', 'efe_key': '14'},
            {'key': '012', 'nombre': 'ATENGUILLO', 'efe_key': '14'},
            {'key': '013', 'nombre': 'ATOTONILCO EL ALTO', 'efe_key': '14'},
            {'key': '014', 'nombre': 'ATOYAC', 'efe_key': '14'},
            {'key': '015', 'nombre': 'AUTLÁN DE NAVARRO', 'efe_key': '14'},
            {'key': '016', 'nombre': 'AYOTLÁN', 'efe_key': '14'},
            {'key': '017', 'nombre': 'AYUTLA', 'efe_key': '14'},
            {'key': '018', 'nombre': 'LA BARCA', 'efe_key': '14'},
            {'key': '019', 'nombre': 'BOLAÑOS', 'efe_key': '14'},
            {'key': '020', 'nombre': 'CABO CORRIENTES', 'efe_key': '14'},
            {'key': '021', 'nombre': 'CASIMIRO CASTILLO', 'efe_key': '14'},
            {'key': '022', 'nombre': 'CIHUATLÁN', 'efe_key': '14'},
            {'key': '023', 'nombre': 'ZAPOTLÁN EL GRANDE', 'efe_key': '14'},
            {'key': '024', 'nombre': 'COCULA', 'efe_key': '14'},
            {'key': '025', 'nombre': 'COLOTLÁN', 'efe_key': '14'},
            {'key': '026', 'nombre': 'CONCEPCIÓN DE BUENOS AIRES', 'efe_key': '14'},
            {'key': '027', 'nombre': 'CUAUTITLÁN DE GARCÍA BARRAGÁN', 'efe_key': '14'},
            {'key': '028', 'nombre': 'CUAUTLA', 'efe_key': '14'},
            {'key': '029', 'nombre': 'CUQUÍO', 'efe_key': '14'},
            {'key': '030', 'nombre': 'CHAPALA', 'efe_key': '14'},
            {'key': '031', 'nombre': 'CHIMALTITÁN', 'efe_key': '14'},
            {'key': '032', 'nombre': 'CHIQUILISTLÁN', 'efe_key': '14'},
            {'key': '033', 'nombre': 'DEGOLLADO', 'efe_key': '14'},
            {'key': '034', 'nombre': 'EJUTLA', 'efe_key': '14'},
            {'key': '035', 'nombre': 'ENCARNACIÓN DE DÍAZ', 'efe_key': '14'},
            {'key': '036', 'nombre': 'ETZATLÁN', 'efe_key': '14'},
            {'key': '037', 'nombre': 'EL GRULLO', 'efe_key': '14'},
            {'key': '038', 'nombre': 'GUACHINANGO', 'efe_key': '14'},
            {'key': '039', 'nombre': 'GUADALAJARA', 'efe_key': '14'},
            {'key': '040', 'nombre': 'HOSTOTIPAQUILLO', 'efe_key': '14'},
            {'key': '041', 'nombre': 'HUEJÚCAR', 'efe_key': '14'},
            {'key': '042', 'nombre': 'HUEJUQUILLA EL ALTO', 'efe_key': '14'},
            {'key': '043', 'nombre': 'LA HUERTA', 'efe_key': '14'},
            {'key': '044', 'nombre': 'IXTLAHUACÁN DE LOS MEMBRILLOS', 'efe_key': '14'},
            {'key': '045', 'nombre': 'IXTLAHUACÁN DEL RÍO', 'efe_key': '14'},
            {'key': '046', 'nombre': 'JALOSTOTITLÁN', 'efe_key': '14'},
            {'key': '047', 'nombre': 'JAMAY', 'efe_key': '14'},
            {'key': '048', 'nombre': 'JESÚS MARÍA', 'efe_key': '14'},
            {'key': '049', 'nombre': 'JILOTLÁN DE LOS DOLORES', 'efe_key': '14'},
            {'key': '050', 'nombre': 'JOCOTEPEC', 'efe_key': '14'},
            {'key': '051', 'nombre': 'JUANACATLÁN', 'efe_key': '14'},
            {'key': '052', 'nombre': 'JUCHITLÁN', 'efe_key': '14'},
            {'key': '053', 'nombre': 'LAGOS DE MORENO', 'efe_key': '14'},
            {'key': '054', 'nombre': 'EL LIMÓN', 'efe_key': '14'},
            {'key': '055', 'nombre': 'MAGDALENA', 'efe_key': '14'},
            {'key': '056', 'nombre': 'SANTA MARÍA DEL ORO', 'efe_key': '14'},
            {'key': '057', 'nombre': 'LA MANZANILLA DE LA PAZ', 'efe_key': '14'},
            {'key': '058', 'nombre': 'MASCOTA', 'efe_key': '14'},
            {'key': '059', 'nombre': 'MAZAMITLA', 'efe_key': '14'},
            {'key': '060', 'nombre': 'MEXTICACÁN', 'efe_key': '14'},
            {'key': '061', 'nombre': 'MEZQUITIC', 'efe_key': '14'},
            {'key': '062', 'nombre': 'MIXTLÁN', 'efe_key': '14'},
            {'key': '063', 'nombre': 'OCOTLÁN', 'efe_key': '14'},
            {'key': '064', 'nombre': 'OJUELOS DE JALISCO', 'efe_key': '14'},
            {'key': '065', 'nombre': 'PIHUAMO', 'efe_key': '14'},
            {'key': '066', 'nombre': 'PONCITLÁN', 'efe_key': '14'},
            {'key': '067', 'nombre': 'PUERTO VALLARTA', 'efe_key': '14'},
            {'key': '068', 'nombre': 'VILLA PURIFICACIÓN', 'efe_key': '14'},
            {'key': '069', 'nombre': 'QUITUPAN', 'efe_key': '14'},
            {'key': '070', 'nombre': 'EL SALTO', 'efe_key': '14'},
            {'key': '071', 'nombre': 'SAN CRISTÓBAL DE LA BARRANCA', 'efe_key': '14'},
            {'key': '072', 'nombre': 'SAN DIEGO DE ALEJANDRÍA', 'efe_key': '14'},
            {'key': '073', 'nombre': 'SAN JUAN DE LOS LAGOS', 'efe_key': '14'},
            {'key': '074', 'nombre': 'SAN JULIÁN', 'efe_key': '14'},
            {'key': '075', 'nombre': 'SAN MARCOS', 'efe_key': '14'},
            {'key': '076', 'nombre': 'SAN MARTÍN DE BOLAÑOS', 'efe_key': '14'},
            {'key': '077', 'nombre': 'SAN MARTÍN HIDALGO', 'efe_key': '14'},
            {'key': '078', 'nombre': 'SAN MIGUEL EL ALTO', 'efe_key': '14'},
            {'key': '079', 'nombre': 'GÓMEZ FARÍAS', 'efe_key': '14'},
            {'key': '080', 'nombre': 'SAN SEBASTIÁN DEL OESTE', 'efe_key': '14'},
            {'key': '081', 'nombre': 'SANTA MARÍA DE LOS ÁNGELES', 'efe_key': '14'},
            {'key': '082', 'nombre': 'SAYULA', 'efe_key': '14'},
            {'key': '083', 'nombre': 'TALA', 'efe_key': '14'},
            {'key': '084', 'nombre': 'TALPA DE ALLENDE', 'efe_key': '14'},
            {'key': '085', 'nombre': 'TAMAZULA DE GORDIANO', 'efe_key': '14'},
            {'key': '086', 'nombre': 'TAPALPA', 'efe_key': '14'},
            {'key': '087', 'nombre': 'TECALITLÁN', 'efe_key': '14'},
            {'key': '088', 'nombre': 'TECOLOTLÁN', 'efe_key': '14'},
            {'key': '089', 'nombre': 'TECHALUTA DE MONTENEGRO', 'efe_key': '14'},
            {'key': '090', 'nombre': 'TENAMAXTLÁN', 'efe_key': '14'},
            {'key': '091', 'nombre': 'TEOCALTICHE', 'efe_key': '14'},
            {'key': '092', 'nombre': 'TEOCUITATLÁN DE CORONA', 'efe_key': '14'},
            {'key': '093', 'nombre': 'TEPATITLÁN DE MORELOS', 'efe_key': '14'},
            {'key': '094', 'nombre': 'TEQUILA', 'efe_key': '14'},
            {'key': '095', 'nombre': 'TEUCHITLÁN', 'efe_key': '14'},
            {'key': '096', 'nombre': 'TIZAPÁN EL ALTO', 'efe_key': '14'},
            {'key': '097', 'nombre': 'TLAJOMULCO DE ZÚÑIGA', 'efe_key': '14'},
            {'key': '098', 'nombre': 'SAN PEDRO TLAQUEPAQUE', 'efe_key': '14'},
            {'key': '099', 'nombre': 'TOLIMÁN', 'efe_key': '14'},
            {'key': '100', 'nombre': 'TOMATLÁN', 'efe_key': '14'},
            {'key': '101', 'nombre': 'TONALÁ', 'efe_key': '14'},
            {'key': '102', 'nombre': 'TONAYA', 'efe_key': '14'},
            {'key': '103', 'nombre': 'TONILA', 'efe_key': '14'},
            {'key': '104', 'nombre': 'TOTATICHE', 'efe_key': '14'},
            {'key': '105', 'nombre': 'TOTOTLÁN', 'efe_key': '14'},
            {'key': '106', 'nombre': 'TUXCACUESCO', 'efe_key': '14'},
            {'key': '107', 'nombre': 'TUXCUECA', 'efe_key': '14'},
            {'key': '108', 'nombre': 'TUXPAN', 'efe_key': '14'},
            {'key': '109', 'nombre': 'UNIÓN DE SAN ANTONIO', 'efe_key': '14'},
            {'key': '110', 'nombre': 'UNIÓN DE TULA', 'efe_key': '14'},
            {'key': '111', 'nombre': 'VALLE DE GUADALUPE', 'efe_key': '14'},
            {'key': '112', 'nombre': 'VALLE DE JUÁREZ', 'efe_key': '14'},
            {'key': '113', 'nombre': 'SAN GABRIEL', 'efe_key': '14'},
            {'key': '114', 'nombre': 'VILLA CORONA', 'efe_key': '14'},
            {'key': '115', 'nombre': 'VILLA GUERRERO', 'efe_key': '14'},
            {'key': '116', 'nombre': 'VILLA HIDALGO', 'efe_key': '14'},
            {'key': '117', 'nombre': 'CAÑADAS DE OBREGÓN', 'efe_key': '14'},
            {'key': '118', 'nombre': 'YAHUALICA DE GONZÁLEZ GALLO', 'efe_key': '14'},
            {'key': '119', 'nombre': 'ZACOALCO DE TORRES', 'efe_key': '14'},
            {'key': '120', 'nombre': 'ZAPOPAN', 'efe_key': '14'},
            {'key': '121', 'nombre': 'ZAPOTILTIC', 'efe_key': '14'},
            {'key': '122', 'nombre': 'ZAPOTITLÁN DE VADILLO', 'efe_key': '14'},
            {'key': '123', 'nombre': 'ZAPOTLÁN DEL REY', 'efe_key': '14'},
            {'key': '124', 'nombre': 'ZAPOTLANEJO', 'efe_key': '14'},
            {'key': '125', 'nombre': 'SAN IGNACIO CERRO GORDO', 'efe_key': '14'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '14'},
            {'key': '001', 'nombre': 'ACAMBAY DE RUÍZ CASTAÑEDA', 'efe_key': '15'},
            {'key': '002', 'nombre': 'ACOLMAN', 'efe_key': '15'},
            {'key': '003', 'nombre': 'ACULCO', 'efe_key': '15'},
            {'key': '004', 'nombre': 'ALMOLOYA DE ALQUISIRAS', 'efe_key': '15'},
            {'key': '005', 'nombre': 'ALMOLOYA DE JUÁREZ', 'efe_key': '15'},
            {'key': '006', 'nombre': 'ALMOLOYA DEL RÍO', 'efe_key': '15'},
            {'key': '007', 'nombre': 'AMANALCO', 'efe_key': '15'},
            {'key': '008', 'nombre': 'AMATEPEC', 'efe_key': '15'},
            {'key': '009', 'nombre': 'AMECAMECA', 'efe_key': '15'},
            {'key': '010', 'nombre': 'APAXCO', 'efe_key': '15'},
            {'key': '011', 'nombre': 'ATENCO', 'efe_key': '15'},
            {'key': '012', 'nombre': 'ATIZAPÁN', 'efe_key': '15'},
            {'key': '013', 'nombre': 'ATIZAPÁN DE ZARAGOZA', 'efe_key': '15'},
            {'key': '014', 'nombre': 'ATLACOMULCO', 'efe_key': '15'},
            {'key': '015', 'nombre': 'ATLAUTLA', 'efe_key': '15'},
            {'key': '016', 'nombre': 'AXAPUSCO', 'efe_key': '15'},
            {'key': '017', 'nombre': 'AYAPANGO', 'efe_key': '15'},
            {'key': '018', 'nombre': 'CALIMAYA', 'efe_key': '15'},
            {'key': '019', 'nombre': 'CAPULHUAC', 'efe_key': '15'},
            {'key': '020', 'nombre': 'COACALCO DE BERRIOZÁBAL', 'efe_key': '15'},
            {'key': '021', 'nombre': 'COATEPEC HARINAS', 'efe_key': '15'},
            {'key': '022', 'nombre': 'COCOTITLÁN', 'efe_key': '15'},
            {'key': '023', 'nombre': 'COYOTEPEC', 'efe_key': '15'},
            {'key': '024', 'nombre': 'CUAUTITLÁN', 'efe_key': '15'},
            {'key': '025', 'nombre': 'CHALCO', 'efe_key': '15'},
            {'key': '026', 'nombre': 'CHAPA DE MOTA', 'efe_key': '15'},
            {'key': '027', 'nombre': 'CHAPULTEPEC', 'efe_key': '15'},
            {'key': '028', 'nombre': 'CHIAUTLA', 'efe_key': '15'},
            {'key': '029', 'nombre': 'CHICOLOAPAN', 'efe_key': '15'},
            {'key': '030', 'nombre': 'CHICONCUAC', 'efe_key': '15'},
            {'key': '031', 'nombre': 'CHIMALHUACÁN', 'efe_key': '15'},
            {'key': '032', 'nombre': 'DONATO GUERRA', 'efe_key': '15'},
            {'key': '033', 'nombre': 'ECATEPEC DE MORELOS', 'efe_key': '15'},
            {'key': '034', 'nombre': 'ECATZINGO', 'efe_key': '15'},
            {'key': '035', 'nombre': 'HUEHUETOCA', 'efe_key': '15'},
            {'key': '036', 'nombre': 'HUEYPOXTLA', 'efe_key': '15'},
            {'key': '037', 'nombre': 'HUIXQUILUCAN', 'efe_key': '15'},
            {'key': '038', 'nombre': 'ISIDRO FABELA', 'efe_key': '15'},
            {'key': '039', 'nombre': 'IXTAPALUCA', 'efe_key': '15'},
            {'key': '040', 'nombre': 'IXTAPAN DE LA SAL', 'efe_key': '15'},
            {'key': '041', 'nombre': 'IXTAPAN DEL ORO', 'efe_key': '15'},
            {'key': '042', 'nombre': 'IXTLAHUACA', 'efe_key': '15'},
            {'key': '043', 'nombre': 'XALATLACO', 'efe_key': '15'},
            {'key': '044', 'nombre': 'JALTENCO', 'efe_key': '15'},
            {'key': '045', 'nombre': 'JILOTEPEC', 'efe_key': '15'},
            {'key': '046', 'nombre': 'JILOTZINGO', 'efe_key': '15'},
            {'key': '047', 'nombre': 'JIQUIPILCO', 'efe_key': '15'},
            {'key': '048', 'nombre': 'JOCOTITLÁN', 'efe_key': '15'},
            {'key': '049', 'nombre': 'JOQUICINGO', 'efe_key': '15'},
            {'key': '050', 'nombre': 'JUCHITEPEC', 'efe_key': '15'},
            {'key': '051', 'nombre': 'LERMA', 'efe_key': '15'},
            {'key': '052', 'nombre': 'MALINALCO', 'efe_key': '15'},
            {'key': '053', 'nombre': 'MELCHOR OCAMPO', 'efe_key': '15'},
            {'key': '054', 'nombre': 'METEPEC', 'efe_key': '15'},
            {'key': '055', 'nombre': 'MEXICALTZINGO', 'efe_key': '15'},
            {'key': '056', 'nombre': 'MORELOS', 'efe_key': '15'},
            {'key': '057', 'nombre': 'NAUCALPAN DE JUÁREZ', 'efe_key': '15'},
            {'key': '058', 'nombre': 'NEZAHUALCÓYOTL', 'efe_key': '15'},
            {'key': '059', 'nombre': 'NEXTLALPAN', 'efe_key': '15'},
            {'key': '060', 'nombre': 'NICOLÁS ROMERO', 'efe_key': '15'},
            {'key': '061', 'nombre': 'NOPALTEPEC', 'efe_key': '15'},
            {'key': '062', 'nombre': 'OCOYOACAC', 'efe_key': '15'},
            {'key': '063', 'nombre': 'OCUILAN', 'efe_key': '15'},
            {'key': '064', 'nombre': 'EL ORO', 'efe_key': '15'},
            {'key': '065', 'nombre': 'OTUMBA', 'efe_key': '15'},
            {'key': '066', 'nombre': 'OTZOLOAPAN', 'efe_key': '15'},
            {'key': '067', 'nombre': 'OTZOLOTEPEC', 'efe_key': '15'},
            {'key': '068', 'nombre': 'OZUMBA', 'efe_key': '15'},
            {'key': '069', 'nombre': 'PAPALOTLA', 'efe_key': '15'},
            {'key': '070', 'nombre': 'LA PAZ', 'efe_key': '15'},
            {'key': '071', 'nombre': 'POLOTITLÁN', 'efe_key': '15'},
            {'key': '072', 'nombre': 'RAYÓN', 'efe_key': '15'},
            {'key': '073', 'nombre': 'SAN ANTONIO LA ISLA', 'efe_key': '15'},
            {'key': '074', 'nombre': 'SAN FELIPE DEL PROGRESO', 'efe_key': '15'},
            {'key': '075', 'nombre': 'SAN MARTÍN DE LAS PIRÁMIDES', 'efe_key': '15'},
            {'key': '076', 'nombre': 'SAN MATEO ATENCO', 'efe_key': '15'},
            {'key': '077', 'nombre': 'SAN SIMÓN DE GUERRERO', 'efe_key': '15'},
            {'key': '078', 'nombre': 'SANTO TOMÁS', 'efe_key': '15'},
            {'key': '079', 'nombre': 'SOYANIQUILPAN DE JUÁREZ', 'efe_key': '15'},
            {'key': '080', 'nombre': 'SULTEPEC', 'efe_key': '15'},
            {'key': '081', 'nombre': 'TECÁMAC', 'efe_key': '15'},
            {'key': '082', 'nombre': 'TEJUPILCO', 'efe_key': '15'},
            {'key': '083', 'nombre': 'TEMAMATLA', 'efe_key': '15'},
            {'key': '084', 'nombre': 'TEMASCALAPA', 'efe_key': '15'},
            {'key': '085', 'nombre': 'TEMASCALCINGO', 'efe_key': '15'},
            {'key': '086', 'nombre': 'TEMASCALTEPEC', 'efe_key': '15'},
            {'key': '087', 'nombre': 'TEMOAYA', 'efe_key': '15'},
            {'key': '088', 'nombre': 'TENANCINGO', 'efe_key': '15'},
            {'key': '089', 'nombre': 'TENANGO DEL AIRE', 'efe_key': '15'},
            {'key': '090', 'nombre': 'TENANGO DEL VALLE', 'efe_key': '15'},
            {'key': '091', 'nombre': 'TEOLOYUCAN', 'efe_key': '15'},
            {'key': '092', 'nombre': 'TEOTIHUACÁN', 'efe_key': '15'},
            {'key': '093', 'nombre': 'TEPETLAOXTOC', 'efe_key': '15'},
            {'key': '094', 'nombre': 'TEPETLIXPA', 'efe_key': '15'},
            {'key': '095', 'nombre': 'TEPOTZOTLÁN', 'efe_key': '15'},
            {'key': '096', 'nombre': 'TEQUIXQUIAC', 'efe_key': '15'},
            {'key': '097', 'nombre': 'TEXCALTITLÁN', 'efe_key': '15'},
            {'key': '098', 'nombre': 'TEXCALYACAC', 'efe_key': '15'},
            {'key': '099', 'nombre': 'TEXCOCO', 'efe_key': '15'},
            {'key': '100', 'nombre': 'TEZOYUCA', 'efe_key': '15'},
            {'key': '101', 'nombre': 'TIANGUISTENCO', 'efe_key': '15'},
            {'key': '102', 'nombre': 'TIMILPAN', 'efe_key': '15'},
            {'key': '103', 'nombre': 'TLALMANALCO', 'efe_key': '15'},
            {'key': '104', 'nombre': 'TLALNEPANTLA DE BAZ', 'efe_key': '15'},
            {'key': '105', 'nombre': 'TLATLAYA', 'efe_key': '15'},
            {'key': '106', 'nombre': 'TOLUCA', 'efe_key': '15'},
            {'key': '107', 'nombre': 'TONATICO', 'efe_key': '15'},
            {'key': '108', 'nombre': 'TULTEPEC', 'efe_key': '15'},
            {'key': '109', 'nombre': 'TULTITLÁN', 'efe_key': '15'},
            {'key': '110', 'nombre': 'VALLE DE BRAVO', 'efe_key': '15'},
            {'key': '111', 'nombre': 'VILLA DE ALLENDE', 'efe_key': '15'},
            {'key': '112', 'nombre': 'VILLA DEL CARBÓN', 'efe_key': '15'},
            {'key': '113', 'nombre': 'VILLA GUERRERO', 'efe_key': '15'},
            {'key': '114', 'nombre': 'VILLA VICTORIA', 'efe_key': '15'},
            {'key': '115', 'nombre': 'XONACATLÁN', 'efe_key': '15'},
            {'key': '116', 'nombre': 'ZACAZONAPAN', 'efe_key': '15'},
            {'key': '117', 'nombre': 'ZACUALPAN', 'efe_key': '15'},
            {'key': '118', 'nombre': 'ZINACANTEPEC', 'efe_key': '15'},
            {'key': '119', 'nombre': 'ZUMPAHUACÁN', 'efe_key': '15'},
            {'key': '120', 'nombre': 'ZUMPANGO', 'efe_key': '15'},
            {'key': '121', 'nombre': 'CUAUTITLÁN IZCALLI', 'efe_key': '15'},
            {'key': '122', 'nombre': 'VALLE DE CHALCO SOLIDARIDAD', 'efe_key': '15'},
            {'key': '123', 'nombre': 'LUVIANOS', 'efe_key': '15'},
            {'key': '124', 'nombre': 'SAN JOSÉ DEL RINCÓN', 'efe_key': '15'},
            {'key': '125', 'nombre': 'TONANITLA', 'efe_key': '15'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '15'},
            {'key': '001', 'nombre': 'ACUITZIO', 'efe_key': '16'},
            {'key': '002', 'nombre': 'AGUILILLA', 'efe_key': '16'},
            {'key': '003', 'nombre': 'ÁLVARO OBREGÓN', 'efe_key': '16'},
            {'key': '004', 'nombre': 'ANGAMACUTIRO', 'efe_key': '16'},
            {'key': '005', 'nombre': 'ANGANGUEO', 'efe_key': '16'},
            {'key': '006', 'nombre': 'APATZINGÁN', 'efe_key': '16'},
            {'key': '007', 'nombre': 'APORO', 'efe_key': '16'},
            {'key': '008', 'nombre': 'AQUILA', 'efe_key': '16'},
            {'key': '009', 'nombre': 'ARIO', 'efe_key': '16'},
            {'key': '010', 'nombre': 'ARTEAGA', 'efe_key': '16'},
            {'key': '011', 'nombre': 'BRISEÑAS', 'efe_key': '16'},
            {'key': '012', 'nombre': 'BUENAVISTA', 'efe_key': '16'},
            {'key': '013', 'nombre': 'CARÁCUARO', 'efe_key': '16'},
            {'key': '014', 'nombre': 'COAHUAYANA', 'efe_key': '16'},
            {'key': '015', 'nombre': 'COALCOMÁN DE VÁZQUEZ PALLARES', 'efe_key': '16'},
            {'key': '016', 'nombre': 'COENEO', 'efe_key': '16'},
            {'key': '017', 'nombre': 'CONTEPEC', 'efe_key': '16'},
            {'key': '018', 'nombre': 'COPÁNDARO', 'efe_key': '16'},
            {'key': '019', 'nombre': 'COTIJA', 'efe_key': '16'},
            {'key': '020', 'nombre': 'CUITZEO', 'efe_key': '16'},
            {'key': '021', 'nombre': 'CHARAPAN', 'efe_key': '16'},
            {'key': '022', 'nombre': 'CHARO', 'efe_key': '16'},
            {'key': '023', 'nombre': 'CHAVINDA', 'efe_key': '16'},
            {'key': '024', 'nombre': 'CHERÁN', 'efe_key': '16'},
            {'key': '025', 'nombre': 'CHILCHOTA', 'efe_key': '16'},
            {'key': '026', 'nombre': 'CHINICUILA', 'efe_key': '16'},
            {'key': '027', 'nombre': 'CHUCÁNDIRO', 'efe_key': '16'},
            {'key': '028', 'nombre': 'CHURINTZIO', 'efe_key': '16'},
            {'key': '029', 'nombre': 'CHURUMUCO', 'efe_key': '16'},
            {'key': '030', 'nombre': 'ECUANDUREO', 'efe_key': '16'},
            {'key': '031', 'nombre': 'EPITACIO HUERTA', 'efe_key': '16'},
            {'key': '032', 'nombre': 'ERONGARÍCUARO', 'efe_key': '16'},
            {'key': '033', 'nombre': 'GABRIEL ZAMORA', 'efe_key': '16'},
            {'key': '034', 'nombre': 'HIDALGO', 'efe_key': '16'},
            {'key': '035', 'nombre': 'LA HUACANA', 'efe_key': '16'},
            {'key': '036', 'nombre': 'HUANDACAREO', 'efe_key': '16'},
            {'key': '037', 'nombre': 'HUANIQUEO', 'efe_key': '16'},
            {'key': '038', 'nombre': 'HUETAMO', 'efe_key': '16'},
            {'key': '039', 'nombre': 'HUIRAMBA', 'efe_key': '16'},
            {'key': '040', 'nombre': 'INDAPARAPEO', 'efe_key': '16'},
            {'key': '041', 'nombre': 'IRIMBO', 'efe_key': '16'},
            {'key': '042', 'nombre': 'IXTLÁN', 'efe_key': '16'},
            {'key': '043', 'nombre': 'JACONA', 'efe_key': '16'},
            {'key': '044', 'nombre': 'JIMÉNEZ', 'efe_key': '16'},
            {'key': '045', 'nombre': 'JIQUILPAN', 'efe_key': '16'},
            {'key': '046', 'nombre': 'JUÁREZ', 'efe_key': '16'},
            {'key': '047', 'nombre': 'JUNGAPEO', 'efe_key': '16'},
            {'key': '048', 'nombre': 'LAGUNILLAS', 'efe_key': '16'},
            {'key': '049', 'nombre': 'MADERO', 'efe_key': '16'},
            {'key': '050', 'nombre': 'MARAVATÍO', 'efe_key': '16'},
            {'key': '051', 'nombre': 'MARCOS CASTELLANOS', 'efe_key': '16'},
            {'key': '052', 'nombre': 'LÁZARO CÁRDENAS', 'efe_key': '16'},
            {'key': '053', 'nombre': 'MORELIA', 'efe_key': '16'},
            {'key': '054', 'nombre': 'MORELOS', 'efe_key': '16'},
            {'key': '055', 'nombre': 'MÚGICA', 'efe_key': '16'},
            {'key': '056', 'nombre': 'NAHUATZEN', 'efe_key': '16'},
            {'key': '057', 'nombre': 'NOCUPÉTARO', 'efe_key': '16'},
            {'key': '058', 'nombre': 'NUEVO PARANGARICUTIRO', 'efe_key': '16'},
            {'key': '059', 'nombre': 'NUEVO URECHO', 'efe_key': '16'},
            {'key': '060', 'nombre': 'NUMARÁN', 'efe_key': '16'},
            {'key': '061', 'nombre': 'OCAMPO', 'efe_key': '16'},
            {'key': '062', 'nombre': 'PAJACUARÁN', 'efe_key': '16'},
            {'key': '063', 'nombre': 'PANINDÍCUARO', 'efe_key': '16'},
            {'key': '064', 'nombre': 'PARÁCUARO', 'efe_key': '16'},
            {'key': '065', 'nombre': 'PARACHO', 'efe_key': '16'},
            {'key': '066', 'nombre': 'PÁTZCUARO', 'efe_key': '16'},
            {'key': '067', 'nombre': 'PENJAMILLO', 'efe_key': '16'},
            {'key': '068', 'nombre': 'PERIBÁN', 'efe_key': '16'},
            {'key': '069', 'nombre': 'LA PIEDAD', 'efe_key': '16'},
            {'key': '070', 'nombre': 'PURÉPERO', 'efe_key': '16'},
            {'key': '071', 'nombre': 'PURUÁNDIRO', 'efe_key': '16'},
            {'key': '072', 'nombre': 'QUERÉNDARO', 'efe_key': '16'},
            {'key': '073', 'nombre': 'QUIROGA', 'efe_key': '16'},
            {'key': '074', 'nombre': 'COJUMATLÁN DE RÉGULES', 'efe_key': '16'},
            {'key': '075', 'nombre': 'LOS REYES', 'efe_key': '16'},
            {'key': '076', 'nombre': 'SAHUAYO', 'efe_key': '16'},
            {'key': '077', 'nombre': 'SAN LUCAS', 'efe_key': '16'},
            {'key': '078', 'nombre': 'SANTA ANA MAYA', 'efe_key': '16'},
            {'key': '079', 'nombre': 'SALVADOR ESCALANTE', 'efe_key': '16'},
            {'key': '080', 'nombre': 'SENGUIO', 'efe_key': '16'},
            {'key': '081', 'nombre': 'SUSUPUATO', 'efe_key': '16'},
            {'key': '082', 'nombre': 'TACÁMBARO', 'efe_key': '16'},
            {'key': '083', 'nombre': 'TANCÍTARO', 'efe_key': '16'},
            {'key': '084', 'nombre': 'TANGAMANDAPIO', 'efe_key': '16'},
            {'key': '085', 'nombre': 'TANGANCÍCUARO', 'efe_key': '16'},
            {'key': '086', 'nombre': 'TANHUATO', 'efe_key': '16'},
            {'key': '087', 'nombre': 'TARETAN', 'efe_key': '16'},
            {'key': '088', 'nombre': 'TARÍMBARO', 'efe_key': '16'},
            {'key': '089', 'nombre': 'TEPALCATEPEC', 'efe_key': '16'},
            {'key': '090', 'nombre': 'TINGAMBATO', 'efe_key': '16'},
            {'key': '091', 'nombre': 'TINGÜINDÍN', 'efe_key': '16'},
            {'key': '092', 'nombre': 'TIQUICHEO DE NICOLÁS ROMERO', 'efe_key': '16'},
            {'key': '093', 'nombre': 'TLALPUJAHUA', 'efe_key': '16'},
            {'key': '094', 'nombre': 'TLAZAZALCA', 'efe_key': '16'},
            {'key': '095', 'nombre': 'TOCUMBO', 'efe_key': '16'},
            {'key': '096', 'nombre': 'TUMBISCATÍO', 'efe_key': '16'},
            {'key': '097', 'nombre': 'TURICATO', 'efe_key': '16'},
            {'key': '098', 'nombre': 'TUXPAN', 'efe_key': '16'},
            {'key': '099', 'nombre': 'TUZANTLA', 'efe_key': '16'},
            {'key': '100', 'nombre': 'TZINTZUNTZAN', 'efe_key': '16'},
            {'key': '101', 'nombre': 'TZITZIO', 'efe_key': '16'},
            {'key': '102', 'nombre': 'URUAPAN', 'efe_key': '16'},
            {'key': '103', 'nombre': 'VENUSTIANO CARRANZA', 'efe_key': '16'},
            {'key': '104', 'nombre': 'VILLAMAR', 'efe_key': '16'},
            {'key': '105', 'nombre': 'VISTA HERMOSA', 'efe_key': '16'},
            {'key': '106', 'nombre': 'YURÉCUARO', 'efe_key': '16'},
            {'key': '107', 'nombre': 'ZACAPU', 'efe_key': '16'},
            {'key': '108', 'nombre': 'ZAMORA', 'efe_key': '16'},
            {'key': '109', 'nombre': 'ZINÁPARO', 'efe_key': '16'},
            {'key': '110', 'nombre': 'ZINAPÉCUARO', 'efe_key': '16'},
            {'key': '111', 'nombre': 'ZIRACUARETIRO', 'efe_key': '16'},
            {'key': '112', 'nombre': 'ZITÁCUARO', 'efe_key': '16'},
            {'key': '113', 'nombre': 'JOSÉ SIXTO VERDUZCO', 'efe_key': '16'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '16'},
            {'key': '001', 'nombre': 'AMACUZAC', 'efe_key': '17'},
            {'key': '002', 'nombre': 'ATLATLAHUCAN', 'efe_key': '17'},
            {'key': '003', 'nombre': 'AXOCHIAPAN', 'efe_key': '17'},
            {'key': '004', 'nombre': 'AYALA', 'efe_key': '17'},
            {'key': '005', 'nombre': 'COATLÁN DEL RÍO', 'efe_key': '17'},
            {'key': '006', 'nombre': 'CUAUTLA', 'efe_key': '17'},
            {'key': '007', 'nombre': 'CUERNAVACA', 'efe_key': '17'},
            {'key': '008', 'nombre': 'EMILIANO ZAPATA', 'efe_key': '17'},
            {'key': '009', 'nombre': 'HUITZILAC', 'efe_key': '17'},
            {'key': '010', 'nombre': 'JANTETELCO', 'efe_key': '17'},
            {'key': '011', 'nombre': 'JIUTEPEC', 'efe_key': '17'},
            {'key': '012', 'nombre': 'JOJUTLA', 'efe_key': '17'},
            {'key': '013', 'nombre': 'JONACATEPEC DE LEANDRO VALLE', 'efe_key': '17'},
            {'key': '014', 'nombre': 'MAZATEPEC', 'efe_key': '17'},
            {'key': '015', 'nombre': 'MIACATLÁN', 'efe_key': '17'},
            {'key': '016', 'nombre': 'OCUITUCO', 'efe_key': '17'},
            {'key': '017', 'nombre': 'PUENTE DE IXTLA', 'efe_key': '17'},
            {'key': '018', 'nombre': 'TEMIXCO', 'efe_key': '17'},
            {'key': '019', 'nombre': 'TEPALCINGO', 'efe_key': '17'},
            {'key': '020', 'nombre': 'TEPOZTLÁN', 'efe_key': '17'},
            {'key': '021', 'nombre': 'TETECALA', 'efe_key': '17'},
            {'key': '022', 'nombre': 'TETELA DEL VOLCÁN', 'efe_key': '17'},
            {'key': '023', 'nombre': 'TLALNEPANTLA', 'efe_key': '17'},
            {'key': '024', 'nombre': 'TLALTIZAPÁN DE ZAPATA', 'efe_key': '17'},
            {'key': '025', 'nombre': 'TLAQUILTENANGO', 'efe_key': '17'},
            {'key': '026', 'nombre': 'TLAYACAPAN', 'efe_key': '17'},
            {'key': '027', 'nombre': 'TOTOLAPAN', 'efe_key': '17'},
            {'key': '028', 'nombre': 'XOCHITEPEC', 'efe_key': '17'},
            {'key': '029', 'nombre': 'YAUTEPEC', 'efe_key': '17'},
            {'key': '030', 'nombre': 'YECAPIXTLA', 'efe_key': '17'},
            {'key': '031', 'nombre': 'ZACATEPEC', 'efe_key': '17'},
            {'key': '032', 'nombre': 'ZACUALPAN DE AMILPAS', 'efe_key': '17'},
            {'key': '033', 'nombre': 'TEMOAC', 'efe_key': '17'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '17'},
            {'key': '001', 'nombre': 'ACAPONETA', 'efe_key': '18'},
            {'key': '002', 'nombre': 'AHUACATLÁN', 'efe_key': '18'},
            {'key': '003', 'nombre': 'AMATLÁN DE CAÑAS', 'efe_key': '18'},
            {'key': '004', 'nombre': 'COMPOSTELA', 'efe_key': '18'},
            {'key': '005', 'nombre': 'HUAJICORI', 'efe_key': '18'},
            {'key': '006', 'nombre': 'IXTLÁN DEL RÍO', 'efe_key': '18'},
            {'key': '007', 'nombre': 'JALA', 'efe_key': '18'},
            {'key': '008', 'nombre': 'XALISCO', 'efe_key': '18'},
            {'key': '009', 'nombre': 'DEL NAYAR', 'efe_key': '18'},
            {'key': '010', 'nombre': 'ROSAMORADA', 'efe_key': '18'},
            {'key': '011', 'nombre': 'RUÍZ', 'efe_key': '18'},
            {'key': '012', 'nombre': 'SAN BLAS', 'efe_key': '18'},
            {'key': '013', 'nombre': 'SAN PEDRO LAGUNILLAS', 'efe_key': '18'},
            {'key': '014', 'nombre': 'SANTA MARÍA DEL ORO', 'efe_key': '18'},
            {'key': '015', 'nombre': 'SANTIAGO IXCUINTLA', 'efe_key': '18'},
            {'key': '016', 'nombre': 'TECUALA', 'efe_key': '18'},
            {'key': '017', 'nombre': 'TEPIC', 'efe_key': '18'},
            {'key': '018', 'nombre': 'TUXPAN', 'efe_key': '18'},
            {'key': '019', 'nombre': 'LA YESCA', 'efe_key': '18'},
            {'key': '020', 'nombre': 'BAHÍA DE BANDERAS', 'efe_key': '18'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '18'},
            {'key': '001', 'nombre': 'ABASOLO', 'efe_key': '19'},
            {'key': '002', 'nombre': 'AGUALEGUAS', 'efe_key': '19'},
            {'key': '003', 'nombre': 'LOS ALDAMAS', 'efe_key': '19'},
            {'key': '004', 'nombre': 'ALLENDE', 'efe_key': '19'},
            {'key': '005', 'nombre': 'ANÁHUAC', 'efe_key': '19'},
            {'key': '006', 'nombre': 'APODACA', 'efe_key': '19'},
            {'key': '007', 'nombre': 'ARAMBERRI', 'efe_key': '19'},
            {'key': '008', 'nombre': 'BUSTAMANTE', 'efe_key': '19'},
            {'key': '009', 'nombre': 'CADEREYTA JIMÉNEZ', 'efe_key': '19'},
            {'key': '010', 'nombre': 'EL CARMEN', 'efe_key': '19'},
            {'key': '011', 'nombre': 'CERRALVO', 'efe_key': '19'},
            {'key': '012', 'nombre': 'CIÉNEGA DE FLORES', 'efe_key': '19'},
            {'key': '013', 'nombre': 'CHINA', 'efe_key': '19'},
            {'key': '014', 'nombre': 'DOCTOR ARROYO', 'efe_key': '19'},
            {'key': '015', 'nombre': 'DOCTOR COSS', 'efe_key': '19'},
            {'key': '016', 'nombre': 'DOCTOR GONZÁLEZ', 'efe_key': '19'},
            {'key': '017', 'nombre': 'GALEANA', 'efe_key': '19'},
            {'key': '018', 'nombre': 'GARCÍA', 'efe_key': '19'},
            {'key': '019', 'nombre': 'SAN PEDRO GARZA GARCÍA', 'efe_key': '19'},
            {'key': '020', 'nombre': 'GENERAL BRAVO', 'efe_key': '19'},
            {'key': '021', 'nombre': 'GENERAL ESCOBEDO', 'efe_key': '19'},
            {'key': '022', 'nombre': 'GENERAL TERÁN', 'efe_key': '19'},
            {'key': '023', 'nombre': 'GENERAL TREVIÑO', 'efe_key': '19'},
            {'key': '024', 'nombre': 'GENERAL ZARAGOZA', 'efe_key': '19'},
            {'key': '025', 'nombre': 'GENERAL ZUAZUA', 'efe_key': '19'},
            {'key': '026', 'nombre': 'GUADALUPE', 'efe_key': '19'},
            {'key': '027', 'nombre': 'LOS HERRERAS', 'efe_key': '19'},
            {'key': '028', 'nombre': 'HIGUERAS', 'efe_key': '19'},
            {'key': '029', 'nombre': 'HUALAHUISES', 'efe_key': '19'},
            {'key': '030', 'nombre': 'ITURBIDE', 'efe_key': '19'},
            {'key': '031', 'nombre': 'JUÁREZ', 'efe_key': '19'},
            {'key': '032', 'nombre': 'LAMPAZOS DE NARANJO', 'efe_key': '19'},
            {'key': '033', 'nombre': 'LINARES', 'efe_key': '19'},
            {'key': '034', 'nombre': 'MARÍN', 'efe_key': '19'},
            {'key': '035', 'nombre': 'MELCHOR OCAMPO', 'efe_key': '19'},
            {'key': '036', 'nombre': 'MIER Y NORIEGA', 'efe_key': '19'},
            {'key': '037', 'nombre': 'MINA', 'efe_key': '19'},
            {'key': '038', 'nombre': 'MONTEMORELOS', 'efe_key': '19'},
            {'key': '039', 'nombre': 'MONTERREY', 'efe_key': '19'},
            {'key': '040', 'nombre': 'PARÁS', 'efe_key': '19'},
            {'key': '041', 'nombre': 'PESQUERÍA', 'efe_key': '19'},
            {'key': '042', 'nombre': 'LOS RAMONES', 'efe_key': '19'},
            {'key': '043', 'nombre': 'RAYONES', 'efe_key': '19'},
            {'key': '044', 'nombre': 'SABINAS HIDALGO', 'efe_key': '19'},
            {'key': '045', 'nombre': 'SALINAS VICTORIA', 'efe_key': '19'},
            {'key': '046', 'nombre': 'SAN NICOLÁS DE LOS GARZA', 'efe_key': '19'},
            {'key': '047', 'nombre': 'HIDALGO', 'efe_key': '19'},
            {'key': '048', 'nombre': 'SANTA CATARINA', 'efe_key': '19'},
            {'key': '049', 'nombre': 'SANTIAGO', 'efe_key': '19'},
            {'key': '050', 'nombre': 'VALLECILLO', 'efe_key': '19'},
            {'key': '051', 'nombre': 'VILLALDAMA', 'efe_key': '19'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '19'},
            {'key': '001', 'nombre': 'ABEJONES', 'efe_key': '20'},
            {'key': '002', 'nombre': 'ACATLÁN DE PÉREZ FIGUEROA', 'efe_key': '20'},
            {'key': '003', 'nombre': 'ASUNCIÓN CACALOTEPEC', 'efe_key': '20'},
            {'key': '004', 'nombre': 'ASUNCIÓN CUYOTEPEJI', 'efe_key': '20'},
            {'key': '005', 'nombre': 'ASUNCIÓN IXTALTEPEC', 'efe_key': '20'},
            {'key': '006', 'nombre': 'ASUNCIÓN NOCHIXTLÁN', 'efe_key': '20'},
            {'key': '007', 'nombre': 'ASUNCIÓN OCOTLÁN', 'efe_key': '20'},
            {'key': '008', 'nombre': 'ASUNCIÓN TLACOLULITA', 'efe_key': '20'},
            {'key': '009', 'nombre': 'AYOTZINTEPEC', 'efe_key': '20'},
            {'key': '010', 'nombre': 'EL BARRIO DE LA SOLEDAD', 'efe_key': '20'},
            {'key': '011', 'nombre': 'CALIHUALÁ', 'efe_key': '20'},
            {'key': '012', 'nombre': 'CANDELARIA LOXICHA', 'efe_key': '20'},
            {'key': '013', 'nombre': 'CIÉNEGA DE ZIMATLÁN', 'efe_key': '20'},
            {'key': '014', 'nombre': 'CIUDAD IXTEPEC', 'efe_key': '20'},
            {'key': '015', 'nombre': 'COATECAS ALTAS', 'efe_key': '20'},
            {'key': '016', 'nombre': 'COICOYÁN DE LAS FLORES', 'efe_key': '20'},
            {'key': '017', 'nombre': 'LA COMPAÑÍA', 'efe_key': '20'},
            {'key': '018', 'nombre': 'CONCEPCIÓN BUENAVISTA', 'efe_key': '20'},
            {'key': '019', 'nombre': 'CONCEPCIÓN PÁPALO', 'efe_key': '20'},
            {'key': '020', 'nombre': 'CONSTANCIA DEL ROSARIO', 'efe_key': '20'},
            {'key': '021', 'nombre': 'COSOLAPA', 'efe_key': '20'},
            {'key': '022', 'nombre': 'COSOLTEPEC', 'efe_key': '20'},
            {'key': '023', 'nombre': 'CUILÁPAM DE GUERRERO', 'efe_key': '20'},
            {'key': '024', 'nombre': 'CUYAMECALCO VILLA DE ZARAGOZA', 'efe_key': '20'},
            {'key': '025', 'nombre': 'CHAHUITES', 'efe_key': '20'},
            {'key': '026', 'nombre': 'CHALCATONGO DE HIDALGO', 'efe_key': '20'},
            {'key': '027', 'nombre': 'CHIQUIHUITLÁN DE BENITO JUÁREZ', 'efe_key': '20'},
            {'key': '028', 'nombre': 'HEROICA CIUDAD DE EJUTLA DE CRESPO', 'efe_key': '20'},
            {'key': '029', 'nombre': 'ELOXOCHITLÁN DE FLORES MAGÓN', 'efe_key': '20'},
            {'key': '030', 'nombre': 'EL ESPINAL', 'efe_key': '20'},
            {'key': '031', 'nombre': 'TAMAZULÁPAM DEL ESPÍRITU SANTO', 'efe_key': '20'},
            {'key': '032', 'nombre': 'FRESNILLO DE TRUJANO', 'efe_key': '20'},
            {'key': '033', 'nombre': 'GUADALUPE ETLA', 'efe_key': '20'},
            {'key': '034', 'nombre': 'GUADALUPE DE RAMÍREZ', 'efe_key': '20'},
            {'key': '035', 'nombre': 'GUELATAO DE JUÁREZ', 'efe_key': '20'},
            {'key': '036', 'nombre': 'GUEVEA DE HUMBOLDT', 'efe_key': '20'},
            {'key': '037', 'nombre': 'MESONES HIDALGO', 'efe_key': '20'},
            {'key': '038', 'nombre': 'VILLA HIDALGO', 'efe_key': '20'},
            {'key': '039', 'nombre': 'HEROICA CIUDAD DE HUAJUAPAN DE LEÓN', 'efe_key': '20'},
            {'key': '040', 'nombre': 'HUAUTEPEC', 'efe_key': '20'},
            {'key': '041', 'nombre': 'HUAUTLA DE JIMÉNEZ', 'efe_key': '20'},
            {'key': '042', 'nombre': 'IXTLÁN DE JUÁREZ', 'efe_key': '20'},
            {'key': '043', 'nombre': 'HEROICA CIUDAD DE JUCHITÁN DE ZARAGOZA', 'efe_key': '20'},
            {'key': '044', 'nombre': 'LOMA BONITA', 'efe_key': '20'},
            {'key': '045', 'nombre': 'MAGDALENA APASCO', 'efe_key': '20'},
            {'key': '046', 'nombre': 'MAGDALENA JALTEPEC', 'efe_key': '20'},
            {'key': '047', 'nombre': 'SANTA MAGDALENA JICOTLÁN', 'efe_key': '20'},
            {'key': '048', 'nombre': 'MAGDALENA MIXTEPEC', 'efe_key': '20'},
            {'key': '049', 'nombre': 'MAGDALENA OCOTLÁN', 'efe_key': '20'},
            {'key': '050', 'nombre': 'MAGDALENA PEÑASCO', 'efe_key': '20'},
            {'key': '051', 'nombre': 'MAGDALENA TEITIPAC', 'efe_key': '20'},
            {'key': '052', 'nombre': 'MAGDALENA TEQUISISTLÁN', 'efe_key': '20'},
            {'key': '053', 'nombre': 'MAGDALENA TLACOTEPEC', 'efe_key': '20'},
            {'key': '054', 'nombre': 'MAGDALENA ZAHUATLÁN', 'efe_key': '20'},
            {'key': '055', 'nombre': 'MARISCALA DE JUÁREZ', 'efe_key': '20'},
            {'key': '056', 'nombre': 'MÁRTIRES DE TACUBAYA', 'efe_key': '20'},
            {'key': '057', 'nombre': 'MATÍAS ROMERO AVENDAÑO', 'efe_key': '20'},
            {'key': '058', 'nombre': 'MAZATLÁN VILLA DE FLORES', 'efe_key': '20'},
            {'key': '059', 'nombre': 'MIAHUATLÁN DE PORFIRIO DÍAZ', 'efe_key': '20'},
            {'key': '060', 'nombre': 'MIXISTLÁN DE LA REFORMA', 'efe_key': '20'},
            {'key': '061', 'nombre': 'MONJAS', 'efe_key': '20'},
            {'key': '062', 'nombre': 'NATIVIDAD', 'efe_key': '20'},
            {'key': '063', 'nombre': 'NAZARENO ETLA', 'efe_key': '20'},
            {'key': '064', 'nombre': 'NEJAPA DE MADERO', 'efe_key': '20'},
            {'key': '065', 'nombre': 'IXPANTEPEC NIEVES', 'efe_key': '20'},
            {'key': '066', 'nombre': 'SANTIAGO NILTEPEC', 'efe_key': '20'},
            {'key': '067', 'nombre': 'OAXACA DE JUÁREZ', 'efe_key': '20'},
            {'key': '068', 'nombre': 'OCOTLÁN DE MORELOS', 'efe_key': '20'},
            {'key': '069', 'nombre': 'LA PE', 'efe_key': '20'},
            {'key': '070', 'nombre': 'PINOTEPA DE DON LUIS', 'efe_key': '20'},
            {'key': '071', 'nombre': 'PLUMA HIDALGO', 'efe_key': '20'},
            {'key': '072', 'nombre': 'SAN JOSÉ DEL PROGRESO', 'efe_key': '20'},
            {'key': '073', 'nombre': 'PUTLA VILLA DE GUERRERO', 'efe_key': '20'},
            {'key': '074', 'nombre': 'SANTA CATARINA QUIOQUITANI', 'efe_key': '20'},
            {'key': '075', 'nombre': 'REFORMA DE PINEDA', 'efe_key': '20'},
            {'key': '076', 'nombre': 'LA REFORMA', 'efe_key': '20'},
            {'key': '077', 'nombre': 'REYES ETLA', 'efe_key': '20'},
            {'key': '078', 'nombre': 'ROJAS DE CUAUHTÉMOC', 'efe_key': '20'},
            {'key': '079', 'nombre': 'SALINA CRUZ', 'efe_key': '20'},
            {'key': '080', 'nombre': 'SAN AGUSTÍN AMATENGO', 'efe_key': '20'},
            {'key': '081', 'nombre': 'SAN AGUSTÍN ATENANGO', 'efe_key': '20'},
            {'key': '082', 'nombre': 'SAN AGUSTÍN CHAYUCO', 'efe_key': '20'},
            {'key': '083', 'nombre': 'SAN AGUSTÍN DE LAS JUNTAS', 'efe_key': '20'},
            {'key': '084', 'nombre': 'SAN AGUSTÍN ETLA', 'efe_key': '20'},
            {'key': '085', 'nombre': 'SAN AGUSTÍN LOXICHA', 'efe_key': '20'},
            {'key': '086', 'nombre': 'SAN AGUSTÍN TLACOTEPEC', 'efe_key': '20'},
            {'key': '087', 'nombre': 'SAN AGUSTÍN YATARENI', 'efe_key': '20'},
            {'key': '088', 'nombre': 'SAN ANDRÉS CABECERA NUEVA', 'efe_key': '20'},
            {'key': '089', 'nombre': 'SAN ANDRÉS DINICUITI', 'efe_key': '20'},
            {'key': '090', 'nombre': 'SAN ANDRÉS HUAXPALTEPEC', 'efe_key': '20'},
            {'key': '091', 'nombre': 'SAN ANDRÉS HUAYÁPAM', 'efe_key': '20'},
            {'key': '092', 'nombre': 'SAN ANDRÉS IXTLAHUACA', 'efe_key': '20'},
            {'key': '093', 'nombre': 'SAN ANDRÉS LAGUNAS', 'efe_key': '20'},
            {'key': '094', 'nombre': 'SAN ANDRÉS NUXIÑO', 'efe_key': '20'},
            {'key': '095', 'nombre': 'SAN ANDRÉS PAXTLÁN', 'efe_key': '20'},
            {'key': '096', 'nombre': 'SAN ANDRÉS SINAXTLA', 'efe_key': '20'},
            {'key': '097', 'nombre': 'SAN ANDRÉS SOLAGA', 'efe_key': '20'},
            {'key': '098', 'nombre': 'SAN ANDRÉS TEOTILÁLPAM', 'efe_key': '20'},
            {'key': '099', 'nombre': 'SAN ANDRÉS TEPETLAPA', 'efe_key': '20'},
            {'key': '100', 'nombre': 'SAN ANDRÉS YAÁ', 'efe_key': '20'},
            {'key': '101', 'nombre': 'SAN ANDRÉS ZABACHE', 'efe_key': '20'},
            {'key': '102', 'nombre': 'SAN ANDRÉS ZAUTLA', 'efe_key': '20'},
            {'key': '103', 'nombre': 'SAN ANTONINO CASTILLO VELASCO', 'efe_key': '20'},
            {'key': '104', 'nombre': 'SAN ANTONINO EL ALTO', 'efe_key': '20'},
            {'key': '105', 'nombre': 'SAN ANTONINO MONTE VERDE', 'efe_key': '20'},
            {'key': '106', 'nombre': 'SAN ANTONIO ACUTLA', 'efe_key': '20'},
            {'key': '107', 'nombre': 'SAN ANTONIO DE LA CAL', 'efe_key': '20'},
            {'key': '108', 'nombre': 'SAN ANTONIO HUITEPEC', 'efe_key': '20'},
            {'key': '109', 'nombre': 'SAN ANTONIO NANAHUATÍPAM', 'efe_key': '20'},
            {'key': '110', 'nombre': 'SAN ANTONIO SINICAHUA', 'efe_key': '20'},
            {'key': '111', 'nombre': 'SAN ANTONIO TEPETLAPA', 'efe_key': '20'},
            {'key': '112', 'nombre': 'SAN BALTAZAR CHICHICÁPAM', 'efe_key': '20'},
            {'key': '113', 'nombre': 'SAN BALTAZAR LOXICHA', 'efe_key': '20'},
            {'key': '114', 'nombre': 'SAN BALTAZAR YATZACHI EL BAJO', 'efe_key': '20'},
            {'key': '115', 'nombre': 'SAN BARTOLO COYOTEPEC', 'efe_key': '20'},
            {'key': '116', 'nombre': 'SAN BARTOLOMÉ AYAUTLA', 'efe_key': '20'},
            {'key': '117', 'nombre': 'SAN BARTOLOMÉ LOXICHA', 'efe_key': '20'},
            {'key': '118', 'nombre': 'SAN BARTOLOMÉ QUIALANA', 'efe_key': '20'},
            {'key': '119', 'nombre': 'SAN BARTOLOMÉ YUCUAÑE', 'efe_key': '20'},
            {'key': '120', 'nombre': 'SAN BARTOLOMÉ ZOOGOCHO', 'efe_key': '20'},
            {'key': '121', 'nombre': 'SAN BARTOLO SOYALTEPEC', 'efe_key': '20'},
            {'key': '122', 'nombre': 'SAN BARTOLO YAUTEPEC', 'efe_key': '20'},
            {'key': '123', 'nombre': 'SAN BERNARDO MIXTEPEC', 'efe_key': '20'},
            {'key': '124', 'nombre': 'SAN BLAS ATEMPA', 'efe_key': '20'},
            {'key': '125', 'nombre': 'SAN CARLOS YAUTEPEC', 'efe_key': '20'},
            {'key': '126', 'nombre': 'SAN CRISTÓBAL AMATLÁN', 'efe_key': '20'},
            {'key': '127', 'nombre': 'SAN CRISTÓBAL AMOLTEPEC', 'efe_key': '20'},
            {'key': '128', 'nombre': 'SAN CRISTÓBAL LACHIRIOAG', 'efe_key': '20'},
            {'key': '129', 'nombre': 'SAN CRISTÓBAL SUCHIXTLAHUACA', 'efe_key': '20'},
            {'key': '130', 'nombre': 'SAN DIONISIO DEL MAR', 'efe_key': '20'},
            {'key': '131', 'nombre': 'SAN DIONISIO OCOTEPEC', 'efe_key': '20'},
            {'key': '132', 'nombre': 'SAN DIONISIO OCOTLÁN', 'efe_key': '20'},
            {'key': '133', 'nombre': 'SAN ESTEBAN ATATLAHUCA', 'efe_key': '20'},
            {'key': '134', 'nombre': 'SAN FELIPE JALAPA DE DÍAZ', 'efe_key': '20'},
            {'key': '135', 'nombre': 'SAN FELIPE TEJALÁPAM', 'efe_key': '20'},
            {'key': '136', 'nombre': 'SAN FELIPE USILA', 'efe_key': '20'},
            {'key': '137', 'nombre': 'SAN FRANCISCO CAHUACUÁ', 'efe_key': '20'},
            {'key': '138', 'nombre': 'SAN FRANCISCO CAJONOS', 'efe_key': '20'},
            {'key': '139', 'nombre': 'SAN FRANCISCO CHAPULAPA', 'efe_key': '20'},
            {'key': '140', 'nombre': 'SAN FRANCISCO CHINDÚA', 'efe_key': '20'},
            {'key': '141', 'nombre': 'SAN FRANCISCO DEL MAR', 'efe_key': '20'},
            {'key': '142', 'nombre': 'SAN FRANCISCO HUEHUETLÁN', 'efe_key': '20'},
            {'key': '143', 'nombre': 'SAN FRANCISCO IXHUATÁN', 'efe_key': '20'},
            {'key': '144', 'nombre': 'SAN FRANCISCO JALTEPETONGO', 'efe_key': '20'},
            {'key': '145', 'nombre': 'SAN FRANCISCO LACHIGOLÓ', 'efe_key': '20'},
            {'key': '146', 'nombre': 'SAN FRANCISCO LOGUECHE', 'efe_key': '20'},
            {'key': '147', 'nombre': 'SAN FRANCISCO NUXAÑO', 'efe_key': '20'},
            {'key': '148', 'nombre': 'SAN FRANCISCO OZOLOTEPEC', 'efe_key': '20'},
            {'key': '149', 'nombre': 'SAN FRANCISCO SOLA', 'efe_key': '20'},
            {'key': '150', 'nombre': 'SAN FRANCISCO TELIXTLAHUACA', 'efe_key': '20'},
            {'key': '151', 'nombre': 'SAN FRANCISCO TEOPAN', 'efe_key': '20'},
            {'key': '152', 'nombre': 'SAN FRANCISCO TLAPANCINGO', 'efe_key': '20'},
            {'key': '153', 'nombre': 'SAN GABRIEL MIXTEPEC', 'efe_key': '20'},
            {'key': '154', 'nombre': 'SAN ILDEFONSO AMATLÁN', 'efe_key': '20'},
            {'key': '155', 'nombre': 'SAN ILDEFONSO SOLA', 'efe_key': '20'},
            {'key': '156', 'nombre': 'SAN ILDEFONSO VILLA ALTA', 'efe_key': '20'},
            {'key': '157', 'nombre': 'SAN JACINTO AMILPAS', 'efe_key': '20'},
            {'key': '158', 'nombre': 'SAN JACINTO TLACOTEPEC', 'efe_key': '20'},
            {'key': '159', 'nombre': 'SAN JERÓNIMO COATLÁN', 'efe_key': '20'},
            {'key': '160', 'nombre': 'SAN JERÓNIMO SILACAYOAPILLA', 'efe_key': '20'},
            {'key': '161', 'nombre': 'SAN JERÓNIMO SOSOLA', 'efe_key': '20'},
            {'key': '162', 'nombre': 'SAN JERÓNIMO TAVICHE', 'efe_key': '20'},
            {'key': '163', 'nombre': 'SAN JERÓNIMO TECÓATL', 'efe_key': '20'},
            {'key': '164', 'nombre': 'SAN JORGE NUCHITA', 'efe_key': '20'},
            {'key': '165', 'nombre': 'SAN JOSÉ AYUQUILA', 'efe_key': '20'},
            {'key': '166', 'nombre': 'SAN JOSÉ CHILTEPEC', 'efe_key': '20'},
            {'key': '167', 'nombre': 'SAN JOSÉ DEL PEÑASCO', 'efe_key': '20'},
            {'key': '168', 'nombre': 'SAN JOSÉ ESTANCIA GRANDE', 'efe_key': '20'},
            {'key': '169', 'nombre': 'SAN JOSÉ INDEPENDENCIA', 'efe_key': '20'},
            {'key': '170', 'nombre': 'SAN JOSÉ LACHIGUIRI', 'efe_key': '20'},
            {'key': '171', 'nombre': 'SAN JOSÉ TENANGO', 'efe_key': '20'},
            {'key': '172', 'nombre': 'SAN JUAN ACHIUTLA', 'efe_key': '20'},
            {'key': '173', 'nombre': 'SAN JUAN ATEPEC', 'efe_key': '20'},
            {'key': '174', 'nombre': 'ÁNIMAS TRUJANO', 'efe_key': '20'},
            {'key': '175', 'nombre': 'SAN JUAN BAUTISTA ATATLAHUCA', 'efe_key': '20'},
            {'key': '176', 'nombre': 'SAN JUAN BAUTISTA COIXTLAHUACA', 'efe_key': '20'},
            {'key': '177', 'nombre': 'SAN JUAN BAUTISTA CUICATLÁN', 'efe_key': '20'},
            {'key': '178', 'nombre': 'SAN JUAN BAUTISTA GUELACHE', 'efe_key': '20'},
            {'key': '179', 'nombre': 'SAN JUAN BAUTISTA JAYACATLÁN', 'efe_key': '20'},
            {'key': '180', 'nombre': 'SAN JUAN BAUTISTA LO DE SOTO', 'efe_key': '20'},
            {'key': '181', 'nombre': 'SAN JUAN BAUTISTA SUCHITEPEC', 'efe_key': '20'},
            {'key': '182', 'nombre': 'SAN JUAN BAUTISTA TLACOATZINTEPEC', 'efe_key': '20'},
            {'key': '183', 'nombre': 'SAN JUAN BAUTISTA TLACHICHILCO', 'efe_key': '20'},
            {'key': '184', 'nombre': 'SAN JUAN BAUTISTA TUXTEPEC', 'efe_key': '20'},
            {'key': '185', 'nombre': 'SAN JUAN CACAHUATEPEC', 'efe_key': '20'},
            {'key': '186', 'nombre': 'SAN JUAN CIENEGUILLA', 'efe_key': '20'},
            {'key': '187', 'nombre': 'SAN JUAN COATZÓSPAM', 'efe_key': '20'},
            {'key': '188', 'nombre': 'SAN JUAN COLORADO', 'efe_key': '20'},
            {'key': '189', 'nombre': 'SAN JUAN COMALTEPEC', 'efe_key': '20'},
            {'key': '190', 'nombre': 'SAN JUAN COTZOCÓN', 'efe_key': '20'},
            {'key': '191', 'nombre': 'SAN JUAN CHICOMEZÚCHIL', 'efe_key': '20'},
            {'key': '192', 'nombre': 'SAN JUAN CHILATECA', 'efe_key': '20'},
            {'key': '193', 'nombre': 'SAN JUAN DEL ESTADO', 'efe_key': '20'},
            {'key': '194', 'nombre': 'SAN JUAN DEL RÍO', 'efe_key': '20'},
            {'key': '195', 'nombre': 'SAN JUAN DIUXI', 'efe_key': '20'},
            {'key': '196', 'nombre': 'SAN JUAN EVANGELISTA ANALCO', 'efe_key': '20'},
            {'key': '197', 'nombre': 'SAN JUAN GUELAVÍA', 'efe_key': '20'},
            {'key': '198', 'nombre': 'SAN JUAN GUICHICOVI', 'efe_key': '20'},
            {'key': '199', 'nombre': 'SAN JUAN IHUALTEPEC', 'efe_key': '20'},
            {'key': '200', 'nombre': 'SAN JUAN JUQUILA MIXES', 'efe_key': '20'},
            {'key': '201', 'nombre': 'SAN JUAN JUQUILA VIJANOS', 'efe_key': '20'},
            {'key': '202', 'nombre': 'SAN JUAN LACHAO', 'efe_key': '20'},
            {'key': '203', 'nombre': 'SAN JUAN LACHIGALLA', 'efe_key': '20'},
            {'key': '204', 'nombre': 'SAN JUAN LAJARCIA', 'efe_key': '20'},
            {'key': '205', 'nombre': 'SAN JUAN LALANA', 'efe_key': '20'},
            {'key': '206', 'nombre': 'SAN JUAN DE LOS CUÉS', 'efe_key': '20'},
            {'key': '207', 'nombre': 'SAN JUAN MAZATLÁN', 'efe_key': '20'},
            {'key': '208', 'nombre': 'SAN JUAN MIXTEPEC', 'efe_key': '20'},
            {'key': '209', 'nombre': 'SAN JUAN MIXTEPEC', 'efe_key': '20'},
            {'key': '210', 'nombre': 'SAN JUAN ÑUMÍ', 'efe_key': '20'},
            {'key': '211', 'nombre': 'SAN JUAN OZOLOTEPEC', 'efe_key': '20'},
            {'key': '212', 'nombre': 'SAN JUAN PETLAPA', 'efe_key': '20'},
            {'key': '213', 'nombre': 'SAN JUAN QUIAHIJE', 'efe_key': '20'},
            {'key': '214', 'nombre': 'SAN JUAN QUIOTEPEC', 'efe_key': '20'},
            {'key': '215', 'nombre': 'SAN JUAN SAYULTEPEC', 'efe_key': '20'},
            {'key': '216', 'nombre': 'SAN JUAN TABAÁ', 'efe_key': '20'},
            {'key': '217', 'nombre': 'SAN JUAN TAMAZOLA', 'efe_key': '20'},
            {'key': '218', 'nombre': 'SAN JUAN TEITA', 'efe_key': '20'},
            {'key': '219', 'nombre': 'SAN JUAN TEITIPAC', 'efe_key': '20'},
            {'key': '220', 'nombre': 'SAN JUAN TEPEUXILA', 'efe_key': '20'},
            {'key': '221', 'nombre': 'SAN JUAN TEPOSCOLULA', 'efe_key': '20'},
            {'key': '222', 'nombre': 'SAN JUAN YAEÉ', 'efe_key': '20'},
            {'key': '223', 'nombre': 'SAN JUAN YATZONA', 'efe_key': '20'},
            {'key': '224', 'nombre': 'SAN JUAN YUCUITA', 'efe_key': '20'},
            {'key': '225', 'nombre': 'SAN LORENZO', 'efe_key': '20'},
            {'key': '226', 'nombre': 'SAN LORENZO ALBARRADAS', 'efe_key': '20'},
            {'key': '227', 'nombre': 'SAN LORENZO CACAOTEPEC', 'efe_key': '20'},
            {'key': '228', 'nombre': 'SAN LORENZO CUAUNECUILTITLA', 'efe_key': '20'},
            {'key': '229', 'nombre': 'SAN LORENZO TEXMELÚCAN', 'efe_key': '20'},
            {'key': '230', 'nombre': 'SAN LORENZO VICTORIA', 'efe_key': '20'},
            {'key': '231', 'nombre': 'SAN LUCAS CAMOTLÁN', 'efe_key': '20'},
            {'key': '232', 'nombre': 'SAN LUCAS OJITLÁN', 'efe_key': '20'},
            {'key': '233', 'nombre': 'SAN LUCAS QUIAVINÍ', 'efe_key': '20'},
            {'key': '234', 'nombre': 'SAN LUCAS ZOQUIÁPAM', 'efe_key': '20'},
            {'key': '235', 'nombre': 'SAN LUIS AMATLÁN', 'efe_key': '20'},
            {'key': '236', 'nombre': 'SAN MARCIAL OZOLOTEPEC', 'efe_key': '20'},
            {'key': '237', 'nombre': 'SAN MARCOS ARTEAGA', 'efe_key': '20'},
            {'key': '238', 'nombre': 'SAN MARTÍN DE LOS CANSECOS', 'efe_key': '20'},
            {'key': '239', 'nombre': 'SAN MARTÍN HUAMELÚLPAM', 'efe_key': '20'},
            {'key': '240', 'nombre': 'SAN MARTÍN ITUNYOSO', 'efe_key': '20'},
            {'key': '241', 'nombre': 'SAN MARTÍN LACHILÁ', 'efe_key': '20'},
            {'key': '242', 'nombre': 'SAN MARTÍN PERAS', 'efe_key': '20'},
            {'key': '243', 'nombre': 'SAN MARTÍN TILCAJETE', 'efe_key': '20'},
            {'key': '244', 'nombre': 'SAN MARTÍN TOXPALAN', 'efe_key': '20'},
            {'key': '245', 'nombre': 'SAN MARTÍN ZACATEPEC', 'efe_key': '20'},
            {'key': '246', 'nombre': 'SAN MATEO CAJONOS', 'efe_key': '20'},
            {'key': '247', 'nombre': 'CAPULÁLPAM DE MÉNDEZ', 'efe_key': '20'},
            {'key': '248', 'nombre': 'SAN MATEO DEL MAR', 'efe_key': '20'},
            {'key': '249', 'nombre': 'SAN MATEO YOLOXOCHITLÁN', 'efe_key': '20'},
            {'key': '250', 'nombre': 'SAN MATEO ETLATONGO', 'efe_key': '20'},
            {'key': '251', 'nombre': 'SAN MATEO NEJÁPAM', 'efe_key': '20'},
            {'key': '252', 'nombre': 'SAN MATEO PEÑASCO', 'efe_key': '20'},
            {'key': '253', 'nombre': 'SAN MATEO PIÑAS', 'efe_key': '20'},
            {'key': '254', 'nombre': 'SAN MATEO RÍO HONDO', 'efe_key': '20'},
            {'key': '255', 'nombre': 'SAN MATEO SINDIHUI', 'efe_key': '20'},
            {'key': '256', 'nombre': 'SAN MATEO TLAPILTEPEC', 'efe_key': '20'},
            {'key': '257', 'nombre': 'SAN MELCHOR BETAZA', 'efe_key': '20'},
            {'key': '258', 'nombre': 'SAN MIGUEL ACHIUTLA', 'efe_key': '20'},
            {'key': '259', 'nombre': 'SAN MIGUEL AHUEHUETITLÁN', 'efe_key': '20'},
            {'key': '260', 'nombre': 'SAN MIGUEL ALOÁPAM', 'efe_key': '20'},
            {'key': '261', 'nombre': 'SAN MIGUEL AMATITLÁN', 'efe_key': '20'},
            {'key': '262', 'nombre': 'SAN MIGUEL AMATLÁN', 'efe_key': '20'},
            {'key': '263', 'nombre': 'SAN MIGUEL COATLÁN', 'efe_key': '20'},
            {'key': '264', 'nombre': 'SAN MIGUEL CHICAHUA', 'efe_key': '20'},
            {'key': '265', 'nombre': 'SAN MIGUEL CHIMALAPA', 'efe_key': '20'},
            {'key': '266', 'nombre': 'SAN MIGUEL DEL PUERTO', 'efe_key': '20'},
            {'key': '267', 'nombre': 'SAN MIGUEL DEL RÍO', 'efe_key': '20'},
            {'key': '268', 'nombre': 'SAN MIGUEL EJUTLA', 'efe_key': '20'},
            {'key': '269', 'nombre': 'SAN MIGUEL EL GRANDE', 'efe_key': '20'},
            {'key': '270', 'nombre': 'SAN MIGUEL HUAUTLA', 'efe_key': '20'},
            {'key': '271', 'nombre': 'SAN MIGUEL MIXTEPEC', 'efe_key': '20'},
            {'key': '272', 'nombre': 'SAN MIGUEL PANIXTLAHUACA', 'efe_key': '20'},
            {'key': '273', 'nombre': 'SAN MIGUEL PERAS', 'efe_key': '20'},
            {'key': '274', 'nombre': 'SAN MIGUEL PIEDRAS', 'efe_key': '20'},
            {'key': '275', 'nombre': 'SAN MIGUEL QUETZALTEPEC', 'efe_key': '20'},
            {'key': '276', 'nombre': 'SAN MIGUEL SANTA FLOR', 'efe_key': '20'},
            {'key': '277', 'nombre': 'VILLA SOLA DE VEGA', 'efe_key': '20'},
            {'key': '278', 'nombre': 'SAN MIGUEL SOYALTEPEC', 'efe_key': '20'},
            {'key': '279', 'nombre': 'SAN MIGUEL SUCHIXTEPEC', 'efe_key': '20'},
            {'key': '280', 'nombre': 'VILLA TALEA DE CASTRO', 'efe_key': '20'},
            {'key': '281', 'nombre': 'SAN MIGUEL TECOMATLÁN', 'efe_key': '20'},
            {'key': '282', 'nombre': 'SAN MIGUEL TENANGO', 'efe_key': '20'},
            {'key': '283', 'nombre': 'SAN MIGUEL TEQUIXTEPEC', 'efe_key': '20'},
            {'key': '284', 'nombre': 'SAN MIGUEL TILQUIÁPAM', 'efe_key': '20'},
            {'key': '285', 'nombre': 'SAN MIGUEL TLACAMAMA', 'efe_key': '20'},
            {'key': '286', 'nombre': 'SAN MIGUEL TLACOTEPEC', 'efe_key': '20'},
            {'key': '287', 'nombre': 'SAN MIGUEL TULANCINGO', 'efe_key': '20'},
            {'key': '288', 'nombre': 'SAN MIGUEL YOTAO', 'efe_key': '20'},
            {'key': '289', 'nombre': 'SAN NICOLÁS', 'efe_key': '20'},
            {'key': '290', 'nombre': 'SAN NICOLÁS HIDALGO', 'efe_key': '20'},
            {'key': '291', 'nombre': 'SAN PABLO COATLÁN', 'efe_key': '20'},
            {'key': '292', 'nombre': 'SAN PABLO CUATRO VENADOS', 'efe_key': '20'},
            {'key': '293', 'nombre': 'SAN PABLO ETLA', 'efe_key': '20'},
            {'key': '294', 'nombre': 'SAN PABLO HUITZO', 'efe_key': '20'},
            {'key': '295', 'nombre': 'SAN PABLO HUIXTEPEC', 'efe_key': '20'},
            {'key': '296', 'nombre': 'SAN PABLO MACUILTIANGUIS', 'efe_key': '20'},
            {'key': '297', 'nombre': 'SAN PABLO TIJALTEPEC', 'efe_key': '20'},
            {'key': '298', 'nombre': 'SAN PABLO VILLA DE MITLA', 'efe_key': '20'},
            {'key': '299', 'nombre': 'SAN PABLO YAGANIZA', 'efe_key': '20'},
            {'key': '300', 'nombre': 'SAN PEDRO AMUZGOS', 'efe_key': '20'},
            {'key': '301', 'nombre': 'SAN PEDRO APÓSTOL', 'efe_key': '20'},
            {'key': '302', 'nombre': 'SAN PEDRO ATOYAC', 'efe_key': '20'},
            {'key': '303', 'nombre': 'SAN PEDRO CAJONOS', 'efe_key': '20'},
            {'key': '304', 'nombre': 'SAN PEDRO COXCALTEPEC CÁNTAROS', 'efe_key': '20'},
            {'key': '305', 'nombre': 'SAN PEDRO COMITANCILLO', 'efe_key': '20'},
            {'key': '306', 'nombre': 'SAN PEDRO EL ALTO', 'efe_key': '20'},
            {'key': '307', 'nombre': 'SAN PEDRO HUAMELULA', 'efe_key': '20'},
            {'key': '308', 'nombre': 'SAN PEDRO HUILOTEPEC', 'efe_key': '20'},
            {'key': '309', 'nombre': 'SAN PEDRO IXCATLÁN', 'efe_key': '20'},
            {'key': '310', 'nombre': 'SAN PEDRO IXTLAHUACA', 'efe_key': '20'},
            {'key': '311', 'nombre': 'SAN PEDRO JALTEPETONGO', 'efe_key': '20'},
            {'key': '312', 'nombre': 'SAN PEDRO JICAYÁN', 'efe_key': '20'},
            {'key': '313', 'nombre': 'SAN PEDRO JOCOTIPAC', 'efe_key': '20'},
            {'key': '314', 'nombre': 'SAN PEDRO JUCHATENGO', 'efe_key': '20'},
            {'key': '315', 'nombre': 'SAN PEDRO MÁRTIR', 'efe_key': '20'},
            {'key': '316', 'nombre': 'SAN PEDRO MÁRTIR QUIECHAPA', 'efe_key': '20'},
            {'key': '317', 'nombre': 'SAN PEDRO MÁRTIR YUCUXACO', 'efe_key': '20'},
            {'key': '318', 'nombre': 'SAN PEDRO MIXTEPEC', 'efe_key': '20'},
            {'key': '319', 'nombre': 'SAN PEDRO MIXTEPEC', 'efe_key': '20'},
            {'key': '320', 'nombre': 'SAN PEDRO MOLINOS', 'efe_key': '20'},
            {'key': '321', 'nombre': 'SAN PEDRO NOPALA', 'efe_key': '20'},
            {'key': '322', 'nombre': 'SAN PEDRO OCOPETATILLO', 'efe_key': '20'},
            {'key': '323', 'nombre': 'SAN PEDRO OCOTEPEC', 'efe_key': '20'},
            {'key': '324', 'nombre': 'SAN PEDRO POCHUTLA', 'efe_key': '20'},
            {'key': '325', 'nombre': 'SAN PEDRO QUIATONI', 'efe_key': '20'},
            {'key': '326', 'nombre': 'SAN PEDRO SOCHIÁPAM', 'efe_key': '20'},
            {'key': '327', 'nombre': 'SAN PEDRO TAPANATEPEC', 'efe_key': '20'},
            {'key': '328', 'nombre': 'SAN PEDRO TAVICHE', 'efe_key': '20'},
            {'key': '329', 'nombre': 'SAN PEDRO TEOZACOALCO', 'efe_key': '20'},
            {'key': '330', 'nombre': 'SAN PEDRO TEUTILA', 'efe_key': '20'},
            {'key': '331', 'nombre': 'SAN PEDRO TIDAÁ', 'efe_key': '20'},
            {'key': '332', 'nombre': 'SAN PEDRO TOPILTEPEC', 'efe_key': '20'},
            {'key': '333', 'nombre': 'SAN PEDRO TOTOLÁPAM', 'efe_key': '20'},
            {'key': '334', 'nombre': 'VILLA DE TUTUTEPEC', 'efe_key': '20'},
            {'key': '335', 'nombre': 'SAN PEDRO YANERI', 'efe_key': '20'},
            {'key': '336', 'nombre': 'SAN PEDRO YÓLOX', 'efe_key': '20'},
            {'key': '337', 'nombre': 'SAN PEDRO Y SAN PABLO AYUTLA', 'efe_key': '20'},
            {'key': '338', 'nombre': 'VILLA DE ETLA', 'efe_key': '20'},
            {'key': '339', 'nombre': 'SAN PEDRO Y SAN PABLO TEPOSCOLULA', 'efe_key': '20'},
            {'key': '340', 'nombre': 'SAN PEDRO Y SAN PABLO TEQUIXTEPEC', 'efe_key': '20'},
            {'key': '341', 'nombre': 'SAN PEDRO YUCUNAMA', 'efe_key': '20'},
            {'key': '342', 'nombre': 'SAN RAYMUNDO JALPAN', 'efe_key': '20'},
            {'key': '343', 'nombre': 'SAN SEBASTIÁN ABASOLO', 'efe_key': '20'},
            {'key': '344', 'nombre': 'SAN SEBASTIÁN COATLÁN', 'efe_key': '20'},
            {'key': '345', 'nombre': 'SAN SEBASTIÁN IXCAPA', 'efe_key': '20'},
            {'key': '346', 'nombre': 'SAN SEBASTIÁN NICANANDUTA', 'efe_key': '20'},
            {'key': '347', 'nombre': 'SAN SEBASTIÁN RÍO HONDO', 'efe_key': '20'},
            {'key': '348', 'nombre': 'SAN SEBASTIÁN TECOMAXTLAHUACA', 'efe_key': '20'},
            {'key': '349', 'nombre': 'SAN SEBASTIÁN TEITIPAC', 'efe_key': '20'},
            {'key': '350', 'nombre': 'SAN SEBASTIÁN TUTLA', 'efe_key': '20'},
            {'key': '351', 'nombre': 'SAN SIMÓN ALMOLONGAS', 'efe_key': '20'},
            {'key': '352', 'nombre': 'SAN SIMÓN ZAHUATLÁN', 'efe_key': '20'},
            {'key': '353', 'nombre': 'SANTA ANA', 'efe_key': '20'},
            {'key': '354', 'nombre': 'SANTA ANA ATEIXTLAHUACA', 'efe_key': '20'},
            {'key': '355', 'nombre': 'SANTA ANA CUAUHTÉMOC', 'efe_key': '20'},
            {'key': '356', 'nombre': 'SANTA ANA DEL VALLE', 'efe_key': '20'},
            {'key': '357', 'nombre': 'SANTA ANA TAVELA', 'efe_key': '20'},
            {'key': '358', 'nombre': 'SANTA ANA TLAPACOYAN', 'efe_key': '20'},
            {'key': '359', 'nombre': 'SANTA ANA YARENI', 'efe_key': '20'},
            {'key': '360', 'nombre': 'SANTA ANA ZEGACHE', 'efe_key': '20'},
            {'key': '361', 'nombre': 'SANTA CATALINA QUIERÍ', 'efe_key': '20'},
            {'key': '362', 'nombre': 'SANTA CATARINA CUIXTLA', 'efe_key': '20'},
            {'key': '363', 'nombre': 'SANTA CATARINA IXTEPEJI', 'efe_key': '20'},
            {'key': '364', 'nombre': 'SANTA CATARINA JUQUILA', 'efe_key': '20'},
            {'key': '365', 'nombre': 'SANTA CATARINA LACHATAO', 'efe_key': '20'},
            {'key': '366', 'nombre': 'SANTA CATARINA LOXICHA', 'efe_key': '20'},
            {'key': '367', 'nombre': 'SANTA CATARINA MECHOACÁN', 'efe_key': '20'},
            {'key': '368', 'nombre': 'SANTA CATARINA MINAS', 'efe_key': '20'},
            {'key': '369', 'nombre': 'SANTA CATARINA QUIANÉ', 'efe_key': '20'},
            {'key': '370', 'nombre': 'SANTA CATARINA TAYATA', 'efe_key': '20'},
            {'key': '371', 'nombre': 'SANTA CATARINA TICUÁ', 'efe_key': '20'},
            {'key': '372', 'nombre': 'SANTA CATARINA YOSONOTÚ', 'efe_key': '20'},
            {'key': '373', 'nombre': 'SANTA CATARINA ZAPOQUILA', 'efe_key': '20'},
            {'key': '374', 'nombre': 'SANTA CRUZ ACATEPEC', 'efe_key': '20'},
            {'key': '375', 'nombre': 'SANTA CRUZ AMILPAS', 'efe_key': '20'},
            {'key': '376', 'nombre': 'SANTA CRUZ DE BRAVO', 'efe_key': '20'},
            {'key': '377', 'nombre': 'SANTA CRUZ ITUNDUJIA', 'efe_key': '20'},
            {'key': '378', 'nombre': 'SANTA CRUZ MIXTEPEC', 'efe_key': '20'},
            {'key': '379', 'nombre': 'SANTA CRUZ NUNDACO', 'efe_key': '20'},
            {'key': '380', 'nombre': 'SANTA CRUZ PAPALUTLA', 'efe_key': '20'},
            {'key': '381', 'nombre': 'SANTA CRUZ TACACHE DE MINA', 'efe_key': '20'},
            {'key': '382', 'nombre': 'SANTA CRUZ TACAHUA', 'efe_key': '20'},
            {'key': '383', 'nombre': 'SANTA CRUZ TAYATA', 'efe_key': '20'},
            {'key': '384', 'nombre': 'SANTA CRUZ XITLA', 'efe_key': '20'},
            {'key': '385', 'nombre': 'SANTA CRUZ XOXOCOTLÁN', 'efe_key': '20'},
            {'key': '386', 'nombre': 'SANTA CRUZ ZENZONTEPEC', 'efe_key': '20'},
            {'key': '387', 'nombre': 'SANTA GERTRUDIS', 'efe_key': '20'},
            {'key': '388', 'nombre': 'SANTA INÉS DEL MONTE', 'efe_key': '20'},
            {'key': '389', 'nombre': 'SANTA INÉS YATZECHE', 'efe_key': '20'},
            {'key': '390', 'nombre': 'SANTA LUCÍA DEL CAMINO', 'efe_key': '20'},
            {'key': '391', 'nombre': 'SANTA LUCÍA MIAHUATLÁN', 'efe_key': '20'},
            {'key': '392', 'nombre': 'SANTA LUCÍA MONTEVERDE', 'efe_key': '20'},
            {'key': '393', 'nombre': 'SANTA LUCÍA OCOTLÁN', 'efe_key': '20'},
            {'key': '394', 'nombre': 'SANTA MARÍA ALOTEPEC', 'efe_key': '20'},
            {'key': '395', 'nombre': 'SANTA MARÍA APAZCO', 'efe_key': '20'},
            {'key': '396', 'nombre': 'SANTA MARÍA LA ASUNCIÓN', 'efe_key': '20'},
            {'key': '397', 'nombre': 'HEROICA CIUDAD DE TLAXIACO', 'efe_key': '20'},
            {'key': '398', 'nombre': 'AYOQUEZCO DE ALDAMA', 'efe_key': '20'},
            {'key': '399', 'nombre': 'SANTA MARÍA ATZOMPA', 'efe_key': '20'},
            {'key': '400', 'nombre': 'SANTA MARÍA CAMOTLÁN', 'efe_key': '20'},
            {'key': '401', 'nombre': 'SANTA MARÍA COLOTEPEC', 'efe_key': '20'},
            {'key': '402', 'nombre': 'SANTA MARÍA CORTIJO', 'efe_key': '20'},
            {'key': '403', 'nombre': 'SANTA MARÍA COYOTEPEC', 'efe_key': '20'},
            {'key': '404', 'nombre': 'SANTA MARÍA CHACHOÁPAM', 'efe_key': '20'},
            {'key': '405', 'nombre': 'VILLA DE CHILAPA DE DÍAZ', 'efe_key': '20'},
            {'key': '406', 'nombre': 'SANTA MARÍA CHILCHOTLA', 'efe_key': '20'},
            {'key': '407', 'nombre': 'SANTA MARÍA CHIMALAPA', 'efe_key': '20'},
            {'key': '408', 'nombre': 'SANTA MARÍA DEL ROSARIO', 'efe_key': '20'},
            {'key': '409', 'nombre': 'SANTA MARÍA DEL TULE', 'efe_key': '20'},
            {'key': '410', 'nombre': 'SANTA MARÍA ECATEPEC', 'efe_key': '20'},
            {'key': '411', 'nombre': 'SANTA MARÍA GUELACÉ', 'efe_key': '20'},
            {'key': '412', 'nombre': 'SANTA MARÍA GUIENAGATI', 'efe_key': '20'},
            {'key': '413', 'nombre': 'SANTA MARÍA HUATULCO', 'efe_key': '20'},
            {'key': '414', 'nombre': 'SANTA MARÍA HUAZOLOTITLÁN', 'efe_key': '20'},
            {'key': '415', 'nombre': 'SANTA MARÍA IPALAPA', 'efe_key': '20'},
            {'key': '416', 'nombre': 'SANTA MARÍA IXCATLÁN', 'efe_key': '20'},
            {'key': '417', 'nombre': 'SANTA MARÍA JACATEPEC', 'efe_key': '20'},
            {'key': '418', 'nombre': 'SANTA MARÍA JALAPA DEL MARQUÉS', 'efe_key': '20'},
            {'key': '419', 'nombre': 'SANTA MARÍA JALTIANGUIS', 'efe_key': '20'},
            {'key': '420', 'nombre': 'SANTA MARÍA LACHIXÍO', 'efe_key': '20'},
            {'key': '421', 'nombre': 'SANTA MARÍA MIXTEQUILLA', 'efe_key': '20'},
            {'key': '422', 'nombre': 'SANTA MARÍA NATIVITAS', 'efe_key': '20'},
            {'key': '423', 'nombre': 'SANTA MARÍA NDUAYACO', 'efe_key': '20'},
            {'key': '424', 'nombre': 'SANTA MARÍA OZOLOTEPEC', 'efe_key': '20'},
            {'key': '425', 'nombre': 'SANTA MARÍA PÁPALO', 'efe_key': '20'},
            {'key': '426', 'nombre': 'SANTA MARÍA PEÑOLES', 'efe_key': '20'},
            {'key': '427', 'nombre': 'SANTA MARÍA PETAPA', 'efe_key': '20'},
            {'key': '428', 'nombre': 'SANTA MARÍA QUIEGOLANI', 'efe_key': '20'},
            {'key': '429', 'nombre': 'SANTA MARÍA SOLA', 'efe_key': '20'},
            {'key': '430', 'nombre': 'SANTA MARÍA TATALTEPEC', 'efe_key': '20'},
            {'key': '431', 'nombre': 'SANTA MARÍA TECOMAVACA', 'efe_key': '20'},
            {'key': '432', 'nombre': 'SANTA MARÍA TEMAXCALAPA', 'efe_key': '20'},
            {'key': '433', 'nombre': 'SANTA MARÍA TEMAXCALTEPEC', 'efe_key': '20'},
            {'key': '434', 'nombre': 'SANTA MARÍA TEOPOXCO', 'efe_key': '20'},
            {'key': '435', 'nombre': 'SANTA MARÍA TEPANTLALI', 'efe_key': '20'},
            {'key': '436', 'nombre': 'SANTA MARÍA TEXCATITLÁN', 'efe_key': '20'},
            {'key': '437', 'nombre': 'SANTA MARÍA TLAHUITOLTEPEC', 'efe_key': '20'},
            {'key': '438', 'nombre': 'SANTA MARÍA TLALIXTAC', 'efe_key': '20'},
            {'key': '439', 'nombre': 'SANTA MARÍA TONAMECA', 'efe_key': '20'},
            {'key': '440', 'nombre': 'SANTA MARÍA TOTOLAPILLA', 'efe_key': '20'},
            {'key': '441', 'nombre': 'SANTA MARÍA XADANI', 'efe_key': '20'},
            {'key': '442', 'nombre': 'SANTA MARÍA YALINA', 'efe_key': '20'},
            {'key': '443', 'nombre': 'SANTA MARÍA YAVESÍA', 'efe_key': '20'},
            {'key': '444', 'nombre': 'SANTA MARÍA YOLOTEPEC', 'efe_key': '20'},
            {'key': '445', 'nombre': 'SANTA MARÍA YOSOYÚA', 'efe_key': '20'},
            {'key': '446', 'nombre': 'SANTA MARÍA YUCUHITI', 'efe_key': '20'},
            {'key': '447', 'nombre': 'SANTA MARÍA ZACATEPEC', 'efe_key': '20'},
            {'key': '448', 'nombre': 'SANTA MARÍA ZANIZA', 'efe_key': '20'},
            {'key': '449', 'nombre': 'SANTA MARÍA ZOQUITLÁN', 'efe_key': '20'},
            {'key': '450', 'nombre': 'SANTIAGO AMOLTEPEC', 'efe_key': '20'},
            {'key': '451', 'nombre': 'SANTIAGO APOALA', 'efe_key': '20'},
            {'key': '452', 'nombre': 'SANTIAGO APÓSTOL', 'efe_key': '20'},
            {'key': '453', 'nombre': 'SANTIAGO ASTATA', 'efe_key': '20'},
            {'key': '454', 'nombre': 'SANTIAGO ATITLÁN', 'efe_key': '20'},
            {'key': '455', 'nombre': 'SANTIAGO AYUQUILILLA', 'efe_key': '20'},
            {'key': '456', 'nombre': 'SANTIAGO CACALOXTEPEC', 'efe_key': '20'},
            {'key': '457', 'nombre': 'SANTIAGO CAMOTLÁN', 'efe_key': '20'},
            {'key': '458', 'nombre': 'SANTIAGO COMALTEPEC', 'efe_key': '20'},
            {'key': '459', 'nombre': 'SANTIAGO CHAZUMBA', 'efe_key': '20'},
            {'key': '460', 'nombre': 'SANTIAGO CHOÁPAM', 'efe_key': '20'},
            {'key': '461', 'nombre': 'SANTIAGO DEL RÍO', 'efe_key': '20'},
            {'key': '462', 'nombre': 'SANTIAGO HUAJOLOTITLÁN', 'efe_key': '20'},
            {'key': '463', 'nombre': 'SANTIAGO HUAUCLILLA', 'efe_key': '20'},
            {'key': '464', 'nombre': 'SANTIAGO IHUITLÁN PLUMAS', 'efe_key': '20'},
            {'key': '465', 'nombre': 'SANTIAGO IXCUINTEPEC', 'efe_key': '20'},
            {'key': '466', 'nombre': 'SANTIAGO IXTAYUTLA', 'efe_key': '20'},
            {'key': '467', 'nombre': 'SANTIAGO JAMILTEPEC', 'efe_key': '20'},
            {'key': '468', 'nombre': 'SANTIAGO JOCOTEPEC', 'efe_key': '20'},
            {'key': '469', 'nombre': 'SANTIAGO JUXTLAHUACA', 'efe_key': '20'},
            {'key': '470', 'nombre': 'SANTIAGO LACHIGUIRI', 'efe_key': '20'},
            {'key': '471', 'nombre': 'SANTIAGO LALOPA', 'efe_key': '20'},
            {'key': '472', 'nombre': 'SANTIAGO LAOLLAGA', 'efe_key': '20'},
            {'key': '473', 'nombre': 'SANTIAGO LAXOPA', 'efe_key': '20'},
            {'key': '474', 'nombre': 'SANTIAGO LLANO GRANDE', 'efe_key': '20'},
            {'key': '475', 'nombre': 'SANTIAGO MATATLÁN', 'efe_key': '20'},
            {'key': '476', 'nombre': 'SANTIAGO MILTEPEC', 'efe_key': '20'},
            {'key': '477', 'nombre': 'SANTIAGO MINAS', 'efe_key': '20'},
            {'key': '478', 'nombre': 'SANTIAGO NACALTEPEC', 'efe_key': '20'},
            {'key': '479', 'nombre': 'SANTIAGO NEJAPILLA', 'efe_key': '20'},
            {'key': '480', 'nombre': 'SANTIAGO NUNDICHE', 'efe_key': '20'},
            {'key': '481', 'nombre': 'SANTIAGO NUYOÓ', 'efe_key': '20'},
            {'key': '482', 'nombre': 'SANTIAGO PINOTEPA NACIONAL', 'efe_key': '20'},
            {'key': '483', 'nombre': 'SANTIAGO SUCHILQUITONGO', 'efe_key': '20'},
            {'key': '484', 'nombre': 'SANTIAGO TAMAZOLA', 'efe_key': '20'},
            {'key': '485', 'nombre': 'SANTIAGO TAPEXTLA', 'efe_key': '20'},
            {'key': '486', 'nombre': 'VILLA TEJÚPAM DE LA UNIÓN', 'efe_key': '20'},
            {'key': '487', 'nombre': 'SANTIAGO TENANGO', 'efe_key': '20'},
            {'key': '488', 'nombre': 'SANTIAGO TEPETLAPA', 'efe_key': '20'},
            {'key': '489', 'nombre': 'SANTIAGO TETEPEC', 'efe_key': '20'},
            {'key': '490', 'nombre': 'SANTIAGO TEXCALCINGO', 'efe_key': '20'},
            {'key': '491', 'nombre': 'SANTIAGO TEXTITLÁN', 'efe_key': '20'},
            {'key': '492', 'nombre': 'SANTIAGO TILANTONGO', 'efe_key': '20'},
            {'key': '493', 'nombre': 'SANTIAGO TILLO', 'efe_key': '20'},
            {'key': '494', 'nombre': 'SANTIAGO TLAZOYALTEPEC', 'efe_key': '20'},
            {'key': '495', 'nombre': 'SANTIAGO XANICA', 'efe_key': '20'},
            {'key': '496', 'nombre': 'SANTIAGO XIACUÍ', 'efe_key': '20'},
            {'key': '497', 'nombre': 'SANTIAGO YAITEPEC', 'efe_key': '20'},
            {'key': '498', 'nombre': 'SANTIAGO YAVEO', 'efe_key': '20'},
            {'key': '499', 'nombre': 'SANTIAGO YOLOMÉCATL', 'efe_key': '20'},
            {'key': '500', 'nombre': 'SANTIAGO YOSONDÚA', 'efe_key': '20'},
            {'key': '501', 'nombre': 'SANTIAGO YUCUYACHI', 'efe_key': '20'},
            {'key': '502', 'nombre': 'SANTIAGO ZACATEPEC', 'efe_key': '20'},
            {'key': '503', 'nombre': 'SANTIAGO ZOOCHILA', 'efe_key': '20'},
            {'key': '504', 'nombre': 'NUEVO ZOQUIÁPAM', 'efe_key': '20'},
            {'key': '505', 'nombre': 'SANTO DOMINGO INGENIO', 'efe_key': '20'},
            {'key': '506', 'nombre': 'SANTO DOMINGO ALBARRADAS', 'efe_key': '20'},
            {'key': '507', 'nombre': 'SANTO DOMINGO ARMENTA', 'efe_key': '20'},
            {'key': '508', 'nombre': 'SANTO DOMINGO CHIHUITÁN', 'efe_key': '20'},
            {'key': '509', 'nombre': 'SANTO DOMINGO DE MORELOS', 'efe_key': '20'},
            {'key': '510', 'nombre': 'SANTO DOMINGO IXCATLÁN', 'efe_key': '20'},
            {'key': '511', 'nombre': 'SANTO DOMINGO NUXAÁ', 'efe_key': '20'},
            {'key': '512', 'nombre': 'SANTO DOMINGO OZOLOTEPEC', 'efe_key': '20'},
            {'key': '513', 'nombre': 'SANTO DOMINGO PETAPA', 'efe_key': '20'},
            {'key': '514', 'nombre': 'SANTO DOMINGO ROAYAGA', 'efe_key': '20'},
            {'key': '515', 'nombre': 'SANTO DOMINGO TEHUANTEPEC', 'efe_key': '20'},
            {'key': '516', 'nombre': 'SANTO DOMINGO TEOJOMULCO', 'efe_key': '20'},
            {'key': '517', 'nombre': 'SANTO DOMINGO TEPUXTEPEC', 'efe_key': '20'},
            {'key': '518', 'nombre': 'SANTO DOMINGO TLATAYÁPAM', 'efe_key': '20'},
            {'key': '519', 'nombre': 'SANTO DOMINGO TOMALTEPEC', 'efe_key': '20'},
            {'key': '520', 'nombre': 'SANTO DOMINGO TONALÁ', 'efe_key': '20'},
            {'key': '521', 'nombre': 'SANTO DOMINGO TONALTEPEC', 'efe_key': '20'},
            {'key': '522', 'nombre': 'SANTO DOMINGO XAGACÍA', 'efe_key': '20'},
            {'key': '523', 'nombre': 'SANTO DOMINGO YANHUITLÁN', 'efe_key': '20'},
            {'key': '524', 'nombre': 'SANTO DOMINGO YODOHINO', 'efe_key': '20'},
            {'key': '525', 'nombre': 'SANTO DOMINGO ZANATEPEC', 'efe_key': '20'},
            {'key': '526', 'nombre': 'SANTOS REYES NOPALA', 'efe_key': '20'},
            {'key': '527', 'nombre': 'SANTOS REYES PÁPALO', 'efe_key': '20'},
            {'key': '528', 'nombre': 'SANTOS REYES TEPEJILLO', 'efe_key': '20'},
            {'key': '529', 'nombre': 'SANTOS REYES YUCUNÁ', 'efe_key': '20'},
            {'key': '530', 'nombre': 'SANTO TOMÁS JALIEZA', 'efe_key': '20'},
            {'key': '531', 'nombre': 'SANTO TOMÁS MAZALTEPEC', 'efe_key': '20'},
            {'key': '532', 'nombre': 'SANTO TOMÁS OCOTEPEC', 'efe_key': '20'},
            {'key': '533', 'nombre': 'SANTO TOMÁS TAMAZULAPAN', 'efe_key': '20'},
            {'key': '534', 'nombre': 'SAN VICENTE COATLÁN', 'efe_key': '20'},
            {'key': '535', 'nombre': 'SAN VICENTE LACHIXÍO', 'efe_key': '20'},
            {'key': '536', 'nombre': 'SAN VICENTE NUÑÚ', 'efe_key': '20'},
            {'key': '537', 'nombre': 'SILACAYOÁPAM', 'efe_key': '20'},
            {'key': '538', 'nombre': 'SITIO DE XITLAPEHUA', 'efe_key': '20'},
            {'key': '539', 'nombre': 'SOLEDAD ETLA', 'efe_key': '20'},
            {'key': '540', 'nombre': 'VILLA DE TAMAZULÁPAM DEL PROGRESO', 'efe_key': '20'},
            {'key': '541', 'nombre': 'TANETZE DE ZARAGOZA', 'efe_key': '20'},
            {'key': '542', 'nombre': 'TANICHE', 'efe_key': '20'},
            {'key': '543', 'nombre': 'TATALTEPEC DE VALDÉS', 'efe_key': '20'},
            {'key': '544', 'nombre': 'TEOCOCUILCO DE MARCOS PÉREZ', 'efe_key': '20'},
            {'key': '545', 'nombre': 'TEOTITLÁN DE FLORES MAGÓN', 'efe_key': '20'},
            {'key': '546', 'nombre': 'TEOTITLÁN DEL VALLE', 'efe_key': '20'},
            {'key': '547', 'nombre': 'TEOTONGO', 'efe_key': '20'},
            {'key': '548', 'nombre': 'TEPELMEME VILLA DE MORELOS', 'efe_key': '20'},
            {'key': '549', 'nombre': 'HEROICA VILLA TEZOATLÁN DE SEGURA Y LUNA, CUNA DE LA INDEPENDENCIA DE OAXACA', 'efe_key': '20'},
            {'key': '550', 'nombre': 'SAN JERÓNIMO TLACOCHAHUAYA', 'efe_key': '20'},
            {'key': '551', 'nombre': 'TLACOLULA DE MATAMOROS', 'efe_key': '20'},
            {'key': '552', 'nombre': 'TLACOTEPEC PLUMAS', 'efe_key': '20'},
            {'key': '553', 'nombre': 'TLALIXTAC DE CABRERA', 'efe_key': '20'},
            {'key': '554', 'nombre': 'TOTONTEPEC VILLA DE MORELOS', 'efe_key': '20'},
            {'key': '555', 'nombre': 'TRINIDAD ZAACHILA', 'efe_key': '20'},
            {'key': '556', 'nombre': 'LA TRINIDAD VISTA HERMOSA', 'efe_key': '20'},
            {'key': '557', 'nombre': 'UNIÓN HIDALGO', 'efe_key': '20'},
            {'key': '558', 'nombre': 'VALERIO TRUJANO', 'efe_key': '20'},
            {'key': '559', 'nombre': 'SAN JUAN BAUTISTA VALLE NACIONAL', 'efe_key': '20'},
            {'key': '560', 'nombre': 'VILLA DÍAZ ORDAZ', 'efe_key': '20'},
            {'key': '561', 'nombre': 'YAXE', 'efe_key': '20'},
            {'key': '562', 'nombre': 'MAGDALENA YODOCONO DE PORFIRIO DÍAZ', 'efe_key': '20'},
            {'key': '563', 'nombre': 'YOGANA', 'efe_key': '20'},
            {'key': '564', 'nombre': 'YUTANDUCHI DE GUERRERO', 'efe_key': '20'},
            {'key': '565', 'nombre': 'VILLA DE ZAACHILA', 'efe_key': '20'},
            {'key': '566', 'nombre': 'SAN MATEO YUCUTINDOO', 'efe_key': '20'},
            {'key': '567', 'nombre': 'ZAPOTITLÁN LAGUNAS', 'efe_key': '20'},
            {'key': '568', 'nombre': 'ZAPOTITLÁN PALMAS', 'efe_key': '20'},
            {'key': '569', 'nombre': 'SANTA INÉS DE ZARAGOZA', 'efe_key': '20'},
            {'key': '570', 'nombre': 'ZIMATLÁN DE ÁLVAREZ', 'efe_key': '20'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '20'},
            {'key': '001', 'nombre': 'ACAJETE', 'efe_key': '21'},
            {'key': '002', 'nombre': 'ACATENO', 'efe_key': '21'},
            {'key': '003', 'nombre': 'ACATLÁN', 'efe_key': '21'},
            {'key': '004', 'nombre': 'ACATZINGO', 'efe_key': '21'},
            {'key': '005', 'nombre': 'ACTEOPAN', 'efe_key': '21'},
            {'key': '006', 'nombre': 'AHUACATLÁN', 'efe_key': '21'},
            {'key': '007', 'nombre': 'AHUATLÁN', 'efe_key': '21'},
            {'key': '008', 'nombre': 'AHUAZOTEPEC', 'efe_key': '21'},
            {'key': '009', 'nombre': 'AHUEHUETITLA', 'efe_key': '21'},
            {'key': '010', 'nombre': 'AJALPAN', 'efe_key': '21'},
            {'key': '011', 'nombre': 'ALBINO ZERTUCHE', 'efe_key': '21'},
            {'key': '012', 'nombre': 'ALJOJUCA', 'efe_key': '21'},
            {'key': '013', 'nombre': 'ALTEPEXI', 'efe_key': '21'},
            {'key': '014', 'nombre': 'AMIXTLÁN', 'efe_key': '21'},
            {'key': '015', 'nombre': 'AMOZOC', 'efe_key': '21'},
            {'key': '016', 'nombre': 'AQUIXTLA', 'efe_key': '21'},
            {'key': '017', 'nombre': 'ATEMPAN', 'efe_key': '21'},
            {'key': '018', 'nombre': 'ATEXCAL', 'efe_key': '21'},
            {'key': '019', 'nombre': 'ATLIXCO', 'efe_key': '21'},
            {'key': '020', 'nombre': 'ATOYATEMPAN', 'efe_key': '21'},
            {'key': '021', 'nombre': 'ATZALA', 'efe_key': '21'},
            {'key': '022', 'nombre': 'ATZITZIHUACÁN', 'efe_key': '21'},
            {'key': '023', 'nombre': 'ATZITZINTLA', 'efe_key': '21'},
            {'key': '024', 'nombre': 'AXUTLA', 'efe_key': '21'},
            {'key': '025', 'nombre': 'AYOTOXCO DE GUERRERO', 'efe_key': '21'},
            {'key': '026', 'nombre': 'CALPAN', 'efe_key': '21'},
            {'key': '027', 'nombre': 'CALTEPEC', 'efe_key': '21'},
            {'key': '028', 'nombre': 'CAMOCUAUTLA', 'efe_key': '21'},
            {'key': '029', 'nombre': 'CAXHUACAN', 'efe_key': '21'},
            {'key': '030', 'nombre': 'COATEPEC', 'efe_key': '21'},
            {'key': '031', 'nombre': 'COATZINGO', 'efe_key': '21'},
            {'key': '032', 'nombre': 'COHETZALA', 'efe_key': '21'},
            {'key': '033', 'nombre': 'COHUECAN', 'efe_key': '21'},
            {'key': '034', 'nombre': 'CORONANGO', 'efe_key': '21'},
            {'key': '035', 'nombre': 'COXCATLÁN', 'efe_key': '21'},
            {'key': '036', 'nombre': 'COYOMEAPAN', 'efe_key': '21'},
            {'key': '037', 'nombre': 'COYOTEPEC', 'efe_key': '21'},
            {'key': '038', 'nombre': 'CUAPIAXTLA DE MADERO', 'efe_key': '21'},
            {'key': '039', 'nombre': 'CUAUTEMPAN', 'efe_key': '21'},
            {'key': '040', 'nombre': 'CUAUTINCHÁN', 'efe_key': '21'},
            {'key': '041', 'nombre': 'CUAUTLANCINGO', 'efe_key': '21'},
            {'key': '042', 'nombre': 'CUAYUCA DE ANDRADE', 'efe_key': '21'},
            {'key': '043', 'nombre': 'CUETZALAN DEL PROGRESO', 'efe_key': '21'},
            {'key': '044', 'nombre': 'CUYOACO', 'efe_key': '21'},
            {'key': '045', 'nombre': 'CHALCHICOMULA DE SESMA', 'efe_key': '21'},
            {'key': '046', 'nombre': 'CHAPULCO', 'efe_key': '21'},
            {'key': '047', 'nombre': 'CHIAUTLA', 'efe_key': '21'},
            {'key': '048', 'nombre': 'CHIAUTZINGO', 'efe_key': '21'},
            {'key': '049', 'nombre': 'CHICONCUAUTLA', 'efe_key': '21'},
            {'key': '050', 'nombre': 'CHICHIQUILA', 'efe_key': '21'},
            {'key': '051', 'nombre': 'CHIETLA', 'efe_key': '21'},
            {'key': '052', 'nombre': 'CHIGMECATITLÁN', 'efe_key': '21'},
            {'key': '053', 'nombre': 'CHIGNAHUAPAN', 'efe_key': '21'},
            {'key': '054', 'nombre': 'CHIGNAUTLA', 'efe_key': '21'},
            {'key': '055', 'nombre': 'CHILA', 'efe_key': '21'},
            {'key': '056', 'nombre': 'CHILA DE LA SAL', 'efe_key': '21'},
            {'key': '057', 'nombre': 'HONEY', 'efe_key': '21'},
            {'key': '058', 'nombre': 'CHILCHOTLA', 'efe_key': '21'},
            {'key': '059', 'nombre': 'CHINANTLA', 'efe_key': '21'},
            {'key': '060', 'nombre': 'DOMINGO ARENAS', 'efe_key': '21'},
            {'key': '061', 'nombre': 'ELOXOCHITLÁN', 'efe_key': '21'},
            {'key': '062', 'nombre': 'EPATLÁN', 'efe_key': '21'},
            {'key': '063', 'nombre': 'ESPERANZA', 'efe_key': '21'},
            {'key': '064', 'nombre': 'FRANCISCO Z. MENA', 'efe_key': '21'},
            {'key': '065', 'nombre': 'GENERAL FELIPE ÁNGELES', 'efe_key': '21'},
            {'key': '066', 'nombre': 'GUADALUPE', 'efe_key': '21'},
            {'key': '067', 'nombre': 'GUADALUPE VICTORIA', 'efe_key': '21'},
            {'key': '068', 'nombre': 'HERMENEGILDO GALEANA', 'efe_key': '21'},
            {'key': '069', 'nombre': 'HUAQUECHULA', 'efe_key': '21'},
            {'key': '070', 'nombre': 'HUATLATLAUCA', 'efe_key': '21'},
            {'key': '071', 'nombre': 'HUAUCHINANGO', 'efe_key': '21'},
            {'key': '072', 'nombre': 'HUEHUETLA', 'efe_key': '21'},
            {'key': '073', 'nombre': 'HUEHUETLÁN EL CHICO', 'efe_key': '21'},
            {'key': '074', 'nombre': 'HUEJOTZINGO', 'efe_key': '21'},
            {'key': '075', 'nombre': 'HUEYAPAN', 'efe_key': '21'},
            {'key': '076', 'nombre': 'HUEYTAMALCO', 'efe_key': '21'},
            {'key': '077', 'nombre': 'HUEYTLALPAN', 'efe_key': '21'},
            {'key': '078', 'nombre': 'HUITZILAN DE SERDÁN', 'efe_key': '21'},
            {'key': '079', 'nombre': 'HUITZILTEPEC', 'efe_key': '21'},
            {'key': '080', 'nombre': 'ATLEQUIZAYAN', 'efe_key': '21'},
            {'key': '081', 'nombre': 'IXCAMILPA DE GUERRERO', 'efe_key': '21'},
            {'key': '082', 'nombre': 'IXCAQUIXTLA', 'efe_key': '21'},
            {'key': '083', 'nombre': 'IXTACAMAXTITLÁN', 'efe_key': '21'},
            {'key': '084', 'nombre': 'IXTEPEC', 'efe_key': '21'},
            {'key': '085', 'nombre': 'IZÚCAR DE MATAMOROS', 'efe_key': '21'},
            {'key': '086', 'nombre': 'JALPAN', 'efe_key': '21'},
            {'key': '087', 'nombre': 'JOLALPAN', 'efe_key': '21'},
            {'key': '088', 'nombre': 'JONOTLA', 'efe_key': '21'},
            {'key': '089', 'nombre': 'JOPALA', 'efe_key': '21'},
            {'key': '090', 'nombre': 'JUAN C. BONILLA', 'efe_key': '21'},
            {'key': '091', 'nombre': 'JUAN GALINDO', 'efe_key': '21'},
            {'key': '092', 'nombre': 'JUAN N. MÉNDEZ', 'efe_key': '21'},
            {'key': '093', 'nombre': 'LAFRAGUA', 'efe_key': '21'},
            {'key': '094', 'nombre': 'LIBRES', 'efe_key': '21'},
            {'key': '095', 'nombre': 'LA MAGDALENA TLATLAUQUITEPEC', 'efe_key': '21'},
            {'key': '096', 'nombre': 'MAZAPILTEPEC DE JUÁREZ', 'efe_key': '21'},
            {'key': '097', 'nombre': 'MIXTLA', 'efe_key': '21'},
            {'key': '098', 'nombre': 'MOLCAXAC', 'efe_key': '21'},
            {'key': '099', 'nombre': 'CAÑADA MORELOS', 'efe_key': '21'},
            {'key': '100', 'nombre': 'NAUPAN', 'efe_key': '21'},
            {'key': '101', 'nombre': 'NAUZONTLA', 'efe_key': '21'},
            {'key': '102', 'nombre': 'NEALTICAN', 'efe_key': '21'},
            {'key': '103', 'nombre': 'NICOLÁS BRAVO', 'efe_key': '21'},
            {'key': '104', 'nombre': 'NOPALUCAN', 'efe_key': '21'},
            {'key': '105', 'nombre': 'OCOTEPEC', 'efe_key': '21'},
            {'key': '106', 'nombre': 'OCOYUCAN', 'efe_key': '21'},
            {'key': '107', 'nombre': 'OLINTLA', 'efe_key': '21'},
            {'key': '108', 'nombre': 'ORIENTAL', 'efe_key': '21'},
            {'key': '109', 'nombre': 'PAHUATLÁN', 'efe_key': '21'},
            {'key': '110', 'nombre': 'PALMAR DE BRAVO', 'efe_key': '21'},
            {'key': '111', 'nombre': 'PANTEPEC', 'efe_key': '21'},
            {'key': '112', 'nombre': 'PETLALCINGO', 'efe_key': '21'},
            {'key': '113', 'nombre': 'PIAXTLA', 'efe_key': '21'},
            {'key': '114', 'nombre': 'PUEBLA', 'efe_key': '21'},
            {'key': '115', 'nombre': 'QUECHOLAC', 'efe_key': '21'},
            {'key': '116', 'nombre': 'QUIMIXTLÁN', 'efe_key': '21'},
            {'key': '117', 'nombre': 'RAFAEL LARA GRAJALES', 'efe_key': '21'},
            {'key': '118', 'nombre': 'LOS REYES DE JUÁREZ', 'efe_key': '21'},
            {'key': '119', 'nombre': 'SAN ANDRÉS CHOLULA', 'efe_key': '21'},
            {'key': '120', 'nombre': 'SAN ANTONIO CAÑADA', 'efe_key': '21'},
            {'key': '121', 'nombre': 'SAN DIEGO LA MESA TOCHIMILTZINGO', 'efe_key': '21'},
            {'key': '122', 'nombre': 'SAN FELIPE TEOTLALCINGO', 'efe_key': '21'},
            {'key': '123', 'nombre': 'SAN FELIPE TEPATLÁN', 'efe_key': '21'},
            {'key': '124', 'nombre': 'SAN GABRIEL CHILAC', 'efe_key': '21'},
            {'key': '125', 'nombre': 'SAN GREGORIO ATZOMPA', 'efe_key': '21'},
            {'key': '126', 'nombre': 'SAN JERÓNIMO TECUANIPAN', 'efe_key': '21'},
            {'key': '127', 'nombre': 'SAN JERÓNIMO XAYACATLÁN', 'efe_key': '21'},
            {'key': '128', 'nombre': 'SAN JOSÉ CHIAPA', 'efe_key': '21'},
            {'key': '129', 'nombre': 'SAN JOSÉ MIAHUATLÁN', 'efe_key': '21'},
            {'key': '130', 'nombre': 'SAN JUAN ATENCO', 'efe_key': '21'},
            {'key': '131', 'nombre': 'SAN JUAN ATZOMPA', 'efe_key': '21'},
            {'key': '132', 'nombre': 'SAN MARTÍN TEXMELUCAN', 'efe_key': '21'},
            {'key': '133', 'nombre': 'SAN MARTÍN TOTOLTEPEC', 'efe_key': '21'},
            {'key': '134', 'nombre': 'SAN MATÍAS TLALANCALECA', 'efe_key': '21'},
            {'key': '135', 'nombre': 'SAN MIGUEL IXITLÁN', 'efe_key': '21'},
            {'key': '136', 'nombre': 'SAN MIGUEL XOXTLA', 'efe_key': '21'},
            {'key': '137', 'nombre': 'SAN NICOLÁS BUENOS AIRES', 'efe_key': '21'},
            {'key': '138', 'nombre': 'SAN NICOLÁS DE LOS RANCHOS', 'efe_key': '21'},
            {'key': '139', 'nombre': 'SAN PABLO ANICANO', 'efe_key': '21'},
            {'key': '140', 'nombre': 'SAN PEDRO CHOLULA', 'efe_key': '21'},
            {'key': '141', 'nombre': 'SAN PEDRO YELOIXTLAHUACA', 'efe_key': '21'},
            {'key': '142', 'nombre': 'SAN SALVADOR EL SECO', 'efe_key': '21'},
            {'key': '143', 'nombre': 'SAN SALVADOR EL VERDE', 'efe_key': '21'},
            {'key': '144', 'nombre': 'SAN SALVADOR HUIXCOLOTLA', 'efe_key': '21'},
            {'key': '145', 'nombre': 'SAN SEBASTIÁN TLACOTEPEC', 'efe_key': '21'},
            {'key': '146', 'nombre': 'SANTA CATARINA TLALTEMPAN', 'efe_key': '21'},
            {'key': '147', 'nombre': 'SANTA INÉS AHUATEMPAN', 'efe_key': '21'},
            {'key': '148', 'nombre': 'SANTA ISABEL CHOLULA', 'efe_key': '21'},
            {'key': '149', 'nombre': 'SANTIAGO MIAHUATLÁN', 'efe_key': '21'},
            {'key': '150', 'nombre': 'HUEHUETLÁN EL GRANDE', 'efe_key': '21'},
            {'key': '151', 'nombre': 'SANTO TOMÁS HUEYOTLIPAN', 'efe_key': '21'},
            {'key': '152', 'nombre': 'SOLTEPEC', 'efe_key': '21'},
            {'key': '153', 'nombre': 'TECALI DE HERRERA', 'efe_key': '21'},
            {'key': '154', 'nombre': 'TECAMACHALCO', 'efe_key': '21'},
            {'key': '155', 'nombre': 'TECOMATLÁN', 'efe_key': '21'},
            {'key': '156', 'nombre': 'TEHUACÁN', 'efe_key': '21'},
            {'key': '157', 'nombre': 'TEHUITZINGO', 'efe_key': '21'},
            {'key': '158', 'nombre': 'TENAMPULCO', 'efe_key': '21'},
            {'key': '159', 'nombre': 'TEOPANTLÁN', 'efe_key': '21'},
            {'key': '160', 'nombre': 'TEOTLALCO', 'efe_key': '21'},
            {'key': '161', 'nombre': 'TEPANCO DE LÓPEZ', 'efe_key': '21'},
            {'key': '162', 'nombre': 'TEPANGO DE RODRÍGUEZ', 'efe_key': '21'},
            {'key': '163', 'nombre': 'TEPATLAXCO DE HIDALGO', 'efe_key': '21'},
            {'key': '164', 'nombre': 'TEPEACA', 'efe_key': '21'},
            {'key': '165', 'nombre': 'TEPEMAXALCO', 'efe_key': '21'},
            {'key': '166', 'nombre': 'TEPEOJUMA', 'efe_key': '21'},
            {'key': '167', 'nombre': 'TEPETZINTLA', 'efe_key': '21'},
            {'key': '168', 'nombre': 'TEPEXCO', 'efe_key': '21'},
            {'key': '169', 'nombre': 'TEPEXI DE RODRÍGUEZ', 'efe_key': '21'},
            {'key': '170', 'nombre': 'TEPEYAHUALCO', 'efe_key': '21'},
            {'key': '171', 'nombre': 'TEPEYAHUALCO DE CUAUHTÉMOC', 'efe_key': '21'},
            {'key': '172', 'nombre': 'TETELA DE OCAMPO', 'efe_key': '21'},
            {'key': '173', 'nombre': 'TETELES DE AVILA CASTILLO', 'efe_key': '21'},
            {'key': '174', 'nombre': 'TEZIUTLÁN', 'efe_key': '21'},
            {'key': '175', 'nombre': 'TIANGUISMANALCO', 'efe_key': '21'},
            {'key': '176', 'nombre': 'TILAPA', 'efe_key': '21'},
            {'key': '177', 'nombre': 'TLACOTEPEC DE BENITO JUÁREZ', 'efe_key': '21'},
            {'key': '178', 'nombre': 'TLACUILOTEPEC', 'efe_key': '21'},
            {'key': '179', 'nombre': 'TLACHICHUCA', 'efe_key': '21'},
            {'key': '180', 'nombre': 'TLAHUAPAN', 'efe_key': '21'},
            {'key': '181', 'nombre': 'TLALTENANGO', 'efe_key': '21'},
            {'key': '182', 'nombre': 'TLANEPANTLA', 'efe_key': '21'},
            {'key': '183', 'nombre': 'TLAOLA', 'efe_key': '21'},
            {'key': '184', 'nombre': 'TLAPACOYA', 'efe_key': '21'},
            {'key': '185', 'nombre': 'TLAPANALÁ', 'efe_key': '21'},
            {'key': '186', 'nombre': 'TLATLAUQUITEPEC', 'efe_key': '21'},
            {'key': '187', 'nombre': 'TLAXCO', 'efe_key': '21'},
            {'key': '188', 'nombre': 'TOCHIMILCO', 'efe_key': '21'},
            {'key': '189', 'nombre': 'TOCHTEPEC', 'efe_key': '21'},
            {'key': '190', 'nombre': 'TOTOLTEPEC DE GUERRERO', 'efe_key': '21'},
            {'key': '191', 'nombre': 'TULCINGO', 'efe_key': '21'},
            {'key': '192', 'nombre': 'TUZAMAPAN DE GALEANA', 'efe_key': '21'},
            {'key': '193', 'nombre': 'TZICATLACOYAN', 'efe_key': '21'},
            {'key': '194', 'nombre': 'VENUSTIANO CARRANZA', 'efe_key': '21'},
            {'key': '195', 'nombre': 'VICENTE GUERRERO', 'efe_key': '21'},
            {'key': '196', 'nombre': 'XAYACATLÁN DE BRAVO', 'efe_key': '21'},
            {'key': '197', 'nombre': 'XICOTEPEC', 'efe_key': '21'},
            {'key': '198', 'nombre': 'XICOTLÁN', 'efe_key': '21'},
            {'key': '199', 'nombre': 'XIUTETELCO', 'efe_key': '21'},
            {'key': '200', 'nombre': 'XOCHIAPULCO', 'efe_key': '21'},
            {'key': '201', 'nombre': 'XOCHILTEPEC', 'efe_key': '21'},
            {'key': '202', 'nombre': 'XOCHITLÁN DE VICENTE SUÁREZ', 'efe_key': '21'},
            {'key': '203', 'nombre': 'XOCHITLÁN TODOS SANTOS', 'efe_key': '21'},
            {'key': '204', 'nombre': 'YAONÁHUAC', 'efe_key': '21'},
            {'key': '205', 'nombre': 'YEHUALTEPEC', 'efe_key': '21'},
            {'key': '206', 'nombre': 'ZACAPALA', 'efe_key': '21'},
            {'key': '207', 'nombre': 'ZACAPOAXTLA', 'efe_key': '21'},
            {'key': '208', 'nombre': 'ZACATLÁN', 'efe_key': '21'},
            {'key': '209', 'nombre': 'ZAPOTITLÁN', 'efe_key': '21'},
            {'key': '210', 'nombre': 'ZAPOTITLÁN DE MÉNDEZ', 'efe_key': '21'},
            {'key': '211', 'nombre': 'ZARAGOZA', 'efe_key': '21'},
            {'key': '212', 'nombre': 'ZAUTLA', 'efe_key': '21'},
            {'key': '213', 'nombre': 'ZIHUATEUTLA', 'efe_key': '21'},
            {'key': '214', 'nombre': 'ZINACATEPEC', 'efe_key': '21'},
            {'key': '215', 'nombre': 'ZONGOZOTLA', 'efe_key': '21'},
            {'key': '216', 'nombre': 'ZOQUIAPAN', 'efe_key': '21'},
            {'key': '217', 'nombre': 'ZOQUITLÁN', 'efe_key': '21'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '21'},
            {'key': '001', 'nombre': 'AMEALCO DE BONFIL', 'efe_key': '22'},
            {'key': '002', 'nombre': 'PINAL DE AMOLES', 'efe_key': '22'},
            {'key': '003', 'nombre': 'ARROYO SECO', 'efe_key': '22'},
            {'key': '004', 'nombre': 'CADEREYTA DE MONTES', 'efe_key': '22'},
            {'key': '005', 'nombre': 'COLÓN', 'efe_key': '22'},
            {'key': '006', 'nombre': 'CORREGIDORA', 'efe_key': '22'},
            {'key': '007', 'nombre': 'EZEQUIEL MONTES', 'efe_key': '22'},
            {'key': '008', 'nombre': 'HUIMILPAN', 'efe_key': '22'},
            {'key': '009', 'nombre': 'JALPAN DE SERRA', 'efe_key': '22'},
            {'key': '010', 'nombre': 'LANDA DE MATAMOROS', 'efe_key': '22'},
            {'key': '011', 'nombre': 'EL MARQUÉS', 'efe_key': '22'},
            {'key': '012', 'nombre': 'PEDRO ESCOBEDO', 'efe_key': '22'},
            {'key': '013', 'nombre': 'PEÑAMILLER', 'efe_key': '22'},
            {'key': '014', 'nombre': 'QUERÉTARO', 'efe_key': '22'},
            {'key': '015', 'nombre': 'SAN JOAQUÍN', 'efe_key': '22'},
            {'key': '016', 'nombre': 'SAN JUAN DEL RÍO', 'efe_key': '22'},
            {'key': '017', 'nombre': 'TEQUISQUIAPAN', 'efe_key': '22'},
            {'key': '018', 'nombre': 'TOLIMÁN', 'efe_key': '22'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '22'},
            {'key': '001', 'nombre': 'COZUMEL', 'efe_key': '23'},
            {'key': '002', 'nombre': 'FELIPE CARRILLO PUERTO', 'efe_key': '23'},
            {'key': '003', 'nombre': 'ISLA MUJERES', 'efe_key': '23'},
            {'key': '004', 'nombre': 'OTHÓN P. BLANCO', 'efe_key': '23'},
            {'key': '005', 'nombre': 'BENITO JUÁREZ', 'efe_key': '23'},
            {'key': '006', 'nombre': 'JOSÉ MARÍA MORELOS', 'efe_key': '23'},
            {'key': '007', 'nombre': 'LÁZARO CÁRDENAS', 'efe_key': '23'},
            {'key': '008', 'nombre': 'SOLIDARIDAD', 'efe_key': '23'},
            {'key': '009', 'nombre': 'TULUM', 'efe_key': '23'},
            {'key': '010', 'nombre': 'BACALAR', 'efe_key': '23'},
            {'key': '011', 'nombre': 'PUERTO MORELOS', 'efe_key': '23'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '23'},
            {'key': '001', 'nombre': 'AHUALULCO', 'efe_key': '24'},
            {'key': '002', 'nombre': 'ALAQUINES', 'efe_key': '24'},
            {'key': '003', 'nombre': 'AQUISMÓN', 'efe_key': '24'},
            {'key': '004', 'nombre': 'ARMADILLO DE LOS INFANTE', 'efe_key': '24'},
            {'key': '005', 'nombre': 'CÁRDENAS', 'efe_key': '24'},
            {'key': '006', 'nombre': 'CATORCE', 'efe_key': '24'},
            {'key': '007', 'nombre': 'CEDRAL', 'efe_key': '24'},
            {'key': '008', 'nombre': 'CERRITOS', 'efe_key': '24'},
            {'key': '009', 'nombre': 'CERRO DE SAN PEDRO', 'efe_key': '24'},
            {'key': '010', 'nombre': 'CIUDAD DEL MAÍZ', 'efe_key': '24'},
            {'key': '011', 'nombre': 'CIUDAD FERNÁNDEZ', 'efe_key': '24'},
            {'key': '012', 'nombre': 'TANCANHUITZ', 'efe_key': '24'},
            {'key': '013', 'nombre': 'CIUDAD VALLES', 'efe_key': '24'},
            {'key': '014', 'nombre': 'COXCATLÁN', 'efe_key': '24'},
            {'key': '015', 'nombre': 'CHARCAS', 'efe_key': '24'},
            {'key': '016', 'nombre': 'EBANO', 'efe_key': '24'},
            {'key': '017', 'nombre': 'GUADALCÁZAR', 'efe_key': '24'},
            {'key': '018', 'nombre': 'HUEHUETLÁN', 'efe_key': '24'},
            {'key': '019', 'nombre': 'LAGUNILLAS', 'efe_key': '24'},
            {'key': '020', 'nombre': 'MATEHUALA', 'efe_key': '24'},
            {'key': '021', 'nombre': 'MEXQUITIC DE CARMONA', 'efe_key': '24'},
            {'key': '022', 'nombre': 'MOCTEZUMA', 'efe_key': '24'},
            {'key': '023', 'nombre': 'RAYÓN', 'efe_key': '24'},
            {'key': '024', 'nombre': 'RIOVERDE', 'efe_key': '24'},
            {'key': '025', 'nombre': 'SALINAS', 'efe_key': '24'},
            {'key': '026', 'nombre': 'SAN ANTONIO', 'efe_key': '24'},
            {'key': '027', 'nombre': 'SAN CIRO DE ACOSTA', 'efe_key': '24'},
            {'key': '028', 'nombre': 'SAN LUIS POTOSÍ', 'efe_key': '24'},
            {'key': '029', 'nombre': 'SAN MARTÍN CHALCHICUAUTLA', 'efe_key': '24'},
            {'key': '030', 'nombre': 'SAN NICOLÁS TOLENTINO', 'efe_key': '24'},
            {'key': '031', 'nombre': 'SANTA CATARINA', 'efe_key': '24'},
            {'key': '032', 'nombre': 'SANTA MARÍA DEL RÍO', 'efe_key': '24'},
            {'key': '033', 'nombre': 'SANTO DOMINGO', 'efe_key': '24'},
            {'key': '034', 'nombre': 'SAN VICENTE TANCUAYALAB', 'efe_key': '24'},
            {'key': '035', 'nombre': 'SOLEDAD DE GRACIANO SÁNCHEZ', 'efe_key': '24'},
            {'key': '036', 'nombre': 'TAMASOPO', 'efe_key': '24'},
            {'key': '037', 'nombre': 'TAMAZUNCHALE', 'efe_key': '24'},
            {'key': '038', 'nombre': 'TAMPACÁN', 'efe_key': '24'},
            {'key': '039', 'nombre': 'TAMPAMOLÓN CORONA', 'efe_key': '24'},
            {'key': '040', 'nombre': 'TAMUÍN', 'efe_key': '24'},
            {'key': '041', 'nombre': 'TANLAJÁS', 'efe_key': '24'},
            {'key': '042', 'nombre': 'TANQUIÁN DE ESCOBEDO', 'efe_key': '24'},
            {'key': '043', 'nombre': 'TIERRA NUEVA', 'efe_key': '24'},
            {'key': '044', 'nombre': 'VANEGAS', 'efe_key': '24'},
            {'key': '045', 'nombre': 'VENADO', 'efe_key': '24'},
            {'key': '046', 'nombre': 'VILLA DE ARRIAGA', 'efe_key': '24'},
            {'key': '047', 'nombre': 'VILLA DE GUADALUPE', 'efe_key': '24'},
            {'key': '048', 'nombre': 'VILLA DE LA PAZ', 'efe_key': '24'},
            {'key': '049', 'nombre': 'VILLA DE RAMOS', 'efe_key': '24'},
            {'key': '050', 'nombre': 'VILLA DE REYES', 'efe_key': '24'},
            {'key': '051', 'nombre': 'VILLA HIDALGO', 'efe_key': '24'},
            {'key': '052', 'nombre': 'VILLA JUÁREZ', 'efe_key': '24'},
            {'key': '053', 'nombre': 'AXTLA DE TERRAZAS', 'efe_key': '24'},
            {'key': '054', 'nombre': 'XILITLA', 'efe_key': '24'},
            {'key': '055', 'nombre': 'ZARAGOZA', 'efe_key': '24'},
            {'key': '056', 'nombre': 'VILLA DE ARISTA', 'efe_key': '24'},
            {'key': '057', 'nombre': 'MATLAPA', 'efe_key': '24'},
            {'key': '058', 'nombre': 'EL NARANJO', 'efe_key': '24'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '24'},
            {'key': '001', 'nombre': 'AHOME', 'efe_key': '25'},
            {'key': '002', 'nombre': 'ANGOSTURA', 'efe_key': '25'},
            {'key': '003', 'nombre': 'BADIRAGUATO', 'efe_key': '25'},
            {'key': '004', 'nombre': 'CONCORDIA', 'efe_key': '25'},
            {'key': '005', 'nombre': 'COSALÁ', 'efe_key': '25'},
            {'key': '006', 'nombre': 'CULIACÁN', 'efe_key': '25'},
            {'key': '007', 'nombre': 'CHOIX', 'efe_key': '25'},
            {'key': '008', 'nombre': 'ELOTA', 'efe_key': '25'},
            {'key': '009', 'nombre': 'ESCUINAPA', 'efe_key': '25'},
            {'key': '010', 'nombre': 'EL FUERTE', 'efe_key': '25'},
            {'key': '011', 'nombre': 'GUASAVE', 'efe_key': '25'},
            {'key': '012', 'nombre': 'MAZATLÁN', 'efe_key': '25'},
            {'key': '013', 'nombre': 'MOCORITO', 'efe_key': '25'},
            {'key': '014', 'nombre': 'ROSARIO', 'efe_key': '25'},
            {'key': '015', 'nombre': 'SALVADOR ALVARADO', 'efe_key': '25'},
            {'key': '016', 'nombre': 'SAN IGNACIO', 'efe_key': '25'},
            {'key': '017', 'nombre': 'SINALOA', 'efe_key': '25'},
            {'key': '018', 'nombre': 'NAVOLATO', 'efe_key': '25'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '25'},
            {'key': '001', 'nombre': 'ACONCHI', 'efe_key': '26'},
            {'key': '002', 'nombre': 'AGUA PRIETA', 'efe_key': '26'},
            {'key': '003', 'nombre': 'ALAMOS', 'efe_key': '26'},
            {'key': '004', 'nombre': 'ALTAR', 'efe_key': '26'},
            {'key': '005', 'nombre': 'ARIVECHI', 'efe_key': '26'},
            {'key': '006', 'nombre': 'ARIZPE', 'efe_key': '26'},
            {'key': '007', 'nombre': 'ATIL', 'efe_key': '26'},
            {'key': '008', 'nombre': 'BACADÉHUACHI', 'efe_key': '26'},
            {'key': '009', 'nombre': 'BACANORA', 'efe_key': '26'},
            {'key': '010', 'nombre': 'BACERAC', 'efe_key': '26'},
            {'key': '011', 'nombre': 'BACOACHI', 'efe_key': '26'},
            {'key': '012', 'nombre': 'BÁCUM', 'efe_key': '26'},
            {'key': '013', 'nombre': 'BANÁMICHI', 'efe_key': '26'},
            {'key': '014', 'nombre': 'BAVIÁCORA', 'efe_key': '26'},
            {'key': '015', 'nombre': 'BAVISPE', 'efe_key': '26'},
            {'key': '016', 'nombre': 'BENJAMÍN HILL', 'efe_key': '26'},
            {'key': '017', 'nombre': 'CABORCA', 'efe_key': '26'},
            {'key': '018', 'nombre': 'CAJEME', 'efe_key': '26'},
            {'key': '019', 'nombre': 'CANANEA', 'efe_key': '26'},
            {'key': '020', 'nombre': 'CARBÓ', 'efe_key': '26'},
            {'key': '021', 'nombre': 'LA COLORADA', 'efe_key': '26'},
            {'key': '022', 'nombre': 'CUCURPE', 'efe_key': '26'},
            {'key': '023', 'nombre': 'CUMPAS', 'efe_key': '26'},
            {'key': '024', 'nombre': 'DIVISADEROS', 'efe_key': '26'},
            {'key': '025', 'nombre': 'EMPALME', 'efe_key': '26'},
            {'key': '026', 'nombre': 'ETCHOJOA', 'efe_key': '26'},
            {'key': '027', 'nombre': 'FRONTERAS', 'efe_key': '26'},
            {'key': '028', 'nombre': 'GRANADOS', 'efe_key': '26'},
            {'key': '029', 'nombre': 'GUAYMAS', 'efe_key': '26'},
            {'key': '030', 'nombre': 'HERMOSILLO', 'efe_key': '26'},
            {'key': '031', 'nombre': 'HUACHINERA', 'efe_key': '26'},
            {'key': '032', 'nombre': 'HUÁSABAS', 'efe_key': '26'},
            {'key': '033', 'nombre': 'HUATABAMPO', 'efe_key': '26'},
            {'key': '034', 'nombre': 'HUÉPAC', 'efe_key': '26'},
            {'key': '035', 'nombre': 'IMURIS', 'efe_key': '26'},
            {'key': '036', 'nombre': 'MAGDALENA', 'efe_key': '26'},
            {'key': '037', 'nombre': 'MAZATÁN', 'efe_key': '26'},
            {'key': '038', 'nombre': 'MOCTEZUMA', 'efe_key': '26'},
            {'key': '039', 'nombre': 'NACO', 'efe_key': '26'},
            {'key': '040', 'nombre': 'NÁCORI CHICO', 'efe_key': '26'},
            {'key': '041', 'nombre': 'NACOZARI DE GARCÍA', 'efe_key': '26'},
            {'key': '042', 'nombre': 'NAVOJOA', 'efe_key': '26'},
            {'key': '043', 'nombre': 'NOGALES', 'efe_key': '26'},
            {'key': '044', 'nombre': 'ONAVAS', 'efe_key': '26'},
            {'key': '045', 'nombre': 'OPODEPE', 'efe_key': '26'},
            {'key': '046', 'nombre': 'OQUITOA', 'efe_key': '26'},
            {'key': '047', 'nombre': 'PITIQUITO', 'efe_key': '26'},
            {'key': '048', 'nombre': 'PUERTO PEÑASCO', 'efe_key': '26'},
            {'key': '049', 'nombre': 'QUIRIEGO', 'efe_key': '26'},
            {'key': '050', 'nombre': 'RAYÓN', 'efe_key': '26'},
            {'key': '051', 'nombre': 'ROSARIO', 'efe_key': '26'},
            {'key': '052', 'nombre': 'SAHUARIPA', 'efe_key': '26'},
            {'key': '053', 'nombre': 'SAN FELIPE DE JESÚS', 'efe_key': '26'},
            {'key': '054', 'nombre': 'SAN JAVIER', 'efe_key': '26'},
            {'key': '055', 'nombre': 'SAN LUIS RÍO COLORADO', 'efe_key': '26'},
            {'key': '056', 'nombre': 'SAN MIGUEL DE HORCASITAS', 'efe_key': '26'},
            {'key': '057', 'nombre': 'SAN PEDRO DE LA CUEVA', 'efe_key': '26'},
            {'key': '058', 'nombre': 'SANTA ANA', 'efe_key': '26'},
            {'key': '059', 'nombre': 'SANTA CRUZ', 'efe_key': '26'},
            {'key': '060', 'nombre': 'SÁRIC', 'efe_key': '26'},
            {'key': '061', 'nombre': 'SOYOPA', 'efe_key': '26'},
            {'key': '062', 'nombre': 'SUAQUI GRANDE', 'efe_key': '26'},
            {'key': '063', 'nombre': 'TEPACHE', 'efe_key': '26'},
            {'key': '064', 'nombre': 'TRINCHERAS', 'efe_key': '26'},
            {'key': '065', 'nombre': 'TUBUTAMA', 'efe_key': '26'},
            {'key': '066', 'nombre': 'URES', 'efe_key': '26'},
            {'key': '067', 'nombre': 'VILLA HIDALGO', 'efe_key': '26'},
            {'key': '068', 'nombre': 'VILLA PESQUEIRA', 'efe_key': '26'},
            {'key': '069', 'nombre': 'YÉCORA', 'efe_key': '26'},
            {'key': '070', 'nombre': 'GENERAL PLUTARCO ELÍAS CALLES', 'efe_key': '26'},
            {'key': '071', 'nombre': 'BENITO JUÁREZ', 'efe_key': '26'},
            {'key': '072', 'nombre': 'SAN IGNACIO RÍO MUERTO', 'efe_key': '26'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '26'},
            {'key': '001', 'nombre': 'BALANCÁN', 'efe_key': '27'},
            {'key': '002', 'nombre': 'CÁRDENAS', 'efe_key': '27'},
            {'key': '003', 'nombre': 'CENTLA', 'efe_key': '27'},
            {'key': '004', 'nombre': 'CENTRO', 'efe_key': '27'},
            {'key': '005', 'nombre': 'COMALCALCO', 'efe_key': '27'},
            {'key': '006', 'nombre': 'CUNDUACÁN', 'efe_key': '27'},
            {'key': '007', 'nombre': 'EMILIANO ZAPATA', 'efe_key': '27'},
            {'key': '008', 'nombre': 'HUIMANGUILLO', 'efe_key': '27'},
            {'key': '009', 'nombre': 'JALAPA', 'efe_key': '27'},
            {'key': '010', 'nombre': 'JALPA DE MÉNDEZ', 'efe_key': '27'},
            {'key': '011', 'nombre': 'JONUTA', 'efe_key': '27'},
            {'key': '012', 'nombre': 'MACUSPANA', 'efe_key': '27'},
            {'key': '013', 'nombre': 'NACAJUCA', 'efe_key': '27'},
            {'key': '014', 'nombre': 'PARAÍSO', 'efe_key': '27'},
            {'key': '015', 'nombre': 'TACOTALPA', 'efe_key': '27'},
            {'key': '016', 'nombre': 'TEAPA', 'efe_key': '27'},
            {'key': '017', 'nombre': 'TENOSIQUE', 'efe_key': '27'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '27'},
            {'key': '001', 'nombre': 'ABASOLO', 'efe_key': '28'},
            {'key': '002', 'nombre': 'ALDAMA', 'efe_key': '28'},
            {'key': '003', 'nombre': 'ALTAMIRA', 'efe_key': '28'},
            {'key': '004', 'nombre': 'ANTIGUO MORELOS', 'efe_key': '28'},
            {'key': '005', 'nombre': 'BURGOS', 'efe_key': '28'},
            {'key': '006', 'nombre': 'BUSTAMANTE', 'efe_key': '28'},
            {'key': '007', 'nombre': 'CAMARGO', 'efe_key': '28'},
            {'key': '008', 'nombre': 'CASAS', 'efe_key': '28'},
            {'key': '009', 'nombre': 'CIUDAD MADERO', 'efe_key': '28'},
            {'key': '010', 'nombre': 'CRUILLAS', 'efe_key': '28'},
            {'key': '011', 'nombre': 'GÓMEZ FARÍAS', 'efe_key': '28'},
            {'key': '012', 'nombre': 'GONZÁLEZ', 'efe_key': '28'},
            {'key': '013', 'nombre': 'GÜÉMEZ', 'efe_key': '28'},
            {'key': '014', 'nombre': 'GUERRERO', 'efe_key': '28'},
            {'key': '015', 'nombre': 'GUSTAVO DÍAZ ORDAZ', 'efe_key': '28'},
            {'key': '016', 'nombre': 'HIDALGO', 'efe_key': '28'},
            {'key': '017', 'nombre': 'JAUMAVE', 'efe_key': '28'},
            {'key': '018', 'nombre': 'JIMÉNEZ', 'efe_key': '28'},
            {'key': '019', 'nombre': 'LLERA', 'efe_key': '28'},
            {'key': '020', 'nombre': 'MAINERO', 'efe_key': '28'},
            {'key': '021', 'nombre': 'EL MANTE', 'efe_key': '28'},
            {'key': '022', 'nombre': 'MATAMOROS', 'efe_key': '28'},
            {'key': '023', 'nombre': 'MÉNDEZ', 'efe_key': '28'},
            {'key': '024', 'nombre': 'MIER', 'efe_key': '28'},
            {'key': '025', 'nombre': 'MIGUEL ALEMÁN', 'efe_key': '28'},
            {'key': '026', 'nombre': 'MIQUIHUANA', 'efe_key': '28'},
            {'key': '027', 'nombre': 'NUEVO LAREDO', 'efe_key': '28'},
            {'key': '028', 'nombre': 'NUEVO MORELOS', 'efe_key': '28'},
            {'key': '029', 'nombre': 'OCAMPO', 'efe_key': '28'},
            {'key': '030', 'nombre': 'PADILLA', 'efe_key': '28'},
            {'key': '031', 'nombre': 'PALMILLAS', 'efe_key': '28'},
            {'key': '032', 'nombre': 'REYNOSA', 'efe_key': '28'},
            {'key': '033', 'nombre': 'RÍO BRAVO', 'efe_key': '28'},
            {'key': '034', 'nombre': 'SAN CARLOS', 'efe_key': '28'},
            {'key': '035', 'nombre': 'SAN FERNANDO', 'efe_key': '28'},
            {'key': '036', 'nombre': 'SAN NICOLÁS', 'efe_key': '28'},
            {'key': '037', 'nombre': 'SOTO LA MARINA', 'efe_key': '28'},
            {'key': '038', 'nombre': 'TAMPICO', 'efe_key': '28'},
            {'key': '039', 'nombre': 'TULA', 'efe_key': '28'},
            {'key': '040', 'nombre': 'VALLE HERMOSO', 'efe_key': '28'},
            {'key': '041', 'nombre': 'VICTORIA', 'efe_key': '28'},
            {'key': '042', 'nombre': 'VILLAGRÁN', 'efe_key': '28'},
            {'key': '043', 'nombre': 'XICOTÉNCATL', 'efe_key': '28'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '28'},
            {'key': '001', 'nombre': 'AMAXAC DE GUERRERO', 'efe_key': '29'},
            {'key': '002', 'nombre': 'APETATITLÁN DE ANTONIO CARVAJAL', 'efe_key': '29'},
            {'key': '003', 'nombre': 'ATLANGATEPEC', 'efe_key': '29'},
            {'key': '004', 'nombre': 'ATLTZAYANCA', 'efe_key': '29'},
            {'key': '005', 'nombre': 'APIZACO', 'efe_key': '29'},
            {'key': '006', 'nombre': 'CALPULALPAN', 'efe_key': '29'},
            {'key': '007', 'nombre': 'EL CARMEN TEQUEXQUITLA', 'efe_key': '29'},
            {'key': '008', 'nombre': 'CUAPIAXTLA', 'efe_key': '29'},
            {'key': '009', 'nombre': 'CUAXOMULCO', 'efe_key': '29'},
            {'key': '010', 'nombre': 'CHIAUTEMPAN', 'efe_key': '29'},
            {'key': '011', 'nombre': 'MUÑOZ DE DOMINGO ARENAS', 'efe_key': '29'},
            {'key': '012', 'nombre': 'ESPAÑITA', 'efe_key': '29'},
            {'key': '013', 'nombre': 'HUAMANTLA', 'efe_key': '29'},
            {'key': '014', 'nombre': 'HUEYOTLIPAN', 'efe_key': '29'},
            {'key': '015', 'nombre': 'IXTACUIXTLA DE MARIANO MATAMOROS', 'efe_key': '29'},
            {'key': '016', 'nombre': 'IXTENCO', 'efe_key': '29'},
            {'key': '017', 'nombre': 'MAZATECOCHCO DE JOSÉ MARÍA MORELOS', 'efe_key': '29'},
            {'key': '018', 'nombre': 'CONTLA DE JUAN CUAMATZI', 'efe_key': '29'},
            {'key': '019', 'nombre': 'TEPETITLA DE LARDIZÁBAL', 'efe_key': '29'},
            {'key': '020', 'nombre': 'SANCTÓRUM DE LÁZARO CÁRDENAS', 'efe_key': '29'},
            {'key': '021', 'nombre': 'NANACAMILPA DE MARIANO ARISTA', 'efe_key': '29'},
            {'key': '022', 'nombre': 'ACUAMANALA DE MIGUEL HIDALGO', 'efe_key': '29'},
            {'key': '023', 'nombre': 'NATÍVITAS', 'efe_key': '29'},
            {'key': '024', 'nombre': 'PANOTLA', 'efe_key': '29'},
            {'key': '025', 'nombre': 'SAN PABLO DEL MONTE', 'efe_key': '29'},
            {'key': '026', 'nombre': 'SANTA CRUZ TLAXCALA', 'efe_key': '29'},
            {'key': '027', 'nombre': 'TENANCINGO', 'efe_key': '29'},
            {'key': '028', 'nombre': 'TEOLOCHOLCO', 'efe_key': '29'},
            {'key': '029', 'nombre': 'TEPEYANCO', 'efe_key': '29'},
            {'key': '030', 'nombre': 'TERRENATE', 'efe_key': '29'},
            {'key': '031', 'nombre': 'TETLA DE LA SOLIDARIDAD', 'efe_key': '29'},
            {'key': '032', 'nombre': 'TETLATLAHUCA', 'efe_key': '29'},
            {'key': '033', 'nombre': 'TLAXCALA', 'efe_key': '29'},
            {'key': '034', 'nombre': 'TLAXCO', 'efe_key': '29'},
            {'key': '035', 'nombre': 'TOCATLÁN', 'efe_key': '29'},
            {'key': '036', 'nombre': 'TOTOLAC', 'efe_key': '29'},
            {'key': '037', 'nombre': 'ZILTLALTÉPEC DE TRINIDAD SÁNCHEZ SANTOS', 'efe_key': '29'},
            {'key': '038', 'nombre': 'TZOMPANTEPEC', 'efe_key': '29'},
            {'key': '039', 'nombre': 'XALOZTOC', 'efe_key': '29'},
            {'key': '040', 'nombre': 'XALTOCAN', 'efe_key': '29'},
            {'key': '041', 'nombre': 'PAPALOTLA DE XICOHTÉNCATL', 'efe_key': '29'},
            {'key': '042', 'nombre': 'XICOHTZINCO', 'efe_key': '29'},
            {'key': '043', 'nombre': 'YAUHQUEMEHCAN', 'efe_key': '29'},
            {'key': '044', 'nombre': 'ZACATELCO', 'efe_key': '29'},
            {'key': '045', 'nombre': 'BENITO JUÁREZ', 'efe_key': '29'},
            {'key': '046', 'nombre': 'EMILIANO ZAPATA', 'efe_key': '29'},
            {'key': '047', 'nombre': 'LÁZARO CÁRDENAS', 'efe_key': '29'},
            {'key': '048', 'nombre': 'LA MAGDALENA TLALTELULCO', 'efe_key': '29'},
            {'key': '049', 'nombre': 'SAN DAMIÁN TEXÓLOC', 'efe_key': '29'},
            {'key': '050', 'nombre': 'SAN FRANCISCO TETLANOHCAN', 'efe_key': '29'},
            {'key': '051', 'nombre': 'SAN JERÓNIMO ZACUALPAN', 'efe_key': '29'},
            {'key': '052', 'nombre': 'SAN JOSÉ TEACALCO', 'efe_key': '29'},
            {'key': '053', 'nombre': 'SAN JUAN HUACTZINCO', 'efe_key': '29'},
            {'key': '054', 'nombre': 'SAN LORENZO AXOCOMANITLA', 'efe_key': '29'},
            {'key': '055', 'nombre': 'SAN LUCAS TECOPILCO', 'efe_key': '29'},
            {'key': '056', 'nombre': 'SANTA ANA NOPALUCAN', 'efe_key': '29'},
            {'key': '057', 'nombre': 'SANTA APOLONIA TEACALCO', 'efe_key': '29'},
            {'key': '058', 'nombre': 'SANTA CATARINA AYOMETLA', 'efe_key': '29'},
            {'key': '059', 'nombre': 'SANTA CRUZ QUILEHTLA', 'efe_key': '29'},
            {'key': '060', 'nombre': 'SANTA ISABEL XILOXOXTLA', 'efe_key': '29'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '29'},
            {'key': '001', 'nombre': 'ACAJETE', 'efe_key': '30'},
            {'key': '002', 'nombre': 'ACATLÁN', 'efe_key': '30'},
            {'key': '003', 'nombre': 'ACAYUCAN', 'efe_key': '30'},
            {'key': '004', 'nombre': 'ACTOPAN', 'efe_key': '30'},
            {'key': '005', 'nombre': 'ACULA', 'efe_key': '30'},
            {'key': '006', 'nombre': 'ACULTZINGO', 'efe_key': '30'},
            {'key': '007', 'nombre': 'CAMARÓN DE TEJEDA', 'efe_key': '30'},
            {'key': '008', 'nombre': 'ALPATLÁHUAC', 'efe_key': '30'},
            {'key': '009', 'nombre': 'ALTO LUCERO DE GUTIÉRREZ BARRIOS', 'efe_key': '30'},
            {'key': '010', 'nombre': 'ALTOTONGA', 'efe_key': '30'},
            {'key': '011', 'nombre': 'ALVARADO', 'efe_key': '30'},
            {'key': '012', 'nombre': 'AMATITLÁN', 'efe_key': '30'},
            {'key': '013', 'nombre': 'NARANJOS AMATLÁN', 'efe_key': '30'},
            {'key': '014', 'nombre': 'AMATLÁN DE LOS REYES', 'efe_key': '30'},
            {'key': '015', 'nombre': 'ANGEL R. CABADA', 'efe_key': '30'},
            {'key': '016', 'nombre': 'LA ANTIGUA', 'efe_key': '30'},
            {'key': '017', 'nombre': 'APAZAPAN', 'efe_key': '30'},
            {'key': '018', 'nombre': 'AQUILA', 'efe_key': '30'},
            {'key': '019', 'nombre': 'ASTACINGA', 'efe_key': '30'},
            {'key': '020', 'nombre': 'ATLAHUILCO', 'efe_key': '30'},
            {'key': '021', 'nombre': 'ATOYAC', 'efe_key': '30'},
            {'key': '022', 'nombre': 'ATZACAN', 'efe_key': '30'},
            {'key': '023', 'nombre': 'ATZALAN', 'efe_key': '30'},
            {'key': '024', 'nombre': 'TLALTETELA', 'efe_key': '30'},
            {'key': '025', 'nombre': 'AYAHUALULCO', 'efe_key': '30'},
            {'key': '026', 'nombre': 'BANDERILLA', 'efe_key': '30'},
            {'key': '027', 'nombre': 'BENITO JUÁREZ', 'efe_key': '30'},
            {'key': '028', 'nombre': 'BOCA DEL RÍO', 'efe_key': '30'},
            {'key': '029', 'nombre': 'CALCAHUALCO', 'efe_key': '30'},
            {'key': '030', 'nombre': 'CAMERINO Z. MENDOZA', 'efe_key': '30'},
            {'key': '031', 'nombre': 'CARRILLO PUERTO', 'efe_key': '30'},
            {'key': '032', 'nombre': 'CATEMACO', 'efe_key': '30'},
            {'key': '033', 'nombre': 'CAZONES DE HERRERA', 'efe_key': '30'},
            {'key': '034', 'nombre': 'CERRO AZUL', 'efe_key': '30'},
            {'key': '035', 'nombre': 'CITLALTÉPETL', 'efe_key': '30'},
            {'key': '036', 'nombre': 'COACOATZINTLA', 'efe_key': '30'},
            {'key': '037', 'nombre': 'COAHUITLÁN', 'efe_key': '30'},
            {'key': '038', 'nombre': 'COATEPEC', 'efe_key': '30'},
            {'key': '039', 'nombre': 'COATZACOALCOS', 'efe_key': '30'},
            {'key': '040', 'nombre': 'COATZINTLA', 'efe_key': '30'},
            {'key': '041', 'nombre': 'COETZALA', 'efe_key': '30'},
            {'key': '042', 'nombre': 'COLIPA', 'efe_key': '30'},
            {'key': '043', 'nombre': 'COMAPA', 'efe_key': '30'},
            {'key': '044', 'nombre': 'CÓRDOBA', 'efe_key': '30'},
            {'key': '045', 'nombre': 'COSAMALOAPAN DE CARPIO', 'efe_key': '30'},
            {'key': '046', 'nombre': 'COSAUTLÁN DE CARVAJAL', 'efe_key': '30'},
            {'key': '047', 'nombre': 'COSCOMATEPEC', 'efe_key': '30'},
            {'key': '048', 'nombre': 'COSOLEACAQUE', 'efe_key': '30'},
            {'key': '049', 'nombre': 'COTAXTLA', 'efe_key': '30'},
            {'key': '050', 'nombre': 'COXQUIHUI', 'efe_key': '30'},
            {'key': '051', 'nombre': 'COYUTLA', 'efe_key': '30'},
            {'key': '052', 'nombre': 'CUICHAPA', 'efe_key': '30'},
            {'key': '053', 'nombre': 'CUITLÁHUAC', 'efe_key': '30'},
            {'key': '054', 'nombre': 'CHACALTIANGUIS', 'efe_key': '30'},
            {'key': '055', 'nombre': 'CHALMA', 'efe_key': '30'},
            {'key': '056', 'nombre': 'CHICONAMEL', 'efe_key': '30'},
            {'key': '057', 'nombre': 'CHICONQUIACO', 'efe_key': '30'},
            {'key': '058', 'nombre': 'CHICONTEPEC', 'efe_key': '30'},
            {'key': '059', 'nombre': 'CHINAMECA', 'efe_key': '30'},
            {'key': '060', 'nombre': 'CHINAMPA DE GOROSTIZA', 'efe_key': '30'},
            {'key': '061', 'nombre': 'LAS CHOAPAS', 'efe_key': '30'},
            {'key': '062', 'nombre': 'CHOCAMÁN', 'efe_key': '30'},
            {'key': '063', 'nombre': 'CHONTLA', 'efe_key': '30'},
            {'key': '064', 'nombre': 'CHUMATLÁN', 'efe_key': '30'},
            {'key': '065', 'nombre': 'EMILIANO ZAPATA', 'efe_key': '30'},
            {'key': '066', 'nombre': 'ESPINAL', 'efe_key': '30'},
            {'key': '067', 'nombre': 'FILOMENO MATA', 'efe_key': '30'},
            {'key': '068', 'nombre': 'FORTÍN', 'efe_key': '30'},
            {'key': '069', 'nombre': 'GUTIÉRREZ ZAMORA', 'efe_key': '30'},
            {'key': '070', 'nombre': 'HIDALGOTITLÁN', 'efe_key': '30'},
            {'key': '071', 'nombre': 'HUATUSCO', 'efe_key': '30'},
            {'key': '072', 'nombre': 'HUAYACOCOTLA', 'efe_key': '30'},
            {'key': '073', 'nombre': 'HUEYAPAN DE OCAMPO', 'efe_key': '30'},
            {'key': '074', 'nombre': 'HUILOAPAN DE CUAUHTÉMOC', 'efe_key': '30'},
            {'key': '075', 'nombre': 'IGNACIO DE LA LLAVE', 'efe_key': '30'},
            {'key': '076', 'nombre': 'ILAMATLÁN', 'efe_key': '30'},
            {'key': '077', 'nombre': 'ISLA', 'efe_key': '30'},
            {'key': '078', 'nombre': 'IXCATEPEC', 'efe_key': '30'},
            {'key': '079', 'nombre': 'IXHUACÁN DE LOS REYES', 'efe_key': '30'},
            {'key': '080', 'nombre': 'IXHUATLÁN DEL CAFÉ', 'efe_key': '30'},
            {'key': '081', 'nombre': 'IXHUATLANCILLO', 'efe_key': '30'},
            {'key': '082', 'nombre': 'IXHUATLÁN DEL SURESTE', 'efe_key': '30'},
            {'key': '083', 'nombre': 'IXHUATLÁN DE MADERO', 'efe_key': '30'},
            {'key': '084', 'nombre': 'IXMATLAHUACAN', 'efe_key': '30'},
            {'key': '085', 'nombre': 'IXTACZOQUITLÁN', 'efe_key': '30'},
            {'key': '086', 'nombre': 'JALACINGO', 'efe_key': '30'},
            {'key': '087', 'nombre': 'XALAPA', 'efe_key': '30'},
            {'key': '088', 'nombre': 'JALCOMULCO', 'efe_key': '30'},
            {'key': '089', 'nombre': 'JÁLTIPAN', 'efe_key': '30'},
            {'key': '090', 'nombre': 'JAMAPA', 'efe_key': '30'},
            {'key': '091', 'nombre': 'JESÚS CARRANZA', 'efe_key': '30'},
            {'key': '092', 'nombre': 'XICO', 'efe_key': '30'},
            {'key': '093', 'nombre': 'JILOTEPEC', 'efe_key': '30'},
            {'key': '094', 'nombre': 'JUAN RODRÍGUEZ CLARA', 'efe_key': '30'},
            {'key': '095', 'nombre': 'JUCHIQUE DE FERRER', 'efe_key': '30'},
            {'key': '096', 'nombre': 'LANDERO Y COSS', 'efe_key': '30'},
            {'key': '097', 'nombre': 'LERDO DE TEJADA', 'efe_key': '30'},
            {'key': '098', 'nombre': 'MAGDALENA', 'efe_key': '30'},
            {'key': '099', 'nombre': 'MALTRATA', 'efe_key': '30'},
            {'key': '100', 'nombre': 'MANLIO FABIO ALTAMIRANO', 'efe_key': '30'},
            {'key': '101', 'nombre': 'MARIANO ESCOBEDO', 'efe_key': '30'},
            {'key': '102', 'nombre': 'MARTÍNEZ DE LA TORRE', 'efe_key': '30'},
            {'key': '103', 'nombre': 'MECATLÁN', 'efe_key': '30'},
            {'key': '104', 'nombre': 'MECAYAPAN', 'efe_key': '30'},
            {'key': '105', 'nombre': 'MEDELLÍN DE BRAVO', 'efe_key': '30'},
            {'key': '106', 'nombre': 'MIAHUATLÁN', 'efe_key': '30'},
            {'key': '107', 'nombre': 'LAS MINAS', 'efe_key': '30'},
            {'key': '108', 'nombre': 'MINATITLÁN', 'efe_key': '30'},
            {'key': '109', 'nombre': 'MISANTLA', 'efe_key': '30'},
            {'key': '110', 'nombre': 'MIXTLA DE ALTAMIRANO', 'efe_key': '30'},
            {'key': '111', 'nombre': 'MOLOACÁN', 'efe_key': '30'},
            {'key': '112', 'nombre': 'NAOLINCO', 'efe_key': '30'},
            {'key': '113', 'nombre': 'NARANJAL', 'efe_key': '30'},
            {'key': '114', 'nombre': 'NAUTLA', 'efe_key': '30'},
            {'key': '115', 'nombre': 'NOGALES', 'efe_key': '30'},
            {'key': '116', 'nombre': 'OLUTA', 'efe_key': '30'},
            {'key': '117', 'nombre': 'OMEALCA', 'efe_key': '30'},
            {'key': '118', 'nombre': 'ORIZABA', 'efe_key': '30'},
            {'key': '119', 'nombre': 'OTATITLÁN', 'efe_key': '30'},
            {'key': '120', 'nombre': 'OTEAPAN', 'efe_key': '30'},
            {'key': '121', 'nombre': 'OZULUAMA DE MASCAREÑAS', 'efe_key': '30'},
            {'key': '122', 'nombre': 'PAJAPAN', 'efe_key': '30'},
            {'key': '123', 'nombre': 'PÁNUCO', 'efe_key': '30'},
            {'key': '124', 'nombre': 'PAPANTLA', 'efe_key': '30'},
            {'key': '125', 'nombre': 'PASO DEL MACHO', 'efe_key': '30'},
            {'key': '126', 'nombre': 'PASO DE OVEJAS', 'efe_key': '30'},
            {'key': '127', 'nombre': 'LA PERLA', 'efe_key': '30'},
            {'key': '128', 'nombre': 'PEROTE', 'efe_key': '30'},
            {'key': '129', 'nombre': 'PLATÓN SÁNCHEZ', 'efe_key': '30'},
            {'key': '130', 'nombre': 'PLAYA VICENTE', 'efe_key': '30'},
            {'key': '131', 'nombre': 'POZA RICA DE HIDALGO', 'efe_key': '30'},
            {'key': '132', 'nombre': 'LAS VIGAS DE RAMÍREZ', 'efe_key': '30'},
            {'key': '133', 'nombre': 'PUEBLO VIEJO', 'efe_key': '30'},
            {'key': '134', 'nombre': 'PUENTE NACIONAL', 'efe_key': '30'},
            {'key': '135', 'nombre': 'RAFAEL DELGADO', 'efe_key': '30'},
            {'key': '136', 'nombre': 'RAFAEL LUCIO', 'efe_key': '30'},
            {'key': '137', 'nombre': 'LOS REYES', 'efe_key': '30'},
            {'key': '138', 'nombre': 'RÍO BLANCO', 'efe_key': '30'},
            {'key': '139', 'nombre': 'SALTABARRANCA', 'efe_key': '30'},
            {'key': '140', 'nombre': 'SAN ANDRÉS TENEJAPAN', 'efe_key': '30'},
            {'key': '141', 'nombre': 'SAN ANDRÉS TUXTLA', 'efe_key': '30'},
            {'key': '142', 'nombre': 'SAN JUAN EVANGELISTA', 'efe_key': '30'},
            {'key': '143', 'nombre': 'SANTIAGO TUXTLA', 'efe_key': '30'},
            {'key': '144', 'nombre': 'SAYULA DE ALEMÁN', 'efe_key': '30'},
            {'key': '145', 'nombre': 'SOCONUSCO', 'efe_key': '30'},
            {'key': '146', 'nombre': 'SOCHIAPA', 'efe_key': '30'},
            {'key': '147', 'nombre': 'SOLEDAD ATZOMPA', 'efe_key': '30'},
            {'key': '148', 'nombre': 'SOLEDAD DE DOBLADO', 'efe_key': '30'},
            {'key': '149', 'nombre': 'SOTEAPAN', 'efe_key': '30'},
            {'key': '150', 'nombre': 'TAMALÍN', 'efe_key': '30'},
            {'key': '151', 'nombre': 'TAMIAHUA', 'efe_key': '30'},
            {'key': '152', 'nombre': 'TAMPICO ALTO', 'efe_key': '30'},
            {'key': '153', 'nombre': 'TANCOCO', 'efe_key': '30'},
            {'key': '154', 'nombre': 'TANTIMA', 'efe_key': '30'},
            {'key': '155', 'nombre': 'TANTOYUCA', 'efe_key': '30'},
            {'key': '156', 'nombre': 'TATATILA', 'efe_key': '30'},
            {'key': '157', 'nombre': 'CASTILLO DE TEAYO', 'efe_key': '30'},
            {'key': '158', 'nombre': 'TECOLUTLA', 'efe_key': '30'},
            {'key': '159', 'nombre': 'TEHUIPANGO', 'efe_key': '30'},
            {'key': '160', 'nombre': 'ÁLAMO TEMAPACHE', 'efe_key': '30'},
            {'key': '161', 'nombre': 'TEMPOAL', 'efe_key': '30'},
            {'key': '162', 'nombre': 'TENAMPA', 'efe_key': '30'},
            {'key': '163', 'nombre': 'TENOCHTITLÁN', 'efe_key': '30'},
            {'key': '164', 'nombre': 'TEOCELO', 'efe_key': '30'},
            {'key': '165', 'nombre': 'TEPATLAXCO', 'efe_key': '30'},
            {'key': '166', 'nombre': 'TEPETLÁN', 'efe_key': '30'},
            {'key': '167', 'nombre': 'TEPETZINTLA', 'efe_key': '30'},
            {'key': '168', 'nombre': 'TEQUILA', 'efe_key': '30'},
            {'key': '169', 'nombre': 'JOSÉ AZUETA', 'efe_key': '30'},
            {'key': '170', 'nombre': 'TEXCATEPEC', 'efe_key': '30'},
            {'key': '171', 'nombre': 'TEXHUACÁN', 'efe_key': '30'},
            {'key': '172', 'nombre': 'TEXISTEPEC', 'efe_key': '30'},
            {'key': '173', 'nombre': 'TEZONAPA', 'efe_key': '30'},
            {'key': '174', 'nombre': 'TIERRA BLANCA', 'efe_key': '30'},
            {'key': '175', 'nombre': 'TIHUATLÁN', 'efe_key': '30'},
            {'key': '176', 'nombre': 'TLACOJALPAN', 'efe_key': '30'},
            {'key': '177', 'nombre': 'TLACOLULAN', 'efe_key': '30'},
            {'key': '178', 'nombre': 'TLACOTALPAN', 'efe_key': '30'},
            {'key': '179', 'nombre': 'TLACOTEPEC DE MEJÍA', 'efe_key': '30'},
            {'key': '180', 'nombre': 'TLACHICHILCO', 'efe_key': '30'},
            {'key': '181', 'nombre': 'TLALIXCOYAN', 'efe_key': '30'},
            {'key': '182', 'nombre': 'TLALNELHUAYOCAN', 'efe_key': '30'},
            {'key': '183', 'nombre': 'TLAPACOYAN', 'efe_key': '30'},
            {'key': '184', 'nombre': 'TLAQUILPA', 'efe_key': '30'},
            {'key': '185', 'nombre': 'TLILAPAN', 'efe_key': '30'},
            {'key': '186', 'nombre': 'TOMATLÁN', 'efe_key': '30'},
            {'key': '187', 'nombre': 'TONAYÁN', 'efe_key': '30'},
            {'key': '188', 'nombre': 'TOTUTLA', 'efe_key': '30'},
            {'key': '189', 'nombre': 'TUXPAN', 'efe_key': '30'},
            {'key': '190', 'nombre': 'TUXTILLA', 'efe_key': '30'},
            {'key': '191', 'nombre': 'URSULO GALVÁN', 'efe_key': '30'},
            {'key': '192', 'nombre': 'VEGA DE ALATORRE', 'efe_key': '30'},
            {'key': '193', 'nombre': 'VERACRUZ', 'efe_key': '30'},
            {'key': '194', 'nombre': 'VILLA ALDAMA', 'efe_key': '30'},
            {'key': '195', 'nombre': 'XOXOCOTLA', 'efe_key': '30'},
            {'key': '196', 'nombre': 'YANGA', 'efe_key': '30'},
            {'key': '197', 'nombre': 'YECUATLA', 'efe_key': '30'},
            {'key': '198', 'nombre': 'ZACUALPAN', 'efe_key': '30'},
            {'key': '199', 'nombre': 'ZARAGOZA', 'efe_key': '30'},
            {'key': '200', 'nombre': 'ZENTLA', 'efe_key': '30'},
            {'key': '201', 'nombre': 'ZONGOLICA', 'efe_key': '30'},
            {'key': '202', 'nombre': 'ZONTECOMATLÁN DE LÓPEZ Y FUENTES', 'efe_key': '30'},
            {'key': '203', 'nombre': 'ZOZOCOLCO DE HIDALGO', 'efe_key': '30'},
            {'key': '204', 'nombre': 'AGUA DULCE', 'efe_key': '30'},
            {'key': '205', 'nombre': 'EL HIGO', 'efe_key': '30'},
            {'key': '206', 'nombre': 'NANCHITAL DE LÁZARO CÁRDENAS DEL RÍO', 'efe_key': '30'},
            {'key': '207', 'nombre': 'TRES VALLES', 'efe_key': '30'},
            {'key': '208', 'nombre': 'CARLOS A. CARRILLO', 'efe_key': '30'},
            {'key': '209', 'nombre': 'TATAHUICAPAN DE JUÁREZ', 'efe_key': '30'},
            {'key': '210', 'nombre': 'UXPANAPA', 'efe_key': '30'},
            {'key': '211', 'nombre': 'SAN RAFAEL', 'efe_key': '30'},
            {'key': '212', 'nombre': 'SANTIAGO SOCHIAPAN', 'efe_key': '30'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '30'},
            {'key': '001', 'nombre': 'ABALÁ', 'efe_key': '31'},
            {'key': '002', 'nombre': 'ACANCEH', 'efe_key': '31'},
            {'key': '003', 'nombre': 'AKIL', 'efe_key': '31'},
            {'key': '004', 'nombre': 'BACA', 'efe_key': '31'},
            {'key': '005', 'nombre': 'BOKOBÁ', 'efe_key': '31'},
            {'key': '006', 'nombre': 'BUCTZOTZ', 'efe_key': '31'},
            {'key': '007', 'nombre': 'CACALCHÉN', 'efe_key': '31'},
            {'key': '008', 'nombre': 'CALOTMUL', 'efe_key': '31'},
            {'key': '009', 'nombre': 'CANSAHCAB', 'efe_key': '31'},
            {'key': '010', 'nombre': 'CANTAMAYEC', 'efe_key': '31'},
            {'key': '011', 'nombre': 'CELESTÚN', 'efe_key': '31'},
            {'key': '012', 'nombre': 'CENOTILLO', 'efe_key': '31'},
            {'key': '013', 'nombre': 'CONKAL', 'efe_key': '31'},
            {'key': '014', 'nombre': 'CUNCUNUL', 'efe_key': '31'},
            {'key': '015', 'nombre': 'CUZAMÁ', 'efe_key': '31'},
            {'key': '016', 'nombre': 'CHACSINKÍN', 'efe_key': '31'},
            {'key': '017', 'nombre': 'CHANKOM', 'efe_key': '31'},
            {'key': '018', 'nombre': 'CHAPAB', 'efe_key': '31'},
            {'key': '019', 'nombre': 'CHEMAX', 'efe_key': '31'},
            {'key': '020', 'nombre': 'CHICXULUB PUEBLO', 'efe_key': '31'},
            {'key': '021', 'nombre': 'CHICHIMILÁ', 'efe_key': '31'},
            {'key': '022', 'nombre': 'CHIKINDZONOT', 'efe_key': '31'},
            {'key': '023', 'nombre': 'CHOCHOLÁ', 'efe_key': '31'},
            {'key': '024', 'nombre': 'CHUMAYEL', 'efe_key': '31'},
            {'key': '025', 'nombre': 'DZÁN', 'efe_key': '31'},
            {'key': '026', 'nombre': 'DZEMUL', 'efe_key': '31'},
            {'key': '027', 'nombre': 'DZIDZANTÚN', 'efe_key': '31'},
            {'key': '028', 'nombre': 'DZILAM DE BRAVO', 'efe_key': '31'},
            {'key': '029', 'nombre': 'DZILAM GONZÁLEZ', 'efe_key': '31'},
            {'key': '030', 'nombre': 'DZITÁS', 'efe_key': '31'},
            {'key': '031', 'nombre': 'DZONCAUICH', 'efe_key': '31'},
            {'key': '032', 'nombre': 'ESPITA', 'efe_key': '31'},
            {'key': '033', 'nombre': 'HALACHÓ', 'efe_key': '31'},
            {'key': '034', 'nombre': 'HOCABÁ', 'efe_key': '31'},
            {'key': '035', 'nombre': 'HOCTÚN', 'efe_key': '31'},
            {'key': '036', 'nombre': 'HOMÚN', 'efe_key': '31'},
            {'key': '037', 'nombre': 'HUHÍ', 'efe_key': '31'},
            {'key': '038', 'nombre': 'HUNUCMÁ', 'efe_key': '31'},
            {'key': '039', 'nombre': 'IXIL', 'efe_key': '31'},
            {'key': '040', 'nombre': 'IZAMAL', 'efe_key': '31'},
            {'key': '041', 'nombre': 'KANASÍN', 'efe_key': '31'},
            {'key': '042', 'nombre': 'KANTUNIL', 'efe_key': '31'},
            {'key': '043', 'nombre': 'KAUA', 'efe_key': '31'},
            {'key': '044', 'nombre': 'KINCHIL', 'efe_key': '31'},
            {'key': '045', 'nombre': 'KOPOMÁ', 'efe_key': '31'},
            {'key': '046', 'nombre': 'MAMA', 'efe_key': '31'},
            {'key': '047', 'nombre': 'MANÍ', 'efe_key': '31'},
            {'key': '048', 'nombre': 'MAXCANÚ', 'efe_key': '31'},
            {'key': '049', 'nombre': 'MAYAPÁN', 'efe_key': '31'},
            {'key': '050', 'nombre': 'MÉRIDA', 'efe_key': '31'},
            {'key': '051', 'nombre': 'MOCOCHÁ', 'efe_key': '31'},
            {'key': '052', 'nombre': 'MOTUL', 'efe_key': '31'},
            {'key': '053', 'nombre': 'MUNA', 'efe_key': '31'},
            {'key': '054', 'nombre': 'MUXUPIP', 'efe_key': '31'},
            {'key': '055', 'nombre': 'OPICHÉN', 'efe_key': '31'},
            {'key': '056', 'nombre': 'OXKUTZCAB', 'efe_key': '31'},
            {'key': '057', 'nombre': 'PANABÁ', 'efe_key': '31'},
            {'key': '058', 'nombre': 'PETO', 'efe_key': '31'},
            {'key': '059', 'nombre': 'PROGRESO', 'efe_key': '31'},
            {'key': '060', 'nombre': 'QUINTANA ROO', 'efe_key': '31'},
            {'key': '061', 'nombre': 'RÍO LAGARTOS', 'efe_key': '31'},
            {'key': '062', 'nombre': 'SACALUM', 'efe_key': '31'},
            {'key': '063', 'nombre': 'SAMAHIL', 'efe_key': '31'},
            {'key': '064', 'nombre': 'SANAHCAT', 'efe_key': '31'},
            {'key': '065', 'nombre': 'SAN FELIPE', 'efe_key': '31'},
            {'key': '066', 'nombre': 'SANTA ELENA', 'efe_key': '31'},
            {'key': '067', 'nombre': 'SEYÉ', 'efe_key': '31'},
            {'key': '068', 'nombre': 'SINANCHÉ', 'efe_key': '31'},
            {'key': '069', 'nombre': 'SOTUTA', 'efe_key': '31'},
            {'key': '070', 'nombre': 'SUCILÁ', 'efe_key': '31'},
            {'key': '071', 'nombre': 'SUDZAL', 'efe_key': '31'},
            {'key': '072', 'nombre': 'SUMA', 'efe_key': '31'},
            {'key': '073', 'nombre': 'TAHDZIÚ', 'efe_key': '31'},
            {'key': '074', 'nombre': 'TAHMEK', 'efe_key': '31'},
            {'key': '075', 'nombre': 'TEABO', 'efe_key': '31'},
            {'key': '076', 'nombre': 'TECOH', 'efe_key': '31'},
            {'key': '077', 'nombre': 'TEKAL DE VENEGAS', 'efe_key': '31'},
            {'key': '078', 'nombre': 'TEKANTÓ', 'efe_key': '31'},
            {'key': '079', 'nombre': 'TEKAX', 'efe_key': '31'},
            {'key': '080', 'nombre': 'TEKIT', 'efe_key': '31'},
            {'key': '081', 'nombre': 'TEKOM', 'efe_key': '31'},
            {'key': '082', 'nombre': 'TELCHAC PUEBLO', 'efe_key': '31'},
            {'key': '083', 'nombre': 'TELCHAC PUERTO', 'efe_key': '31'},
            {'key': '084', 'nombre': 'TEMAX', 'efe_key': '31'},
            {'key': '085', 'nombre': 'TEMOZÓN', 'efe_key': '31'},
            {'key': '086', 'nombre': 'TEPAKÁN', 'efe_key': '31'},
            {'key': '087', 'nombre': 'TETIZ', 'efe_key': '31'},
            {'key': '088', 'nombre': 'TEYA', 'efe_key': '31'},
            {'key': '089', 'nombre': 'TICUL', 'efe_key': '31'},
            {'key': '090', 'nombre': 'TIMUCUY', 'efe_key': '31'},
            {'key': '091', 'nombre': 'TINUM', 'efe_key': '31'},
            {'key': '092', 'nombre': 'TIXCACALCUPUL', 'efe_key': '31'},
            {'key': '093', 'nombre': 'TIXKOKOB', 'efe_key': '31'},
            {'key': '094', 'nombre': 'TIXMEHUAC', 'efe_key': '31'},
            {'key': '095', 'nombre': 'TIXPÉHUAL', 'efe_key': '31'},
            {'key': '096', 'nombre': 'TIZIMÍN', 'efe_key': '31'},
            {'key': '097', 'nombre': 'TUNKÁS', 'efe_key': '31'},
            {'key': '098', 'nombre': 'TZUCACAB', 'efe_key': '31'},
            {'key': '099', 'nombre': 'UAYMA', 'efe_key': '31'},
            {'key': '100', 'nombre': 'UCÚ', 'efe_key': '31'},
            {'key': '101', 'nombre': 'UMÁN', 'efe_key': '31'},
            {'key': '102', 'nombre': 'VALLADOLID', 'efe_key': '31'},
            {'key': '103', 'nombre': 'XOCCHEL', 'efe_key': '31'},
            {'key': '104', 'nombre': 'YAXCABÁ', 'efe_key': '31'},
            {'key': '105', 'nombre': 'YAXKUKUL', 'efe_key': '31'},
            {'key': '106', 'nombre': 'YOBAÍN', 'efe_key': '31'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '31'},
            {'key': '001', 'nombre': 'APOZOL', 'efe_key': '32'},
            {'key': '002', 'nombre': 'APULCO', 'efe_key': '32'},
            {'key': '003', 'nombre': 'ATOLINGA', 'efe_key': '32'},
            {'key': '004', 'nombre': 'BENITO JUÁREZ', 'efe_key': '32'},
            {'key': '005', 'nombre': 'CALERA', 'efe_key': '32'},
            {'key': '006', 'nombre': 'CAÑITAS DE FELIPE PESCADOR', 'efe_key': '32'},
            {'key': '007', 'nombre': 'CONCEPCIÓN DEL ORO', 'efe_key': '32'},
            {'key': '008', 'nombre': 'CUAUHTÉMOC', 'efe_key': '32'},
            {'key': '009', 'nombre': 'CHALCHIHUITES', 'efe_key': '32'},
            {'key': '010', 'nombre': 'FRESNILLO', 'efe_key': '32'},
            {'key': '011', 'nombre': 'TRINIDAD GARCÍA DE LA CADENA', 'efe_key': '32'},
            {'key': '012', 'nombre': 'GENARO CODINA', 'efe_key': '32'},
            {'key': '013', 'nombre': 'GENERAL ENRIQUE ESTRADA', 'efe_key': '32'},
            {'key': '014', 'nombre': 'GENERAL FRANCISCO R. MURGUÍA', 'efe_key': '32'},
            {'key': '015', 'nombre': 'EL PLATEADO DE JOAQUÍN AMARO', 'efe_key': '32'},
            {'key': '016', 'nombre': 'GENERAL PÁNFILO NATERA', 'efe_key': '32'},
            {'key': '017', 'nombre': 'GUADALUPE', 'efe_key': '32'},
            {'key': '018', 'nombre': 'HUANUSCO', 'efe_key': '32'},
            {'key': '019', 'nombre': 'JALPA', 'efe_key': '32'},
            {'key': '020', 'nombre': 'JEREZ', 'efe_key': '32'},
            {'key': '021', 'nombre': 'JIMÉNEZ DEL TEUL', 'efe_key': '32'},
            {'key': '022', 'nombre': 'JUAN ALDAMA', 'efe_key': '32'},
            {'key': '023', 'nombre': 'JUCHIPILA', 'efe_key': '32'},
            {'key': '024', 'nombre': 'LORETO', 'efe_key': '32'},
            {'key': '025', 'nombre': 'LUIS MOYA', 'efe_key': '32'},
            {'key': '026', 'nombre': 'MAZAPIL', 'efe_key': '32'},
            {'key': '027', 'nombre': 'MELCHOR OCAMPO', 'efe_key': '32'},
            {'key': '028', 'nombre': 'MEZQUITAL DEL ORO', 'efe_key': '32'},
            {'key': '029', 'nombre': 'MIGUEL AUZA', 'efe_key': '32'},
            {'key': '030', 'nombre': 'MOMAX', 'efe_key': '32'},
            {'key': '031', 'nombre': 'MONTE ESCOBEDO', 'efe_key': '32'},
            {'key': '032', 'nombre': 'MORELOS', 'efe_key': '32'},
            {'key': '033', 'nombre': 'MOYAHUA DE ESTRADA', 'efe_key': '32'},
            {'key': '034', 'nombre': 'NOCHISTLÁN DE MEJÍA', 'efe_key': '32'},
            {'key': '035', 'nombre': 'NORIA DE ÁNGELES', 'efe_key': '32'},
            {'key': '036', 'nombre': 'OJOCALIENTE', 'efe_key': '32'},
            {'key': '037', 'nombre': 'PÁNUCO', 'efe_key': '32'},
            {'key': '038', 'nombre': 'PINOS', 'efe_key': '32'},
            {'key': '039', 'nombre': 'RÍO GRANDE', 'efe_key': '32'},
            {'key': '040', 'nombre': 'SAIN ALTO', 'efe_key': '32'},
            {'key': '041', 'nombre': 'EL SALVADOR', 'efe_key': '32'},
            {'key': '042', 'nombre': 'SOMBRERETE', 'efe_key': '32'},
            {'key': '043', 'nombre': 'SUSTICACÁN', 'efe_key': '32'},
            {'key': '044', 'nombre': 'TABASCO', 'efe_key': '32'},
            {'key': '045', 'nombre': 'TEPECHITLÁN', 'efe_key': '32'},
            {'key': '046', 'nombre': 'TEPETONGO', 'efe_key': '32'},
            {'key': '047', 'nombre': 'TEÚL DE GONZÁLEZ ORTEGA', 'efe_key': '32'},
            {'key': '048', 'nombre': 'TLALTENANGO DE SÁNCHEZ ROMÁN', 'efe_key': '32'},
            {'key': '049', 'nombre': 'VALPARAÍSO', 'efe_key': '32'},
            {'key': '050', 'nombre': 'VETAGRANDE', 'efe_key': '32'},
            {'key': '051', 'nombre': 'VILLA DE COS', 'efe_key': '32'},
            {'key': '052', 'nombre': 'VILLA GARCÍA', 'efe_key': '32'},
            {'key': '053', 'nombre': 'VILLA GONZÁLEZ ORTEGA', 'efe_key': '32'},
            {'key': '054', 'nombre': 'VILLA HIDALGO', 'efe_key': '32'},
            {'key': '055', 'nombre': 'VILLANUEVA', 'efe_key': '32'},
            {'key': '056', 'nombre': 'ZACATECAS', 'efe_key': '32'},
            {'key': '057', 'nombre': 'TRANCOSO', 'efe_key': '32'},
            {'key': '058', 'nombre': 'SANTA MARÍA DE LA PAZ', 'efe_key': '32'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '32'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '36'},
            {'key': '997', 'nombre': 'NO APLICA', 'efe_key': '97'},
            {'key': '998', 'nombre': 'SE IGNORA', 'efe_key': '98'},
            {'key': '999', 'nombre': 'NO ESPECIFICADO', 'efe_key': '99'},*/
        ];
    }

    getLocalidades(): Observable<Array<LocalidadesView>> {
        return this._http.get('/assets/api/localidades.json').map((res) => res.json());
    }

    getEscolaridad() {
        return this._http.get('/assets/api/escolaridad.json').map((res) => res.json());
    }

    getTipoSangre() {
        return [
            {id: 4, nombre: 'O+'},
            {id: 8, nombre: 'O-'},
            {id: 1, nombre: 'A+'},
            {id: 5, nombre: 'A-'},
            {id: 2, nombre: 'B+'},
            {id: 6, nombre: 'B-'},
            {id: 3, nombre: 'AB+'},
            {id: 7, nombre: 'AB-'},
            {id: 8, nombre: 'Desconoce tipo de sangre'},
        ];
    }

    getFactorRH() {
        return [
            {id: 1, nombre: 'Positivo'},
            {id: 2, nombre: 'Negativo'},
        ];
    }

    getReligion() {
        return [
            /*{'clave': '0001', 'nombre': '* CATÓLICA'},
            {'clave': '0001', 'nombre': 'AGUSTINO'},
            {'clave': '0001', 'nombre': 'AMOR MISERICORDIOSO'},
            {'clave': '0001', 'nombre': 'ASUNCIONISTA'},
            {'clave': '0001', 'nombre': 'BENEDICTINO'},
            {'clave': '0001', 'nombre': 'CAPUCHINO'},
            {'clave': '0001', 'nombre': 'CARMELITA'},
            {'clave': '0001', 'nombre': 'CARMELITA DESCALZA'},
            {'clave': '0001', 'nombre': 'CATÓLICA, APOSTÓLICA Y ROMANA'},
            {'clave': '0001', 'nombre': 'CISTERCIENSE'},
            {'clave': '0001', 'nombre': 'CLARETIANO'},
            {'clave': '0001', 'nombre': 'CLÉRIGOS DE SAN VIATOR'},
            {'clave': '0001', 'nombre': 'COMBONIANO'},
            {'clave': '0001', 'nombre': 'COMUNIDAD SALESIANA'},
            {'clave': '0001', 'nombre': 'CONCEPCIONISTA FRANCISCANA'},
            {'clave': '0001', 'nombre': 'CONGREGACIÓN DE LOS PADRES BAYONESES'},
            {'clave': '0001', 'nombre': 'CORAZONISTA'},
            {'clave': '0001', 'nombre': 'DOMINICO'},
            {'clave': '0001', 'nombre': 'ERMITAÑO EUCARÍSTICO DEL PADRE CELESTIAL'},
            {'clave': '0001', 'nombre': 'ESCLAVAS DE MARÍA INMACULADA'},
            {'clave': '0001', 'nombre': 'ESCOLAPIO'},
            {'clave': '0001', 'nombre': 'EUDISTA'},
            {'clave': '0001', 'nombre': 'FRANCISCANO'},
            {'clave': '0001', 'nombre': 'GUADALUPANO'},
            {'clave': '0001', 'nombre': 'HERMANDAD DE SACERDOTES OPERARIOS DIOCESANOS'},
            {'clave': '0001', 'nombre': 'HERMANO DE LA SAGRADA FAMILIA'},
            {'clave': '0001', 'nombre': 'HERMANO DE NUESTRA SEÑORA DE LA CONSOLACIÓN EN EL MUNDO'},
            {'clave': '0001', 'nombre': 'HERMANO DE SAN JUAN DE DIOS'},
            {'clave': '0001', 'nombre': 'HERMANOS FOSSORES DE LA SAGRADA MISERICORDIA'},
            {'clave': '0001', 'nombre': 'HERMANO OBRERO DE MARÍA'},
            {'clave': '0001', 'nombre': 'HIJAS DE LA CARIDAD DE SAN VICENTE DE PAUL'},
            {'clave': '0001', 'nombre': 'HIJAS DE LA INMACULADA CONCEPCIÓN'},
            {'clave': '0001', 'nombre': 'HIJAS DE MARÍA AUXILIADORA'},
            {'clave': '0001', 'nombre': 'HIJAS DE SAN PABLO'},
            {'clave': '0001', 'nombre': 'HIJAS DEL DIVINO SALVADOR'},
            {'clave': '0001', 'nombre': 'HIJAS DEL ESPÍRITU SANTO'},
            {'clave': '0001', 'nombre': 'HIJOS DE LA SAGRADA FAMILIA'},
            {'clave': '0001', 'nombre': 'HIJOS DE MARÍA MADRE DE LA IGLESIA'},
            {'clave': '0001', 'nombre': 'HOSPITALARIO DE SAN JUAN DE DIOS'},
            {'clave': '0001', 'nombre': 'IGLESIA MADRE'},
            {'clave': '0001', 'nombre': 'INSTITUTO DEL VERBO ENCARNADO'},
            {'clave': '0001', 'nombre': 'JERÓNIMO'},
            {'clave': '0001', 'nombre': 'JESUITA'},
            {'clave': '0001', 'nombre': 'LAZARISTA'},
            {'clave': '0001', 'nombre': 'LEGIONARIOS DE CRISTO'},
            {'clave': '0001', 'nombre': 'LOORDISTA'},
            {'clave': '0001', 'nombre': 'MANTELLATA SIERVA DE MARÍA'},
            {'clave': '0001', 'nombre': 'MERCEDARIA DEL SANTÍSIMO SACRAMENTO'},
            {'clave': '0001', 'nombre': 'MARISTA'},
            {'clave': '0001', 'nombre': 'MISIONERA DE LA CRUZ'},
            {'clave': '0001', 'nombre': 'MISIONERA DEL VERBO DIVINO'},
            {'clave': '0001', 'nombre': 'MISIONERA HIJA DEL CORAZÓN DE MARÍA'},
            {'clave': '0001', 'nombre': 'MISIONERO DE GUADALUPE'},
            {'clave': '0001', 'nombre': 'MISIONERO DE LA INMACULADA CONCEPCIÓN'},
            {'clave': '0001', 'nombre': 'MISIONERO DEL ESPÍRITU SANTO'},
            {'clave': '0001', 'nombre': 'MISIONERO REDENTORISTA'},
            {'clave': '0001', 'nombre': 'MISIONERO XAVERIANO EN EL MUNDO'},
            {'clave': '0001', 'nombre': 'MONJE DE LA ADORACIÓN'},
            {'clave': '0001', 'nombre': 'NUESTRA SEÑORA DE LA CONSOLACIÓN'},
            {'clave': '0001', 'nombre': 'OBRA DE DON ORIONE'},
            {'clave': '0001', 'nombre': 'OPERARIO DEL REINO DE CRISTO'},
            {'clave': '0001', 'nombre': 'ORDEN DE SAN BENITO'},
            {'clave': '0001', 'nombre': 'PASIONISTA'},
            {'clave': '0001', 'nombre': 'PAULINO'},
            {'clave': '0001', 'nombre': 'PEQUEÑA OBRA DE LA DIVINA PROVIDENCIA'},
            {'clave': '0001', 'nombre': 'RELIGIOSA DE LA ASUNCIÓN'},
            {'clave': '0001', 'nombre': 'RELIGIOSA DE NUESTRA SEÑORA DE SIÓN'},
            {'clave': '0001', 'nombre': 'RELIGIOSA DE SAN JOSÉ DE GERONA'},
            {'clave': '0001', 'nombre': 'RELIGIOSA DEL SAGRADO CORAZÓN'},
            {'clave': '0001', 'nombre': 'SAGRADO CORAZÓN'},
            {'clave': '0001', 'nombre': 'SALESIANO'},
            {'clave': '0001', 'nombre': 'SAN JOSÉ DE TARBES'},
            {'clave': '0001', 'nombre': 'SERVIDOR DEL SEÑOR Y DE LA VIRGEN'},
            {'clave': '0001', 'nombre': 'SIERVA DE JESÚS'},
            {'clave': '0001', 'nombre': 'SIERVA DE JESÚS DE LA CARIDAD'},
            {'clave': '0001', 'nombre': 'SIERVA DE LA PASIÓN'},
            {'clave': '0001', 'nombre': 'SIERVO DE MARÍA'},
            {'clave': '0001', 'nombre': 'SOCIEDAD DE SAN PABLO'},
            {'clave': '0001', 'nombre': 'TERCIARIA CAPUCHINA DE LA SAGRADA FAMILIA'},
            {'clave': '0001', 'nombre': 'URSULINA DE LA UNIÓN ROMANA'},
            {'clave': '0001', 'nombre': 'VICENTINO'},
            {'clave': '1101', 'nombre': '* ANABAUTISTA'},
            {'clave': '1101', 'nombre': 'ANABAPTISTA'},
            {'clave': '1101', 'nombre': 'REBAUTIZADORES'},
            {'clave': '1101', 'nombre': 'REBAUTIZADO'},
            {'clave': '1102', 'nombre': '* ANGLICANA'},
            {'clave': '1103', 'nombre': '* BAUTISTA'},
            {'clave': '1103', 'nombre': 'BAPTISTA'},
            {'clave': '1103', 'nombre': 'BAUTISTA CRISTIANO'},
            {'clave': '1103', 'nombre': 'BAUTISTAS FUNDAMENTALES'},
            {'clave': '1103', 'nombre': 'BAUTISTAS INDEPENDIENTES'},
            {'clave': '1103', 'nombre': 'BÍBLICOS BAUTISTAS'},
            {'clave': '1103', 'nombre': 'CONVENCIÓN REGIONAL O NACIONAL BAUTISTA'},
            {'clave': '1103', 'nombre': 'CRISTIANOS BAUTISTAS EVANGÉLICOS'},
            {'clave': '1103', 'nombre': 'CRISTIANOS BÍBLICOS BAUTISTAS'},
            {'clave': '1103', 'nombre': 'MISIÓN MEXICANA BAUTISTA'},
            {'clave': '1104', 'nombre': '* CALVINISTA'},
            {'clave': '1104', 'nombre': 'CALVINISMO'},
            {'clave': '1104', 'nombre': 'CALVINOS'},
            {'clave': '1105', 'nombre': '* CONGREGACIONAL'},
            {'clave': '1105', 'nombre': 'CONGREGACIONALISTA'},
            {'clave': '1105', 'nombre': 'PADRES PEREGRINOS'},
            {'clave': '1106', 'nombre': '* CUÁQUERA'},
            {'clave': '1106', 'nombre': 'AMIGOS'},
            {'clave': '1106', 'nombre': 'AMIGOS EN LA VERDAD'},
            {'clave': '1106', 'nombre': 'COMITÉ NORTEAMERICANO DEL SERVICIO DE LOS AMIGOS'},
            {'clave': '1106', 'nombre': 'HIJOS DE LA CRUZ'},
            {'clave': '1106', 'nombre': 'IGLESIA EVANGÉLICA LOS AMIGOS'},
            {'clave': '1106', 'nombre': 'LOS AMIGOS DEL NORESTE'},
            {'clave': '1106', 'nombre': 'SOCIEDAD DE AMIGOS'},
            {'clave': '1107', 'nombre': '* DEL NAZARENO'},
            {'clave': '1107', 'nombre': 'IGLESIA DEL NAZARENO'},
            {'clave': '1108', 'nombre': '* DISCÍPULOS DE CRISTO'},
            {'clave': '1109', 'nombre': '* EJÉRCITO DE SALVACIÓN'},
            {'clave': '1110', 'nombre': '* EPISCOPALIANA'},
            {'clave': '1110', 'nombre': 'EPISCOPAL'},
            {'clave': '1110', 'nombre': 'EPISCOPALIANO'},
            {'clave': '1110', 'nombre': 'IGLESIA EPISCOPAL'},
            {'clave': '1110', 'nombre': 'IGLESIA LIBERAL'},
            {'clave': '1110', 'nombre': 'IGLESIA PROTESTANTE EPISCOPAL'},
            {'clave': '1111', 'nombre': '* LUTERANA'},
            {'clave': '1111', 'nombre': 'ALIANZA APOSTÓLICA LUTERANA'},
            {'clave': '1111', 'nombre': 'FEDERACIÓN LUTERANA MUNDIAL'},
            {'clave': '1111', 'nombre': 'IGLESIA CRISTIANA LUTERANA'},
            {'clave': '1111', 'nombre': 'IGLESIA EVANGÉLICA LUTERANA CONFESIONAL'},
            {'clave': '1111', 'nombre': 'IGLESIA EVANGÉLICA LUTERANA TRIUNFO'},
            {'clave': '1111', 'nombre': 'LUTERANISMO'},
            {'clave': '1111', 'nombre': 'SÍNODO LUTERANO DE MÉXICO'},
            {'clave': '1112', 'nombre': '* MENONITA'},
            {'clave': '1112', 'nombre': 'AMISH'},
            {'clave': '1112', 'nombre': 'AMMANITAS'},
            {'clave': '1112', 'nombre': 'MENONES'},
            {'clave': '1113', 'nombre': '* METODISTA'},
            {'clave': '1113', 'nombre': 'IGLESIA BÍBLICA METODISTA'},
            {'clave': '1113', 'nombre': 'IGLESIA CRISTIANA METODISTA'},
            {'clave': '1113', 'nombre': 'IGLESIA CRISTIANA METODISTA EPISCOPAL'},
            {'clave': '1113', 'nombre': 'IGLESIA METODISTA'},
            {'clave': '1113', 'nombre': 'IGLESIA METODISTA AFRICANA'},
            {'clave': '1113', 'nombre': 'IGLESIA METODISTA CONGREGACIONAL'},
            {'clave': '1113', 'nombre': 'IGLESIA METODISTA DE LA SANTIDAD'},
            {'clave': '1113', 'nombre': 'IGLESIA METODISTA EVANGÉLICA'},
            {'clave': '1113', 'nombre': 'IGLESIA METODISTA INDEPENDIENTE EL REDENTOR'},
            {'clave': '1113', 'nombre': 'IGLESIA METODISTA LIBRE'},
            {'clave': '1113', 'nombre': 'IGLESIA METODISTA MEXICANA'},
            {'clave': '1113', 'nombre': 'IGLESIA METODISTA PROTESTANTE'},
            {'clave': '1113', 'nombre': 'IGLESIA METODISTA UNIDA'},
            {'clave': '1114', 'nombre': '* PRESBITERIANA'},
            {'clave': '1114', 'nombre': 'ALIANZA PRESBITERIANA'},
            {'clave': '1114', 'nombre': 'IGLESIA EVANGÉLICA PRESBITERIANA DE CRISTO'},
            {'clave': '1114', 'nombre': 'IGLESIA EVANGÉLICA PRESBITERIANA EMMANUEL'},
            {'clave': '1114', 'nombre': 'IGLESIA EVANGÉLICA PRESBITERIANA UNIDA EN CRISTO Y ADELANTE'},
            {'clave': '1114', 'nombre': 'IGLESIA NACIONAL PRESBITERIANA “PRÍNCIPE DE PAZ”'},
            {'clave': '1114', 'nombre': 'IGLESIA PRESBITERIANA COREANA EN MÉXICO'},
            {'clave': '1114', 'nombre': 'IGLESIA PRESBITERIANA JESUCRISTO ÁREA DE SALVACIÓN'},
            {'clave': '1114', 'nombre': 'PRESBITERIANA CONSERVADORA'},
            {'clave': '1114', 'nombre': 'PRESBITERIANA INDEPENDIENTE'},
            {'clave': '1114', 'nombre': 'PRESBITERIANA REFORMADA'},
            {'clave': '1114', 'nombre': 'PRESBITERIO'},
            {'clave': '1201', 'nombre': '* AMISTAD CRISTIANA:'},
            {'clave': '1201', 'nombre': 'MINISTERIAL'},
            {'clave': '1201', 'nombre': 'VIDA NUEVA'},
            {'clave': '1201', 'nombre': 'CENTRO FAMILIAR AMISTAD CRISTIANA'},
            {'clave': '1202', 'nombre': '* ASAMBLEAS DE DIOS'},
            {'clave': '1202', 'nombre': 'CONCILIO DE LAS ASAMBLEAS DE DIOS'},
            {'clave': '1203', 'nombre': '* CASA DE ORACIÓN:'},
            {'clave': '1203', 'nombre': 'PALABRA DE FE'},
            {'clave': '1203', 'nombre': 'Y ALABANZA'},
            {'clave': '1203', 'nombre': 'IGLESIA CASA DE ORACIÓN'},
            {'clave': '1204', 'nombre': '* CENTRO DE FE:'},
            {'clave': '1204', 'nombre': 'ESPERANZA JONAZ'},
            {'clave': '1204', 'nombre': 'ESPERANZA Y AMOR DE LA CRUZADA MISIÓN'},
            {'clave': '1204', 'nombre': 'ROCA DE SALVACIÓN'},
            {'clave': '1205', 'nombre': '* FUERZA ÁGAPE'},
            {'clave': '1205', 'nombre': 'RESTAURACIÓN ÁGAPE'},
            {'clave': '1205', 'nombre': 'CENTRO DE EVANGELIZACIÓN Y REHABILITACIÓN ÁGAPE'},
            {'clave': '1205', 'nombre': 'IGLESIA ÁGAPE MINISTERIO'},
            {'clave': '1205', 'nombre': 'IGLESIA CRISTIANA BÍBLICA ÁGAPE'},
            {'clave': '1205', 'nombre': 'IGLESIA FUERZA ÁGAPE CRISTIANA'},
            {'clave': '1205', 'nombre': 'MINISTERIO AMOR ÁGAPE, ABUNDANTE FE'},
            {'clave': '1206', 'nombre': '* IGLESIA ALFA Y OMEGA'},
            {'clave': '1206', 'nombre': 'JESUCRISTO ALFA Y OMEGA'},
            {'clave': '1206', 'nombre': 'OMEGA CENTRO CRISTIANO'},
            {'clave': '1206', 'nombre': 'UNIDAD EVANGÉLICA ALFA Y OMEGA'},
            {'clave': '1207', 'nombre': '* IGLESIA AGUA VIVA'},
            {'clave': '1207', 'nombre': 'FRATERNIDAD DE IGLESIAS AGUA VIVA'},
            {'clave': '1207', 'nombre': 'CENTRO CRISTIANO PENTECOSTÉS AGUA VIVA'},
            {'clave': '1207', 'nombre': 'FRATERNIDAD PENTECOSTÉS DE IGLESIAS CRISTIANAS MANANTIAL DE VIDA'},
            {'clave': '1207', 'nombre': 'FUENTE DEL AGUA DE LA VIDA'},
            {'clave': '1207', 'nombre': 'IGLESIA BÍBLICARÍOS DE AGUA VIVA'},
            {'clave': '1207', 'nombre': 'AGUA DE VIDA'},
            {'clave': '1207', 'nombre': 'IGLESIA CRISTIANA RÍOS DE AGUA VIVA'},
            {'clave': '1207', 'nombre': 'SIÓN Y CENTRO BÍBLICO RÍOS DE AGUA VIVA'},
            {'clave': '1207', 'nombre': 'IGLESIA DE CRISTO EVANGÉLICA PENTECOSTÉS FUENTE DE AGUA VIVA'},
            {'clave': '1207', 'nombre': 'DEFENSORES DE LA FE RÍOS DE AGUA VIVA'},
            {'clave': '1207', 'nombre': 'IGLESIA MINISTERIO AGUA VIVA'},
            {'clave': '1207', 'nombre': 'CRUZADA EVANGÉLICA AGUA VIVA'},
            {'clave': '1207', 'nombre': 'MANANTIAL DE VIDA'},
            {'clave': '1207', 'nombre': 'MINISTERIO AGUA VIVA'},
            {'clave': '1208', 'nombre': '* IGLESIA APOSTÓLICA:'},
            {'clave': '1208', 'nombre': 'CRISTO LA ROCA'},
            {'clave': '1208', 'nombre': 'DE LA FE EN CRISTO JESÚS'},
            {'clave': '1208', 'nombre': 'DEL DIOS VIVIENTE'},
            {'clave': '1208', 'nombre': 'DEL DIOS VIVIENTE ORIGINAL'},
            {'clave': '1208', 'nombre': 'ESPÍRITU DE JESUCRISTO'},
            {'clave': '1208', 'nombre': 'UNIDAD DEL NOMBRE DE JESUCRISTO'},
            {'clave': '1208', 'nombre': 'IGLESIA NUEVA APOSTÓLICA'},
            {'clave': '1208', 'nombre': 'RED APOSTÓLICA PENTECOSTÉS'},
            {'clave': '1209', 'nombre': '* IGLESIA DE DIOS:'},
            {'clave': '1209', 'nombre': 'CRISTIANA'},
            {'clave': '1209', 'nombre': 'CRISTIANA APOSTÓLICA PENTECOSTÉS'},
            {'clave': '1209', 'nombre': 'CRISTIANA PENTECOSTÉS'},
            {'clave': '1209', 'nombre': 'EN CRISTO JESÚS'},
            {'clave': '1209', 'nombre': 'EMPRESA MISIÓN POSADA'},
            {'clave': '1209', 'nombre': 'CRISTO VIENE'},
            {'clave': '1209', 'nombre': 'EVANGÉLICA RENOVACIÓN EN CRISTO'},
            {'clave': '1209', 'nombre': 'LA PUERTA ABIERTA'},
            {'clave': '1209', 'nombre': 'IGLESIA DE DIOS PENTECOSTÉS:'},
            {'clave': '1209', 'nombre': 'RESTAURACIÓN EN CRISTO'},
            {'clave': '1209', 'nombre': 'FILADELFIA'},
            {'clave': '1209', 'nombre': 'INDEPENDIENTE'},
            {'clave': '1209', 'nombre': 'MONTE DE SIÓN'},
            {'clave': '1209', 'nombre': 'MONTE SINAÍ'},
            {'clave': '1209', 'nombre': 'MOVIMIENTO INTERNACIONAL'},
            {'clave': '1209', 'nombre': 'PUERTA DEL CIELO'},
            {'clave': '1209', 'nombre': 'POR FE'},
            {'clave': '1209', 'nombre': 'ROCA DE PODER'},
            {'clave': '1209', 'nombre': 'MARANATHA'},
            {'clave': '1209', 'nombre': 'MINISTERIO'},
            {'clave': '1209', 'nombre': 'IGLESIA DE DIOS EVANGÉLICA'},
            {'clave': '1210', 'nombre': '* IGLESIA DE DIOS DE LA PROFECÍA'},
            {'clave': '1211', 'nombre': '* IGLESIA DEL EVANGELIO COMPLETO'},
            {'clave': '1211', 'nombre': 'IGLESIA DEL EVANGELIO:'},
            {'clave': '1211', 'nombre': 'CUADRANGULAR'},
            {'clave': '1211', 'nombre': 'CUÁDRUPLE CASA DE ORACIÓN'},
            {'clave': '1211', 'nombre': 'CUÁDRUPLE DE MARAVILLAS'},
            {'clave': '1211', 'nombre': 'DE LA NUEVA PAZ'},
            {'clave': '1211', 'nombre': 'CRISTO VIENE'},
            {'clave': '1211', 'nombre': 'COMISIÓN CRISTIANA DEL EVANGELIO COMPLETO'},
            {'clave': '1211', 'nombre': 'IGLESIA LIBRE PENTECOSTÉS DEL EVANGELIO COMPLETO'},
            {'clave': '1211', 'nombre': 'IGLESIA DE DIOS PENTECOSTÉS DEL EVANGELIO COMPLETO'},
            {'clave': '1212', 'nombre': '* IGLESIA EVANGÉLICA DE HERMANOS:'},
            {'clave': '1212', 'nombre': 'CONGREGADOS EN EL NOMBRE DEL SEÑOR JESUCRISTO'},
            {'clave': '1212', 'nombre': 'REUNIDOS EN EL NOMBRE DEL SEÑOR JESUCRISTO'},
            {'clave': '1212', 'nombre': 'REUNIDOS EN EL TEMPLO DEL SEÑOR JESUCRISTO'},
            {'clave': '1213', 'nombre': '* IGLESIA APOSENTO ALTO'},
            {'clave': '1213', 'nombre': 'CENTRO CRISTIANO APOSENTO ALTO'},
            {'clave': '1213', 'nombre': 'CONCILIO DE IGLESIAS CRISTIANAS APOSENTO ALTO'},
            {'clave': '1213', 'nombre': 'IGLESIA CRISTIANA MISIONERA APOSENTO ALTO'},
            {'clave': '1213', 'nombre': 'IGLESIA PENTECOSTÉS APOSENTO ALTO'},
            {'clave': '1214', 'nombre': '* INDÍGENA PENTECOSTAL'},
            {'clave': '1214', 'nombre': 'CONSEJO INDÍGENA CAMPESINO EVANGÉLICO'},
            {'clave': '1214', 'nombre': 'IGLESIA PENTECOSTÉS INDEPENDIENTE TZOTZIL'},
            {'clave': '1215', 'nombre': '* LA VOZ DE LA PIEDRA ANGULAR'},
            {'clave': '1215', 'nombre': 'PIEDRA ANGULAR'},
            {'clave': '1216', 'nombre': '* MISIONERA PENTECOSTAL'},
            {'clave': '1216', 'nombre': 'MISIONERO EVANGELÍSTICO ANTIOQUÍA'},
            {'clave': '1216', 'nombre': 'EMBAJADORES DE JESUCRISTO IGLESIA MISIONERA PENTECOSTÉS'},
            {'clave': '1216', 'nombre': 'IGLESIA EVANGÉLICA INDEPENDIENTE MISIONEROS'},
            {'clave': '1216', 'nombre': 'IGLESIA MISIONERO DE DIOS ROSA DE SAHARON'},
            {'clave': '1216', 'nombre': 'IGLESIA MISIONERA PENTECOSTAL'},
            {'clave': '1216', 'nombre': 'IGLESIA MISIONEROS DEL EVANGELIO PENTECOSTÉS'},
            {'clave': '1217', 'nombre': '* CRISTIANA'},
            {'clave': '1218', 'nombre': '* SOCIEDADES CRISTIANAS'},
            {'clave': '1218', 'nombre': 'ALIANZA CRISTIANA:'},
            {'clave': '1218', 'nombre': 'EMMANUEL'},
            {'clave': '1218', 'nombre': 'EVANGÉLICA'},
            {'clave': '1218', 'nombre': 'PENTECOSTÉS'},
            {'clave': '1218', 'nombre': 'PENTECOSTÉS INDEPENDIENTE'},
            {'clave': '1218', 'nombre': 'ASAMBLEAS DE IGLESIAS CRISTIANAS'},
            {'clave': '1218', 'nombre': 'CENTRO CRISTIANO:'},
            {'clave': '1218', 'nombre': 'ADONAI'},
            {'clave': '1218', 'nombre': 'AMANDO Y VENCIENDO'},
            {'clave': '1218', 'nombre': 'AMOR VIVIENTE'},
            {'clave': '1218', 'nombre': 'ARREBATAMIENTO'},
            {'clave': '1218', 'nombre': 'BETHEL PENTECOSTÉS'},
            {'clave': '1218', 'nombre': 'CAMBIO DE VIDA'},
            {'clave': '1218', 'nombre': 'CAMINO NUEVO'},
            {'clave': '1218', 'nombre': 'COMUNIDAD CRISTIANA DE COMPUERTAS'},
            {'clave': '1218', 'nombre': 'CRISTO VIENE'},
            {'clave': '1218', 'nombre': 'EL SHADDAI'},
            {'clave': '1218', 'nombre': 'EVANGÉLICA HUESTES DE LA FE'},
            {'clave': '1218', 'nombre': 'FUENTE DE VIDA'},
            {'clave': '1218', 'nombre': 'GENEZARET'},
            {'clave': '1218', 'nombre': 'SHALOM'},
            {'clave': '1218', 'nombre': 'IGLESIA PRIMITIVA'},
            {'clave': '1218', 'nombre': 'INTERNACIONAL'},
            {'clave': '1218', 'nombre': 'JUAN 14:6'},
            {'clave': '1218', 'nombre': 'LA GRAN COMISIÓN'},
            {'clave': '1218', 'nombre': 'LIRIO DE LOS VALLES'},
            {'clave': '1218', 'nombre': 'MANANTIAL DE VIDA'},
            {'clave': '1218', 'nombre': 'MARANATHA'},
            {'clave': '1218', 'nombre': 'NUEVA CREACIÓN'},
            {'clave': '1218', 'nombre': 'UN NUEVO AMANECER'},
            {'clave': '1218', 'nombre': 'VIDA ABUNDANTE'},
            {'clave': '1218', 'nombre': 'VIDA NUEVA'},
            {'clave': '1218', 'nombre': 'VINO NUEVO'},
            {'clave': '1218', 'nombre': 'ZAMAR'},
            {'clave': '1218', 'nombre': 'CENTRO CRISTO ES EL CAMINO'},
            {'clave': '1218', 'nombre': 'CENTRO CULTURAL CRISTIANO SIÓN'},
            {'clave': '1218', 'nombre': 'CENTRO DE ALABANZA NUEVA VIDA'},
            {'clave': '1218', 'nombre': 'CENTRO DE AMOR EMMANUEL'},
            {'clave': '1218', 'nombre': 'CENTRO DE CONVIVENCIA CRISTIANA:'},
            {'clave': '1218', 'nombre': 'BETHEL'},
            {'clave': '1218', 'nombre': 'EBEN-EZER'},
            {'clave': '1218', 'nombre': 'CENTRO DE INTEGRACIÓN FAMILIAR CRISTIANO GETHSEMANÍ'},
            {'clave': '1218', 'nombre': 'CENTRO FAMILIAR CRISTIANO:'},
            {'clave': '1218', 'nombre': 'TIERRA DE PAZ'},
            {'clave': '1218', 'nombre': 'YO Y MI CASA'},
            {'clave': '1218', 'nombre': 'COMPAÑERISMO CENTRO CRISTIANO LA PUERTA'},
            {'clave': '1218', 'nombre': 'COMPAÑERISMO CRISTIANO HORIZONTE'},
            {'clave': '1218', 'nombre': 'COMPAÑERISMO DE LAS IGLESIAS JESÚS SALVA'},
            {'clave': '1218', 'nombre': 'COMUNIDAD CRISTIANA:'},
            {'clave': '1218', 'nombre': 'CRISTO VIVE'},
            {'clave': '1218', 'nombre': 'FILADELFIA'},
            {'clave': '1218', 'nombre': 'COMUNIÓN CRISTIANA:'},
            {'clave': '1218', 'nombre': 'TORRE FUERTE'},
            {'clave': '1218', 'nombre': 'CONVENCIÓN DE IGLESIAS CRISTIANAS FILADELFIA'},
            {'clave': '1218', 'nombre': 'CONCILIO CRISTIANO:'},
            {'clave': '1218', 'nombre': 'PENTECOSTÉS'},
            {'clave': '1218', 'nombre': 'EVANGÉLICO EBEN-EZER'},
            {'clave': '1218', 'nombre': 'DAMASCO PENTECOSTÉS'},
            {'clave': '1218', 'nombre': 'CONFRATERNIDAD DE IGLESIAS CRISTIANAS:'},
            {'clave': '1218', 'nombre': 'EVANGÉLICOS DISCÍPULOS DE CRISTO'},
            {'clave': '1218', 'nombre': 'MANANTIALES DE VIDA'},
            {'clave': '1218', 'nombre': 'MISIONEROS'},
            {'clave': '1218', 'nombre': 'DEFENSORES DE LA FE CRISTIANA'},
            {'clave': '1218', 'nombre': 'EMBAJADORES DE LA IGLESIA CRISTIANA JESÚS DE NAZARETH'},
            {'clave': '1218', 'nombre': 'FRATERNIDAD CRISTIANA:'},
            {'clave': '1218', 'nombre': 'DE FE'},
            {'clave': '1218', 'nombre': 'PRÍNCIPE DE PAZ'},
            {'clave': '1218', 'nombre': 'VIDA ABUNDANTE'},
            {'clave': '1218', 'nombre': 'GRUPO DE UNIDAD CRISTIANA'},
            {'clave': '1218', 'nombre': 'IGLESIA COMPAÑERISMO CRISTIANO'},
            {'clave': '1218', 'nombre': 'IGLESIA COMUNIDAD CRISTIANA BET-EL'},
            {'clave': '1218', 'nombre': 'IGLESIA COMUNIDAD CRISTIANA TEMPLO CALVARIO'},
            {'clave': '1218', 'nombre': 'IGLESIA CRISTIANA:'},
            {'clave': '1218', 'nombre': 'CASA DEL ALFARERO'},
            {'clave': '1218', 'nombre': 'CENTRO DE CONVIVENCIA'},
            {'clave': '1218', 'nombre': 'CRISTO EN EL PODER'},
            {'clave': '1218', 'nombre': 'FRATERNIDAD'},
            {'clave': '1218', 'nombre': 'EL SHADDAI'},
            {'clave': '1218', 'nombre': 'EL SHADDAI, PENTECOSTÉS'},
            {'clave': '1218', 'nombre': 'GRUPO VIDA NUEVA'},
            {'clave': '1218', 'nombre': 'INDEPENDIENTE PENTECOSTAL'},
            {'clave': '1218', 'nombre': 'INTERDENOMINACIONAL'},
            {'clave': '1218', 'nombre': 'DE AVIVAMIENTO MARANATHA'},
            {'clave': '1218', 'nombre': 'JESÚS EL BUEN PASTOR'},
            {'clave': '1218', 'nombre': 'LIBRES PARA SERVIR'},
            {'clave': '1218', 'nombre': 'MONTE DE SIÓN'},
            {'clave': '1218', 'nombre': 'PENTECOSTAL EMMANUEL'},
            {'clave': '1218', 'nombre': 'PRÍNCIPE DE LOS PASTORES'},
            {'clave': '1218', 'nombre': 'UNIDA'},
            {'clave': '1218', 'nombre': 'UNIVERSAL APOSTÓLICA DE JESÚS PENTECOSTÉS'},
            {'clave': '1218', 'nombre': 'VERBO'},
            {'clave': '1218', 'nombre': 'VIDA'},
            {'clave': '1218', 'nombre': 'IGLESIA CRISTO VIENE'},
            {'clave': '1218', 'nombre': 'IGLESIA DE COMPAÑERISMO CRISTIANO'},
            {'clave': '1218', 'nombre': 'ASAMBLEA CRISTIANA'},
            {'clave': '1218', 'nombre': 'RESTAURACION FAMILIAR CRISTIANA'},
            {'clave': '1218', 'nombre': 'UNIDAD CRISTIANA DE AVIVAMIENTO MONTE DE GETHSEMANÍ'},
            {'clave': '1219', 'nombre': '* EVANGÉLICA'},
            {'clave': '1219', 'nombre': 'EVANGELISTA'},
            {'clave': '1219', 'nombre': 'HERMANO SEPARADO'},
            {'clave': '1220', 'nombre': '* SOCIEDADES EVANGÉLICAS'},
            {'clave': '1220', 'nombre': 'AFILIACIÓN DE IGLESIAS EVANGÉLICAS LIBRES'},
            {'clave': '1220', 'nombre': 'AGRUPACIÓN EVANGÉLICA DEL ESPÍRITU SANTO'},
            {'clave': '1220', 'nombre': 'ALIANZA DE IGLESIAS EVANGÉLICAS'},
            {'clave': '1220', 'nombre': 'ALIANZA EVANGÉLICA:'},
            {'clave': '1220', 'nombre': 'PENTECOSTÉS SEGUIDORES DE JESUCRISTO'},
            {'clave': '1220', 'nombre': 'MISIONERA'},
            {'clave': '1220', 'nombre': 'ALIANZA MINISTERIAL EVANGÉLICA'},
            {'clave': '1220', 'nombre': 'ALIANZA VIDA ABUNDANTE'},
            {'clave': '1220', 'nombre': 'CENTRO EVANGELÍSTICO:'},
            {'clave': '1220', 'nombre': 'BUENAS NUEVAS IGLESIA PENTECOSTÉS'},
            {'clave': '1220', 'nombre': 'DE REHABILITACIÓN, ESPERANZA JONAZ'},
            {'clave': '1220', 'nombre': 'CENTROS EVANGÉLICOS DE ADORACION'},
            {'clave': '1220', 'nombre': 'COMUNIDAD EVANGÉLICA DE ESTUDIO BÍBLICO Y ALABANZA'},
            {'clave': '1220', 'nombre': 'CONCILIO EVANGÉLICO DIOS ES AMOR'},
            {'clave': '1220', 'nombre': 'CONFRATERNIDAD DE IGLESIAS EVANGÉLICAS'},
            {'clave': '1220', 'nombre': 'CONFRATERNIDAD EVANGÉLICA:'},
            {'clave': '1220', 'nombre': 'MINISTERIAL LA NUEVA ESPERANZA'},
            {'clave': '1220', 'nombre': 'EVANGELISMO GLOBAL'},
            {'clave': '1220', 'nombre': 'FRATERNIDAD DE IGLESIAS Y PASTORES EVANGÉLICOS'},
            {'clave': '1220', 'nombre': 'FRATERNIDAD DE IGLESIAS EVANGÉLICAS'},
            {'clave': '1220', 'nombre': 'FRATERNIDAD EVANGÉLICA:'},
            {'clave': '1220', 'nombre': 'DE JESUCRISTO'},
            {'clave': '1220', 'nombre': 'PENTECOSTÉS'},
            {'clave': '1220', 'nombre': 'MISIONERA'},
            {'clave': '1220', 'nombre': 'PENTECOSTÉS PRÍNCIPE DE PAZ'},
            {'clave': '1220', 'nombre': 'UNIDAD CRISTIANA'},
            {'clave': '1220', 'nombre': 'FRATERNIDAD MINISTERIAL DE IGLESIAS EVANGÉLICAS'},
            {'clave': '1220', 'nombre': 'FUNDACION DE IGLESIAS EVANGÉLICAS ALCANZANDO AL MÉXICO MARGINADO'},
            {'clave': '1220', 'nombre': 'IGLESIA EVANGÉLICA:'},
            {'clave': '1220', 'nombre': 'ASAMBLEAS PENTECOSTALES'},
            {'clave': '1220', 'nombre': 'AUTÓNOMA'},
            {'clave': '1220', 'nombre': 'PENTECOSTÉS, BETHEL'},
            {'clave': '1220', 'nombre': 'BETHESDA Y SUS OBRAS'},
            {'clave': '1220', 'nombre': 'CRISTO ES EL SEÑOR'},
            {'clave': '1220', 'nombre': 'DE CRISTO PASTORES PENTECOSTÉS'},
            {'clave': '1220', 'nombre': 'DE DIOS EN CRISTO JESÚS'},
            {'clave': '1220', 'nombre': 'DE LA COMUNIDAD NUESTRO REFUGIO'},
            {'clave': '1220', 'nombre': 'DEL CAMINO'},
            {'clave': '1220', 'nombre': 'DEL DIOS VIVO INDEPENDIENTE'},
            {'clave': '1220', 'nombre': 'DEL PRÍNCIPE DE PAZ'},
            {'clave': '1220', 'nombre': 'DEL SEÑOR JESUCRISTO'},
            {'clave': '1220', 'nombre': 'DIOS CON NOSOTROS'},
            {'clave': '1220', 'nombre': 'EL MESÍAS'},
            {'clave': '1220', 'nombre': 'EMMANUEL'},
            {'clave': '1220', 'nombre': 'FORMACIÓN DE FE'},
            {'clave': '1220', 'nombre': 'FRATERNIDAD CRISTIANA'},
            {'clave': '1220', 'nombre': 'FUENTE DE LA VIDA ETERNA'},
            {'clave': '1220', 'nombre': 'GENTIL DE CRISTO'},
            {'clave': '1220', 'nombre': 'GREY DE DIOS'},
            {'clave': '1220', 'nombre': 'HEBRÓN'},
            {'clave': '1220', 'nombre': 'LA GRAN COMISIÓN MARCOS 16:15'},
            {'clave': '1220', 'nombre': 'LA HERMOSA'},
            {'clave': '1220', 'nombre': 'MINISTERIO DE LA FE QUE VENCE AL MUNDO'},
            {'clave': '1220', 'nombre': 'LA NUEVA ESPERANZA'},
            {'clave': '1220', 'nombre': 'LA PUERTA'},
            {'clave': '1220', 'nombre': 'LOS PEREGRINOS'},
            {'clave': '1220', 'nombre': 'MARANATHA'},
            {'clave': '1220', 'nombre': 'UNIDA DE DIOS VIVO'},
            {'clave': '1220', 'nombre': 'MISIONERA INDEPENDIENTE JERUEL'},
            {'clave': '1220', 'nombre': 'MONTE HOREB DE BARRERA'},
            {'clave': '1220', 'nombre': 'MONTE SINAÍ'},
            {'clave': '1220', 'nombre': 'JESUCRISTO ES EL SEÑOR'},
            {'clave': '1220', 'nombre': 'NUEVA GENERACIÓN'},
            {'clave': '1220', 'nombre': 'PENTECOSTÉS DEL DIOS VIVO'},
            {'clave': '1220', 'nombre': 'ROCA DE FE'},
            {'clave': '1220', 'nombre': 'VOZ EN LA MONTAÑA'},
            {'clave': '1220', 'nombre': 'NUEVO PACTO DE GRACIA'},
            {'clave': '1220', 'nombre': 'PEREGRINA'},
            {'clave': '1220', 'nombre': 'PIEDRA VIVA'},
            {'clave': '1220', 'nombre': 'REUNIDOS EN CRISTO'},
            {'clave': '1220', 'nombre': 'SAN PABLO'},
            {'clave': '1220', 'nombre': 'TIERRA PROMETIDA'},
            {'clave': '1220', 'nombre': 'UNIDAD CRISTIANA'},
            {'clave': '1220', 'nombre': 'UNIDAD CRISTIANA ROCA DE SALVACIÓN'},
            {'clave': '1220', 'nombre': 'UNIÓN'},
            {'clave': '1220', 'nombre': 'UNIVERSAL DE JESUCRISTO TEMPLO EL TABERNÁCULO'},
            {'clave': '1220', 'nombre': 'IGLESIA EVANGÉLICA BETHEL:'},
            {'clave': '1220', 'nombre': 'CASA DE DIOS'},
            {'clave': '1220', 'nombre': 'IGLESIA EVANGÉLICA CONGREGACIONAL:'},
            {'clave': '1220', 'nombre': 'EBEN EZER'},
            {'clave': '1220', 'nombre': 'HERMANOS EN CRISTO'},
            {'clave': '1220', 'nombre': 'IGLESIA EVANGELÍSTICA FUNDAMENTALISTA'},
            {'clave': '1220', 'nombre': 'IGLESIA FRATERNIDAD EVANGÉLICA'},
            {'clave': '1220', 'nombre': 'IGLESIA LA MISIÓN EVANGÉLICA'},
            {'clave': '1220', 'nombre': 'IGLESIA EVANGÉLICA DE LA MISIÓN BÍBLICA'},
            {'clave': '1220', 'nombre': 'MINISTERIO EVANGELÍSTICO EMBAJADORES DE CRISTO'},
            {'clave': '1220', 'nombre': 'UNIDAD EVANGÉLICA EL REDENTOR'},
            {'clave': '1221', 'nombre': 'EVANGÉLICA NEOTESTAMENTARIA'},
            {'clave': '1221', 'nombre': 'IGLESIA CRISTIANA PENTECOSTÉS NEOTESTAMENTARIA'},
            {'clave': '1222', 'nombre': '* PENTECOSTAL'},
            {'clave': '1222', 'nombre': 'PENTECOSTÉS'},
            {'clave': '1223', 'nombre': '* SOCIEDADES PENTECOSTALES'},
            {'clave': '1223', 'nombre': 'ASAMBLEAS:'},
            {'clave': '1223', 'nombre': 'EBEN - EZER'},
            {'clave': '1223', 'nombre': 'PENTECOSTALES'},
            {'clave': '1223', 'nombre': 'DE IGLESIAS PENTECOSTALES'},
            {'clave': '1223', 'nombre': 'PENTECOSTALES EMMANUEL'},
            {'clave': '1223', 'nombre': 'CONCILIO DE LA IGLESIA DE DIOS PENTECOSTÉS'},
            {'clave': '1223', 'nombre': 'CONFRATERNIDAD PENTECOSTÉS:'},
            {'clave': '1223', 'nombre': 'EL BUEN PASTOR'},
            {'clave': '1223', 'nombre': 'FRENTE DE LINEAS Y MISIONES PENTECOSTÉS'},
            {'clave': '1223', 'nombre': 'IGLESIA CONCORDIA PENTECOSTÉS'},
            {'clave': '1223', 'nombre': 'IGLESIA CONGREGACIONAL PENTECOSTÉS:'},
            {'clave': '1223', 'nombre': 'AMOR Y VIDA'},
            {'clave': '1223', 'nombre': 'IGLESIA DE JESUCRISTO PENTECOSTÉS'},
            {'clave': '1223', 'nombre': 'IGLESIA DE PENTECOSTÉS DE SANIDAD DIVINA'},
            {'clave': '1223', 'nombre': 'IGLESIA PENTECOSTÉS:'},
            {'clave': '1223', 'nombre': 'BETHEL'},
            {'clave': '1223', 'nombre': 'DEL NOMBRE DE JESÚS'},
            {'clave': '1223', 'nombre': 'REHABOTH'},
            {'clave': '1223', 'nombre': 'UNIDA'},
            {'clave': '1223', 'nombre': 'ANTIOQUÍA'},
            {'clave': '1223', 'nombre': 'IGLESIA PENTECOSTÉS PESCADORES'},
            {'clave': '1223', 'nombre': 'UNIÓN PENTECOSTÉS'},
            {'clave': '1223', 'nombre': 'IGLESIA PRIMITIVA PENTECOSTÉS DE MÉXICO'},
            {'clave': '1223', 'nombre': 'IGLESIA SANTA PENTECOSTÉS DEL NUEVO RENACIMIENTO'},
            {'clave': '1223', 'nombre': 'IGLESIA TRINITARIA PENTECOSTÉS EL FORO DE SANTIDAD'},
            {'clave': '1223', 'nombre': 'TORRE FUERTE ESPIRITUAL PENTECOSTÉS'},
            {'clave': '1224', 'nombre': '* PENTECOSTAL INDEPENDIENTE'},
            {'clave': '1224', 'nombre': 'COMUNIÓN CRISTIANA PENTECOSTÉS INDEPENDIENTE'},
            {'clave': '1224', 'nombre': 'FRATERNIDAD DE IGLESIAS CRISTIANAS:'},
            {'clave': '1224', 'nombre': 'EVANGÉLICA PENTECOSTÉS INDEPENDIENTE'},
            {'clave': '1224', 'nombre': 'UNIDAS'},
            {'clave': '1224', 'nombre': 'IGLESIA DE CRISTO PENTECOSTÉS INDEPENDIENTE'},
            {'clave': '1224', 'nombre': 'IGLESIA EVANGÉLICA INDEPENDIENTE:'},
            {'clave': '1224', 'nombre': 'EL BUEN CAMINO'},
            {'clave': '1224', 'nombre': 'BELEM'},
            {'clave': '1224', 'nombre': 'DE LA COMUNIDAD'},
            {'clave': '1224', 'nombre': 'EL BUEN PASTOR JESÚS'},
            {'clave': '1224', 'nombre': 'GETHSEMANÍ'},
            {'clave': '1224', 'nombre': 'PENTECOSTÉS GÓLGOTA'},
            {'clave': '1224', 'nombre': 'IGLESIA PENTECOSTÉS:'},
            {'clave': '1224', 'nombre': 'INDEPENDIENTE'},
            {'clave': '1224', 'nombre': 'INDEPENDIENTE JESUCRISTO PRÍNCIPE DE PAZ Y MISIONES'},
            {'clave': '1224', 'nombre': 'INDEPENDIENTE BETHANIA'},
            {'clave': '1224', 'nombre': 'INDEPENDIENTE FUENTE DE PAZ Y VIDA ETERNA'},
            {'clave': '1224', 'nombre': 'INDEPENDIENTE GETHSEMANÍ'},
            {'clave': '1224', 'nombre': 'INDEPENDIENTE LA HERMOSA'},
            {'clave': '1224', 'nombre': 'IGLESIA INDEPENDIENTE PENTECOSTÉS EMMANUEL'},
            {'clave': '1224', 'nombre': 'IGLESIA CRISTIANOS INDEPENDIENTES BETHEL'},
            {'clave': '1224', 'nombre': 'CONCILIO DE IGLESIAS CRISTIANAS EVANGÉLICAS INDEPENDIENTES'},
            {'clave': '1224', 'nombre': 'EVANGÉLICA PENTECOSTÉS INDEPENDIENTE'},
            {'clave': '1224', 'nombre': 'IGLESIA LIBRE PENTECOSTÉS'},
            {'clave': '1225', 'nombre': '* SOCIEDADES CRISTIANAS EVANGÉLICAS'},
            {'clave': '1225', 'nombre': 'CENTRO DE ENSEÑANZA CRISTIANA EVANGÉLICA EL REY YA VIENE'},
            {'clave': '1225', 'nombre': 'IGLESIA CRISTIANA EVANGÉLICA:'},
            {'clave': '1225', 'nombre': 'PENTECOSTÉS INDEPENDIENTE'},
            {'clave': '1225', 'nombre': 'MENSAJERO DEL REINO'},
            {'clave': '1225', 'nombre': 'BELEM'},
            {'clave': '1225', 'nombre': 'DE ACCIÓN MISIONERA'},
            {'clave': '1225', 'nombre': 'DIOS CON NOSOTROS'},
            {'clave': '1225', 'nombre': 'EL DIOS VIVIENTE'},
            {'clave': '1225', 'nombre': 'FIEL Y VERDADERO'},
            {'clave': '1225', 'nombre': 'GETHSEMANÍ'},
            {'clave': '1225', 'nombre': 'JESÚS DE NAZARETH'},
            {'clave': '1225', 'nombre': 'LA HERMOSA'},
            {'clave': '1225', 'nombre': 'LUZ Y VIDA'},
            {'clave': '1225', 'nombre': 'PAGIEL'},
            {'clave': '1225', 'nombre': 'PRÍNCIPE DE PAZ'},
            {'clave': '1225', 'nombre': 'JESUCRISTO ROCA MÍA'},
            {'clave': '1225', 'nombre': 'QUE SE REÚNE EN NOMBRE DEL SEÑOR JESUCRISTO'},
            {'clave': '1225', 'nombre': 'APOCALIPSIS 21:2'},
            {'clave': '1225', 'nombre': 'DIOS ES AMOR'},
            {'clave': '1225', 'nombre': 'EL NUEVO PACTO DEL SEÑOR JESUCRISTO ESPIRITUAL'},
            {'clave': '1225', 'nombre': 'INDEPENDIENTE EL BUEN PASTOR'},
            {'clave': '1225', 'nombre': 'LA GLORIA'},
            {'clave': '1225', 'nombre': 'MONTE HOREB'},
            {'clave': '1225', 'nombre': 'PENTECOSTÉS MONTE DE SIÓN'},
            {'clave': '1225', 'nombre': 'IGLESIAS UNIDAS EVANGÉLICAS CRISTIANAS'},
            {'clave': '1225', 'nombre': 'MOVIMIENTO EVANGÉLICO CRISTIANO'},
            {'clave': '1226', 'nombre': '* SOCIEDADES CRISTIANAS PENTECOSTALES'},
            {'clave': '1226', 'nombre': 'CONVIVENCIA CRISTIANA PENTECOSTÉS'},
            {'clave': '1226', 'nombre': 'IGLESIA CRISTIANA PENTECOSTÉS:'},
            {'clave': '1226', 'nombre': 'CRISTO ARCA DE SALVACIÓN'},
            {'clave': '1226', 'nombre': 'DE JESUCRISTO'},
            {'clave': '1226', 'nombre': 'DE JUBILEO'},
            {'clave': '1226', 'nombre': 'DE LOS MINISTERIOS EVANGELÍSTICOS VERDAD Y VIDA'},
            {'clave': '1226', 'nombre': 'DEL DIOS TRINO Y TRINA UNIDAD'},
            {'clave': '1226', 'nombre': 'EL CAMINO, LA VERDAD Y LA VIDA'},
            {'clave': '1226', 'nombre': 'EL LIRIO DE LOS VALLES'},
            {'clave': '1226', 'nombre': 'GETZEMANÍ'},
            {'clave': '1226', 'nombre': 'MINISTERIOS UNIDOS'},
            {'clave': '1226', 'nombre': 'MONTE DE SIÓN'},
            {'clave': '1226', 'nombre': 'IGLESIA PALESTINA CRISTIANA PENTECOSTÉS'},
            {'clave': '1227', 'nombre': '* SOCIEDADES EVANGÉLICAS PENTECOSTALES'},
            {'clave': '1227', 'nombre': 'CONCILIO DE IGLESIAS EVANGÉLICAS PENTECOSTÉS:'},
            {'clave': '1227', 'nombre': 'GETHSEMANÍ'},
            {'clave': '1227', 'nombre': 'MONTE SINAÍ'},
            {'clave': '1227', 'nombre': 'HERMANDAD EVANGÉLICA PENTECOSTÉS INDEPENDIENTE'},
            {'clave': '1227', 'nombre': 'IGLESIA DEL CONCILIO EVANGÉLICO PENTECOSTAL'},
            {'clave': '1227', 'nombre': 'IGLESIA EVANGÉLICA PENTECOSTÉS:'},
            {'clave': '1227', 'nombre': 'BETLEHEM'},
            {'clave': '1227', 'nombre': 'MONTE DE SIÓN'},
            {'clave': '1227', 'nombre': 'ADONAI'},
            {'clave': '1227', 'nombre': 'ALFA PENIEL'},
            {'clave': '1227', 'nombre': 'BETHEL Y MISIONES'},
            {'clave': '1227', 'nombre': 'CASA DE ORACIÓN JESUCRISTO EL BUEN PASTOR'},
            {'clave': '1227', 'nombre': 'CRISTO ES LA PUERTA'},
            {'clave': '1227', 'nombre': 'CRISTO LA ÚNICA ESPERANZA'},
            {'clave': '1227', 'nombre': 'CRISTO ROCA DE MI SALVACIÓN'},
            {'clave': '1227', 'nombre': 'CRISTO SANA Y SALVA'},
            {'clave': '1227', 'nombre': 'DE JESUCRISTO'},
            {'clave': '1227', 'nombre': 'EBEN EZER'},
            {'clave': '1227', 'nombre': 'EDÉN'},
            {'clave': '1227', 'nombre': 'EL BUEN SAMARITANO'},
            {'clave': '1227', 'nombre': 'EL DIVINO COMPAÑERO'},
            {'clave': '1227', 'nombre': 'EL PUENTE DE DIOS'},
            {'clave': '1227', 'nombre': 'EL VIVIENTE QUE ME VE'},
            {'clave': '1227', 'nombre': 'FILADELFIA'},
            {'clave': '1227', 'nombre': 'FUNDAMENTALISTA BÍBLICA'},
            {'clave': '1227', 'nombre': 'GETSEMANÍ'},
            {'clave': '1227', 'nombre': 'GLORIA Y ALABANZAS'},
            {'clave': '1227', 'nombre': 'GUIANZA DEL ESPÍRITU SANTO'},
            {'clave': '1227', 'nombre': 'LIBRE POR JESUCRISTO'},
            {'clave': '1227', 'nombre': 'LINAJE ESCOGIDO REAL SACERDOCIO'},
            {'clave': '1227', 'nombre': 'LIRIO DE LOS VALLES'},
            {'clave': '1227', 'nombre': 'LOS SEMBRADOS DE LA FE'},
            {'clave': '1227', 'nombre': 'MONTE DE LOS OLIVOS'},
            {'clave': '1227', 'nombre': 'MONTE SINAÍ DE CRISTO'},
            {'clave': '1227', 'nombre': 'EMMANUEL'},
            {'clave': '1227', 'nombre': 'LA PEÑA DE HOREB'},
            {'clave': '1227', 'nombre': 'NAZARETH'},
            {'clave': '1227', 'nombre': 'PRÍNCIPE DE PAZ'},
            {'clave': '1227', 'nombre': 'SENDEROS DE MISIONES'},
            {'clave': '1227', 'nombre': 'UNIVERSAL DE JESUCRISTO'},
            {'clave': '1227', 'nombre': 'ILUMINADOS POR SU PALABRA'},
            {'clave': '1227', 'nombre': 'MOVIMIENTO EVANGÉLICO PENTECOSTÉS'},
            {'clave': '1228', 'nombre': '* SOCIEDADES CRISTIANAS EVANGÉLICAS PENTECOSTALES'},
            {'clave': '1228', 'nombre': 'EL BUEN SAMARITANO CENTRO CRISTIANO EVANGÉLICO PENTECOSTÉS'},
            {'clave': '1228', 'nombre': 'IGLESIA CRISTIANA EVANGÉLICA PENTECOSTÉS:'},
            {'clave': '1228', 'nombre': 'BETHEL'},
            {'clave': '1228', 'nombre': 'MONTE SINAÍ'},
            {'clave': '1228', 'nombre': 'FILADELFIA'},
            {'clave': '1228', 'nombre': 'MONTE PISGA'},
            {'clave': '1229', 'nombre': '* SOLDADOS DE LA CRUZ DE CRISTO'},
            {'clave': '1230', 'nombre': '* TABERNÁCULOS'},
            {'clave': '1230', 'nombre': 'TABERNÁCULO BÍBLICO EVANGÉLICO SHECKINAH'},
            {'clave': '1230', 'nombre': 'TABERNÁCULO CRISTIANO FILADELFIA'},
            {'clave': '1230', 'nombre': 'TABERNÁCULO EBEN-EZER'},
            {'clave': '1230', 'nombre': 'TABERNÁCULO PEMIEL'},
            {'clave': '1231', 'nombre': '* TRADICIONALISTAS'},
            {'clave': '1232', 'nombre': '* OTRAS SOCIEDADES EVANGÉLICAS PENTECOSTAL'},
            {'clave': '1232', 'nombre': 'ALCANCE:'},
            {'clave': '1232', 'nombre': 'AGUASCALIENTES'},
            {'clave': '1232', 'nombre': 'VICTORIA'},
            {'clave': '1232', 'nombre': 'CENTRO DE REHABILITACIÓN, ESPERANZA EL SHADDAI'},
            {'clave': '1232', 'nombre': 'NUEVA CREACIÓN'},
            {'clave': '1232', 'nombre': 'CRUZADA MISIONERA DE JESUCRISTO'},
            {'clave': '1232', 'nombre': 'ENCUENTRO CON DIOS EN EL GETZEMANÍ'},
            {'clave': '1232', 'nombre': 'IGLESIA ASAMBLEAS DE BETHEL'},
            {'clave': '1232', 'nombre': 'IGLESIA CENTRO DE LAS BUENAS NUEVAS DE JESUCRISTO'},
            {'clave': '1232', 'nombre': 'IGLESIA DEL DIOS VIVO CRISTO VIENE'},
            {'clave': '1232', 'nombre': 'IGLESIA DEL REYNO DE JESUCRISTO'},
            {'clave': '1232', 'nombre': 'IGLESIA EMMANUEL CRISTO REY'},
            {'clave': '1232', 'nombre': 'IGLESIA FILADELFIA:'},
            {'clave': '1232', 'nombre': 'DEL DIVINO SALVADOR'},
            {'clave': '1232', 'nombre': 'IGLESIA LA PUERTA ABIERTA'},
            {'clave': '1232', 'nombre': 'IGLESIA LIRIO DE LOS VALLES'},
            {'clave': '1232', 'nombre': 'IGLESIA MARANATHA CRISTO VIENE'},
            {'clave': '1232', 'nombre': 'IGLESIA DEL EVANGELIO DE CRISTO PENTECOSTÉS'},
            {'clave': '1232', 'nombre': 'IGLESIA MINISTERIOS CRISTIANOS EL CAMINO'},
            {'clave': '1232', 'nombre': 'IGLESIA PALABRA VIVIENTE DE SIÓN'},
            {'clave': '1232', 'nombre': 'IGLESIA SENDA DE VIDA'},
            {'clave': '1232', 'nombre': 'IGLESIA UNIVERSAL DE JESUCRISTO INDEPENDIENTE'},
            {'clave': '1232', 'nombre': 'IGLESIA, MINISTERIOS LA GRAN COMISIÓN'},
            {'clave': '1232', 'nombre': 'LA NUEVA ESPERANZA'},
            {'clave': '1232', 'nombre': 'PERDÓN Y AMISTAD CRISTIANA'},
            {'clave': '1232', 'nombre': 'VIDA ABUNDANTE'},
            {'clave': '1232', 'nombre': 'VIDA NUEVA PARA EL MUNDO'},
            {'clave': '1233', 'nombre': '* NEOPENTECOSTAL'},
            {'clave': '1299', 'nombre': '* PENTECOSTAL INSUFICIENTEMENTE ESPECIFICADA'},
            {'clave': '1301', 'nombre': '* IGLESIA DEL DIOS VIVO, COLUMNA Y APOYO DE LA VERDAD, LA LUZ DEL MUNDO'},
            {'clave': '1301', 'nombre': 'COLUMNA Y APOYO DE LA VERDAD'},
            {'clave': '1301', 'nombre': 'COLUMNA Y BALUARTE DE LA VERDAD'},
            {'clave': '1301', 'nombre': 'HERMOSA PROVINCIA'},
            {'clave': '1301', 'nombre': 'IGLESIA DE LA LUZ DEL MUNDO'},
            {'clave': '1301', 'nombre': 'IGLESIA DEL DIOS VIVO'},
            {'clave': '1301', 'nombre': 'LA LUZ DEL MUNDO'},
            {'clave': '1401', 'nombre': '* ALIANZA CRISTIANA Y MISIONERA'},
            {'clave': '1402', 'nombre': '* APOSTÓLICA NO PENTECOSTAL'},
            {'clave': '1402', 'nombre': 'ESPIRITUAL CRISTIANA'},
            {'clave': '1402', 'nombre': 'MISIONERA ALCANCE LEÓN'},
            {'clave': '1402', 'nombre': 'IGLESIA CRISTIANA APOSTÓLICA DE JESÚS'},
            {'clave': '1403', 'nombre': '* ASOCIACIONES EVANGÉLICAS NO PENTECOSTALES'},
            {'clave': '1403', 'nombre': 'BUENAS NUEVAS DE JESUCRISTO'},
            {'clave': '1403', 'nombre': 'FUNDAMENTO DE APÓSTOLES Y PROFETAS'},
            {'clave': '1403', 'nombre': 'CAMINO AL CIELO'},
            {'clave': '1403', 'nombre': 'CASA DEL REY'},
            {'clave': '1403', 'nombre': 'CEDROS DEL SINAÍ'},
            {'clave': '1403', 'nombre': 'COSECHA LATINA'},
            {'clave': '1403', 'nombre': 'CRUZADORES DE LA FE'},
            {'clave': '1403', 'nombre': 'DE DIOS INCORPORADO VIVIENDO EN FE'},
            {'clave': '1403', 'nombre': 'EL REY DE GLORIA'},
            {'clave': '1403', 'nombre': 'EL MINISTERIO DE FE EN MARCHA'},
            {'clave': '1403', 'nombre': 'EL REINO DE DIOS EN SU PLENITUD'},
            {'clave': '1403', 'nombre': 'FAMILIA DE LA FE'},
            {'clave': '1403', 'nombre': 'FAMILIAS UNIDAS EN CRISTO'},
            {'clave': '1403', 'nombre': 'FLAMA DE LA VERDAD'},
            {'clave': '1403', 'nombre': 'LA VIÑA DEL SEÑOR'},
            {'clave': '1403', 'nombre': 'LA VOZ DE LA TROMPETA FINAL'},
            {'clave': '1403', 'nombre': 'MEXICANOS PARA ALCANZAR AL MUNDO'},
            {'clave': '1403', 'nombre': 'NACIONAL DEL GALILEO'},
            {'clave': '1403', 'nombre': 'SANTIDAD NUEVA ESPERANZA'},
            {'clave': '1404', 'nombre': '* BÍBLICA:'},
            {'clave': '1404', 'nombre': 'ANTIOQUÍA'},
            {'clave': '1404', 'nombre': 'BETHEL'},
            {'clave': '1404', 'nombre': 'COMUNIÓN DE CREYENTES'},
            {'clave': '1404', 'nombre': 'CRISTIANA EMANUEL'},
            {'clave': '1404', 'nombre': 'MISIONERA'},
            {'clave': '1404', 'nombre': 'MISIONERA COMUNIÓN DE CREYENTES'},
            {'clave': '1404', 'nombre': 'NUEVO PANORAMA'},
            {'clave': '1404', 'nombre': 'VIDA NUEVA'},
            {'clave': '1404', 'nombre': 'FUNDAMENTAL'},
            {'clave': '1405', 'nombre': '* CONFRATERNIDADES'},
            {'clave': '1406', 'nombre': '* IGLESIA DE CRISTO:'},
            {'clave': '1406', 'nombre': 'CRISTIANA'},
            {'clave': '1406', 'nombre': 'ESTRELLA'},
            {'clave': '1406', 'nombre': 'FRATERNIDAD DE LAS IGLESIAS DE CRISTO'},
            {'clave': '1406', 'nombre': 'TEMPLO DE JUDA'},
            {'clave': '1407', 'nombre': '* IGLESIA DE GRACIA, PAZ Y MISERICORDIA'},
            {'clave': '1408', 'nombre': '* IGLESIA DE LA BIBLIA ABIERTA'},
            {'clave': '1409', 'nombre': '* IGLESIA DE SANTIDAD'},
            {'clave': '1410', 'nombre': '* IGLESIA EVANGÉLICA SALEM'},
            {'clave': '1411', 'nombre': '* IGLESIA MEXICANA “LA MUJER HERMOSA VESTIDA DE SOL”'},
            {'clave': '1412', 'nombre': '* MESIÁNICAS'},
            {'clave': '1412', 'nombre': 'CIELO NUEVO Y TIERRA NUEVA'},
            {'clave': '1412', 'nombre': 'DIOS ESCUDO NUESTRO'},
            {'clave': '1412', 'nombre': 'ARMAGEDÓN'},
            {'clave': '1412', 'nombre': 'ESPIRITUAL MUNDIAL THAKAR SINGH'},
            {'clave': '1412', 'nombre': 'IGLESIA MESIÁNICA SEKAI KYUSEI KYO'},
            {'clave': '1413', 'nombre': '* MINISTERIOS EVANGÉLICOS'},
            {'clave': '1413', 'nombre': 'ALAS DE FE'},
            {'clave': '1413', 'nombre': 'CRISTIANO, FUENTE DE SALVACIÓN'},
            {'clave': '1413', 'nombre': 'CRISTIANO, MISIÓN DE CRISTO'},
            {'clave': '1413', 'nombre': 'CRISTIANO, ROCA DE SALVACIÓN'},
            {'clave': '1413', 'nombre': 'HEBRÓN'},
            {'clave': '1413', 'nombre': 'KOINONIA'},
            {'clave': '1413', 'nombre': 'EL MESÍAS'},
            {'clave': '1413', 'nombre': 'MISIONERO CONFRATERNIDAD DE LA ASCENSIÓN'},
            {'clave': '1413', 'nombre': 'NUEVAS DE GRAN GOZO'},
            {'clave': '1413', 'nombre': 'PAN DE VIDA'},
            {'clave': '1413', 'nombre': 'UNIDOS CON CRISTO'},
            {'clave': '1413', 'nombre': 'VOZ QUE CLAMA EN EL DESIERTO'},
            {'clave': '1413', 'nombre': 'IGLESIAS DE LA PALABRA VIVIENTE'},
            {'clave': '1413', 'nombre': 'LA PALABRA VIVIENTE DE FE'},
            {'clave': '1414', 'nombre': '* MISIONEROS EVANGÉLICOS'},
            {'clave': '1414', 'nombre': 'DE SANTIDAD EN EXTENSIÓN'},
            {'clave': '1414', 'nombre': 'TESTIMONIO QUE JEHOVÁ ES DIOS ENTRE NOSOTROS'},
            {'clave': '1415', 'nombre': '* MOVIMIENTOS EVANGÉLICOS'},
            {'clave': '1415', 'nombre': 'CRISTIANO DEL ESPÍRITU SANTO'},
            {'clave': '1415', 'nombre': 'ECOS DEL CALVARIO'},
            {'clave': '1416', 'nombre': '* NUEVA JERUSALÉN'},
            {'clave': '1417', 'nombre': '* VISIÓN MUNDIAL'},
            {'clave': '1499', 'nombre': '* EVANGÉLICA INSUFICIENTEMENTE ESPECIFICADA'},
            {'clave': '1499', 'nombre': 'PROTESTANTE'},
            {'clave': '2001', 'nombre': '* ADVENTISTA DEL SÉPTIMO DÍA'},
            {'clave': '2001', 'nombre': 'ADVENTISTA'},
            {'clave': '2001', 'nombre': 'IGLESIA ADVENTISTA DE LA PROMESA,'},
            {'clave': '2001', 'nombre': 'IGLESIA ADVENTISTA DEL PACTO'},
            {'clave': '2001', 'nombre': 'IGLESIA ADVENTISTA REFORMADA'},
            {'clave': '2001', 'nombre': 'IGLESIA CRISTIANA ADVENTISTA'},
            {'clave': '2001', 'nombre': 'IGLESIA SABATISTA'},
            {'clave': '2001', 'nombre': 'SABÁTICO'},
            {'clave': '2001', 'nombre': 'SABATISTA'},
            {'clave': '2001', 'nombre': 'UNIÓN DE LA VIDA Y ADVIENTO'},
            {'clave': '2002', 'nombre': '* IGLESIA DE JESUCRISTO DE LOS SANTOS DE LOS ÚLTIMOS DÍAS'},
            {'clave': '2002', 'nombre': 'IGLESIA DE CRISTO DEL LOTE DEL TEMPLO'},
            {'clave': '2002', 'nombre': 'IGLESIA DE JESUCRISTO BICKERTONISTA'},
            {'clave': '2002', 'nombre': 'IGLESIA DE JESUCRISTO CUTLERISTA'},
            {'clave': '2002', 'nombre': 'MORMONA'},
            {'clave': '2002', 'nombre': 'MORMÓN'},
            {'clave': '2003', 'nombre': '* TESTIGOS DE JEHOVÁ'},
            {'clave': '2003', 'nombre': 'ESTUDIANTES DE LA BIBLIA'},
            {'clave': '2003', 'nombre': 'EVANGELIO DEL REINO'},
            {'clave': '2003', 'nombre': 'MILLERISTA'},
            {'clave': '2003', 'nombre': 'RUSELLISTA'},
            {'clave': '2003', 'nombre': 'SALÓN DEL REINO'},
            {'clave': '2003', 'nombre': 'SOCIEDAD DEL NUEVO MUNDO'},
            {'clave': '2003', 'nombre': 'TORRE DE VIGÍA'},
            {'clave': '3001', 'nombre': '* ORTODOXA'},
            {'clave': '3001', 'nombre': 'DIÓCESIS MARONITA DE MÉXICO'},
            {'clave': '3001', 'nombre': 'EPARQUIA NUESTRA SEÑORA DE LOS MÁRTIRES DEL LÍBANO'},
            {'clave': '3001', 'nombre': 'EPARQUIA, GRECO-MELQUITA CATÓLICA EN MÉXICO'},
            {'clave': '3001', 'nombre': 'IGLESIA CATÓLICA APOSTÓLICA ORTODOXA'},
            {'clave': '3001', 'nombre': 'IGLESIA CATÓLICA APOSTÓLICA ORTODOXA DEL PATRIARCA DE MOSCÚ'},
            {'clave': '3001', 'nombre': 'IGLESIA ORTODOXA CATÓLICA'},
            {'clave': '3001', 'nombre': 'IGLESIA CATÓLICA GRIEGA'},
            {'clave': '3002', 'nombre': '* IGLESIA DE CRISTO CIENTÍFICA'},
            {'clave': '3002', 'nombre': 'CIENCIA CRISTIANA'},
            {'clave': '3002', 'nombre': 'CIENTISTAS'},
            {'clave': '3002', 'nombre': 'IGLESIA CIENTÍFICA'},
            {'clave': '3099', 'nombre': 'OTRAS CRISTIANAS INSUFICIENTEMENTE ESPECIFICADAS'},
            {'clave': '4001', 'nombre': '* BRAHMANISMO'},
            {'clave': '4002', 'nombre': '* BUDISMO'},
            {'clave': '4002', 'nombre': 'JODO SHINSHU HONGWANJI-HA MISION'},
            {'clave': '4002', 'nombre': 'JODO SHINSHU HONGWANJI-HA EKOJI'},
            {'clave': '4002', 'nombre': 'KUMPENLAMA GAUCEN'},
            {'clave': '4002', 'nombre': 'INSTITUTO LAMA TZONG KHAPA'},
            {'clave': '4002', 'nombre': 'ASOCIACIÓN SANTACIRAMA'},
            {'clave': '4002', 'nombre': 'ASOCIACIÓN SOKA GAKKAI'},
            {'clave': '4002', 'nombre': 'CENTRO DE ESTUDIOS CEURESIG'},
            {'clave': '4002', 'nombre': 'CENTRO DE ESTUDIOS CH’AU DI SCAREMOCCIA'},
            {'clave': '4002', 'nombre': 'CENTRO MILAREPA'},
            {'clave': '4002', 'nombre': 'CENTRO PAGODA DE CASTEL FOCOGNANO'},
            {'clave': '4002', 'nombre': 'CENTRO RABTEN GHE PELIUG'},
            {'clave': '4002', 'nombre': 'CENTRO SARAS WATI'},
            {'clave': '4002', 'nombre': 'CENTRO ZEN FRES SURGIENTES'},
            {'clave': '4003', 'nombre': '* CENTRO ONKARANADA'},
            {'clave': '4003', 'nombre': 'CENTRO DE LUZ DIVINA'},
            {'clave': '4003', 'nombre': 'ONKARANANDA'},
            {'clave': '4004', 'nombre': '* COMUNIDAD DEL ARCO IRIS'},
            {'clave': '4004', 'nombre': 'ESCUELAS TÁNTRICAS'},
            {'clave': '4004', 'nombre': 'ESCUELA INICIÁTICA'},
            {'clave': '4005', 'nombre': '* CONFUSIONISMO'},
            {'clave': '4006', 'nombre': '* HARE KRISHNA'},
            {'clave': '4006', 'nombre': 'ASOCIACIÓN CONCIENCIA DE KRISHNA'},
            {'clave': '4006', 'nombre': 'ISKCON'},
            {'clave': '4007', 'nombre': '* HINDUISMO'},
            {'clave': '4007', 'nombre': 'SANATANA DHARUES'},
            {'clave': '4008', 'nombre': '* JAINISMO'},
            {'clave': '4008', 'nombre': 'DIGAMBARAS'},
            {'clave': '4008', 'nombre': 'JAINISTAS'},
            {'clave': '4008', 'nombre': 'STHANAKAVASIS'},
            {'clave': '4008', 'nombre': 'SUDUANISMO'},
            {'clave': '4008', 'nombre': 'SUDVANISTAS'},
            {'clave': '4008', 'nombre': 'VETAMBARES'},
            {'clave': '4009', 'nombre': '* SINTOISMO'},
            {'clave': '4009', 'nombre': 'TENRIKYO'},
            {'clave': '4010', 'nombre': '* TAOISMO'},
            {'clave': '4010', 'nombre': 'TAOISTAS DEL ESPÍRITU CLAUD'},
            {'clave': '5001', 'nombre': '* JUDAICA'},
            {'clave': '5001', 'nombre': 'ISRAELITA'},
            {'clave': '5001', 'nombre': 'JUDÍA'},
            {'clave': '5001', 'nombre': 'JUDÍOS ORTODOXOS'},
            {'clave': '5001', 'nombre': 'JUDÍOS CONSERVADORES'},
            {'clave': '5001', 'nombre': 'JUDÍOS REFORMADOS'},
            {'clave': '6001', 'nombre': '* MUSULMANA'},
            {'clave': '6001', 'nombre': 'AHMADIA DEL ISLAM'},
            {'clave': '6001', 'nombre': 'COMUNIDAD ISLÁMICA'},
            {'clave': '6001', 'nombre': 'ORDEN SUFÍ SALVETI YERRAJI'},
            {'clave': '6001', 'nombre': 'SIKH DHARMA'},
            {'clave': '7001', 'nombre': '* MOVIMIENTOS DE MEXICANIDAD'},
            {'clave': '7001', 'nombre': 'HUAXCAM DIOS XASTACNA'},
            {'clave': '7001', 'nombre': 'MOVIMIENTO CONFEDERADO RESTAURADOR DE LA CULTURA DE ANÁHUAC'},
            {'clave': '7001', 'nombre': 'MOVIMIENTO DE NEOMEXICANIDAD'},
            {'clave': '7001', 'nombre': 'IN KALTONAL, CASA DEL SOL'},
            {'clave': '8001', 'nombre': '* ÁGORA'},
            {'clave': '8002', 'nombre': '* ANANDA MARGA'},
            {'clave': '8002', 'nombre': 'DHARMA PRACHAR'},
            {'clave': '8002', 'nombre': 'NUEVA ASOCIACIÓN HUMANISTA'},
            {'clave': '8002', 'nombre': 'RENACIMIENTO UNIVERSAL'},
            {'clave': '8003', 'nombre': '* BAHAISMO'},
            {'clave': '8003', 'nombre': 'BAHAI'},
            {'clave': '8003', 'nombre': 'BAHAISTAS'},
            {'clave': '8003', 'nombre': 'TEORÍA DE LA UTILIZACIÓN PROGRESIVA'},
            {'clave': '8003', 'nombre': 'ASAMBLEA ESPIRITUAL NACIONAL DE LOS BAHAI'},
            {'clave': '8004', 'nombre': '* IGLESIA DE LA CIENCIOLOGÍA'},
            {'clave': '8004', 'nombre': 'DIANÉTICA'},
            {'clave': '8005', 'nombre': '* IGLESIA DE LA UNIFICACIÓN'},
            {'clave': '8005', 'nombre': 'SECTA DE MOON'},
            {'clave': '8006', 'nombre': '* INSTITUTO ARICA'},
            {'clave': '8006', 'nombre': 'INSTITUTO GNOSEOLOGÍA'},
            {'clave': '8007', 'nombre': '* MASONERÍA'},
            {'clave': '8007', 'nombre': 'MASÓN'},
            {'clave': '8008', 'nombre': '* MEDITACION TRASCENDENTAL'},
            {'clave': '8008', 'nombre': 'CORPORACIÓN MAHARISHI LAND VEDA'},
            {'clave': '8008', 'nombre': 'MOVIMIENTO ESPIRITUAL DE REGENERACIÓN'},
            {'clave': '8008', 'nombre': 'SOCIEDAD INTERNACIONAL DE MEDITACIÓN'},
            {'clave': '8009', 'nombre': '* MISIÓN RAMA'},
            {'clave': '8010', 'nombre': '* MOVIMIENTO RAELIANO'},
            {'clave': '8010', 'nombre': 'RAELIANO'},
            {'clave': '8011', 'nombre': '* MOVIMIENTOS NEW AGE'},
            {'clave': '8011', 'nombre': 'GRAN FRATERNIDAD UNIVERSAL'},
            {'clave': '8011', 'nombre': 'ACUARIANOS'},
            {'clave': '8011', 'nombre': 'BUENA VOLUNTAD MUNDIAL'},
            {'clave': '8011', 'nombre': 'CONSEJOS DE VISIÓN'},
            {'clave': '8011', 'nombre': 'CONSPIRACIÓN DE ACUARIO'},
            {'clave': '8011', 'nombre': 'CONTACTO ANGÉLICO'},
            {'clave': '8011', 'nombre': 'ERA DE ACUARIO'},
            {'clave': '8011', 'nombre': 'ESCUELA ESPIRITUAL DE BORUP, DINAMARCA'},
            {'clave': '8011', 'nombre': 'LOS ALTERNATIVOS'},
            {'clave': '8011', 'nombre': 'LOS ESPIRITUALES'},
            {'clave': '8011', 'nombre': 'NEW AGE'},
            {'clave': '8011', 'nombre': 'NUEVA ERA'},
            {'clave': '8011', 'nombre': 'ONEGES'},
            {'clave': '8011', 'nombre': 'RED INTERNACIONAL DE CONCIENCIA'},
            {'clave': '8012', 'nombre': '* NEOISRAELITA'},
            {'clave': '8012', 'nombre': 'COMUNIDAD CRISTIANA ISRAELITA'},
            {'clave': '8012', 'nombre': 'CONGREGACIÓN JUDÍO MESIÁNICA BETH-SHALOM'},
            {'clave': '8012', 'nombre': 'EL LEÓN DE LA TRIBU DE JUDÁ'},
            {'clave': '8012', 'nombre': 'IGLESIA DE DIOS DE ISRAEL DEL NUEVO PACTO'},
            {'clave': '8012', 'nombre': 'IGLESIA DE DIOS ISRAELITA'},
            {'clave': '8012', 'nombre': 'IGLESIA DE DIOS JUDEO CRISTIANA'},
            {'clave': '8012', 'nombre': 'IGLESIA ISRAELITA CASA DE DIOS'},
            {'clave': '8012', 'nombre': 'IGLESIA ISRAELITA PARA LA DIFUSIÓN DE LAS SAGRADAS ESCRITURAS'},
            {'clave': '8012', 'nombre': 'IGLESIA PROFÉTICA DE SARDES'},
            {'clave': '8012', 'nombre': 'IGLESIA TRINITARIA POZO DE JACOB'},
            {'clave': '8012', 'nombre': 'LINAJE ESCOGIDO DE DIOS'},
            {'clave': '8012', 'nombre': 'PUEBLO DEL SANTO DE ISRAEL MONTE HOREB'},
            {'clave': '8012', 'nombre': 'TIERRA PROMETIDA'},
            {'clave': '8013', 'nombre': '* NIÑOS DE DIOS'},
            {'clave': '8013', 'nombre': 'FAMILIA DEL AMOR'},
            {'clave': '8014', 'nombre': '* NUEVA ACRÓPOLIS'},
            {'clave': '8014', 'nombre': 'ACROPOLITANOS'},
            {'clave': '8015', 'nombre': '* OCULTISTAS'},
            {'clave': '8015', 'nombre': 'ADORADORES DE SATÁN'},
            {'clave': '8015', 'nombre': 'CANDOMBLE'},
            {'clave': '8015', 'nombre': 'DEMONÍACAS'},
            {'clave': '8015', 'nombre': 'HIJAS DE BELCEBÚ'},
            {'clave': '8015', 'nombre': 'IGLESIA DE LA VEY'},
            {'clave': '8015', 'nombre': 'IGLESIA LUCIFERIANA'},
            {'clave': '8015', 'nombre': 'LAS HIJAS DEL FUEGO'},
            {'clave': '8015', 'nombre': 'ORDEN DEL CENTRO ÁUREO'},
            {'clave': '8015', 'nombre': 'ORDO TEMPLI ORIENTIS'},
            {'clave': '8015', 'nombre': 'SATÁNICOS'},
            {'clave': '8015', 'nombre': 'SATANISTAS'},
            {'clave': '8015', 'nombre': 'TEMPLO DE SET'},
            {'clave': '8015', 'nombre': 'VUDÚ'},
            {'clave': '8016', 'nombre': '* PALMAR DE TROYA'},
            {'clave': '8017', 'nombre': '* ROSA CRUCES'},
            {'clave': '8018', 'nombre': '* TEOSOFÍA'},
            {'clave': '8019', 'nombre': '* YESIDAS'},
            {'clave': '9001', 'nombre': '* ESPIRITUALISTA'},
            {'clave': '9001', 'nombre': 'ESPIRITISTA'},
            {'clave': '9001', 'nombre': 'ESPIRITUALISTA TRINITARIO MARIANO:'},
            {'clave': '9001', 'nombre': 'CONGREGACIÓN DEL SEXTO SELLO'},
            {'clave': '9001', 'nombre': 'PAZ, UNIÓN Y LUZ'},
            {'clave': '9001', 'nombre': 'RESURRECCIÓN A LA VIDA DE LA GRACIA'},
            {'clave': '9001', 'nombre': 'LA SANTÍSIMA TRINIDAD'},
            {'clave': '9001', 'nombre': 'DEL SÉPTIMO SELLO, FUENTE DE LUZ Y REDENCIÓN DE ELÍAS'},
            {'clave': '9001', 'nombre': 'PAZ, UNIÓN Y BUENA VOLUNTAD'},
            {'clave': '9001', 'nombre': 'ELÍAS, REDENCIÓN Y PAZ'},
            {'clave': '9001', 'nombre': 'SANTUARIO SEÑOR DEL PERDÓN'},
            {'clave': '9001', 'nombre': 'SILOE'},
            {'clave': '9001', 'nombre': 'KAZZAHUAKA'},
            {'clave': '9001', 'nombre': 'JESÚS, MARÍA Y JOSÉ'},
            {'clave': '9001', 'nombre': 'LA LUZ DEL TERCER MILENIO'},
            {'clave': '9001', 'nombre': 'SIMIENTE DE SIÓN'},
            {'clave': '9001', 'nombre': 'AMOR, CARIDAD Y JUSTICIA'},
            {'clave': '9001', 'nombre': 'AMOR Y LUZ'},
            {'clave': '9001', 'nombre': 'AMOR, LUZ Y SABIDURÍA'},
            {'clave': '9001', 'nombre': 'CASA DE BELEM'},
            {'clave': '9001', 'nombre': 'DEL SÉPTIMO SELLO FUENTE LUZ DE LA MONTAÑA'},
            {'clave': '9001', 'nombre': 'SIMIENTE DE ABRAHAM, CASA DE ISAAC'},
            {'clave': '9001', 'nombre': 'LA ENREDADORA'},
            {'clave': '9001', 'nombre': 'CHOZA DE ELÍAS TEMPLO DEL ESPÍRITU SANTO'},
            {'clave': '9001', 'nombre': 'ESPIRITUALISTA DE LA TERCERA ERA'},
            {'clave': '9001', 'nombre': 'CRISTIANA ESPIRITUAL'},
            {'clave': '9001', 'nombre': 'ESPIRITUAL TERCER SELLO'},
            {'clave': '9001', 'nombre': 'FIDENCISTA CRISTIANA'},
            {'clave': '9001', 'nombre': 'SOCIEDAD JUDICTORA REINADO DE LEONARDO ALCALA LEOS'},
            {'clave': '9001', 'nombre': 'IGLESIA VERDADERA CRISTIANA ELÍAS'},
            {'clave': '9001', 'nombre': 'IGLESIA ELIASISTA'},
            {'clave': '9001', 'nombre': 'CRISTIANA EVANGÉLICA ELÍAS'},
            {'clave': '9001', 'nombre': 'ESPIRITUALISTA FE, ESPERANZA Y CARIDAD, JESÚS DE LA MONTAÑA'},
            {'clave': '9001', 'nombre': 'ESPIRITUALISTA PARA EL DIVINO MAESTRO Y LA PUREZA DE MARÍA'},
            {'clave': '9001', 'nombre': 'PATRIARCAL ELÍAS'},
            {'clave': '9001', 'nombre': 'LAS TRES POTENCIAS, PADRE, HIJO Y ESPÍRITU SANTO'},
            {'clave': '9001', 'nombre': 'LA LUZ Y ESPERANZA'},
            {'clave': '9001', 'nombre': 'ESPÍRITU SANTO, PUREZA, AMOR Y LUZ'},
            {'clave': '9100', 'nombre': 'NINGUNA RELIGIÓN'},
            {'clave': '9999', 'nombre': 'RELIGIÓN NO ESPECIFICADA'},*/
        ];
    }

    getEstadoCivil() {
        return [
            {id: 1, nombre: 'CASADO(A)'},
            {id: 2, nombre: 'DIVORCIADO(A)'},
            {id: 3, nombre: 'SOLTERO(A)'},
            {id: 4, nombre: 'UNION LIBRE(A)'},
            {id: 5, nombre: 'VIUDO(A)'}
        ];
    }

    getOcupaciones() {
        return this._http.get('/assets/api/ocupacion.json').map((res) => res.json());
    }

    getAlergias() {
        return this._http.get('/assets/api/alergias.json').map((res) => res.json());
    }

    getDiscapacidades() {
        return this._http.get('/assets/api/discapacidades.json').map((res) => res.json());
    }

    getInstitucionesMedicas() {
        return this._http.get('/assets/api/insmedica.json').map((res) => res.json());
    }

    getParentesco() {
        return this._http.get('/assets/api/parentesco.json').map((res) => res.json());
    }


    getCodigosPostales(): Observable<Array<CodigosPostalesView>> {
        return this._http.get('/assets/api/codigospostales.json').map((res) => res.json());
    }


    getEtnia() {
        return this._http.get('/assets/api/grupo-etnico.json').map((res) => res.json());
    }


    getLenguaIndigena() {
        return this._http.get('/assets/api/lengua-indigena.json').map((res) => res.json());
    }

    getCatEspecialidades() {
        return this._http.get('/assets/api/especialidades.json').map((res) => res.json());
    }
        getNacionalidades() {
        return this._http.get('/assets/api/nacionalidad.json').map((res) => res.json());
    }

    getCatCodigosPostalesUnicos() {
        return this._http.get('/assets/api/codigos-postales-unicos.json').map((res) => res.json());
    }
}
