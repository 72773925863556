import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlanEstudioComponent} from './plan-estudio.component';
import {SmartadminModule} from '../../../shared/smartadmin.module';
import {ModalModule} from 'ngx-bootstrap';
import {DoctorService} from '../../../+doctor/doctor.service';
import {PacienteService} from '../../../+paciente/paciente.service';
import {StudiesService} from "../../../shared/services/studies.service";
import {JqueryUiModule} from "../../../shared/ui/jquery-ui/jquery-ui.module";

@NgModule({
    imports: [
        SmartadminModule,
        JqueryUiModule,
        ModalModule.forRoot()
    ],
    declarations: [PlanEstudioComponent],
    entryComponents: [PlanEstudioComponent],
    providers: [
        DoctorService,
        PacienteService,
        StudiesService
    ]
})
export class PlanEstudioModule {
}
