import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IframeConsultaComponent} from './iframe-consulta.component';
import {SmartadminModule} from "../shared/smartadmin.module";
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {ShaService} from "../shared/jssha/jssha.service";
import {JqueryUiModule} from "../shared/ui/jquery-ui/jquery-ui.module";
import {CatalogosService} from "../shared/services/catalogos.service";
import {ComunService} from "../shared/services/comun.service";
import {AuthNioService} from '../+login-nio/auth-nio.service';
import {UsersService} from '../+admin/+users/users.service';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {DetalleConsultaComponent} from '../+consulta/detalle-consulta/detalle-consulta.component';
import {CollapseModule} from 'ngx-bootstrap';
import {CiesService} from '../shared/services/cies.service';
import { RecipesService } from '../shared/services/recipes.service';
import { FormioModule } from 'angular-formio';
import { FormatoService } from '../+formato/formato.service';
import { DoctorService } from 'app/+doctor/doctor.service';
import {routing} from "../+consulta/+detalle/consulta.detalle.routing";
import {ConsultaLayoutModule} from "../+consulta/layout/consulta.layout.module";
import {DetalleConsultaModule} from "../+consulta/detalle-consulta/detalle-consulta.module";
import {TerminaConsultaModule} from "../+consulta/termina-consulta/termina-consulta.module";

@NgModule({
  declarations: [
    IframeConsultaComponent
   ],
  exports: [IframeConsultaComponent],
  providers: [
      CatalogosService,
      ComunService,
      ShaService,
      AuthNioService,
      UsersService,
      CiesService, RecipesService, FormatoService, DoctorService

  ],
imports: [
  SmartadminModule,
  ReactiveFormsModule,
  JqueryUiModule,
  NgxQRCodeModule,
  NgbModule,
  NgSelectModule,
  NgbPaginationModule, NgbAlertModule,
  NgZorroAntdModule.forRoot(),
  CollapseModule.forRoot(),
  FormioModule,
  ConsultaLayoutModule,
  DetalleConsultaModule,
  TerminaConsultaModule
  
] 
})
export class IframeConsultaModule { }
