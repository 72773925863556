/**
 * Created by gcuellar on 24/04/17.
 */
import {NgModule} from '@angular/core';
import {HasPermissionDirective} from './haspermission/hasPermission.directive';

@NgModule({
    declarations: [
        HasPermissionDirective,
    ],
    exports: [
        HasPermissionDirective,
    ]
})
export class SharedModule {}
