import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {NotificationService} from "../../utils/notification.service";
import {UserDetailsService} from '../user.details.service';
import {AuthNioService} from 'app/+login-nio/auth-nio.service';
import {MedicoView} from "../../../+doctor/doctor.classes";

declare var $:any;

@Component({
  selector: 'sa-logout',
  template: `
<ul id="horizontal-list" class="pull-center text-align-center">
<li id="consulta_l" class="vertical-middle-before mr-1_5">
    <div class="dropdown nueva-consulta"> 
        <div class="dropdown-toggle color-blue">
            CONFIGURACION
            <i class="ml-0_5 fa fa-angle-down"></i>
        </div>
        <div class="dropdown-content" >
            <div class="dropdown-item" *ngIf="!espac">
              <div data-toggle="modal" data-target="#exampleModal2">LINK ALTA PACIENTE</div>
            </div>
            <div class="dropdown-item">
                <div data-toggle="modal" data-target="#exampleModal">CAMBIAR CONTRASEÑA</div>
            </div>
            <div (click)="logout()" class="dropdown-item">
               CERRAR SESION
            </div>
        </div>
    </div>
</li>
</ul>


  `,
  styles: []
})
export class LogoutComponent implements OnInit {
  espac:boolean=false;

  constructor(private router: Router,
              private notificationService: NotificationService,
              public authService: AuthNioService,
                private userDetailsService: UserDetailsService) {
                  if(this.authService.token.idTipoUsuario ==3){this.espac=true;}

                }

  showPopup(){
    this.notificationService.smartMessageBox({
      title : "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'><strong>" + $('#show-shortcut').text() + "</strong></span> ?",
      content : "You can improve your security further after logging out by closing this opened browser",
      buttons : '[No][Yes]'

    }, (ButtonPressed) => {
      if (ButtonPressed == "Yes") {
        this.logout()
      }
    });
  }

  // logout(){
  //     this.router.navigate(['/auth/login'])
  // }

  logout(): void {
    this.userDetailsService.usersDetails = new MedicoView();
    delete this.userDetailsService.fullName;
    this.authService.logout();
  }

  changePasword():void{
  
  }
  ngOnInit() {

  }



}
