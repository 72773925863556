import {Component} from '@angular/core';
import {AuthNioService} from 'app/+login-nio/auth-nio.service';
import {ShaService} from 'app/shared/jssha/jssha.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {UserDetailsService} from 'app/shared/user/user.details.service';
import {Login} from "./auth-nio.classes";
import {MedicoView} from "../+doctor/doctor.classes";
import {I18nService} from "../shared/i18n";
import {environment} from "../../environments/environment";
import { UserResetpostPassword } from 'app/+admin/+users/users.classes';
import { NotificationService } from 'app/shared/utils/notification.service';
import { PacienteService } from 'app/+paciente/paciente.service';

@Component({
    selector: 'login-nio',
    templateUrl: './login-nio.component.html',
    styleUrls: ['./login-nio.component.css']
})
export class loginNioComponent {
    public _username: string;
    public _password: string;
    public _mail: string;
    usrest:UserResetpostPassword = new UserResetpostPassword();

    protected MSG_ERROR_500 = this.i18nService.getTranslation('Fail to log in, try again.');
    protected MSG_ERROR_FAIL = this.i18nService.getTranslation('Incorrect password or username');
    protected MSG_ERROR_UNKNOWN = this.i18nService.getTranslation('Fail to log in, try again. Unknown error');
    isLoading = false;
    public _idgroups : number =0;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private authService: AuthNioService,
                private shaService: ShaService,
                private userDetailsService: UserDetailsService,
                private i18nService: I18nService,
                private pacienteService : PacienteService,
                private notificationService: NotificationService) {
        this.userDetailsService.usersDetails = new MedicoView();
        delete this.userDetailsService.fullName;

        this.i18nService.subscribe(() => {
            this.MSG_ERROR_500 = this.i18nService.getTranslation('ERROR AL INICIAR SESION, INTENTELO MAS TARDE');
            this.MSG_ERROR_FAIL = this.i18nService.getTranslation('USUARIO Y/O CONTRASEÑA INCORRECTOS');
            this.MSG_ERROR_UNKNOWN = this.i18nService.getTranslation('ERROR AL INICIAR SESION, INTENTELO MAS TARDE. ERROR DESCONOCIDO');
        }, null);
    }

    espuebla_:boolean;
    esbaja_:boolean;
    ngOnInit(): void {

//?redirect=paciente/detalle/e7cadea1-74a2-4df4-b1d4-241bb2a14623/cursos-clinicos

        if (environment.navigate=="ES_PB") {
            this.espuebla_=true;
        } else {
            this.espuebla_=false;
            if (environment.navlogos=="ES_BC") {
                this.esbaja_=true;
            }
        }
        
    }

  
    resetPass(): void {
        console.log(this._mail);
        this.userDetailsService.udgetRecoverypass(this._mail).take(1)
        .subscribe(
            (US) => {
               console.log(US);
               
                this._mail="";
                document.getElementById("cerrarmodal").click();

                this.usrest.username = US.username;
                console.log(this.usrest);
        
                let result = '';
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                const charactersLength = characters.length;
                 for (let i = 0; i < 8; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                    }
                this.usrest.password=result;
                console.log(this.usrest);
        
                var encryptpas = this.shaService.encrypt( `${result}{${US.username}}`,1000,'SHA-256','HEX');
                this.usrest.hash = encryptpas;
                console.log(this.usrest);
               this.userDetailsService.udpostResetPassword(this.usrest).subscribe(
                  () => {
                      this.notificationService.smallBox(
                          {
                              title: 'SE ENVIO UN CORREO CON SU NUEVA CONTRASEÑA',
                              content: '<br>',
                              color: "#739E73",
                              icon: "fa fa-check",
                              timeout: 3000
                          }
                          );
                  });

            },
            () => {
                console.log("entro1");
                            this.notificationService.smallBox(
                          {
                              title: 'OCURRIO UN ERROR INTENTELO MAS TARDE',
                              content: '<br>',
                              color: "#d9534f",
                              icon: "fa fa-times",
                              timeout: 3000
                          }
                          );
                document.getElementById("cerrarmodal").click();
            }
            
        );


    }

    doLogin(): void {
        this.isLoading = true;

        /**
         * CRETE LOGIN OBJECT FOR STORE DATA
         */
        let LOGIN: Login = new Login();

        /**
         * INIT THAT STORAGE, AND HASH PSW
         */
        LOGIN.username = this._username;
        LOGIN.password = this.shaService.encrypt(
            `${this._password}{${LOGIN.username}}`,
            1000,
            'SHA-256',
            'HEX');
        // sre print
        //alert('Para: <' + `${this._password}{${LOGIN.username}}` + '> el pwd es: ' + LOGIN.password);

        // GET EMPTY TOKEN
        this.authService.loadToken(null);

        // SUBSCRIBE AND MAKE THE REQUEST
        this.authService.login(LOGIN)
            .subscribe((toks) => {
                console.log(toks);
                console.log(toks.idTipoUsuario);

                if(environment.Instance_file=='ECE'){
                    this._idgroups=24;
                }else{
                    this._idgroups=14;
                }

                if(toks.idTipoUsuario !=3){
                    if(toks.id_grupo_principal==this._idgroups){
                        console.log("es14");
                        
                        var redirect="";
                        this.activatedRoute.queryParams.subscribe(params => {
                            redirect = params['redirect'];
                            console.log(redirect);
                            this.router.navigate([redirect]);
                          });
                    }else{
                        this.router.navigate(['home']);
                    }
                }else{
                    this.pacienteService.getByIdUser(this.authService.token.userId).subscribe((pac) => {
                        var rout = 'paciente/detalle/'+pac.idPaciente;
                        console.log(rout)
                        this.router.navigate([rout]);
                    });
                    console.log("es paciente");
                    console.log("fin paciente");
                }
            }, (error) => {
                this.isLoading = false;
                if (error.status === 500) {
                        this.notificationService.smallBox(
                          {
                              title: this.MSG_ERROR_500,
                              content: '<br>',
                              color: "#d9534f",
                              icon: "fa fa-times",
                              timeout: 3000
                          }
                        );
                } else if (error.status === 400) {
                    this.notificationService.smallBox(
                        {
                            title: this.MSG_ERROR_FAIL,
                            content: '<br>',
                            color: "#d9534f",
                            icon: "fa fa-times",
                            timeout: 3000
                        }
                      );
                } else {
                    this.notificationService.smallBox(
                        {
                            title: this.MSG_ERROR_UNKNOWN,
                            content: '<br>',
                            color: "#d9534f",
                            icon: "fa fa-times",
                            timeout: 3000
                        }
                      );
                }
            });
    }

 


}
