import {DomicilioView} from "../shared/classes/common.classes";
import {User} from "../+admin/+users/users.classes";
import { Time } from "@angular/common";

export class MedicoView {
    idMedico: string;
    apellidoMaterno: string = "";
    apellidoPaterno: string = "";
    nombre: string = "";
    userName: string;
    lugarNacimiento: string;
    curp: string;
    rfc: string;
    telefonoFijo: string;
    telefonoMovil: string;
    email: string;
    idUsuario: number;
    activo: boolean;
    fechaNacimiento: Date;
    especialidadViewList: EspecialidadView[];
    domicilioViewList: DomicilioView[];
    medicofirma : medicoFirmaView;
    estadoCivil: string;
    fechaCreacion: Date;
    sexo: string;
    fullName: string;
    userAppView?: User;
    id_cat_nacionalidades:number;
    id_cat_entidades:number;
    id_institucion:number;
    per_id:number;
    act_id:number;
    atr_id:number;
    Id_cat_clues:number;
    id_cat_clues:number;
    jor_id:number;
    id_cat_especialidades:number;
    con_id:number;
    pla_id:number;
}

export class medicoFirmaView{
    idFirmaMedico : number = null;
    nombre: string = "";
    contenido: string = "";
    fechaCreacion: string = "";
}


export class medicoFirmaPlus extends medicoFirmaView {
    imgFirma: FormData;
    imgFirma64: string;
    imgFirmaFile: File;

    constructor(mfirm?: medicoFirmaView) {
        super();
        this.imgFirma = new FormData();
        this.imgFirma64 = '';
         if (mfirm) {
            for (const i in mfirm) {
                super[i] = mfirm[i];
            }
        } else {
            this.idFirmaMedico = null;
            this.nombre= null;
            this.contenido= null;
            this.fechaCreacion= null;
        }
    }
}


export class Agendamedicos{
 fechaingresoinst :Date;
 idmedico : string = "";
 meddom : boolean = false;
 medlun : boolean = false;
 medmar : boolean = false;
 medmie : boolean = false;
 medjue : boolean = false;
 medvie : boolean = false;
 medsab : boolean = false;
 lunEntMat: string;
 lunSalMat: string;
 lunEntVesp: string;
 lunSalVesp: string;
 lunEntNoct: string;
 lunSalNoct: string;
 marEntMat: string;
 marSalMat: string;
 marEntVesp: string;
 marSalVesp: string;
 marEntNoct: string;
 marSalNoct: string;
 mieEntMat: string;
 mieSalMat: string;
 mieEntVesp: string;
 mieSalVesp: string;
 mieEntNoct: string;
 mieSalNoct: string;
 jueEntMat: string;
 jueSalMat: string;
 jueEntVesp: string;
 jueSalVesp: string;
 jueEntNoct: string;
 jueSalNoct: string;
 vieEntMat: string;
 vieSalMat: string;
 vieEntVesp: string;
 vieSalVesp: string;
 vieEntNoct: string;
 vieSalNoct: string;
 sabEntMat: string;
 sabSalMat: string;
 sabEntVesp: string;
 sabSalVesp: string;
 sabEntNoct: string;
 sabSalNoct: string;
 domEntMat: string;
 domSalMat: string;
 domEntVesp: string;
 domSalVesp: string;
 domEntNoct: string;
 domSalNoct: string;
}

export class EspecialidadView {
    cedula: string;
    especialidad: string;
    fechaCreacion: Date;
    fechaValidacion: Date;
    idEspecialidad: string;
    imagenCedula: string;
    imagenDiploma: string;
    universidad: string;
    validado: boolean;
    imgCedula64: string;
    imgTitulo64: string;
    nombreImagenCedula: string;
    nombreImagenDiploma: string;
}

export class EspecialidadViewPlus extends EspecialidadView {
    imgCedula: FormData;
    imgCedula64: string;
    imgCedulaFile: File;
    imgTituloFile: File;
    imgTitulo: FormData;
    imgTitulo64: string;

    constructor(esp?: EspecialidadView) {
        super();
        this.imgCedula = new FormData();
        this.imgCedula64 = '';
        this.imgTitulo = new FormData();
        this.imgTitulo64 = '';
        if (esp) {
            for (const i in esp) {
                super[i] = esp[i];
            }
        } else {
            this.idEspecialidad = null;
            this.cedula = null;
            this.especialidad = null;
            this.imagenCedula = null;
            this.imagenDiploma = null;
            this.universidad = null;
        }
    }

    getEspecialidad(): EspecialidadView {
        return <EspecialidadView> this;
    }
}
