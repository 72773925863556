import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CatVacunaView, VacunaDosisView, VacunaView} from '../../../../paciente.classes';
import {PacienteService} from '../../../../paciente.service';
import {isNullOrUndefined} from 'util';
import {FormBuilder, FormGroup, NgForm, ValidationErrors, Validators} from "@angular/forms";
import {ListaErrorsForms} from "../../../../../shared/classes/catalog.classes";

@Component({
    selector: 'app-esquema-vacunacion',
    templateUrl: './esquema-vacunacion.component.html',
    styleUrls: ['../paciente.historia-clinica.component.css']
})
export class EsquemaVacunacionComponent implements OnInit, OnChanges {
    //@ViewChild('frmVacuna') private formVacuna: NgForm; // validacion

    @Input() wholeObject: any = {};
    @Output() wholeObjectChange = new EventEmitter<VacunaView[]>();
    formVacuna: FormGroup;
    trySubmitForm = false; //para la validacion
    esquemaVacunacion: VacunaView[] = [];
    notas: boolean = false;


    catVacunas: CatVacunaView[];
    selectedDosis: VacunaDosisView = null;
    selectedVacuna: CatVacunaView = null;

    fechaAplicacion: Date = null;


    constructor(private pacienteService: PacienteService, private formBuilder: FormBuilder) {
        this.formVacuna =
            this.formBuilder.group({
                selectedVacuna: ['', [Validators.required]],
                selectedDosis: ['', [Validators.required]],
                fechaAplicacion: ['', [Validators.required]],
            });
    }

    ngOnInit() {
        this.pacienteService.getCatVacuna().subscribe(d => this.catVacunas = d);
    }


    cargarErrores() {
        Object.keys(this.formVacuna.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.formVacuna.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                    const nuevo = new ListaErrorsForms(key.toString(), keyError.toString());
                });
            }
        });
    }


    agregarVacuna() {
        this.cargarErrores();
        this.trySubmitForm = true;
        if (this.formVacuna.valid) {
            const vacuna: VacunaView = {
                nombre: this.selectedVacuna.nombre,
                dosis: this.selectedDosis,
                enfermedad: this.selectedVacuna.enfermedad,
                fecha: this.fechaAplicacion,
                notas:''
            };

            this.esquemaVacunacion = [...this.esquemaVacunacion, vacuna];
            this.selectedVacuna = new CatVacunaView();
            this.selectedDosis = new VacunaDosisView();
            this.sendData();
            this.limpiarValores();
        }
    }

    limpiarValores() {
        this.fechaAplicacion = null;
        this.selectedDosis = null;
        this.selectedVacuna = null;
    }

    sendData() {
        this.wholeObject.esquemaVacunacion = this.esquemaVacunacion;
        this.wholeObjectChange.emit(this.wholeObject);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('wholeObject')) {
            const value = changes['wholeObject'].currentValue;
            if (value && value.hasOwnProperty('esquemaVacunacion')) {
                this.esquemaVacunacion = value['esquemaVacunacion'];
            } else {
                this.wholeObject.esquemaVacunacion = this.esquemaVacunacion;
            }
        }

    }

}
