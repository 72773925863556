import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class ShareDataService {
  private subject = new ReplaySubject<any>(0);
  private subjectBoton = new ReplaySubject<any>(0);
  constructor() {
   }
  sendParameter(value?: any, type?: any) {
    if (type) {
      this.subject.next({ value: value, type: type });
    } else {
      this.subject.next({ value: value });
    }
  }
  sendParameterBoton(value: any) {
    this.subjectBoton.next({ value: value });
  }
  getParameter(): Observable<any> {
  return this.subject.asObservable();
}
getParameterBoton(): Observable<any> {
  return this.subjectBoton.asObservable();
}
stopSharing () {
  this.subject.complete();
}

}
