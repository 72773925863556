import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    CamposTablaPersonalesPatologicos,
    PantallaPersonalesPatologicos
} from '../../../../../shared/classes/common.classes';
import {PageRequest} from '../../../../../common/http/page.request';
import {Cie10} from '../../../../../shared/classes/cies.classes';
import {CiesService} from '../../../../../shared/services/cies.service';
import {CapitalizePipe} from "../../../../../shared/utils/capitalize.pipe";

@Component({
    selector: 'app-personales-patologicos',
    templateUrl: './personales-patologicos.component.html',
    styleUrls: ['../paciente.historia-clinica.component.css']
})
export class PersonalesPatologicosComponent implements OnInit, OnChanges {
    @Input() private wholeObject: any = {};
    @Output() private wholeObjectChange: EventEmitter<PantallaPersonalesPatologicos> = new EventEmitter<PantallaPersonalesPatologicos>();

    private searchCieTimeout: number;
    private optionsSearchCie: PageRequest = new PageRequest();
    // Array campos tabla personales patologicos
    arrayCamposTPP: CamposTablaPersonalesPatologicos[] = [];
    pantallaPersonalesPatologicos: PantallaPersonalesPatologicos = new PantallaPersonalesPatologicos();

    private selectedCie10: Cie10;
    searchCie10: string;
    cargandoCie: boolean = false;
    catCie10: Array<Cie10> = [];
    onlyConsultas: any[] = [];
    loadingDataTable: boolean = false;
    total: number = 0;
    pageIndex: number = 0;
    pageSize: number = 0;
    readOnly: boolean = false;
    notas: boolean = false;

    constructor(private ciesService: CiesService,
                private capitalizePipe: CapitalizePipe) {
    }

    ngOnInit(): void {
        this.optionsSearchCie.page = 0;
        this.optionsSearchCie.size = 10;
        this.optionsSearchCie.orderType = "desc";

        this.arrayCamposTPP = [
            new CamposTablaPersonalesPatologicos('Diabetes'),
            new CamposTablaPersonalesPatologicos('Hipertension arterial'),
            new CamposTablaPersonalesPatologicos('Obesidad/Sobrepeso'),
            new CamposTablaPersonalesPatologicos('Cardiovasculares'),
            new CamposTablaPersonalesPatologicos('Endocrinos'),
            new CamposTablaPersonalesPatologicos('Renales'),
            new CamposTablaPersonalesPatologicos('Hepaticos'),
            new CamposTablaPersonalesPatologicos('Cerebrovasculares'),
            new CamposTablaPersonalesPatologicos('Psiquiatricas/Neuro'),
            new CamposTablaPersonalesPatologicos('Oncologicos'),
            new CamposTablaPersonalesPatologicos('Transmision sexual')
        ];
        this.llenarObjeto();
    }

    agregarEnfermedad(): void {
        if (this.selectedCie10 && this.selectedCie10.nombre) {
            this.selectedCie10.nombre = this.capitalizePipe.transform(this.selectedCie10.nombre);
            if (this.arrayCamposTPP.findIndex((campoTPP) => campoTPP.enfermedad.toLowerCase() == this.selectedCie10.nombre.toLowerCase()) < 0) {
                const nuevo = new CamposTablaPersonalesPatologicos(this.selectedCie10.nombre);
                nuevo.tieneEnfermedad = true;
                this.arrayCamposTPP.push(nuevo);
                // Limpiamos el input
                this.selectedCie10 = null;
                this.searchCie10 = null;
                setTimeout(() => this.llenarObjeto(), 300);
            }
        }
    }

    llenarObjeto(): void {
        this.pantallaPersonalesPatologicos.datosTabla = this.arrayCamposTPP;
        // Enviamos el valor
        this.wholeObject.personalesPatologicos = this.pantallaPersonalesPatologicos;
        this.wholeObjectChange.emit(this.wholeObject);
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('wholeObject')) {
            const value = changes['wholeObject'].currentValue;
            if (value && value.hasOwnProperty('personalesPatologicos')) {
                this.pantallaPersonalesPatologicos = value['personalesPatologicos'];
                this.arrayCamposTPP = this.pantallaPersonalesPatologicos.datosTabla;
            } else {
                this.wholeObject.personalesPatologicos = this.pantallaPersonalesPatologicos;
            }
        }
    }

    autocompleteCie(ev: KeyboardEvent, type: string): void {
        if (this.searchCieTimeout) clearTimeout(this.searchCieTimeout);
        if (ev.keyCode === 27 && this.selectedCie10) {
            this.catCie10 = [];
            if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
            } else {
                this.cargandoCie = true;
                this.ciesService.getCie10(this.selectedCie10.idCie10)
                    .subscribe((cie: Cie10) => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                        () => this.cargandoCie = false,
                        () => this.cargandoCie = false);
            }
        } else {
            this.searchCieTimeout = window.setTimeout(() => {
                let input: HTMLInputElement = <HTMLInputElement>ev.target;
                input.onblur = null;
                if (this.searchCie10) {
                    this.optionsSearchCie.orderColumn = "nombre";
                    this.optionsSearchCie.datosBusqueda = this.searchCie10;
                    this.cargandoCie = true;
                    this.ciesService.getPageCie10(this.optionsSearchCie)
                        .subscribe((pageCie10) => {
                                this.catCie10 = pageCie10.content;
                                if (this.catCie10.length) {
                                    if (ev.keyCode === 13) this.assignCie(this.catCie10[0], type);
                                } else if (this.selectedCie10) {
                                    input.onblur = () => {
                                        input.onblur = null;
                                        if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                                            this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                                        } else {
                                            this.ciesService.getCie10(this.selectedCie10.idCie10)
                                                .subscribe((cie: Cie10) => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                                                    () => this.cargandoCie = false,
                                                    () => this.cargandoCie = false);
                                        }
                                    };
                                }
                            }, () => this.cargandoCie = false,
                            () => this.cargandoCie = false);

                } else {
                    this.catCie10 = [];
                    if (this.selectedCie10) {
                        input.onblur = () => {
                            input.onblur = null;
                            if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                                this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                            } else {
                                this.ciesService.getCie10(this.selectedCie10.idCie10).subscribe(
                                    cie => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                                    () => {
                                        this.cargandoCie = false
                                    },
                                    () => this.cargandoCie = false);
                            }
                        };
                    }
                }
            }, 300);
        }
    }

    assignCie(cie: any, type?: string): void {
        this.selectedCie10 = cie;
        this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`;
        this.catCie10 = [];
    }

    forceSelectCie(ev: MouseEvent): void {
        let input: HTMLInputElement = <HTMLInputElement>ev.target;
        setTimeout(() => input.setSelectionRange(0, input.value.length), 50);
    }
}
