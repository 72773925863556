import {Component, OnInit, Output,EventEmitter, ElementRef } from '@angular/core';
import {UserDetailsService} from '../user.details.service';
import {AuthNioService} from 'app/+login-nio/auth-nio.service';
import {MedicoView} from "../../../+doctor/doctor.classes";

@Component({
    selector: 'sa-login-info',
    templateUrl: './login-info.component.html',
    styleUrls: ['./login-info.css']
})
export class LoginInfoComponent implements OnInit {
    @Output() imagenBase:EventEmitter <string>=new EventEmitter;
    constructor(public authService: AuthNioService,
                private userDetailsService: UserDetailsService) {
    }
    isCollapsedCyc: boolean[] = [];
    lista:string[]=[];
    index:number;
    imagen:string;
    ngOnInit(): void {
        var activename =this.authService.token.nombre_grupo_principal;
        var long =Object.keys(this.authService.token.grupos).length;
        for (let index = 0; index < long; index++) {
            const element =this.authService.token.grupos[index];
            var idgrupo=Object.keys(element).map(function(key){
               var ungrupo=element[key];
               var unsolonombre=Object.keys(ungrupo);
               return unsolonombre[0]
            });
            const lista=[];
            if(idgrupo[0]==activename){
                this.isCollapsedCyc[index]=true;
            }
            this.lista.push(idgrupo[0]);           
        }
        jQuery('.trigger').on('click', function (event) {
            event.preventDefault();
            jQuery('#' + $(this).attr('href')).toggle('fast');
            return false;
        });
    }

    logout(): void {
        this.userDetailsService.usersDetails = new MedicoView();
        delete this.userDetailsService.fullName;
        this.authService.logout();
    }
    seleccionaGrupo(item,index){

        var long =Object.keys(this.authService.token.grupos).length;
        var idgrupo;
        var imagengrupo;
        for(let x =0 ; x<long;x++){
            this.isCollapsedCyc[x]=false;
        }
        this.isCollapsedCyc[index]=true;
        for (let index = 0; index < long; index++) {

            const element =this.authService.token.grupos[index];          
                idgrupo=Object.keys(element).map(function(key){
                var ungrupo=element[key];
                var unsolonombre=Object.keys(ungrupo);
                    if(unsolonombre[0]==item){               
                         imagengrupo=Object.keys(ungrupo).map((key)=>{
                             index=long;
                            return ungrupo[key];
                        });
                        return imagengrupo[0];
                    }
                    
                });
                this.imagen=idgrupo[0];          
            
        }
        
        for (let index = 0; index < long; index++) {
            const element =this.authService.token.grupos[index];
            var indexgrupo=Object.keys(element).map(function(key){
               var ungrupo=element[key];
               var unsolonombre=Object.keys(ungrupo);
               if(item == unsolonombre[0]){
                   localStorage.setItem('id_select_group',key)
               }

            });         
        }
        localStorage.setItem("image_group_selected",this.imagen)
        
        this.imagenBase.emit(item);
    }


}
