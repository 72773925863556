export class Format {
    public active: boolean = true;
    public categoryName: string;
    public formatType: string;
    public idFormat: number;
    public jsonFormat: any;
    public title: string;
    public version: number;
}

export class FormatResult {
    createdDate: Date | number;
    deviceId: string;
    idFormat: number;
    formatView: Format;
    idConsulta: number;
    idFormatResult: number;
    jsonAnswer: any;
    username: string;
}

export class FormatResultList {
    createdDate: Date;
    idFormat: number;
    idFormatResult: number;
    jsonAnswer: any;
    username: string;
}
