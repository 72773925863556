import {Component, OnInit, Input, Output, SimpleChanges, EventEmitter} from '@angular/core';
import {AntecedentesGinecobstetricos} from '../../../../../shared/classes/common.classes';
import {ShareDataService} from '../../../consulta-actual/share-data.service';
import {ListaErrorsForms} from '../../../../../shared/classes/catalog.classes';
import {PatientView} from '../../../../paciente.classes';
import { Console } from 'console';
@Component({
    selector: 'app-antecedentes-gineco',
    templateUrl: './antecedentes-gineco.component.html',
    styleUrls: ['../paciente.historia-clinica.component.css']
})
export class AntecedentesGinecoComponent implements OnInit {
    @Input() private wholeObject: any = {};
    @Output() private wholeObjectChange = new EventEmitter<AntecedentesGinecobstetricos>();
    ginecobstetricos: AntecedentesGinecobstetricos = new AntecedentesGinecobstetricos();
    papanicolauFecha: Date;
    private documento: any;
    fechaInvalid = false;
    notas: boolean = false;

    fechaInvalidmamo = false;
    @Input() paciente: PatientView = new PatientView(); // Revisar esto que no funcionó
    constructor(private dataService: ShareDataService) {
    }

    ngOnInit(): void {
        this.dataService.getParameter()
            .subscribe(() => this.llenarObjeto());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('wholeObject')) {
            const value = changes['wholeObject'].currentValue;
            if (value && value.hasOwnProperty('ginecobstetricos')) {
                this.ginecobstetricos = value['ginecobstetricos'];
            } else {
                this.wholeObject.ginecobstetricos = this.ginecobstetricos;
            }
        }
        if (changes.hasOwnProperty('paciente')) {
            const value = changes['paciente'].currentValue;
            if (value && value.hasOwnProperty('sexo') && value['sexo'].length) {
                this.paciente = value;
            }
        }
    }

    getDate(fecha: string): void {
        fecha += ' 00:00:00';
        this.ginecobstetricos.papanicolauFecha = new Date(fecha);
        if (this.ginecobstetricos.papanicolauFecha >= new Date()) {
            console.log('la fecha es futura'); this.fechaInvalid = false;
        } else { this.fechaInvalid = true; console.log('la fecha es correcta');}
        
    }

    getdatemamario(fecha){
        fecha += ' 00:00:00';
        this.ginecobstetricos.mamografiaFecha = new Date(fecha);
        if (this.ginecobstetricos.mamografiaFecha >= new Date()) {
            console.log('la fecha es futura'); this.fechaInvalidmamo = false;
        } else { this.fechaInvalidmamo = true; console.log('la fecha es correcta');}

    }
    llenarObjeto(): void {
        // Enviamos el valor
        this.wholeObject.ginecobstetricos = this.ginecobstetricos;
        this.wholeObjectChange.emit(this.wholeObject);
        
    }
}
