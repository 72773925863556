import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {PageImpl} from "../../common/http/PageImpl";
import {PageRequest} from "../../common/http/page.request";

@Component({
    selector: 'app-nio-pagination',
    templateUrl: 'nio-pagination.component.html',
    styleUrls: ['nio-pagination.component.css']
})
export class NioPaginationComponent implements OnChanges {
    @Input() pageData: PageImpl<any>;
    @Input() lenghtControl: boolean;

    @Output() paginationUpdate: EventEmitter<PageRequest> = new EventEmitter();

    pages: number[];

    ngOnChanges(changes: SimpleChanges) {
        for (let prop in changes) {
            if (prop == 'pageData' && this.pageData) {
                let start: number = this.pageData.number - 2;
                let end: number = this.pageData.totalPages;
                if (start < 0) start = 0;

                this.pages = [];
                for (let i = 0; start < end && i < 5; i++) {
                    this.pages.push(start);
                    start++;
                }
            }
        }
    }

    go(nextOrPrev: number) {
        if (nextOrPrev < 0 && !this.pageData.first) {
            this.toPage(this.pageData.number - 1);
        } else if (nextOrPrev > 0 && !this.pageData.last) {
            this.toPage(this.pageData.number + 1);
        }
    }

    toPage(page: number) {
        let pageRequest: PageRequest = new PageRequest;
        pageRequest.size = this.pageData.size;
        pageRequest.page = page;

        this.paginationUpdate.emit(pageRequest);
    }
}
