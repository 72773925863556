import {Injectable} from '@angular/core';
import {RequestMethod, Response} from "@angular/http";
import {RequestConfig} from "../rest/rest.request";
import {CustomHttp} from "../../common/http/custom.http";
import {Observable} from "rxjs";

@Injectable()
export class ReportsService {
    private GET_NOTE_MEDICAL = new RequestConfig('Reports-GetNoteMedical', 'text/plain', true, RequestMethod.Get, '/reportes/notas-medicas/{idConsulta}/{idGroup}'); //GGR20200618 agrego idGrupo seleccionado
    private GET_NOTE_EVOLUTION = new RequestConfig('Reports-GetNoteEvolution', 'text/plain', true, RequestMethod.Get, '/reportes/notas-evolucion/{idConsulta}');
    private GET_NOTE_INTERCONSULTA = new RequestConfig('Reports-GetNoteInterconsulta', 'text/plain', true, RequestMethod.Get, '/reportes/notas-interconsulta/{idConsulta}');
    private GET_NOTE_HISTCLINICA = new RequestConfig('Reports-GetNoteInterconsulta', 'text/plain', true, RequestMethod.Get, '/reportes/historia-clinica/{idPaciente}');
    private GET_NOTE_REFERENCE = new RequestConfig('Reports-GetNoteReference', 'text/plain', true, RequestMethod.Get, '/reportes/referencia/{idConsulta}');
    private GET_NOTE_COUNTERREF = new RequestConfig('Reports-GetNoteCounterRef', 'text/plain', true, RequestMethod.Get, '/reportes/contrarreferencia/{idConsulta}');
    private GET_RECIPE_NOTE = new RequestConfig('Reports-GetRecipeNote', 'text/plain', true, RequestMethod.Get, '/reportes/receta/{idConsulta}/{idGroup}'); //GGR20200622 agrego idGroupo seleccionado
    private GET_FORMATO_ESTUDIOS_NOTE = new RequestConfig('Reports-GetRecipeNote', 'text/plain', true, RequestMethod.Get, '/reportes/solicitud-estudios/{idConsulta}/{folio}/{idGroup}'); //GGR20200803 agrego idGroupo seleccionado

    constructor(private http: CustomHttp) {
    }

    //GGR20200618 agrego idGrupo seleccionado
    getNoteMedical(idConsulta: number, idGroup: number): Observable<string> {
        return this.http.sendRequest(this.GET_NOTE_MEDICAL, null, idConsulta, idGroup)
            .map((res: Response) => res.text())
            .catch((error) => Observable.throw(error));
    }

    getNoteEvolution(idConsulta: number): Observable<string> {
        return this.http.sendRequest(this.GET_NOTE_EVOLUTION, null, idConsulta)
            .map((res: Response) => res.text())
            .catch((error) => Observable.throw(error));
    }

    getNoteInterconsulta(idConsulta: number): Observable<string> {
        return this.http.sendRequest(this.GET_NOTE_INTERCONSULTA, null, idConsulta)
            .map((res: Response) => res.text())
            .catch((error) => Observable.throw(error));
    }

    getNoteReference(idConsulta: number): Observable<string> {
        return this.http.sendRequest(this.GET_NOTE_REFERENCE, null, idConsulta)
            .map((res: Response) => res.text())
            .catch((error) => Observable.throw(error));
    }

    getNoteCounterReference(idConsulta: number): Observable<string> {
        return this.http.sendRequest(this.GET_NOTE_COUNTERREF, null, idConsulta)
            .map((res: Response) => res.text())
            .catch((error) => Observable.throw(error));
    }

    getRecipeNote(idConsulta: number, idGroup: number): Observable<string> {
        return this.http.sendRequest(this.GET_RECIPE_NOTE, null, idConsulta, idGroup)
            .map((res: Response) => res.text())
            .catch((error) => Observable.throw(error));
    }
    gethISTORIACLINICA(idPaciente : String): Observable<string> {
        return this.http.sendRequest(this.GET_NOTE_HISTCLINICA, null, idPaciente)
            .map((res: Response) => res.text())
            .catch((error) => Observable.throw(error));
    }
    /*IMPRIMIR FORMATO DE ESTUDIOS*/ //GGR20200803 agrego idGrupo seleccionado
    getFormatoEstudios(idConsulta: number, folio: number, idGroup: number): Observable<any> {
        return this.http.sendRequest(this.GET_FORMATO_ESTUDIOS_NOTE, null, idConsulta, folio, idGroup)
        .map((res: Response) => res.text())
            .catch((error) => Observable.throw(error));
    }
}
