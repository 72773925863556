import {NgModule} from "@angular/core";
import {ConsultaLayoutFooterComponent} from "./consulta.layout.footer.component";
import {ConsultaService} from "../../shared/consulta.service";
import {UserDetailsService} from "../../../shared/user/user.details.service";
import {SmartadminModule} from "../../../shared/smartadmin.module";
import {BsModalService} from "ngx-bootstrap";
import {PlanRecetaModule} from "../../+detalle/plan-receta/plan-receta.module";
import {PlanEstudioModule} from "../../+detalle/plan-estudio/plan-estudio.module";

@NgModule({
    imports: [
        SmartadminModule,
        PlanRecetaModule,
        PlanEstudioModule
    ],
    declarations: [ConsultaLayoutFooterComponent],
    exports: [ConsultaLayoutFooterComponent],
    providers: [
        ConsultaService,
        UserDetailsService,
        BsModalService
    ]
})
export class ConsultaLayoutFooterModule {
}
