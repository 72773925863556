import {NgModule} from '@angular/core';
import {PlanRecetaComponent} from './plan-receta.component';
import {ModalModule} from 'ngx-bootstrap';
import {SmartadminModule} from '../../../shared/smartadmin.module';
import {DoctorService} from '../../../+doctor/doctor.service';
import {PacienteService} from '../../../+paciente/paciente.service';
import {JqueryUiModule} from '../../../shared/ui/jquery-ui/jquery-ui.module';
import {SmartadminInputModule} from '../../../shared/forms/input/smartadmin-input.module';
import {MedicinesService} from "../../../shared/services/medicines.service";
import {RecipesService} from "../../../shared/services/recipes.service";

@NgModule({
    imports: [
        SmartadminModule,
        JqueryUiModule,
        SmartadminInputModule,
        ModalModule.forRoot()
    ],
    declarations: [PlanRecetaComponent],
    entryComponents: [PlanRecetaComponent],
    providers: [
        DoctorService,
        PacienteService,
        MedicinesService,
        RecipesService
    ]
})
export class PlanRecetaModule {
}
