import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {ConsultaService} from 'app/+consulta/shared/consulta.service';
import {ConsultaListaService} from 'app/+consulta/+lista/consulta.lista.service';
import {NotificationService} from 'app/shared/utils/notification.service';
import * as $ from 'jquery'
import {ConsultaView} from '../shared/consulta.classes';
import {isNullOrUndefined} from 'util';
import {HasPermissionService} from '../../shared/haspermission/hasPermission.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-termina-consulta',
    templateUrl: './termina-consulta.component.html',
    styleUrls: ['./termina-consulta.component.css']
})
export class TerminaConsultaComponent implements OnInit {
    @Input() consulta: ConsultaView | number;
    @Output() updateMessage: EventEmitter<ConsultaView> = new EventEmitter();

    selectedConsulta: ConsultaView;

    constructor(private consultaService: ConsultaService,
                private notificationService: NotificationService,
                private hasPermissionService: HasPermissionService,
                @Optional() private listConsultaService: ConsultaListaService,
                private router: Router) {
    }

    ngOnInit() {
        if (this.consulta) {
            if (this.consulta instanceof ConsultaView) {
                this.selectedConsulta = this.consulta;
            } else {
                this.consultaService.get(this.consulta).subscribe(
                    consulta => this.selectedConsulta = consulta,
                    error => this.notificationService.bigBoxError({content: 'Se necesita la consulta y no se pudo obtener referencia'})
                );
            }
        } else {
            if (isNullOrUndefined(this.listConsultaService)) {
                this.notificationService.bigBoxError({content: 'Se necesita la consulta y no se pudo obtener referencia'});
            } else if (this.listConsultaService.getConsultaSelected()) {
                this.consultaService.get(this.listConsultaService.getConsultaSelected().idConsulta).subscribe(
                    consulta => this.selectedConsulta = consulta,
                    error => this.notificationService.bigBoxError({content: 'Se necesita la consulta y no se pudo obtener referencia'})
                );
            }
        }
    }

    terminarConsulta() {
       if (this.hasPermissionService.canAccess('ROLE_FINISH_CONSULTA')) {
            this.selectedConsulta.feachaFin = new Date();

            this.consultaService.finalizarConsulta(this.selectedConsulta)
                .subscribe(() => {
                    this.notificationService.bigBoxSuccess({content: 'Consulta guardada exitosamente '});
                    this.selectedConsulta.idEstadoConsulta = 4;
                    this.selectedConsulta.estadoConsulta = 'Finalizada';
                    this.updateMessage.emit(this.selectedConsulta);
                    this.router.navigate(['consulta/lista']);
                }, error => {
                    this.notificationService.bigBoxError({content: `No se pudo guardar la consulta`});
                    console.error(error);
                });
        }
    }
}
