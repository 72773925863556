import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import {ConsultaView} from '../../shared/consulta.classes';
import {PatientView} from '../../../+paciente/paciente.classes';
import {MedicoView} from '../../../+doctor/doctor.classes';
import {DoctorService} from '../../../+doctor/doctor.service';
import {PacienteService} from '../../../+paciente/paciente.service';
import {Subscription} from 'rxjs/Subscription';
import {ConsultaService} from '../../shared/consulta.service';
import {StudiesService} from "../../../shared/services/studies.service";
import {StudyJSON, NuevoEstudioView, DetalleEstudioView} from '../../../shared/classes/studies.classes';
import {PageRequest} from "../../../common/http/page.request";
import {ReportsService} from 'app/shared/services/reports.service';
import {ShareDataService} from '../../../+paciente/+detalle/consulta-actual/share-data.service';

@Component({
    selector: 'app-plan-estudio',
    templateUrl: './plan-estudio.component.html',
    styleUrls: ['./plan-estudio.component.css']
})
export class PlanEstudioComponent implements OnInit, OnDestroy {
    // VOY A UTILIZAR LA NUEVA CLASE DE ESTUDIOVIEW
    estudios: NuevoEstudioView[] = [];
    estudio: NuevoEstudioView = new NuevoEstudioView();
    consulta: ConsultaView = new ConsultaView();
    paciente: PatientView = new PatientView();
    medico: MedicoView = new MedicoView();
    fullNameMedico: string = '';
    domicilioPaciente: string = '';
    estudioDescripcion: string = '';
    canEdit: boolean = true;
    cargando: boolean = false;
    showModal: boolean = false;
    showFormatModal: boolean = false;

    private subscription: Subscription = new Subscription();

    @ViewChild('dialogStudiesFilterList') private dialogStudiesFilterListEl: ElementRef;
    dialogStudiesConfig = {
        autoOpen: false,
        closeText: '',
        draggable: false,
        resizable: false,
        modal: true,
        width: 400,
        classes: {
            'ui-dialog-titlebar': 'no-border',
        },
        position: {my: 'left top', at: 'left top', of: $('#txtEstudio')}
    };
    studiesFilterList: StudyJSON[] = [];
    searchStudiesFilterList: string;
    loadingStudiesFilterList: boolean = false;
    private timeoutFitlerList: number;
    private pageSearchFilterList: PageRequest = new PageRequest();
    private isLastFromFilterList: boolean = false;
    private selectedStudy: StudyJSON;

    constructor(public bsModalRef: BsModalRef,
                private doctorService: DoctorService,
                private pacienteService: PacienteService,
                private consultaService: ConsultaService,
                private studiesService: StudiesService,
                private reportsService: ReportsService,
                private dataService: ShareDataService) {
    }

    ngOnInit(): void {
        this.estudio.detallesEstudioList = [];

        this.consultaService.getMapEstatusConsulta()
            .subscribe((mapEstatusConsulta) => this.canEdit = this.consulta.idEstadoConsulta == mapEstatusConsulta.get('ConsultaEnTurno'));

        if (!(this.medico.idMedico && this.medico.especialidadViewList.length && this.medico.especialidadViewList[0].cedula.length)) {
            if (this.consulta && this.consulta.idMedico) {
                this.subscription.add(
                    this.doctorService.getById(this.consulta.idMedico, true)
                        .subscribe((medic) => {
                            this.medico = medic;
                            this.fullNameMedico = `${medic.nombre} ${medic.apellidoPaterno} ${medic.apellidoMaterno}`;
                        })
                );
            }
        } else {
            this.fullNameMedico = `${this.medico.nombre} ${this.medico.apellidoPaterno} ${this.medico.apellidoMaterno}`;
        }

        if (!(this.paciente && this.paciente.idPaciente)) {
            if (this.consulta && this.consulta.idPaciente) {
                this.subscription.add(
                    this.pacienteService.getById(this.consulta.idPaciente, true)
                        .subscribe((patient) => {
                            if (patient && patient.idPaciente) {
                                this.paciente = patient;
                                if (this.paciente.domicilioViewList.length) {
                                    const _address = this.paciente.domicilioViewList[0];
                                    this.domicilioPaciente = `${_address.domicilio}
                                                        ${_address.colonia ? 'Colonia: ' + _address.colonia : ''}
                                                        ${_address.municipio}
                                                        ${_address.estado}
                                                        ${this.paciente.telefonoCelular ? 'Teléfono: ' + this.paciente.telefonoCelular : this.paciente.telefonoLocal ? 'Teléfono: ' + this.paciente.telefonoLocal : ''}`;
                                }
                            }
                        })
                );
            }
        }

        this.pageSearchFilterList.page = 0;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    crearAgregarDetallesAlFormato(): void {
        if (!this.canEdit) {
            return;
        }

        if (this.estudioDescripcion !== '') { //agregado por eli
            if (this.estudio && this.estudio.idEstudio) {
                this.cargando = true;
                // si ya existe solo hago push al objeto y el PUT al objeto
                let detalles = new DetalleEstudioView(this.selectedStudy.Descripcion, this.selectedStudy.Estudio);
                this.estudio.detallesEstudioList.push(detalles);
                this.studiesService.updateFormatoEstudio(this.estudio.idEstudio, this.estudio).subscribe((estudioViewUpdated) => {
                    this.estudio = estudioViewUpdated;
                    this.cargando = false;
                }, () => {
                    this.cargando = false;
                    this.estudio.detallesEstudioList.pop();
                }, () => this.cargando = false);
            } else {
                this.cargando = true;
                this.estudio.idMedico = this.consulta.idMedico;
                this.estudio.nombreMedico = this.consulta.nombreMedico;
                let arrEspecialidad = this.medico.especialidadViewList;
                this.estudio.cedulaMedico = this.medico && arrEspecialidad.length ? arrEspecialidad[0].cedula : null;
                this.estudio.universidadMedico = this.medico && arrEspecialidad.length ? arrEspecialidad[0].universidad : null;
                this.estudio.especialidadMedico = this.medico && arrEspecialidad.length ? arrEspecialidad[0].especialidad : null;
                this.estudio.idPaciente = this.paciente.idPaciente;
                this.estudio.nombrePaciente = this.consulta.nombrePaciente;
                this.estudio.correoPaciente = this.paciente.email;
                this.estudio.edadPaciente = Math.abs(new Date(Date.now() - new Date(this.paciente.fechaNacimiento).getTime()).getUTCFullYear() - 1970);
                this.estudio.curpPaciente = this.paciente.curp;
                this.estudio.numeroExpediente = this.paciente.numeroExpediente;
                this.estudio.fechaCreacion = new Date();
                this.estudio.idConsulta = this.consulta.idConsulta;
                this.estudio.sexoPaciente = this.paciente.sexo;
                this.estudio.activo = true;  // Sre20042020 Faltaba ese attr para poder grabar
                // genero un nuevo objeto de detalles del estudio
                let detalles = new DetalleEstudioView(this.selectedStudy.Descripcion, this.selectedStudy.Estudio);
                this.estudio.detallesEstudioList.push(detalles);
                this.studiesService.save(this.estudio).subscribe((estudioViewCreated) => {
                    this.estudio = estudioViewCreated;
                    // this.loadStudies();
                    this.cargando = false;
                }, () => {
                    this.estudio.detallesEstudioList.pop();
                    this.cargando = false;
                }, () => this.cargando = false);
            }
            this.estudioDescripcion = '';
        } else { //agregado por eli
            console.log(this.estudioDescripcion, 'la descripcion esta vacia');
            this.showFormatModal = true;
        }
    }

    eliminarDetalle(index: number): void {
        if (!this.canEdit) {
            return;
        }
        this.estudio.detallesEstudioList.splice(index, 1);
        // si queremos hacer un update tenemos que asegurarnos que aun hay detalles en el formato
        if (this.estudio.detallesEstudioList.length) {
            this.cargando = true;
            this.studiesService.updateFormatoEstudio(this.estudio.idEstudio, this.estudio)
                .subscribe((estudioViewUpdated) => {
                    this.estudio = estudioViewUpdated;
                    this.cargando = false;
                }, () => this.cargando = false);
        }
    }

    private get dialogStudiesFilterList(): any {
        if (!this.dialogStudiesFilterListEl) {
            return null;
        }

        return $(this.dialogStudiesFilterListEl.nativeElement);
    }

    openDialogStudiesFilterList(event: MouseEvent): void {
        if (this.dialogStudiesFilterList) {
            this.studiesFilterList = [];

            if (event) {
                this.dialogStudiesFilterList.dialog('option', 'position', {
                    my: 'left top',
                    at: 'left top',
                    of: event.target
                });
            }

            this.dialogStudiesFilterList.dialog('open');
        }
    }

    loadFilterList(reset: boolean = false, more: boolean = false): void {
        clearTimeout(this.timeoutFitlerList);

        if (more) {
            this.pageSearchFilterList.page++;
        }

        if (reset) {
            this.pageSearchFilterList.page = 0;
            this.studiesFilterList = [];
            this.isLastFromFilterList = false;
        }

        if (this.searchStudiesFilterList && this.searchStudiesFilterList.length > 2) {
            this.loadingStudiesFilterList = true;
            this.timeoutFitlerList = window.setTimeout(() => {
                this.pageSearchFilterList.datosBusqueda = this.searchStudiesFilterList;
                this.studiesService.filterFromJSON(this.searchStudiesFilterList)
                    .subscribe((studiesJSON) => {
                        this.studiesFilterList = [];
                        this.studiesFilterList.push(...studiesJSON);
                        this.isLastFromFilterList = true;
                        this.loadingStudiesFilterList = false;
                    }, () => this.loadingStudiesFilterList = false);
            }, 500);
        }
    }

    onFilterListScroll(event: MouseEvent): void {
        if (!this.isLastFromFilterList) {
            if ((<HTMLElement>event.target).offsetHeight + (<HTMLElement>event.target).scrollTop >= (<HTMLElement>event.target).scrollHeight) {
                this.loadFilterList(false, true);
            }
        }
    }

    selectStudyFilterList(study: StudyJSON): void {
        this.selectedStudy = study;
        this.estudioDescripcion = this.selectedStudy.Descripcion;
        this.searchStudiesFilterList = '';

        if (this.dialogStudiesFilterList) {
            this.dialogStudiesFilterList.dialog('close');
        }
    }

    EstudioViewTieneDetalles(): void {
        if (this.estudio && this.estudio.detallesEstudioList && this.estudio.detallesEstudioList.length) {
            this.dataService.sendParameter();
            this.bsModalRef.hide();
        } else {
            this.showModal = true;
            (<any>$('#modalInfo')).modal('show');
        }
    }

    cancelaFormato(): void {
        if (this.estudio && this.estudio.idEstudio) {
            this.cargando = true;
            this.studiesService.delete(this.estudio.idEstudio).subscribe(() => {
                this.cargando = false;
                this.bsModalRef.hide();
            }, () => this.cargando = false, () => this.cargando = false);
        } else {
            this.bsModalRef.hide();
        }
    }

    noAgregar(): void {
        this.showModal = false;
        (<any>$('#modalInfo')).modal('hide');
        this.cancelaFormato();
    }

    downloadDocument(): void {
        this.cargando = true;
        var idGroup = Number(localStorage.getItem('id_select_group')); //GGR20200803 agrego idGrupo seleccionado
        this.reportsService.getFormatoEstudios(this.estudio.idConsulta, this.estudio.folio, idGroup).subscribe((base64File) => {
            this.cargando = false;
            let fileName = `reporteEstudios-${this.estudio.nombrePaciente}`;
            fileName.toString().trim();
            let url = `data:application/pdf;base64,${base64File}`;
            fetch(url)
                .then(res => res.blob())
                .then(function (blob) {
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, fileName);
                    } else {
                        var elem = window.document.createElement('a');
                        elem.href = window.URL.createObjectURL(blob);
                        elem.download = fileName;
                        document.body.appendChild(elem);
                        elem.click();
                        document.body.removeChild(elem);
                    }
                });
        }, () => this.cargando = false, () => this.cargando = false);
    }
}
