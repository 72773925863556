import {Injectable} from '@angular/core';
import {CustomHttp} from "../../common/http/custom.http";
import {RequestConfig} from "../rest/rest.request";
import {RequestMethod, Response} from "@angular/http";
import {Observable} from "rxjs";
import {MedicineProduct, MedicineSearch} from "../classes/medicine.classes";

@Injectable()
export class MedicinesService {
    private GET_SEARCH = new RequestConfig('Medicines-GetSearch', 'application/json', true, RequestMethod.Get, '/medicamento/search');
    private GET_BY_SUBSTANCE = new RequestConfig('Medicines-GetBySubstance', 'application/json', true, RequestMethod.Get, '/medicamento/sustancia/{idSubstance}');
    private GET_BY_PRODUCT = new RequestConfig('Medicines-GetByProduct', 'application/json', true, RequestMethod.Get, '/medicamento/{idProduct}');

    constructor(private http: CustomHttp) {
    }

    search(search: {textoBusqueda: string}): Observable<MedicineSearch> {
        return this.http.sendRequest(this.GET_SEARCH, search)
            .map((res: Response) => res.json().getResultsDetailResult)
            .catch((error) => this.http.handleError(error));
    }

    getBySustancia(idSubstance: number): Observable<MedicineProduct[]> {
        return this.http.sendRequest(this.GET_BY_SUBSTANCE, null, idSubstance)
            .map((res: Response) => res.json().getDrugsShortDetailBySubstanceResult)
            .catch((error) => this.http.handleError(error));
    }

    getByProductId(idProduct: number, filters: {categoryId: number, pharmaFormId: number, divisionId: number}): Observable<MedicineProduct> {
        return this.http.sendRequest(this.GET_BY_PRODUCT, filters, idProduct)
            .map((res: Response) => res.json().getDrugShortInformationResult)
            .catch((error) => this.http.handleError(error));
    }
}
