import { Component, ElementRef, OnDestroy, OnInit, ViewChild, EventEmitter, Output, Input 
, OnChanges,
SimpleChanges,
AfterViewInit,
AfterViewChecked} from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { HistoriaClinicaModule } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/paciente.historia-clinica.module';
import {AuthNioService} from '../+login-nio/auth-nio.service';
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {PatientView, DatosContactoView, DatosAdicionalesView, DatosClinicosView, Tutor, RelTutor,CatVacunaView, VacunaDosisView, VacunaView, EsquemaVacunacionView} from '../+paciente/paciente.classes';
import {NotificationService} from 'app/shared/utils/notification.service';


import {UserDetailsService} from 'app/shared/user/user.details.service';
import {isNullOrUndefined} from 'util';
import {HasPermissionService} from "../shared/haspermission/hasPermission.service";
import {ShareDataService} from '../+paciente/+detalle/consulta-actual/share-data.service'


import {UserAppView, UserIdentity, UserImageView} from "../shared/classes/user.classes";
import {Subscription} from 'rxjs/Subscription';
import {DatePipe} from "@angular/common";
import {ShaService} from "../shared/jssha/jssha.service";
import {PageRequest} from "../common/http/page.request";
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels
} from "@techiediaries/ngx-qrcode";

import {environment} from "../../environments/environment";
import { NzTabSetComponent } from 'ng-zorro-antd';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

import {Cie10, Cie9} from '../shared/classes/cies.classes';
import {CiesService} from '../shared/services/cies.service';
import {CapitalizePipe} from "../shared/utils/capitalize.pipe";
import { StudyJSON } from 'app/shared/classes/studies.classes';
import { StudiesService } from 'app/shared/services/studies.service';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PacienteService } from 'app/+paciente/paciente.service';
import { ListaErrorsForms } from 'app/shared/classes/catalog.classes';

  const createHost = require('cross-domain-storage/host');
  const createGuest = require('cross-domain-storage/guest');
  const storageHost = createHost([
    {
        origin: environment.api_url, // https://cct.telemedicina.lat:9211
        allowedMethods: ['get', 'set', 'remove'],
    },
    {
      origin: 'https://teleprueba.alenthatest.net',
      allowedMethods: ['get', 'set'],
    },
    {
      origin: 'https://sos.alentha.us',
      allowedMethods: ['get', 'set'],
    }, 
    {
      origin: 'https://sos.alentha.us:4344',
      allowedMethods: ['get', 'set'],
    },
    {
      origin: 'https://teleprueba.co:4343',
      allowedMethods: ['get', 'set'],
    },
  ]);

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class IframeComponent implements OnInit {
  
  @Input() idPaciente: string;
  guardando = false;
  private showModal = false;//mensajes
  private showModal2 = false;
  cambios = false;

  registerExists: boolean = false;
  objGeneral: any = {
      'idHistoriaClinicaGeneral': null,
      'idPaciente': null,
      'fechaCreacion': null,
      'version': 1.1,
      'vigencia': null,
      'ultimaModificacion': null,
      'activo': true,
      'creadoPor': 'sysAdmin', /// TODO: nombre de medico
      'hcg': {}
  };
  paciente: PatientView = new PatientView();
  @ViewChild('ClinicHistoryTabs') tabset: NzTabSetComponent;

  private searchCieTimeout: number;
  private optionsSearchCie: PageRequest = new PageRequest();
  // Array campos tabla personales patologicos
  private dialogStudiesFilterListEl: ElementRef;//cuadro de busqueda de estudio
  private fileDocInput: ElementRef;
    papanicolauFecha: Date;
    private documento: any;
    fechaInvalid = false;
    notas: boolean = false;

    fechaInvalidmamo = false;

    isEditingDescripcion = false;
    isExtensionValid = true;
    //para el cuadro de busqueda de estudio
    studiesFilterList: StudyJSON[] = [];
    private timeoutFitlerList: number;
    private pageSearchFilterList: PageRequest = new PageRequest();
    private isLastFromFilterList = false;
    searchStudiesFilterList: string;
    loadingStudiesFilterList = false;
    private selectedStudy: StudyJSON;
    dialogStudiesConfig = {
        autoOpen: false,
        closeText: '',
        draggable: false,
        resizable: false,
        modal: true,
        width: 400,
        classes: {
            'ui-dialog-titlebar': 'no-border',
        },
        position: {my: 'left top', at: 'left top', of: $('#txtEstudio')}
    };
  selectTipoDroga: string = '';
  // Tabla historia laboral
  empresaInput: string = '';
  puestoInput: string = '';
  antiguedadInput: string;

  agentesQuimicosInput: string;

  private selectedCie10: Cie10;
  searchCie10: string;
  cargandoCie: boolean = false;
  catCie10: Array<Cie10> = [];
  onlyConsultas: any[] = [];
  loadingDataTable: boolean = false;
  total: number = 0;
  pageIndex: number = 0;
  pageSize: number = 0;
  readOnly: boolean = false;
 

 private selectedCie9: Cie9;
 searchCie9: string;
 catCie9: Array<Cie9> = [];
 cargandoCie9: boolean = false;
 readOnly9: boolean = false;
 dateFormat = 'dd/MM/yyyy';



 formVacuna: FormGroup;
 trySubmitForm = false; //para la validacion
 esquemaVacunacion: VacunaView[] = [];


 catVacunas: CatVacunaView[];
 selectedDosis: VacunaDosisView = null;
 selectedVacuna: CatVacunaView = null;

 fechaAplicacion: Date = null;
 idpac ="";


 


  constructor( private pacienteService: PacienteService,   private router: Router,private authService: AuthNioService,
    private ciesService: CiesService,public hasPermissionService: HasPermissionService,
    private capitalizePipe: CapitalizePipe,private studiesService: StudiesService,private formBuilder:
     FormBuilder,private routeURL: ActivatedRoute,
                private userDetailsService: UserDetailsService,
                private activatedRoute: ActivatedRoute,
                private dataService: ShareDataService

    ) {

      this.formVacuna =
      this.formBuilder.group({
          selectedVacuna: ['', [Validators.required]],
          selectedDosis: ['', [Validators.required]],
          fechaAplicacion: ['', [Validators.required]],
      });



     }

  ngOnInit() { 
    console.log("neto al INIT");
    
    
    this.routeURL.queryParams.subscribe(params => {
        this.idpac = params.IdPaciente;
        if(!this.idpac){
          this.router.navigate(['login']);
        }
      });
      console.log("INIT PACIENTES");

            // Obtenemos los datos del usuario actual para mandarlo en el objeto
            let lc = localStorage.getItem("auth_token");
            const user = JSON.parse(lc);
            this.objGeneral.creadoPor = user.name;
         
            if (this.idpac) {
              this.pacienteService.getById(this.idpac).take(1)
                  .subscribe(
                      (patient) => {
                          if (!patient.idPaciente) return;
                          if (patient.idPaciente == this.idpac) {
                              this.paciente = patient;
                              // @ts-ignore
                              this.pacienteService.pacienteActual = this.paciente;
                          }
                      }
                  );
                this.objGeneral.idPaciente = this.idpac;
                this.pacienteService.getHistoriaCG(this.idpac)
                    .subscribe((historiaClinica) => {
                         this.registerExists = true;
                        if (historiaClinica) {
                            this.objGeneral = historiaClinica;
                            this.objGeneral.hcg = JSON.parse(this.objGeneral.hcg);
                        }
                    });
            }

  }


  navigateTabs(forward: boolean): void {
    if (forward && this.tabset.nzSelectedIndex < this.tabset.listOfNzTabComponent.length - 1) {
        this.tabset.nzSelectedIndex++;
    } else if (this.tabset.nzSelectedIndex > 0) {
        this.tabset.nzSelectedIndex--;
    }
}



subjectNext() {
  this.dataService.sendParameter('EMIT');
}

si() {
  this.showModal = false;
  this.cambios = true;
  this.guardar();
}


SaveAndNext(){

  if (this.hasPermissionService.canAccess('update.historia.clinica')) {
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosCarotideoPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosCarotideoRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosCarotideoSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosCarotideoIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo.intensidad;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosRadialPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.radial.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosRadialRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.radial.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosRadialSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.radial.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosRadialIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.radial.intensidad;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosBraquialPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.braquial.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosBraquialRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.braquial.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosBraquialSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.braquial.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosBraquialIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.braquial.intensidad;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosFemoralPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.femoral.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosFemoralRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.femoral.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosFemoralSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.femoral.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosFemoralIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.femoral.intensidad;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosPopitleoPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.popitleo.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosPopitleoRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.popitleo.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosPopitleoSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.popitleo.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosPopitleoIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.popitleo.intensidad;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosTibialPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.tibial.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosTibialRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.tibial.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosTibialSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.tibial.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosTibialIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.tibial.intensidad;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoenTiempo = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoEn.tiempo;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoenEspacio = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoEn.espacio;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoenPersona = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoEn.persona;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscularBrazoIzq = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscular.brazoDer;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscularBrazoDer = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscular.brazoIzq;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscularPiernaIzq = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscular.piernaIzq;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscularPiernaDer = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscular.piernaDer;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoBrazoizqEstado = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.brazoIzq.estado;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoBrazoizqComentario = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.brazoIzq.comentario;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoBrazoderEstado = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.brazoDer.estado;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoBrazoderComentario = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.brazoDer.comentario;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoPiernaizqEstado = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.piernaIzq.estado;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoPiernaizqComentario = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.piernaIzq.comentario;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoPiernaderEstado = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.piernaDer.estado;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoPiernaderComentario = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.piernaDer.comentario;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoCejasParpadoPestana = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.cejasParpadoPestana;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoConjuntivas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.conjuntivas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoConjuntivasDescripcion = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.conjuntivasDescripcion;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoPupilas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.pupilas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoReflejosPupulas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.reflejosPupulas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoMovimientos = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.movimientos;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoTonoOcular = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.tonoOcular;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoCejasParpadoPestana = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.cejasParpadoPestana;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoConjuntivas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.conjuntivas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoConjuntivasDescripcion = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.conjuntivasDescripcion;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoPupilas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.pupilas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoReflejosPupulas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.reflejosPupulas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoMovimientos = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.movimientos;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoTonoOcular = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.tonoOcular;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaTrismus = this.objGeneral.hcg.exploracionFisica.cabeza.boca.trismus;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaHalitosis = this.objGeneral.hcg.exploracionFisica.cabeza.boca.halitosis;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaMucosa = this.objGeneral.hcg.exploracionFisica.cabeza.boca.mucosa;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaMucosaTipo = this.objGeneral.hcg.exploracionFisica.cabeza.boca.mucosaTipo;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaCandidiasis = this.objGeneral.hcg.exploracionFisica.cabeza.boca.candidiasis;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaLengua = this.objGeneral.hcg.exploracionFisica.cabeza.boca.lengua;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeDolor = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.dolor;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeHiperemia = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.hiperemia;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeAmigdalas = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.amigdalas;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeAmigdalasTipo = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.amigdalasTipo;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeCuerpoExtrano = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.cuerpoExtrano;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeHemorragia = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.hemorragia;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaDientes = this.objGeneral.hcg.exploracionFisica.cabeza.boca.dientes;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaEnciasGingivorragia = this.objGeneral.hcg.exploracionFisica.cabeza.boca.encias.gingivorragia;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaEnciasGingivitis = this.objGeneral.hcg.exploracionFisica.cabeza.boca.encias.gingivitis;

      if (this.objGeneral.hasOwnProperty('idHistoriaClinicaGeneral') && parseInt(this.objGeneral.idHistoriaClinicaGeneral, 10)) {
          // put
              let id = this.objGeneral.idHistoriaClinicaGeneral;
              this.objGeneral.ultimaModificacion = (new Date()).getTime();
             this.pacienteService.putHistoriaCG(this.idPaciente, this.objGeneral)
              .subscribe();
      } else {
              this.objGeneral.fechaCreacion = (new Date()).getTime();
              this.objGeneral.ultimaModificacion = (new Date()).getTime();
              this.objGeneral.vigencia = (new Date()).getTime();
              this.objGeneral.hcg = JSON.parse(JSON.stringify(this.objGeneral.hcg));
              this.pacienteService.postHistoriaCG(this.objGeneral)
                  .subscribe(
                      () => {
                          // debugger;
                          this.guardando = false;
                          // Hacemos un GET inmediatamente para tener el objeto actualizado
                          this.pacienteService.getHistoriaCG(this.idPaciente).subscribe((hcg) => {
                              this.objGeneral = hcg;
                              this.objGeneral.hcg = JSON.parse(this.objGeneral.hcg);
                          });
                      });
      }


  }
console.log("HCG S&N");
console.log(this.objGeneral);
}

guardar() {
if (this.cambios && !this.showModal2) {
  if (this.hasPermissionService.canAccess('update.historia.clinica')) {

       console.log("HCG1");
       console.log(this.objGeneral.hcg.exploracionFisica.extremidades);
      // console.log(this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo.ritmo);
      // console.log(this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo);
      // console.log(this.objGeneral.hcg.exploracionFisica.extremidades.pulsos['carotideo']);
      // console.log(this.objGeneral.hcg.exploracionFisica.extremidades.pulsos['carotideo']['ritmo']);
      // console.log(this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo.presente);
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosCarotideoPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosCarotideoRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosCarotideoSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosCarotideoIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.carotideo.intensidad;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosRadialPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.radial.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosRadialRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.radial.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosRadialSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.radial.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosRadialIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.radial.intensidad;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosBraquialPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.braquial.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosBraquialRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.braquial.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosBraquialSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.braquial.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosBraquialIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.braquial.intensidad;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosFemoralPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.femoral.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosFemoralRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.femoral.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosFemoralSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.femoral.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosFemoralIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.femoral.intensidad;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosPopitleoPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.popitleo.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosPopitleoRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.popitleo.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosPopitleoSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.popitleo.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosPopitleoIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.popitleo.intensidad;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosTibialPresente=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.tibial.presente;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosTibialRitmo=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.tibial.ritmo;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosTibialSimetria=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.tibial.simetria;
      this.objGeneral.hcg.exploracionFisica.extremidades.pulsosTibialIntensidad=this.objGeneral.hcg.exploracionFisica.extremidades.pulsos.tibial.intensidad;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoenTiempo = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoEn.tiempo;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoenEspacio = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoEn.espacio;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoenPersona = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.orientadoEn.persona;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscularBrazoIzq = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscular.brazoDer;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscularBrazoDer = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscular.brazoIzq;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscularPiernaIzq = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscular.piernaIzq;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscularPiernaDer = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.fuerzaMuscular.piernaDer;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoBrazoizqEstado = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.brazoIzq.estado;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoBrazoizqComentario = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.brazoIzq.comentario;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoBrazoderEstado = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.brazoDer.estado;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoBrazoderComentario = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.brazoDer.comentario;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoPiernaizqEstado = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.piernaIzq.estado;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoPiernaizqComentario = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.piernaIzq.comentario;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoPiernaderEstado = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.piernaDer.estado;
      this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteoPiernaderComentario = this.objGeneral.hcg.exploracionFisica.sistemaNervioso.reflejoOsteo.piernaDer.comentario;


      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoCejasParpadoPestana = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.cejasParpadoPestana;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoConjuntivas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.conjuntivas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoConjuntivasDescripcion = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.conjuntivasDescripcion;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoPupilas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.pupilas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoReflejosPupulas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.reflejosPupulas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoMovimientos = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.movimientos;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdoTonoOcular = this.objGeneral.hcg.exploracionFisica.cabeza.ojoIzquierdo.tonoOcular;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoCejasParpadoPestana = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.cejasParpadoPestana;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoConjuntivas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.conjuntivas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoConjuntivasDescripcion = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.conjuntivasDescripcion;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoPupilas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.pupilas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoReflejosPupulas = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.reflejosPupulas;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoMovimientos = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.movimientos;
      this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerechoTonoOcular = this.objGeneral.hcg.exploracionFisica.cabeza.ojoDerecho.tonoOcular;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaTrismus = this.objGeneral.hcg.exploracionFisica.cabeza.boca.trismus;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaHalitosis = this.objGeneral.hcg.exploracionFisica.cabeza.boca.halitosis;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaMucosa = this.objGeneral.hcg.exploracionFisica.cabeza.boca.mucosa;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaMucosaTipo = this.objGeneral.hcg.exploracionFisica.cabeza.boca.mucosaTipo;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaCandidiasis = this.objGeneral.hcg.exploracionFisica.cabeza.boca.candidiasis;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaLengua = this.objGeneral.hcg.exploracionFisica.cabeza.boca.lengua;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeDolor = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.dolor;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeHiperemia = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.hiperemia;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeAmigdalas = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.amigdalas;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeAmigdalasTipo = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.amigdalasTipo;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeCuerpoExtrano = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.cuerpoExtrano;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaOrofaringeHemorragia = this.objGeneral.hcg.exploracionFisica.cabeza.boca.orofaringe.hemorragia;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaDientes = this.objGeneral.hcg.exploracionFisica.cabeza.boca.dientes;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaEnciasGingivorragia = this.objGeneral.hcg.exploracionFisica.cabeza.boca.encias.gingivorragia;
      this.objGeneral.hcg.exploracionFisica.cabeza.bocaEnciasGingivitis = this.objGeneral.hcg.exploracionFisica.cabeza.boca.encias.gingivitis;

      this.guardando = true;
      if (this.guardando) {
      }
      if (this.objGeneral.hasOwnProperty('idHistoriaClinicaGeneral') && parseInt(this.objGeneral.idHistoriaClinicaGeneral, 10)) {
      // put
          let id = this.objGeneral.idHistoriaClinicaGeneral;
          this.objGeneral.ultimaModificacion = (new Date()).getTime();
         this.pacienteService.putHistoriaCG(this.idPaciente, this.objGeneral)
          .subscribe(
                  () => {
                      this.guardando = false;
                  },
                  () => {
                      this.guardando = false;
                  },
                  () => {
                      this.guardando = false;
                      this.showModal2 = true;

                  }
              );
      } else {
          this.objGeneral.fechaCreacion = (new Date()).getTime();
          this.objGeneral.ultimaModificacion = (new Date()).getTime();
          this.objGeneral.vigencia = (new Date()).getTime();

          this.objGeneral.hcg = JSON.parse(JSON.stringify(this.objGeneral.hcg));

          this.pacienteService.postHistoriaCG(this.objGeneral)
              .subscribe(
                  () => {
                      // debugger;
                      this.guardando = false;
                      // Hacemos un GET inmediatamente para tener el objeto actualizado
                      this.pacienteService.getHistoriaCG(this.idPaciente).subscribe((hcg) => {
                          this.objGeneral = hcg;
                          this.objGeneral.hcg = JSON.parse(this.objGeneral.hcg);
                      });
                  },
                  () => {
                      this.guardando = false;
                  },
                  () => {
                      this.guardando = false;
                      this.showModal2 = true;
                  }
              );
      }
  }
}
console.log("HCG");
console.log(this.objGeneral);
}


}
