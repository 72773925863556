import {NgModule} from "@angular/core";
import {ConsultaLayoutSubheaderModule} from "./subheader/consulta.layout.subheader.module";
import {ConsultaLayoutHeaderComponent} from "./consulta.layout.header.component";
import {ConsultaListaService} from "../../+lista/consulta.lista.service";
import {ConsultaService} from "../../shared/consulta.service";
import {SmartadminModule} from "../../../shared/smartadmin.module";
import {NgZorroAntdModule} from "ng-zorro-antd";
import {PacienteFichaModule} from "../../../+paciente/+ficha/paciente.ficha.module";

@NgModule({
    imports: [
        SmartadminModule,
        NgZorroAntdModule.forRoot(),
        PacienteFichaModule,
        ConsultaLayoutSubheaderModule
    ],
    declarations: [ConsultaLayoutHeaderComponent],
    exports: [ConsultaLayoutHeaderComponent],
    providers: [
        ConsultaService,
        ConsultaListaService
    ]
})
export class ConsultaLayoutHeaderModule {
}
