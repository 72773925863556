import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginNioRoutingModule} from './login-nio-routing.module';
import {loginNioComponent} from './login-nio.component';
import {FormsModule} from '@angular/forms';
import {ShaService} from "../shared/jssha/jssha.service";

@NgModule({
    imports: [
        CommonModule,
        LoginNioRoutingModule,
        FormsModule
    ],
    declarations: [loginNioComponent],
    exports: [loginNioComponent],
    providers: [ShaService]
})
export class LoginNioModule {
}
