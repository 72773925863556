import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DiagnosticoHCG, ExploracionFisica} from '../../../../../shared/classes/common.classes';
import {Cie10} from '../../../../../shared/classes/cies.classes';
import {PageRequest} from '../../../../../common/http/page.request';
import {CiesService} from '../../../../../shared/services/cies.service';
import {CapitalizePipe} from "../../../../../shared/utils/capitalize.pipe";

@Component({
    selector: 'app-diagnostico',
    templateUrl: './diagnostico.component.html',
    styleUrls: ['../paciente.historia-clinica.component.css']
})
export class DiagnosticoComponent implements OnInit, OnChanges {
    @Input() private wholeObject: any = {};
    @Output() private wholeObjectChange: EventEmitter<ExploracionFisica> = new EventEmitter<ExploracionFisica>();

    private searchCieTimeout: number;
    private optionsSearchCie: PageRequest = new PageRequest();

    private selectedCie10: Cie10;
    searchCie10: string;
    catCie10: Array<Cie10> = [];
    cargandoCie: boolean = false;
    diagnosticoHCG: DiagnosticoHCG = new DiagnosticoHCG();
    readOnly: boolean = false;
    notas: boolean = false;

    constructor(private ciesService: CiesService,
                private capitalizePipe: CapitalizePipe) {
    }

    ngOnInit(): void {
        this.optionsSearchCie.page = 0;
        this.optionsSearchCie.size = 10;
        this.optionsSearchCie.orderType = "desc";
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('wholeObject')) {
            const value = changes['wholeObject'].currentValue;
            if (value && value.hasOwnProperty('diagnosticoHCG')) {
                this.diagnosticoHCG = value['diagnosticoHCG'];
            } else {
                this.wholeObject.diagnosticoHCG = this.diagnosticoHCG;
            }
        }

    }

    borrarCie10(cie10: Cie10): void {
        this.diagnosticoHCG.lstCie10 = this.diagnosticoHCG.lstCie10
            .filter((cie10) => cie10.catalogKey !== cie10.catalogKey);
    }

    addCie10(): void {
        if (this.selectedCie10 && this.selectedCie10.nombre) {
            this.selectedCie10.nombre = this.capitalizePipe.transform(this.selectedCie10.nombre);
            if (this.diagnosticoHCG.lstCie10.findIndex((cie10) => cie10.nombre.toLowerCase() == this.selectedCie10.nombre.toLowerCase()) < 0) {
                this.diagnosticoHCG.lstCie10 = [...this.diagnosticoHCG.lstCie10, this.selectedCie10];
                this.selectedCie10 = new Cie10();
                this.selectedCie10 = null;
                this.searchCie10 = null;

                setTimeout(() => this.sendData(), 300);
            }
        }
    }

    sendData(): void {
        //    Enviar el objeto
        this.wholeObject.diagnosticoHCG = this.diagnosticoHCG;
        this.wholeObjectChange.emit(this.wholeObject);
    }

    autocompleteCie(ev: KeyboardEvent, type: string): void {
        if (this.searchCieTimeout) clearTimeout(this.searchCieTimeout);
        if (ev.keyCode === 27 && this.selectedCie10) {
            this.catCie10 = [];
            if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
            } else {
                this.cargandoCie = true;
                this.ciesService.getCie10(this.selectedCie10.idCie10)
                    .subscribe((cie) => {
                        this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`;
                        this.cargandoCie = false;
                    }, () => {
                        this.cargandoCie = false;
                    }, () => {
                        this.cargandoCie = false;
                    });
            }
        } else {
            this.searchCieTimeout = window.setTimeout(
                () => {
                    let input: HTMLInputElement = <HTMLInputElement>ev.target;
                    input.onblur = null;
                    if (this.searchCie10) {
                        this.optionsSearchCie.orderColumn = "nombre";
                        this.cargandoCie = true;
                        this.optionsSearchCie.datosBusqueda = this.searchCie10;
                        this.ciesService.getPageCie10(this.optionsSearchCie)
                            .subscribe((pageCie10) => {
                                    this.catCie10 = pageCie10.content;
                                    if (this.catCie10.length) {
                                        if (ev.keyCode === 13) this.assignCie(this.catCie10[0], type);
                                    } else if (this.selectedCie10) {
                                        input.onblur = () => {
                                            input.onblur = null;
                                            if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                                                this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                                            } else {
                                                this.ciesService.getCie10(this.selectedCie10.idCie10)
                                                    .subscribe((cie) => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                                                        () => this.cargandoCie = false,
                                                        () => this.cargandoCie = false);
                                            }
                                        };
                                    }
                                },
                                () => this.cargandoCie = false,
                                () => this.cargandoCie = false);
                    } else {
                        this.catCie10 = [];
                        if (this.selectedCie10) {
                            input.onblur = () => {
                                input.onblur = null;
                                if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                                    this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                                } else {
                                    this.ciesService.getCie10(this.selectedCie10.idCie10)
                                        .subscribe((cie) => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                                            () => this.cargandoCie = false,
                                            () => this.cargandoCie = false);
                                }
                            };
                        }
                    }
                }, 300);
        }
    }

    assignCie(cie: Cie10, type?: string): void {
        this.selectedCie10 = cie;
        this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`;
        this.catCie10 = [];
    }

    forceSelectCie(ev: MouseEvent): void {
        let input: HTMLInputElement = <HTMLInputElement>ev.target;
        setTimeout(() => input.setSelectionRange(0, input.value.length), 50);
    }

}
