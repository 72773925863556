import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-iframe-dash',
  templateUrl: './iframe-dash.component.html',
  styleUrls: ['./iframe-dash.component.css']
})
export class IframeDashComponent implements OnInit {
  param1: string;
  param2: string;
  constructor(private route: ActivatedRoute) {
   }

   extdev_:boolean;
   ngOnInit() {
    if(environment.navext=="EXT_DEV") {
      this.extdev_=true;
    } else{
      this.extdev_=false;
    }
    this.param1 = this.route.snapshot.queryParamMap.get('param1');
    console.log(this.param1);
  }
  saludo(value) {
    console.log(value);
    
  }
  ngOnChange(){
    this.param1 = this.route.snapshot.queryParamMap.get('param1');
    console.log(this.param1);
  }
}
