import {NgModule} from "@angular/core";
import {NioPaginationComponent} from "./nio-pagination.component";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [CommonModule],
    declarations: [NioPaginationComponent],
    exports: [NioPaginationComponent]
})
export class NioPaginationModule {
}