import {Injectable} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {AuthNioService} from 'app/+login-nio/auth-nio.service';

/**
 * Created by gcuellar on 20/04/17.
 */
@Injectable()
export class HasPermissionService {

    constructor(private authService: AuthNioService) {
    }

    getPermissions() {
        return !isNullOrUndefined(this.authService.token) ? this.authService.token.roles : [];
    }

    /**
     *
     * @param Modules
     *      string with requested comma separated modules
     *      @format module_submodule
     * @returns {boolean}
     *      true if the user has permission of some requested modules
     */
    private hasPermission(permissions: string[]): boolean {
        return permissions.some((permission) => {
            let searchPattern: RegExp, hasPermission: boolean; // Variables to save the search pattern and a boolean if the permission was found
            const notPermissionFlag: boolean = (permission[0] === '!'); // Check if there is a not operator in the module (true/false)
            if (notPermissionFlag) {
                permission = permission.slice(1);
            }

            if (permission.trim().search(/ROLE_.*/) === -1) {
                searchPattern = new RegExp('\\bROLE_' + permission + '\\b');
            } else {
                searchPattern = new RegExp('\\b' + permission + '\\b');
            }

            hasPermission = !!this.getPermissions().find((permissioSearch) => (permissioSearch.search(searchPattern) !== -1));
            return hasPermission !== notPermissionFlag;
        });
    }

    /**
     *
     * @param permissionRequest
     *      string with requested comma separated modules
     *      @format module.permission, module.permission
     * @returns {boolean}
     *      true if the user has some requested permission
     */
    canAccess(permissionRequest: string): boolean {
        if (!!permissionRequest) {
            permissionRequest = permissionRequest.trim().replace(/\./g, '_'); // Replace dots (.) by underscore (_)
            let permissionsArray: string[] = permissionRequest.split(',');
            for (let i = 0; i < permissionsArray.length; i++) {
                permissionsArray[i] = permissionsArray[i].trim().toUpperCase();
            }

            if (permissionsArray.length) {
                return this.hasPermission(permissionsArray);
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    isLogged() {
        return !!this.authService.token;
    }
}
