
export class ErrorResponse {
    constructor(public code: string,
                public status: number,
                public message: string,
                public errorList: Array<string>,
                public error_description?: string,
                public error?: string) {

    }


    toString(): string{
        return JSON.stringify(this);
    }
}
