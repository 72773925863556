import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IframeComponent} from './iframe.component';
import {SmartadminModule} from "../shared/smartadmin.module";
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {ShaService} from "../shared/jssha/jssha.service";
import {JqueryUiModule} from "../shared/ui/jquery-ui/jquery-ui.module";
import {CatalogosService} from "../shared/services/catalogos.service";
import {ComunService} from "../shared/services/comun.service";
import {AuthNioService} from '../+login-nio/auth-nio.service';
import {UsersService} from '../+admin/+users/users.service';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {HeredoFamiliaresModule} from '../+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/heredo-familiares/heredo-familiares.module';
import {ExploracionFisicaModule} from '../+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/exploracion-fisica/exploracion-fisica.module';
import { ShareDataService } from '../+paciente/+detalle/consulta-actual/share-data.service';
import {SmartadminInputModule} from "../shared/forms/input/smartadmin-input.module";
import {StudiesService} from "../shared/services/studies.service";
import {ModalModule} from "ngx-bootstrap";
import { AntecedentesGinecoModule } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/antecedentes-gineco/antecedentes-gineco.module';
import { DiagnosticoModule } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/diagnostico/diagnostico.module';
import { EsquemaVacunacionModule } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/esquema-vacunacion/esquema-vacunacion.module';
import { EstudiosLaboratorioModule } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/estudios-laboratorio/estudios-laboratorio.module';
import { InterrogatorioModule } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/interrogatorio/interrogatorio.module';
import { PadecimientoActualModule } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/padecimiento-actual/padecimiento-actual.module';
import { PersonalesNoPatologicosModule } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/personales-no-patologicos/personales-no-patologicos.module';
import { PersonalesPatologicosModule } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/personales-patologicos/personales-patologicos.module';
import { TerapeuticaEmpleadaModule } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/terapeutica-empleada/terapeutica-empleada.module';

@NgModule({
  declarations: [
    IframeComponent,
    
   ],
  exports: [IframeComponent],
  providers: [
      CatalogosService,
      ComunService,
      ShaService,
      AuthNioService,
      UsersService,
      ShareDataService, StudiesService
  ],
imports: [
  SmartadminModule,
  ReactiveFormsModule,
  JqueryUiModule,
  NgxQRCodeModule,
  NgbModule,
  NgSelectModule,
  NgbPaginationModule, NgbAlertModule,
  NgZorroAntdModule.forRoot(),
  ModalModule.forRoot(),
  FormsModule,
  SmartadminInputModule,
  ExploracionFisicaModule,
  HeredoFamiliaresModule,
  PersonalesPatologicosModule
  ,PersonalesNoPatologicosModule
  ,PadecimientoActualModule
  ,InterrogatorioModule
  ,EstudiosLaboratorioModule
  ,DiagnosticoModule
  ,TerapeuticaEmpleadaModule
  ,EsquemaVacunacionModule
  ,AntecedentesGinecoModule
] 
})
export class IframeModule { }
