export class Medicine { //DetalleRecetaView
    idDetalleReceta?: number;
    cantidad: number;
    denominacionDistintiva: string;
    denominacionGenerica: string;
    dosis: string;
    frecuencia: string;
    indicacionesMedicas: string;
    periodo: string;
    unidad: string;
    viaAdministracion: string;
    presentacion: string;
    substancias: MedicineSubstance[];
}

export class MedicineProduct {
    ProductId: number;
    Brand: string; 
    CategotyId: number;
    CategoryName: string;
    DivisionId: number;
    DivisionName: string; 
    DivisionShortName: string;
    PharmaFormId: number;
    EnglishPharmaForm: string;
    PharmaForm: string;
    Presentations: MedicinePresentation[];
    Substances: MedicineSubstance[];
    ICDs: MedicineICD[];
}

export class MedicinePresentation {
    ContentUnitId: number;
    ContentUnitName: string;
    ExternalPackId: number;
    ExternalPackName: string;
    InternalPackId: number;
    InternalPackName: string;
    PresentationId: number;
    Presentation: string;
    PreserntationAveragePrice: string;
    QtyExternalPack: number;
    QtyContentUnit: string;
    QtyInternalPack: string;
    WeightUnitId: number;
    QtyWeightUnit: string;
    WeightShortName: string;
}

export class MedicineSubstance {
    ActiveSubstanceId: number;
    Active: boolean;
    Description: string;
    EnglishDescription: string;
}

export class MedicineSearch {
    Products: MedicineProduct[];
    Substances: MedicineSubstance[];
}

export class MedicineICD {
    ICDId: number;
    ICDKey: string;
    ICDSons: number;
    Active: boolean;
    EnglishDescription: string;
    SpanishDescription: string;
    ParentId: number;
}
