import { Injectable } from '@angular/core';
import {isNullOrUndefined} from 'util';
import {RequestMethod} from '@angular/http';

export class RequestConfig {
    public code: string;
    public contentType: string;
    public secure: Boolean;
    public method: RequestMethod;
    public uri: string;
    public download = false;
    constructor(code: string, contentType: string, secure: Boolean, method: RequestMethod, uri: string, download = false) {
        this.code = code;
        this.contentType = contentType;
        this.secure = secure;
        this.method = method;
        this.uri = uri;
        //console.log(this.uri);
        if (!isNullOrUndefined(download)) {
            this.download = download;
        }
    }

    toString(): string {
        return '{"code":"' + this.code + '", "contentType":"' + this.contentType +
        '", "secure":" ' + this.secure + '", "method":" ' + this.method + '", "uri":"' + this.uri + '"}';
    }
}




export class RestResponse {
    private _code: string;
    private _status: number;
    private _message: string;
    private _errorList: Array<string>;


    /**
     * @param codigo de respuesta
     * @param status HttpStatus 200,500
     * @param message Mensaje de servidor
     * @param errorList lista de errores
     * */
    constructor(code: string, status: number, message: string, errorList: Array<string>) {
        this._code = code;
        this._status = status;
        this._message = message;
        this._errorList = errorList;
    }


    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get status(): number {
        return this._status;
    }

    set status(value: number) {
        this._status = value;
    }

    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

    get errorList(): Array<string> {
        return this._errorList;
    }

    set errorList(value: Array<string>) {
        this._errorList = value;
    }

    toString(): string {
        return '{ "code":"' + this.code + '", "message":"' + this.message +
        '","status":"' + this.status + '","errorList":' + this.errorList.toString() + '}';
    }
}
