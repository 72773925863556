import {Medicine} from "./medicine.classes";



export class RecipeList{
    recipes: Recipe[] = [];
}

export class Recipe { //RecetaView
    idReceta?: string;
    activo: boolean;
    cadenaOriginal: string;
    cedulaMedico: string;
    cuidadosGenerales: string;
    curpPaciente: string;
    detalleRecetaViewList: Medicine[] = [];
    diagnosticoPaciente: string;
    domicilioViewList: RecipeAddress[] = [];
    edadPaciente: number;
    especialidadMedico: string;
    fechaCreacion: Date;
    medicoId: string;
    nombreMedico: string;
    nombrePaciente: string;
    numeroExpediente: number;
    numeroFolio: number;
    pacienteId: string;
    sello: string;
    sexoPaciente: string;
    universidadMedico: string;
    consultaId: number;
}

export class RecipeAddress { //DomicilioView
    idDomicilio?: number;
    calle: string;
    colonia: string;
    cp: string;
    email: string;
    estado: string;
    localidad: string;
    municipio: string;
    numeroExterior: string;
    numeroInterior: string;
    pais: string;
    personaId?: string;
    referencia: string;
    tipoUsuario: boolean;
}
