import {Injectable} from '@angular/core';
import {RequestConfig} from "../shared/rest/rest.request";
import {Headers, Http, RequestMethod, RequestOptions, URLSearchParams,Response} from "@angular/http";
import {ServicesSubject} from "../common/http/services.subject";
import {Evento, TipoEvento} from "./evento.classes";
import {CustomHttp} from "../common/http/custom.http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import {PageImpl} from "../common/http/PageImpl";
import {NiomedicPageRequest, NiomedicPageRequest2} from "../common/http/niomedic.page.request";
import { environment } from 'environments/environment';

@Injectable()
export class EventosService {
    private GET = new RequestConfig('Evento-Get', 'application/json', true, RequestMethod.Get, '/eventos/{idEvento}');
    private GET_PAGE = new RequestConfig('Evento-GetPage', 'application/json', true, RequestMethod.Get, '/eventos/search');
    private CREATE = new RequestConfig('Evento-Create', 'application/json', true, RequestMethod.Post, '/eventos');
    private MODIFY = new RequestConfig('Evento-Modify', 'application/json', true, RequestMethod.Put, '/eventos/modificar/{idEventos}');
    private SCHEDULE = new RequestConfig('Evento-Schedule', 'application/json', true, RequestMethod.Put, '/eventos/reagendar/{idEventos}?nuevaFecha={nuevaFecha}&nuevaFechaFin={nuevaFechaFin}');
    private CANCEL = new RequestConfig('Evento-Create', 'application/json', true, RequestMethod.Delete, '/eventos/cancelar/{idEventos}');
    

    private GET_EVENT_TYPE = new RequestConfig('EventoTipo-Get', 'application/json', true, RequestMethod.Get, '/catalogo-tipo-evento/{idCatalogoTipoEvento}');
    private GET_ALL_EVENT_TYPE = new RequestConfig('EventoTipo-GetAll', 'application/json', true, RequestMethod.Get, '/catalogo-tipo-evento/findAll');
    private ADD_EVENT_TYPE = new RequestConfig('EventoTipo-Add', 'application/json', true, RequestMethod.Post, '/catalogo-tipo-evento');
    private UPDATE_EVENT_TYPE = new RequestConfig('EventoTipo-Update', 'application/json', true, RequestMethod.Put, '/catalogo-tipo-evento/{idCatalogoTipoEvento}');

    private event: ServicesSubject<Evento> = new ServicesSubject();
    private eventType: ServicesSubject<TipoEvento> = new ServicesSubject();
    private allEventTypes: ServicesSubject<TipoEvento[]> = new ServicesSubject();

    constructor(private http: CustomHttp,private _http: Http) {
    }



    getEventos(url: string){
        let options: RequestOptions = new RequestOptions();
        options.headers = new Headers();
        options.method = RequestMethod.Get;
        options.headers.append('Content-Type', 'application/json');
        //options.headers.append('Authorization', tokentype + ' ' + token);
        var urls = environment.api_url+ url
        console.log(urls);
        
        return this._http.request(urls,options).pipe(map((res: Response) => res.json()))
      }

    getById(idEvento: number, refresh?: boolean): Observable<Evento> {
        if (this.event.needRefresh || refresh) {
            this.event.needRefresh = false;
            this.http.sendRequest(this.GET, null, idEvento)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.event.needRefresh = !this.event.data;
                    return this.http.handleError(error);
                })
                .subscribe((evento) => {
                    this.event.subject.next(evento);
                    this.event.data = evento;
                });
        } else if (this.event.data) {
            if (this.event.data.idEventos != idEvento) {
                delete this.event.data;
                this.event.subject.next(new Evento());
                this.getById(idEvento, true);
            }
        }

        return this.event.subject;
    }

    getPage(options: NiomedicPageRequest): Observable<PageImpl<Evento>> {
        return this.http.sendRequest(this.GET_PAGE, options)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    getPage2(options: NiomedicPageRequest2): Observable<Evento> {
        return this.http.sendRequest(this.GET_PAGE, options)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Agendar un evento
     * @param evento
     */
    create(evento: Evento): Observable<Evento> {
        return this.http.sendRequest(this.CREATE, evento)
            .map((res: Response) => res.json())
            .catch(error =>  Observable.throw(error));
    }

    /**
     * Re-agendar un evento
     * @param evento
     */
    schedule(evento: Evento): Observable<Boolean> {
        if (evento.idEventos) {
            return this.http.sendRequest(this.SCHEDULE, null, evento.idEventos, evento.inicio, evento.fin)
                .map((res: Response) => res.json())
                .catch(error => Observable.throw(error));
        }
    }


    modify(evento: Evento): Observable<Evento> {
        if (evento.idEventos) {
            return this.http.sendRequest(this.MODIFY, evento, evento.idEventos)
                .map((res: Response) => res.json())
                .catch(error => Observable.throw(error));
        } else {
            return this.create(evento);
        }
    }
    // /eventos/cancelar/{idEventos}

    cancel(idEventos: number): Observable<any> {
        return this.http.sendRequest(this.CANCEL, null, idEventos)
        .catch(error => this.http.handleError(error));
    }

    getEventTypeById(idTipoEvento: number, refresh?: boolean): Observable<TipoEvento> {
        if (this.eventType.needRefresh || refresh) {
            this.eventType.needRefresh = false;
            this.http.sendRequest(this.GET_EVENT_TYPE, null, idTipoEvento)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.eventType.needRefresh = !this.eventType.data;
                    return this.http.handleError(error);
                })
                .subscribe((eventType) => {
                    this.eventType.subject.next(eventType);
                    this.eventType.data = eventType;
                });
        } else if (this.eventType.data) {
            if (this.eventType.data.idTipoEvento != idTipoEvento) {
                delete this.eventType.data;
                this.eventType.subject.next(new TipoEvento());
                this.getById(idTipoEvento, true);
            }
        }

        return this.eventType.subject;
    }

    getEventTypes(refresh?: boolean): Observable<TipoEvento[]> {
        if (this.allEventTypes.needRefresh || refresh) {
            this.allEventTypes.needRefresh = false;
            this.http.sendRequest(this.GET_ALL_EVENT_TYPE)
                .map((res: Response) => {
                    let c = [];
                    c = res.json().filter( item => item.idTipoEvento !== 1);
                    return c;
                })
                .catch(error => {
                    this.allEventTypes.needRefresh = !this.allEventTypes.data;
                    return this.http.handleError(error)
                })
                .subscribe((eventTypes) => {
                    this.allEventTypes.subject.next(eventTypes);
                    this.allEventTypes.data = eventTypes;
                });
        }

        return this.allEventTypes.subject.asObservable();
    }

    addEventType(eventType: TipoEvento): Observable<Response> {
        return this.http.sendRequest(this.ADD_EVENT_TYPE, eventType)
            .catch(error => this.http.handleError(error));
    }

    updateEventType(eventType: TipoEvento): Observable<Response> {
        if (eventType.idTipoEvento) {
            return this.http.sendRequest(this.SCHEDULE, eventType, eventType.idTipoEvento)
                .catch(error => this.http.handleError(error));
        } else {
            return this.addEventType(eventType);
        }
    }
}
