import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoginInfoComponent} from "./login-info";
import {LogoutComponent} from "./logout/logout.component";
import {NmLoginInfoComponent} from './nm-login-info/nm-login-info.component';
import {SharedModule} from '../shared.module';
import {DoctorService} from '../../+doctor/doctor.service';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        LoginInfoComponent,
        LogoutComponent,
        NmLoginInfoComponent
    ],
    exports: [
        LoginInfoComponent,
        LogoutComponent
    ],
    providers: [DoctorService]
})
export class UserModule {
}
