import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { element } from 'protractor';
import {
    PantallaPersonalesNoPatologicos,
    PersonalesNoPatologicosAcDeportiva,
    PersonalesNoPatologicosHabAlimenticios,
    PersonalesNoPatologicosHabHigienicos, PersonalesNoPatologicosHistoriaLaboral,
    PersonalesNoPatologicosMascotas,
    PersonalesNoPatologicosTablaConsumo, PersonalesNoPatologicosTablaLaboral,
    PersonalesNoPatologicosVivienda
} from '../../../../../shared/classes/common.classes';

@Component({
    selector: 'app-personales-no-patologicos',
    templateUrl: './personales-no-patologicos.component.html',
    styleUrls: ['../paciente.historia-clinica.component.css'] 
})
export class PersonalesNoPatologicosComponent implements OnInit, OnChanges {
    noPatologicosVivienda: PersonalesNoPatologicosVivienda = new PersonalesNoPatologicosVivienda();
    noPatologicosMascotas: PersonalesNoPatologicosMascotas = new PersonalesNoPatologicosMascotas();
    noPatologicosDeportiva: PersonalesNoPatologicosAcDeportiva = new PersonalesNoPatologicosAcDeportiva();
    noPatologicosAlimenticios: PersonalesNoPatologicosHabAlimenticios = new PersonalesNoPatologicosHabAlimenticios();
    noPatologicosHabHigienicos: PersonalesNoPatologicosHabHigienicos = new PersonalesNoPatologicosHabHigienicos();
    noPatologicosHistoriaLaboral: PersonalesNoPatologicosHistoriaLaboral = new PersonalesNoPatologicosHistoriaLaboral();
    // Objeto general
    pantallaPersonalesNoPatologicos: PantallaPersonalesNoPatologicos = new PantallaPersonalesNoPatologicos();
    // Array de tipo tabla no patologicos consumo
    arrayCamposTNPC: PersonalesNoPatologicosTablaConsumo[];
    arrayCamposTNPHL: PersonalesNoPatologicosTablaLaboral[];
    // Variables privadas que se emplean para el llenado de las tablas dinamicas
    // Tabla consumo
    selectTipoDroga: string = '';
    // Tabla historia laboral
    empresaInput: string = '';
    puestoInput: string = '';
    antiguedadInput: string;
    notas: boolean = false;

    agentesQuimicosInput: string;
    @Input() wholeObject: any = {};
    @Output() wholeObjectChange = new EventEmitter<PantallaPersonalesNoPatologicos>();

    constructor() {
    }

    ngOnInit() {
        this.arrayCamposTNPC = [
            new PersonalesNoPatologicosTablaConsumo('Alcohol'),
            new PersonalesNoPatologicosTablaConsumo('Cigarro'),
            new PersonalesNoPatologicosTablaConsumo('Café')
        ];
        this.arrayCamposTNPHL = [];
        this.llenarObjeto();
    }

    agregarDroga() {
        if (this.selectTipoDroga && this.selectTipoDroga !== '') {
            // this.coincidencias(this.selectTipoDroga);
            if (this.arrayCamposTNPC.findIndex((campoTNCP) => campoTNCP.nombreDroga.toLowerCase() == this.selectTipoDroga.toLowerCase()) < 0) {
            const nuevo = new PersonalesNoPatologicosTablaConsumo(this.selectTipoDroga);
            this.arrayCamposTNPC.push(nuevo);
            this.selectTipoDroga = '';
            setTimeout(() => {
                this.llenarObjeto();
            }, 300);
        }
    }
    }
    llenarObjeto() {
        if (!this.noPatologicosDeportiva.acDeportivaSi && !this.noPatologicosDeportiva.acDeportivaNo) {
            this.noPatologicosDeportiva.acDeportivaNo = true; 
        }
       
        
        this.pantallaPersonalesNoPatologicos.personalesNoPatologicosVivienda = this.noPatologicosVivienda;
        this.pantallaPersonalesNoPatologicos.personalesNoPatologicosMascotas = this.noPatologicosMascotas;
        this.pantallaPersonalesNoPatologicos.personalesNoPatologicosAcDeportiva = this.noPatologicosDeportiva;
        this.pantallaPersonalesNoPatologicos.personalesNoPatologicosHabAlimenticios = this.noPatologicosAlimenticios;
        this.pantallaPersonalesNoPatologicos.personalesNoPatologicosHabHigienicos = this.noPatologicosHabHigienicos;
        this.pantallaPersonalesNoPatologicos.PersonalesNoPatologicosTablaConsumo = this.arrayCamposTNPC;
        this.pantallaPersonalesNoPatologicos.personalesNoPatologicosHistoriaLaboral = this.noPatologicosHistoriaLaboral;
        this.pantallaPersonalesNoPatologicos.personalesNoPatologicosTablaLaboral = this.arrayCamposTNPHL;
        this.wholeObject.pantallaPersonalesNoPatologicos = this.pantallaPersonalesNoPatologicos;
        this.wholeObjectChange.emit(this.wholeObject);
    }

    agregarHistoriaLaboral() {
        if (this.empresaInput.length > 0 && this.puestoInput.length > 0 && this.antiguedadInput && this.agentesQuimicosInput) {
            const nuevoLaboral = new PersonalesNoPatologicosTablaLaboral(this.empresaInput, this.puestoInput, this.antiguedadInput, this.agentesQuimicosInput);
            this.arrayCamposTNPHL.push(nuevoLaboral);
            this.empresaInput = '', this.puestoInput = '', this.antiguedadInput = null, this.agentesQuimicosInput = '';
            setTimeout(() => {
                this.llenarObjeto();
            }, 300);
        }
    }

    borrarHistoriaLaboral(historiaLaboral: any) {
        // Eliminamos un elemento del array

        this.arrayCamposTNPHL.splice(this.arrayCamposTNPHL.indexOf(historiaLaboral), 1);
        setTimeout(() => {
            this.llenarObjeto();
        }, 300);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('wholeObject')) {
            const value = changes['wholeObject'].currentValue;
            if (value && value.hasOwnProperty('pantallaPersonalesNoPatologicos')) {
                this.pantallaPersonalesNoPatologicos = value['pantallaPersonalesNoPatologicos'];
                this.noPatologicosVivienda = this.pantallaPersonalesNoPatologicos.personalesNoPatologicosVivienda || new PersonalesNoPatologicosVivienda();
                this.noPatologicosMascotas = this.pantallaPersonalesNoPatologicos.personalesNoPatologicosMascotas || new PersonalesNoPatologicosMascotas();
                this.noPatologicosDeportiva = this.pantallaPersonalesNoPatologicos.personalesNoPatologicosAcDeportiva || new PersonalesNoPatologicosAcDeportiva();
                this.noPatologicosAlimenticios = this.pantallaPersonalesNoPatologicos.personalesNoPatologicosHabAlimenticios || new PersonalesNoPatologicosHabAlimenticios();
                this.noPatologicosHabHigienicos = this.pantallaPersonalesNoPatologicos.personalesNoPatologicosHabHigienicos || new PersonalesNoPatologicosHabHigienicos();
                this.noPatologicosHistoriaLaboral = this.pantallaPersonalesNoPatologicos.personalesNoPatologicosHistoriaLaboral || new PersonalesNoPatologicosHistoriaLaboral();
                this.arrayCamposTNPC = this.pantallaPersonalesNoPatologicos.PersonalesNoPatologicosTablaConsumo || this.arrayCamposTNPC;
                this.arrayCamposTNPHL = this.pantallaPersonalesNoPatologicos.personalesNoPatologicosTablaLaboral || this.arrayCamposTNPHL;
            } else {
                this.wholeObject.pantallaPersonalesNoPatologicos = this.pantallaPersonalesNoPatologicos;
            }
        }
    }

    currentValue() {
        if (this.noPatologicosMascotas.mascotasNo) {
            this.noPatologicosMascotas.mascotasSi = true;
            this.noPatologicosMascotas.mascotasNo = false;
        } else {
            this.noPatologicosMascotas.mascotasSi = false;
            this.noPatologicosMascotas.mascotasNo = true;
        }
    }
}
