import {Injectable} from '@angular/core';
import {RequestMethod, Response} from '@angular/http';
import {RequestConfig} from '../../shared/rest/rest.request';
import {CustomHttp} from '../../common/http/custom.http';
import {ConsultaView, EstadoConsulta, EstatusConsulta, CanalConsulta} from '../shared/consulta.classes';

@Injectable()
export class ConsultaListaService {
    private GET_CONSULTA = new RequestConfig('GCONSULT', 'application/json', true, RequestMethod.Get, '/consulta/findAll');
    private rGetTipoConsulta = new RequestConfig('LC-GTC', 'application/json', true, RequestMethod.Get, '/cat-tipo-consulta');
    private rGetEstatusConsulta = new RequestConfig('LC-GESC', 'application/json', true, RequestMethod.Get, '/cat-estatus-consulta');
    private rGetEstadoConsulta = new RequestConfig('LC-GEC', 'application/json', true, RequestMethod.Get, '/cat-estado-consulta');
    private rPutConsulta = new RequestConfig('LC-PC', 'application/json', true, RequestMethod.Put, '/consulta/{idPaciente}');

    private _consultaSelected: ConsultaView;

    public catTipoConsulta: CanalConsulta[] = [];
    public catEstatusConsulta: EstatusConsulta[];
    public catEstadoConsulta: EstadoConsulta[];

    constructor(private http: CustomHttp) {
        this.catEstatusConsulta = [
            {
                'id': 1,
                'descripcion': 'No Iniciada'
            },
            {
                'id': 2,
                'descripcion': 'Consulta en Turno'
            },
            {
                'id': 3,
                'descripcion': 'Cancelada'
            },
            {
                'id': 4,
                'descripcion': 'Finalizada'
            }
        ];

        this.catEstadoConsulta = [
            {
                'id': 1,
                'descripcion': 'Inicial'
            },
            {
                'id': 2,
                'descripcion': 'Sub secuente'
            }
        ];
    }

    getConsultas(): any {
        return this.http.sendRequest(this.GET_CONSULTA)
            .map(
                (res): Response => {
                    const x  = res.json();
                    return x
                }
            )
    }

    getConsultaSelected(): ConsultaView {
        return this._consultaSelected;
    }

    setConsultaSelected(value: ConsultaView) {
        this._consultaSelected = value;
    }

    putConsulta (c: ConsultaView) {
        return this.http.sendRequest(this.rPutConsulta, c, c.idPaciente)
            .map((res): Response => { return res })
    }

}
