

export class PageImpl<T> {
    content: Array<T> = [];
    public first: boolean;
    public last: boolean;
    public number: number;
    public numberOfElements: number;
    public size: number;
    public totalElements: number;
    public totalPages: number;
}
