import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Headers, Http, RequestMethod, RequestOptions, URLSearchParams} from '@angular/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {RequestConfig} from '../shared/rest/rest.request';
import {Subject} from 'rxjs/Subject';
import {Login, TokenManager} from "./auth-nio.classes";

@Injectable()
export class AuthNioService {
    private LOG_OUT: RequestConfig = new RequestConfig('AC003', 'application/json', true, RequestMethod.Post, '/users/secure/logout');

    private _token: TokenManager;
    public _refreshing: Subject<TokenManager> = new Subject<TokenManager>();
    public _isTokenRefreshing = false;

    constructor(private router: Router,
                private http: Http) {
        this._token = <TokenManager>JSON.parse(localStorage.getItem('auth_token'));
        if (!this._token) {
            console.warn(`Token from local storage was null`);
            this.loadToken(null);
            this.router.navigate(['login']);
        }
    }

    isTokenValid(): boolean {
        return !!this._token && this._token.expires_in > new Date().getTime();
    }

    get token(): TokenManager {
        return this._token;
    }

    loadToken(value: TokenManager): TokenManager {
        this._token = value;
        if (value === null) {
            localStorage.removeItem("token");
            localStorage.removeItem('auth_token');
            localStorage.removeItem("nio-userdetail");
            localStorage.removeItem("id_select_group");
            localStorage.removeItem("group_name_selected");
            localStorage.removeItem("image_group_selected");
            localStorage.removeItem("idprincipalrol");
        } else {
            this._token.expires_in = (new Date().getTime() + ((this._token.expires_in - 1) * 1000));
            localStorage.setItem('auth_token', JSON.stringify(this._token));
            if(this._token.id_grupo_principal !=null && this._token.nombre_grupo_principal!=null)
            {
            localStorage.setItem("group_name_selected",this._token.nombre_grupo_principal);
            localStorage.setItem("id_select_group",''+this._token.id_grupo_principal);
            localStorage.setItem("image_group_selected",this._token.imagen_grupo_principal);
            }
            localStorage.setItem('idprincipalrol',''+this._token.idTipoUsuario);
        }

        return this._token;
    }

    login(login: Login): Observable<TokenManager> {
        let body: URLSearchParams = new URLSearchParams();
        body.append('grant_type', 'password');
        body.append('client_id', environment.client_id);
        body.append('client_secret', environment.client_secret);
        body.append('username', login.username);
        body.append('password', login.password);
        let options: RequestOptions = new RequestOptions();
        options.headers = new Headers();
        options.headers.append('Content-Type', 'application/x-www-form-urlencoded');
        // sre print
        //alert('Contacto login a ' + environment.oauth_server);
        return this.http.post(environment.oauth_server, body, options).map(res => this.loadToken(res.json()));
    }

    refreshToken(): Observable<TokenManager> {
        if (!this._isTokenRefreshing) {
            this._isTokenRefreshing = true;
            let body: URLSearchParams = new URLSearchParams();
            body.append('grant_type', 'refresh_token');
            body.append('client_id', environment.client_id);
            body.append('client_secret', environment.client_secret);
            body.append('refresh_token', this._token.refresh_token);
            let options: RequestOptions = new RequestOptions();
            options.headers = new Headers();
            options.headers.append('Content-Type', 'application/x-www-form-urlencoded');
            return this.http.post(environment.oauth_server, body, options)
                .map(res => {
                    let token = this.loadToken(res.json());
                    this._isTokenRefreshing = false;
                    this._refreshing.next(token);
                    return token;
                }).catch(err => {
                    if (!!JSON.parse(err._body) && !!JSON.parse(err._body).error && JSON.parse(err._body).error === "invalid_grant") {
                        err.status = 401;
                    }
                    this._isTokenRefreshing = false;
                    this._refreshing.error(err);
                    this.logout(true);
                    return Observable.throw(err);
                });
        } else {
            return this._refreshing.asObservable();
        }
    }

    logout(replaceURL: boolean = false) {
        console.log(this._token);
         let isPatientaut;

         if(this._token.idTipoUsuario==3){
            isPatientaut = true;
         }else{
            isPatientaut =false;
         }

        if (!this._token) {
            this.loadToken(null);
            this.router.navigate(['login'], {replaceUrl: replaceURL});
        } else {
            let options: RequestOptions = new RequestOptions();
            options.headers = new Headers();
            options.headers.append('Content-Type', this.LOG_OUT.contentType);
            options.headers.append('Authorization', this._token.token_type + ' ' + this._token.access_token);

            this.http.post(environment.api_url + this.LOG_OUT.uri, {}, options)
                .subscribe(() => {
                    this.loadToken(null);
                    if(isPatientaut){
                        this.router.navigate(['login'], {replaceUrl: replaceURL}).then(() => {
                                                window.location.reload();});
                    }else{
                        this.router.navigate(['login'], {replaceUrl: replaceURL});
                    }
                }, () => {
                    this.loadToken(null);
                    if(isPatientaut){
                        this.router.navigate(['login'], {replaceUrl: replaceURL}).then(() => {
                                                window.location.reload();});
                    }else{
                        this.router.navigate(['login'], {replaceUrl: replaceURL});
                    }
                     //this.router.navigate(['login'], {replaceUrl: replaceURL});
                });
        }
    }
}
