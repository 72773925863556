// TODO startDate and endDate should be Date type but request to backend requires string by now
export class PageRequest {
    active: boolean;
    name: string;
    page: number;
    size: number;
    selectGroup?: number;
    orderColumn: string;
    orderType: string;
    startDate?: Date | number;
    endDate?: Date | number;
    datosBusqueda?: string | Date;
    titulo?: string;
}
export class PageRequestCanalizado {
    active: boolean;
    name: string;
    page: number;
    size: number;
    selectGroup?: number;
    orderColumn: string;
    orderType: string;
    startDate?: Date | number;
    endDate?: Date | number;
    datosBusqueda?: string | Date;
    titulo?: string;
    idUser:number;
}
export class PageRequestAtendidos {
    active: boolean;
    name: string;
    page: number;
    size: number;
    selectGroup?: number;
    orderColumn: string;
    orderType: string;
    startDate?: Date | number;
    endDate?: Date | number;
    datosBusqueda?: string | Date;
    titulo?: string;
    idUser:number;
}
export class CluesRequest {
    fkEntidad: string;
    claveInstitucion: string;
}
