import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PadecimientoActual, PantallaPersonalesNoPatologicos} from '../../../../../shared/classes/common.classes';

@Component({
  selector: 'app-padecimiento-actual',
  templateUrl: './padecimiento-actual.component.html',
  styleUrls: ['../paciente.historia-clinica.component.css']
})
export class PadecimientoActualComponent implements OnInit, OnChanges {
  @Input() wholeObject: any = {};
  @Output() wholeObjectChange = new EventEmitter<PadecimientoActual>();
  notas: boolean = false;

  padecimientoActual: PadecimientoActual;
  constructor() {
    this.padecimientoActual = new PadecimientoActual();
  }

  ngOnInit() {
  }

  sendData() {
    this.wholeObject.padecimientoActual = this.padecimientoActual;
    this.wholeObjectChange.emit(this.wholeObject);
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('wholeObject')) {
      const value = changes['wholeObject'].currentValue;
      if (value  && value.hasOwnProperty('padecimientoActual')) {
        this.padecimientoActual = value['padecimientoActual'];
      } else {
        this.wholeObject.padecimientoActual = this.padecimientoActual;
      }
    }
  }

}
