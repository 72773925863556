import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, Renderer2, OnInit } from '@angular/core';
import {isNullOrUndefined} from 'util';
import {ConsultaService} from "../../../shared/consulta.service";
import {NotificationService} from "../../../../shared/utils/notification.service";
import {HasPermissionService} from '../../../../shared/haspermission/hasPermission.service';
import {ConsultaView} from "../../../shared/consulta.classes";
import {I18nService} from "../../../../shared/i18n";
import {CatEstadoConsultaView} from "../../../../shared/services/catalogos.service";
import {ShareDataService} from '../../../../+paciente/+detalle/consulta-actual/share-data.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-sub-header-consulta',
    templateUrl: './consulta.layout.subheader.component.html',
    styleUrls: ['./consulta.layout.subheader.component.css']
})
export class ConsultaLayoutSubheaderComponent implements OnChanges, OnInit {
    @ViewChild("buttonFinalizarConsulta") myButton: ElementRef;
    @Input() _nowConsulta: ConsultaView = new ConsultaView;
    private idConsulta: number;
    private estadoConsultaActual: CatEstadoConsultaView = new CatEstadoConsultaView();

    catEstatusConsulta: Map<string, number> = new Map();
    isSubsecuente: boolean = false;
    private returnConsult: boolean = false;
    updating: boolean = false;

    cancelDialogOptions = {
        autoOpen: false,
        resizable: false,
        modal: true,
        buttons: [{
            html: this.i18n.getTranslation('Accept'),
            click: () => {
                // console.log(this._nowConsulta, 'o yes');
                this.cancelaConsulta();
                (<any>$('#cancel-dialog')).dialog("close");
            }
        }, {
            html: this.i18n.getTranslation('Cancel'),
            click: () => {
                (<any>$('#cancel-dialog')).dialog("close");
            }
        }]
    };

    constructor(private consultaService: ConsultaService,
                private notificationService: NotificationService,
                private hasPermissionService: HasPermissionService,
                private shareDataService: ShareDataService,
                private i18n: I18nService,
                private renderer: Renderer2,
                private router: Router) {
        this.i18n.subscribe(() => {
            delete this.cancelDialogOptions;
            setTimeout(() => {
                this.cancelDialogOptions = {
                    autoOpen: false,
                    resizable: false,
                    modal: true,
                    buttons: [{
                        html: this.i18n.getTranslation('Accept'),
                        click: () => {
                           //  console.log(this._nowConsulta, 'skajsak');
                            this.cancelaConsulta();
                            (<any>$('#cancel-dialog')).dialog("close");
                        }
                    }, {
                        html: this.i18n.getTranslation('Cancel'),
                        click: () => {
                            (<any>$('#cancel-dialog')).dialog("close");
                        }
                    }]
                };
            }, 200);
        }, null);
    }
    ngOnInit(): void {}
    

    ngOnChanges(changes: SimpleChanges): void {
        for (let propName in changes) {
            if (propName === '_nowConsulta') {
                if (!isNullOrUndefined(this._nowConsulta)) {
                    if (this._nowConsulta.idConsulta != this.idConsulta) {
                        this.consultaService.get(this._nowConsulta.idConsulta)
                            .subscribe((consulta) => {
                                if (!consulta.idConsulta) return;
                                if (consulta.idConsulta == this._nowConsulta.idConsulta) {
                                    this._nowConsulta = consulta;
                                    // console.log('que pedo', this._nowConsulta);
                                    this.idConsulta = this._nowConsulta.idConsulta;
                                    this.isSubsecuente = (this._nowConsulta.tipoConsulta === 'Sub secuente');
                                    if (this._nowConsulta.idConsulta) this.loadStatusConsulta();
                                }
                            });
                    }
                }
            }
        }
    }

    private loadStatusConsulta(): void {
        if (this._nowConsulta) {
            this.consultaService.getEstatusConsulta()
                .subscribe((catEstatusConsulta) => {
                    catEstatusConsulta.forEach((catEstatus) => {
                        this.catEstatusConsulta.set(
                            catEstatus.descripcion
                                .replace(/\b\w/g, wordFirstChar => wordFirstChar.toLocaleUpperCase())
                                .replace(/\s/g, ''),
                            catEstatus.id
                        );
                    });
                });
        }
    }

    iniciaConsulta(): void {
        if (this.hasPermissionService.canAccess('start.consulta, update.consulta')) {
            if (!this.updating) {
                /*
                * 3 caminos posibles
                * 1** el doctor creo la consulta y la quiere iniciar de inmediato
                * 5** la consulta se confirmo en en rewcepcion pero no paso con la enfernera por signos viatales
                 * 7 la enfermera si registro signos vitales y por ende paso a sala de espera para que el dcotor la pasara a consulta iniciada*/
                if (this._nowConsulta.idEstadoConsulta == 1 || this._nowConsulta.idEstadoConsulta == 5 || this._nowConsulta.idEstadoConsulta == 7) {
                    this.updating = true;
                    this.consultaService.iniciarConsulta(this._nowConsulta)
                        .subscribe(() => {
                            this._nowConsulta.fechaInicio = (new Date());
                            this._nowConsulta.idEstadoConsulta = 2;
                            this._nowConsulta.estadoConsulta = 'Consulta en Turno';
                            this.consultaService.repeatGet(this._nowConsulta.idConsulta);

                            this.updating = false;
                        }, () => {
                            this.notificationService.bigBox({
                                title: 'Error al iniciar',
                                content: `No se pudo iniciar la consulta`,
                                color: '#C46A69',
                                icon: 'fa fa-warning shake animated',
                                timeout: 6000
                            });

                            this.updating = false;
                        });
                }
            }
        }
    }

    tieneCie10(): void {
        // Ahora primero tenemos que validar la ruta para saber si esta en el detalle de la consulta
        // o en alguno de los submenus
        let str = this.router.url.toString().substring(this.router.url.toString().length - 1, this.router.url.toString().length);
        // true si no es entero
        if (isNaN(parseInt(str, 10))) {
            // subject a la vista
            this.returnConsult = true;
            this.shareDataService.sendParameterBoton(true);
            setTimeout(() => {
                this.showCies();
            }, 500);
        } else {
            this.showCies();
        }
    }
    showCies() {
       this.returnConsult = false;
        if (this._nowConsulta.listaPadecimiento.length) {
            (<any>$('#modalTerminarConsula')).modal('show');
        } else {
        (<any>$('#agregarCies')).modal('show');
        }
    }

    agregaCieDefault(): void {
        //enviare un boolean a modo de decir quiero agregar padecimiento default
        this.shareDataService.sendParameter(true);
        (<any>$('#modalTerminarConsula')).modal('show');
        (<any>$('#agregarCies')).modal('hide');
    }

    enviarADiagnosticos(): void {
        (<any>$('#agregarCies')).modal('hide');
        this.consultaService.activeTabSOAP = 2;
    }

    private cancelaConsulta(): void {
        // No esta actualizado el objeto al igual que en curso clinico y se tomn los valores anteriores
        // console.log(this._nowConsulta.idConsulta, this.idConsulta, 'antes de ');
        if (this.hasPermissionService.canAccess('cancel.consulta, update.consulta')) {
            if (!this.updating) {
                if (this._nowConsulta.idEstadoConsulta == this.catEstatusConsulta.get('ConsultaEnTurno') || this._nowConsulta.idEstadoConsulta == this.catEstatusConsulta.get('NoIniciada')) {
                    this.updating = true;
                    this.consultaService.getEstadoConsultaActual(3).subscribe((estadoConsultaActual: CatEstadoConsultaView) => {
                        this.estadoConsultaActual = estadoConsultaActual;
                        this.consultaService.cancelarConsulta(this._nowConsulta, this.estadoConsultaActual)
                            .subscribe(() => {
                                this._nowConsulta.fechaCancelacion = (new Date());
                                this._nowConsulta.idEstadoConsulta = 3;
                                this._nowConsulta.estadoConsulta = 'Cancelada';
                                this.consultaService.repeatGet(this._nowConsulta.idConsulta);

                                this.updating = false;
                            }, () => {
                                this.notificationService.bigBox({
                                    title: 'Error al cancelar',
                                    content: `No se pudo cancelar la consulta`,
                                    color: '#C46A69',
                                    icon: 'fa fa-warning shake animated',
                                    timeout: 6000
                                });

                                this.updating = false;
                            });
                    });
                }
            }
        }
    }
}
