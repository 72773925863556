import {GroupView} from "../+groups/group.classes";

export class User {
    public idUserApp: number;
    public profileId: number;
    public permissionsList: Array<number> = [];
    public name: string;
    public username: string;
    public email: string;
    public groupList: Array<number> = [];
    public groups: Array<GroupView> = [];
    public password: string;
    public password2: string;
    public password3: string;
    public telefono: string;
    public extraList: Array<ExtraInfo> = [];
    public status: string;
    public idWorkShift: number;
    public imageProfile: ImageProfile;
    public connectionStatus: string;
    public idUsersAssigned: Array<number> = [];
    public infoUsers: Array<any> = [];
    idTipoUsuario: number;
    public profileName: string;
    public grupoPrincipal: number;
}
export class UserResetPassword{
    public idUserApp : number;
    public password: string;
    public newPassword: string;
}
export class UserchangePassword{
    public username : string;
    public password: string;
    public oldPassword: string;
    public newPassword: string;
    public newPassword2: string;
    public newPassword3: string;
}
export class UserResetpostPassword{
    public username : string;
    public password: string;
    public hash: string;
}

export class ExtraInfo {
    public userExtraInfoId: number;
    public key: string;
    public value: string;
    public status: string = 'ACTIVO';
}

export class ImageProfile {
    contentType: string;
    createdDate: string;
    encodedImageContent: string;
    encodedImageContentSmall: string;
    imageName: string;
    userImageId: 0
}

export class ByProfile {
    idProfile: number;
    idGroups: Array<number> = [];
}

export class UserRequestView {
    public name: string;
    public page: number;
    public size: number;
    public orderColumn: string;
    public orderType: string;
    public idGroups: Array<number> = [];
}
