import {NgModule} from "@angular/core";
import {ConsultaLayoutComponent} from "./consulta.layout.component";
import {ConsultaLayoutFooterModule} from "./footer/consulta.layout.footer.module";
import {ConsultaLayoutHeaderModule} from "./header/consulta.layout.header.module";
import {SmartadminModule} from "../../shared/smartadmin.module";

@NgModule({
    imports: [
        SmartadminModule,
        ConsultaLayoutHeaderModule,
        ConsultaLayoutFooterModule
    ],
    declarations: [ConsultaLayoutComponent]
})
export class ConsultaLayoutModule {
}
