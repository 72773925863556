import {Injectable} from '@angular/core';
import {CustomHttp} from "../../common/http/custom.http";
import {RequestConfig} from "../rest/rest.request";
import {RequestMethod, Response} from "@angular/http";
import {Observable} from "rxjs";
import {Recipe} from "../classes/recipe.classes";

@Injectable()
export class RecipesService {
    private GET_BY_CONSULTA = new RequestConfig('Recipes-GetByConsulta', 'application/json', true, RequestMethod.Get, '/receta/por-consulta/{idConsulta}');
    private CREATE = new RequestConfig('Recipes-Create', 'application/json', true, RequestMethod.Post, '/receta');
    private UPDATE = new RequestConfig('Recipes-Update', 'application/json', true, RequestMethod.Put, '/receta/{idRecipe}');
    private DELETE = new RequestConfig('recete-Delete', 'application/json', true, RequestMethod.Delete, '/receta/{idReceta}');
    constructor(private http: CustomHttp) {
    }
 
    /**
     * Plan terapéutico y tratamiento consultar Receta
     * HTTP Method: GET
     * MS: 73.249.23.243:9119/
     * EP: /receta/por-consulta/{consultaId}
     * Body params: NULL
     * @param idConsulta
     */
    getByConsulta(idConsulta: number): Observable<Recipe> {
        return this.http.sendRequest(this.GET_BY_CONSULTA, null, idConsulta)
            .map((res: Response) => res.json())
            //.catch(error => { }); /*this.http.handleError(error)*/
    }

    /**
     * Plan terapéutico y tratamiento generar Receta
     * HTTP Method: POST
     * MS: 73.249.23.243:9119/
     * EP: /receta
     * Body params: receta
     * @param receta
     */
    create(recipe: Recipe): Observable<Recipe> {
        return this.http.sendRequest(this.CREATE, recipe)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Plan terapéutico y tratamiento actualizar Receta
     * HTTP Method: PUT
     * MS: 73.249.23.243:9119/
     * EP: /receta/{idReceta}
     * Body params: receta (RecetaView)
     * @param receta
     * @param idReceta
     */
    update(recipe: Recipe, idRecipe: string): Observable<Recipe> {
        return this.http.sendRequest(this.UPDATE, recipe, idRecipe)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }
    delete(idRecipe: string): Observable<any> {
        return this.http.sendRequest(this.DELETE, null, idRecipe)
           // .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }
}
