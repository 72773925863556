import {Component, Input, OnInit} from '@angular/core';
import {ConsultaService} from '../../shared/consulta.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConsultaView} from "../../shared/consulta.classes";
import {PlanRecetaComponent} from "../../+detalle/plan-receta/plan-receta.component";
import {UserDetailsService} from "../../../shared/user/user.details.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {PlanEstudioComponent} from "../../+detalle/plan-estudio/plan-estudio.component";
import {ShareDataService} from '../../../+paciente/+detalle/consulta-actual/share-data.service';
import {RecipesService} from 'app/shared/services/recipes.service';
import {Recipe} from '../../../shared/classes/recipe.classes';
import { NotificationService } from 'app/shared/utils/notification.service';

@Component({
    selector: 'app-footer-consulta',
    templateUrl: './consulta.layout.footer.component.html',
    styleUrls: ['./consulta.layout.footer.component.css']
})
export class ConsultaLayoutFooterComponent implements OnInit {
    @Input() consulta: ConsultaView;

    bsModalRecipe: BsModalRef;
    bsModalPrescription: BsModalRef;
    receta: Recipe = new Recipe();

    constructor(public consultaService: ConsultaService,
                private activedRoute: ActivatedRoute,
                private userDetailsService: UserDetailsService,
                private modalService: BsModalService,
                private notificationService: NotificationService,
                private sharedService: ShareDataService,
                private router: Router,
                private recipesService: RecipesService) {
    }

    ngOnInit(): void {
        if (!this.consulta) {
            this.consulta = new ConsultaView();
            this.activedRoute.params.subscribe((params) => {
                this.getRecetas(params.id);
                this.consultaService.get(params.id)
                    .subscribe((consulta) => this.consulta = consulta);
            });
        }
        this.sharedService.getParameterBoton()
            .subscribe(() => {
                if (this.consulta && this.consulta.idConsulta) {
                    this.router.navigate(['/consulta/detalle/', this.consulta.idConsulta]);
                }
            });
    }

    openModalRecipe(event: MouseEvent): void {
        event.stopPropagation();

        
        if (this.consulta) {
            if(this.consulta.listaPadecimiento.length>0){
                const init = {
                    consulta: this.consulta,
                    medico: this.userDetailsService.usersDetails
                };
    
                this.bsModalRecipe = this.modalService.show(PlanRecetaComponent, {
                    class: 'modal-lg width-963px',
                    backdrop: 'static',
                    initialState: init
                });
            }else{
                this.notificationService.bigBox({
                    title: 'ERROR',
                    content: `SE DEBE AGREGAR UN DIAGNOSTICO`,
                    color: '#C46A69',
                    icon: 'fa fa-warning shake animated',
                    timeout: 2000
                });
            }
            
        }
    }

    openModalPrescription(event: MouseEvent): void {
        event.stopPropagation();

        const init = {
            consulta: this.consulta,
            medico: this.userDetailsService.usersDetails
        };

        this.bsModalPrescription = this.modalService.show(PlanEstudioComponent, {
            class: 'modal-lg width-963px',
            backdrop: 'static',
            initialState: init
        });
    }

    // codigo y validacion temporal para evitar error al cargar el componente de recetas
    getRecetas(idConsulta): void {
        this.recipesService.getByConsulta(idConsulta)
            .subscribe((recipe) => this.receta = recipe);
    }
}
