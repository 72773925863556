import {Injectable} from '@angular/core';
import {CustomHttp} from "../common/http/custom.http";
import {Format, FormatResult, FormatResultList} from "./formato.classes";
import {RequestConfig} from "../shared/rest/rest.request";
import {RequestMethod, Response} from "@angular/http";
import {Observable} from "rxjs";
import {PageImpl} from "../common/http/PageImpl";
import {isNullOrUndefined} from "util";

@Injectable()
export class FormatoService {
    private GET_ALL = new RequestConfig("FTS", "application/json", true, RequestMethod.Get, "/formats/all");
    private BY_ID = new RequestConfig("FTS", "application/json", true, RequestMethod.Get, "/formats/{idFormat}");
    private BY_USER = new RequestConfig("FTS", "application/json", true, RequestMethod.Get, "/formats/user/{idUsuario}");
    private GET_PAGE = new RequestConfig("FTS", "application/json", true, RequestMethod.Get, "/formats");
    private RESULT = new RequestConfig("FTS", "application/json", true, RequestMethod.Get, "/formats/result");
    private RESULT_LIST = new RequestConfig("FTS", "application/json", true, RequestMethod.Get, "/formats/result/list");
    private RESULT_BY_ID = new RequestConfig("FTS", "application/json", true, RequestMethod.Get, "/formats/result/{idFormatResult}");
    private CREATE = new RequestConfig("FTS", "application/json", true, RequestMethod.Post, "/formats");
    private CREATE_RESULT = new RequestConfig("FTS", "application/json", true, RequestMethod.Post, "/formats/result/{idFormat}");
    private EDIT_CODE = new RequestConfig("FTS", "application/json", true, RequestMethod.Put, "/formats/{idFormat}");
    private DEACTIVATE = new RequestConfig("FTS", "application/json", true, RequestMethod.Put, "/formats/status/{idFormat}");
    private DELETE_CODE = new RequestConfig("FTS", "application/json", true, RequestMethod.Delete, "/formats/{idFormat}");

    constructor(private http: CustomHttp) {
    }

    /**
     * Envia una petición a WS para guardar formato
     */
    saveFormat(formato: Format): Observable<Format> {
        return this.http.sendRequest(this.CREATE, formato)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener detalles de un formato
     */
    getFormat(idFormat: number): Observable<Format> {
        return this.http.sendRequest(this.BY_ID, {}, idFormat)
            .map((res: Response) => res.json())
            .catch((error) => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener todos los formatos
     */
    getAllFormats(active?: boolean): Observable<Format[]> {
        return this.http.sendRequest(this.GET_ALL)
            .map((res: Response) => res.json())
            .catch((error) => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener la lista de formatos ligados a un usuario
     * @param idUser: ID numerico del usuario
     */
    getByUser(idUser: number): Observable<Format[]> {
        return this.http.sendRequest(this.BY_USER, {}, idUser)
            .map((res: Response) => res.json())
            .catch((error) => this.http.handleError(error));
    }

    /**
     * Envía peticion a WS para obtener lista paginada de formatos
     * @param options: objeto con filtros para la busqueda
     */
    getPage(options: any): Observable<PageImpl<Format>> {
        return this.http.sendRequest(this.GET_PAGE, options)
            .map((res: Response) => res.json())
            .catch((error) => this.http.handleError(error));
    }

    /**
     * Envía peticion a WS para obtener los resultados de formatos ligados a una consulta
     * @param filter: idConsulta a la que se ligaron las respuestas, active formatos activos o inactivos
     */
    getResults(filter: {idConsulta: number, active?: boolean}): Observable<FormatResult[]> {
        if (!isNullOrUndefined(filter.active)) {
            return this.http.sendRequest(this.RESULT, filter)
                .map((res: Response) => res.json())
                .catch((error) => this.http.handleError(error));
        } else {
            return this.http.sendRequest(this.RESULT_LIST, filter)
                .map((res: Response) => {
                    return res.json().map((formatResultList: FormatResultList) => {
                        let formatResult: FormatResult = new FormatResult();
                        formatResult.idFormatResult = formatResultList.idFormatResult;
                        formatResult.idFormat = formatResultList.idFormat;
                        formatResult.createdDate = formatResultList.createdDate;
                        formatResult.username = formatResultList.username;
                        formatResult.jsonAnswer = formatResultList.jsonAnswer;
                        return formatResult;
                    });
                })
                .catch((error) => this.http.handleError(error));
        }
    }

    /**
     * Envía peticion a WS para obtener el resultado buscado
     * @param idFormatResult, ID del resultado a obtener
     */
    getResultsById(idFormatResult: number): Observable<FormatResult> {
        return this.http.sendRequest(this.RESULT_BY_ID, {}, idFormatResult)
            .map((res: Response) => res.json())
            .catch((error) => this.http.handleError(error));
    }

    /**
     * Envía peticion a WS para obtener guardar el resultado de un formato
     * @param idFormat, ID del formato del que se respondio y guardaran resultados
     */
    saveResult(idFormat: number, formatResult: FormatResult): Observable<FormatResult> {
        return this.http.sendRequest(this.CREATE_RESULT, formatResult, idFormat)
            .map((res: Response) => res.json())
            .catch((error) => this.http.handleError(error));
    }

    /**
     * Envía petición a WS para editar datos de formato
     */
    updateFormat(formato: Format): Observable<Format> {
        return this.http.sendRequest(this.EDIT_CODE, formato, formato.idFormat)
            .map((res: Response) => res.json())
            .catch((error) => this.http.handleError(error));
    }

    /**
     * Envia petición al servidor para desactivar el formato
     */
    deactivateFormat(idFormat: number): Observable<Response> {
        return this.http.sendRequest(this.DEACTIVATE, {}, idFormat)
            .catch((error) => this.http.handleError(error));
    }

    /**
     * Envia petición al servidor para eliminar datos de formato
     */
    deleteFormat(idFormat: number): Observable<Response> {
        return this.http.sendRequest(this.DELETE_CODE, {}, idFormat)
            .catch((error) => this.http.handleError(error));
    }
}
