import {Component, OnInit} from '@angular/core';
import {ConsultaService} from '../../shared/consulta.service';
import {ConsultaListaService} from '../../+lista/consulta.lista.service';
import {ConsultaView} from '../../shared/consulta.classes';
import {ActivatedRoute, Params} from "@angular/router";

@Component({
    selector: 'app-header-consulta',
    templateUrl: './consulta.layout.header.component.html',
    styleUrls: ['./consulta.layout.header.component.css']
})
export class ConsultaLayoutHeaderComponent implements OnInit {
    _nowConsulta: ConsultaView = new ConsultaView();

    constructor(private listConsultaService: ConsultaListaService,
                private consultaService: ConsultaService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        if (this.listConsultaService.getConsultaSelected() && this.listConsultaService.getConsultaSelected().idConsulta == this.route.snapshot.params.id) {
            // Obtencion de la consulta actual y posteriormente del paciente con base en el nuevo servicio de consultas
            this._nowConsulta = this.listConsultaService.getConsultaSelected();
        } else {
            this.route.params
                .subscribe((params: Params) => {
                    if (!isNaN(params.id)) {
                        this.consultaService.get(params.id).subscribe(consulta => {
                            if (!consulta.idConsulta) return;

                            if (consulta.idConsulta == params.id) {
                                setTimeout(() => this._nowConsulta = consulta, 500);
                                if (!this.listConsultaService.getConsultaSelected() || this.listConsultaService.getConsultaSelected().idConsulta != consulta.idConsulta) {
                                    this.listConsultaService.setConsultaSelected(consulta);
                                }
                            }
                        });
                    } else {
                        history.back();
                        return;
                    }
                });
        }
    }
}
