import {Injectable} from '@angular/core';
import {CustomHttp} from '../common/http/custom.http';
import {Observable} from 'rxjs/Observable';
import {PageImpl} from '../common/http/PageImpl';
import {RequestMethod, Response} from '@angular/http';
import {RequestConfig} from '../shared/rest/rest.request';
import {HasPermissionService} from '../shared/haspermission/hasPermission.service';
import {ServicesSubject} from '../common/http/services.subject';
import {PageRequest} from '../common/http/page.request';
import {DomicilioView} from "../shared/classes/common.classes";
import {EspecialidadView, MedicoView} from "./doctor.classes";
import {UserAppView} from '../shared/classes/user.classes';
import { DescripcionPuestoView } from './descripcion-puesto.classes';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient } from '@angular/common/http';

@Injectable()
export class DoctorService {
    private GET_PAGE = new RequestConfig('Doctor-GetPage', 'application/json', true, RequestMethod.Get, '/medicos/page');
    private GET_CODE = new RequestConfig('GM002', 'application/json', true, RequestMethod.Get, '/medicos/{idMedico}');
    private GET_MEDICO_USUARIO = new RequestConfig('GM002', 'application/json', true, RequestMethod.Get, '/medico-usuario/{idUsuario}');
    private GET_MEDICO_XUSUARIO = new RequestConfig('GM002', 'application/json', true, RequestMethod.Get, '/medicos/obtenerPorNombreUsuario/{userName}');
    private GET_ID_USUARIO = new RequestConfig('Doctor-GetByIdUsr', 'application/json', true, RequestMethod.Get, '/medicos/obtenerPorIdUsuario/{idUsuario}');
    private GET_DP_MEDICO = new RequestConfig('DP-GetByIdMed', 'application/json', true, RequestMethod.Get, '/descripcionPuesto/{idMedico}');
    private POST_MEDICO = new RequestConfig('Doctor-Post', 'application/json', true, RequestMethod.Post, '/medicos/');
    private POST_DESC_PUESTO = new RequestConfig('DescripcionPuesto-Post', 'application/json', true, RequestMethod.Post, '/descripcionPuesto/');
    private PUT_DESC_PUESTO = new RequestConfig('DescripcionPuesto-Put', 'application/json', true, RequestMethod.Put, '/descripcionPuesto/{idDescPuesto}');
    private PUT_MEDICO = new RequestConfig('Doctor-Put', 'application/json', true, RequestMethod.Put, '/medicos/{idMedico}');
    private POST_USER = new RequestConfig('Doctor-PostMedicoUser', 'application/json', true, RequestMethod.Post, '/medico-usuario');
    private PUT_USER = new RequestConfig('Doctor-PutMedicoUser', 'application/json', true, RequestMethod.Put, '/medico-usuario');
    private GET_MedicoAgenda = new RequestConfig('MEDICO-AGENDA', 'application/json', true, RequestMethod.Get, '/Agendamedicos/search?idmedico={idMedico}')

    private POST_CEDULA = new RequestConfig('Doctor-PostCedula', 'application/json', true, RequestMethod.Post, '/medicos/subirCedula/{idMedico}');
    private POST_TITULO = new RequestConfig('Doctor-PostTitulo', 'application/json', true, RequestMethod.Post, '/medicos/subirDiploma/{idMedico}');

    private POST_ADDRESS = new RequestConfig('Doctor-PostAddress', 'application/json', true, RequestMethod.Post, '/medicos/{idMedico}/domicilio');
    private PUT_ADDRESS = new RequestConfig('Doctor-PutAddress', 'application/json', true, RequestMethod.Put, '/medicos/{idMedico}/domicilio');
    private DELETE_ADDRESS = new RequestConfig('Doctor-DeleteAddress', 'application/json', true, RequestMethod.Delete, '/medicos/{idMedico}/domicilio');

    private POST_ESPECIALIDAD = new RequestConfig('Doctor-PostEspecialidad', 'application/json', true, RequestMethod.Post, '/medicos/{idMedico}/especialidad');
    private PUT_ESPECIALIDAD = new RequestConfig('Doctor-PutEspecialidad', 'application/json', true, RequestMethod.Put, '/medicos/{idMedico}/especialidad');
    private DELETE_ESPECIALIAD = new RequestConfig('Doctor-DeleteEspecialidad', 'application/json', true, RequestMethod.Delete, '/medicos/{idMedico}/especialidad');

    private GET_IMAGE_USER = new RequestConfig('Doctor-get-image-user', 'application/json', true, RequestMethod.Get, '/users/findImageByUsername/');

    private medico: ServicesSubject<MedicoView> = new ServicesSubject();
    private descPuesto: ServicesSubject<DescripcionPuestoView> = new ServicesSubject();
    private medicoUsuario: ServicesSubject<{medico: MedicoView, usuario: UserAppView}> = new ServicesSubject();
    private userApp: ServicesSubject<UserAppView> = new ServicesSubject();
    private medicos: ServicesSubject<MedicoView[]> = new ServicesSubject();    

    constructor(private http: CustomHttp,
                private hasPermissionService: HasPermissionService) {
    }

    getById(idMedico: string, refresh?: boolean): Observable<MedicoView> {
        if (this.medico.needRefresh || refresh) {
            this.medico.needRefresh = false;
            this.http.sendRequest(this.GET_CODE, null, idMedico)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.medico.needRefresh = !this.medico.data;
                    return this.http.handleError(error);
                })
                .subscribe(medicoView => {
                    this.medico.subject.next(medicoView);
                    this.medico.data = medicoView;
                });
        } else if (this.medico.data) {
            if (this.medico.data.idMedico != idMedico) {
                delete this.medico.data;
                this.medico.subject.next(new MedicoView());
                this.getById(idMedico, true);
            }
        }

        return this.medico.subject.asObservable();
    }
    /*OBTENER MEDICO USUARIO*/

    getMedicoUsuario(idUsuario: number, refresh?: boolean): Observable<{medico: MedicoView, usuario: UserAppView}> {
        let image = true;
        if (this.medicoUsuario.needRefresh || refresh) {
            this.medicoUsuario.needRefresh = false;
            this.http.sendRequest(this.GET_MEDICO_USUARIO, null, idUsuario)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.medicoUsuario.needRefresh = !this.medicoUsuario.data;
                    return Observable.throw(error);
                })
                .subscribe(medicoUsuarioView => {
                    this.medicoUsuario.subject.next({medico: medicoUsuarioView.medicoView, usuario: medicoUsuarioView.userAppView});
                    this.medicoUsuario.data = medicoUsuarioView;
                });
        } else if (this.medicoUsuario.data) {
            if (this.medicoUsuario.data.medico.idUsuario != idUsuario) {
                delete this.medicoUsuario.data;
                this.medicoUsuario.subject.next();
                this.getByIdUser(idUsuario, true);
            }
        }
        return this.medicoUsuario.subject.asObservable();
    }


    getByIdUser(idUsuario: number, refresh?: boolean): Observable<MedicoView> {
        if (this.medico.needRefresh || refresh) {
            this.medico.needRefresh = false;
            this.http.sendRequest(this.GET_ID_USUARIO, null, idUsuario)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.medico.needRefresh = !this.medico.data;
                    return Observable.throw(error);
                })
                .subscribe(medicoView => {
                    this.medico.subject.next(medicoView);
                    this.medico.data = medicoView;
                });
        } else if (this.medico.data) {
            if (this.medico.data.idUsuario != idUsuario) {
                delete this.medico.data;
                this.medico.subject.next(new MedicoView());
                this.getByIdUser(idUsuario, true);
            }
        }
        return this.medico.subject.asObservable();
    }

    getDPByIdDoctor(idMedico: string): Observable<DescripcionPuestoView> {       
        return this.http.sendRequest(this.GET_DP_MEDICO, null, idMedico)
            .map((res: Response) => res.json())
            .catch(error => { return Observable.throw(error) });
    }


    getPage(options?: PageRequest): Observable<PageImpl<MedicoView>> {
        if (!options) {
            options = new PageRequest();
            options.orderColumn = "nombre";
            options.orderType = "desc";
        }

        // fix for MS Pacientes 20190403
        options.datosBusqueda = options.name;
        return this.http.sendRequest(this.GET_PAGE, options)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error)/*this.http.handleError(error)*/);
    }

    getAll(refresh?: boolean): Observable<MedicoView[]> {
        if (this.medicos.needRefresh || refresh) {
            let options: PageRequest = new PageRequest();
            options.orderColumn = "nombre";
            options.orderType = "desc";
            options.size = 10000;

            this.medicos.needRefresh = false;
            this.http.sendRequest(this.GET_PAGE, options)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.medicos.needRefresh = !this.medicos.data;
                    return this.http.handleError(error)
                })
                .subscribe((medicosPage: PageImpl<MedicoView>) => {
                    this.medicos.subject.next(medicosPage.content);
                    this.medicos.data = medicosPage.content;
                });
        }

        return this.medicos.subject.asObservable();
    }

    /**
     * Alta/Actualizacion de nuevo medico
     * @param medico MedicoView
     * @param isEditing boolean if TRUE then the request method is PUT otherwise is POST
     */
    save(medico: MedicoView, isEditing: boolean = false) {
        if (isEditing && this.hasPermissionService.canAccess('ROLE_UPDATE_MEDICO')) {
            return this.http.sendRequest(this.PUT_MEDICO, medico, medico.idMedico)
                .catch(error => this.http.handleError(error));
        }


    }

    /**
     * Guardar o actualizar el medico y el user APP, es decir usuario y médico
     * @param {{medicoView: MedicoView; userAppView: UserAppView}} medicoUser
     * @param {boolean} isEditing
     */
    saveMedicoAndUserApp(medicoUser: {medicoView: MedicoView, userAppView: UserAppView}, isEditing: boolean): Observable<Response> {
        if (this.hasPermissionService.canAccess('ROLE_CREATE_MEDICO') || this.hasPermissionService.canAccess('ROLE_UPDATE_MEDICO')) {
            if (isEditing) {
                return this.http.sendRequest(this.PUT_USER, medicoUser)
                    .catch(error => this.http.handleError(error));
            } else {
                return this.http.sendRequest(this.POST_USER, medicoUser)
                    .catch(error => this.http.handleError(error));
            }
        }
    }

    //uso para nuevas peticiones post Agenda Medico
    private reqGeneric = new RequestConfig('CR-PostPatologico', 'application/json', true, RequestMethod.Get, '/personal-patologico');


    saveMedicoAgenda(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'AgendaMedicos';
        this.reqGeneric.uri = '/Agendamedicos';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }

    //url pruebas: http://192.168.0.50:8081
    //url prod: http://bajacalifornia.telemedicina.lat:9225
    saveDescripcionPuesto(descPuesto: DescripcionPuestoView, isEditing: boolean, idDescPuesto: number) {
        if (isEditing) {
            return this.http.sendRequest(this.PUT_DESC_PUESTO, descPuesto, idDescPuesto)
            .catch(error => this.http.handleError(error)); ;
        } else {
            return this.http.sendRequest(this.POST_DESC_PUESTO, descPuesto)
            .catch(error => this.http.handleError(error));
        }        
    }

    saveCedula(form: FormData, idMedico: string) {
        return this.http.sendRequestFilePost(this.POST_CEDULA, form, idMedico)
            .catch(error => this.http.handleError(error));
    }

    saveTitulo(form: FormData, idMedico: string) {
        return this.http.sendRequestFilePost(this.POST_TITULO, form, idMedico)
            .catch(error => this.http.handleError(error));
    }

    saveAddress(idMedico: string, domicilio: DomicilioView[]) {
        return this.http.sendRequest(this.POST_ADDRESS, domicilio, idMedico)
            .catch(error => this.http.handleError(error));
    }

    updateAddress(idMedico: string, domicilio: DomicilioView[]) {
        return this.http.sendRequest(this.PUT_ADDRESS, domicilio, idMedico)
            .catch(error => this.http.handleError(error));
    }

    deleteAddress(idMedico: string, domicilio: DomicilioView[]) {
        return this.http.sendRequest(this.DELETE_ADDRESS, domicilio, idMedico)
            .catch(error => this.http.handleError(error));
    }

    saveEspecialidad(idMedico: string, especialidad: EspecialidadView[], isEditing: boolean = false) {
        if (this.hasPermissionService.canAccess('ROLE_UPDATE_MEDICO')) {
            return this.http.sendRequest(isEditing ? this.PUT_ESPECIALIDAD : this.POST_ESPECIALIDAD, especialidad, idMedico)
                .catch(error => this.http.handleError(error));
        }
    }

    deleteEspecialidad(idMedico: string, especialidad: EspecialidadView[]) {
        return this.http.sendRequest(this.DELETE_ESPECIALIAD, especialidad, idMedico)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Obtener mediante el enp-point /users/findImageByUsername el avatar del usuario
     * @param idUsuario
     */
    getMedicoUsuario2(idUsuario: number): Observable<{medicoView: MedicoView, userAppView: UserAppView}> {
           return  this.http.sendRequest(this.GET_MEDICO_USUARIO, null, idUsuario)
                .map((res: Response) => res.json())
    }

    getMedicoXUsuario(userName: string): Observable<MedicoView> {
        return  this.http.sendRequest(this.GET_MEDICO_XUSUARIO, null, userName)
             .map((res: Response) => res.json())
 }

 getMedAgenda(idMedico: string): Observable<any> {
    return this.http.sendRequest(this.GET_MedicoAgenda, null, idMedico)
        .map((res: Response) => res.json())
        //.catch(error => this.http.handleError(error));
}
    
}
