import { Injectable } from '@angular/core';

@Injectable()
export class VariablesService {

  total_pacientes = 0;
  total_doctores = 0;
  total_consultas = 0;
  total_documentos = 0;

  constructor() { }


}
