export enum FullcalendarViewTypes {
    MONTH = "dayGridMonth",
    WEEK = "timeGridWeek",
    DAY = "timeGridDay"
}

export class DatepickerRegional {
    languages = {
        "es": {
            closeText: 'Cerrar',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Juv', 'Vie', 'Sab'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
            weekHeader: 'Sm',
            // dateFormat: 'dd/mm/yy',
            firstDay: 0,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        }
    };
}

export class Evento {
    idEventos: number;
    idUsuario: number;
    idConsulta: number;
    titulo: string;
    inicio: Date;
    fin: Date;
    ubicacion: string;
    conferencia: string;
    descripcion: string;
    alerta: Date;
    visible: boolean;
    tipoEventoId: number;
    invitadosViewList: Invitado[];
    _eventoDescripcion: string;
    _eventoColor: string;
    regionSanitaria: number;
    unidadMedica: string;
    status: number;
    idUsuarioRecibe: number;
    idPaciente: string;
    especialidad: string;
}

export class Invitado {
    idInvitados: number;
    idUsuario: number;
    nombreCompleto: string;
}

export class TipoEvento {
    color: string;
    descripcion: string;
    idTipoEvento: number;
}
export class ETIQUETA {
    valor: string;
    nombre: string;
    tipo: string;
    indice: number;
}

