import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AntecedentesGinecoComponent } from './antecedentes-gineco.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SmartadminInputModule } from 'app/shared/forms/input/smartadmin-input.module';
import { SmartadminModule } from 'app/shared/smartadmin.module';
import { JqueryUiModule } from 'app/shared/ui/jquery-ui/jquery-ui.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { AccordionModule, ModalModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [AntecedentesGinecoComponent],
  exports: [AntecedentesGinecoComponent],
  imports: [
    CommonModule,
    SmartadminModule,
    AccordionModule.forRoot(),
    JqueryUiModule,
    ModalModule.forRoot(),
    FormsModule,
    SmartadminInputModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgZorroAntdModule.forRoot(),
  ]
})
export class AntecedentesGinecoModule { }
