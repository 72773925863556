import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExploracionFisicaComponent } from './exploracion-fisica.component';
import {AccordionModule} from 'ngx-bootstrap';
import {SmartadminModule} from '../../../../../shared/smartadmin.module';

@NgModule({
  imports: [
    CommonModule,
    SmartadminModule,
    AccordionModule.forRoot()
  ],
  declarations: [ExploracionFisicaComponent],
  exports: [ExploracionFisicaComponent]
})
export class ExploracionFisicaModule { }
