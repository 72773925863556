import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {RequestConfig} from "../rest/rest.request";
import {RequestMethod, Response} from "@angular/http";
import {CustomHttp} from "../../common/http/custom.http";
import {JsonApiService} from "../../core/api/json-api.service";
import { EstudiosYResultados, EstudioView, ResultadoEstudio, StudyJSON, NuevoEstudioView } from '../classes/studies.classes';
import {ServicesSubject} from "../../common/http/services.subject";
import {Subscriber} from "rxjs/src/Subscriber";
import {error} from "util";
import { ImagenLaboratorioListView, DocumentosListView } from '../classes/common.classes';

@Injectable()
export class StudiesService {
    private GET_BY_CONSULTA = new RequestConfig('Studies-GetByConsulta', 'application/json', true, RequestMethod.Get, '/estudio/por-consulta/{consultaId}');
    private POST_ESTUDIO = new RequestConfig('Studies-Create', 'application/json', true, RequestMethod.Post, '/estudio');
    private PUT_ESTUDIO = new RequestConfig('Studies-update', 'application/json', true, RequestMethod.Put, '/estudio/{idEstudio}');
    private DELETE = new RequestConfig('Studies-Delete', 'application/json', true, RequestMethod.Delete, '/estudio/{idEstudio}');
    private GET_BY_ID_ESTUDIO_VIEW = new RequestConfig('get-estudioview-by-id', 'application/json', true, RequestMethod.Get, '/estudio/{idEstudio}');
    private GET_IMGS_BY_PADECIMIENTO = new RequestConfig('getDocumentosPorPadecimiento', 'application/json', true, RequestMethod.Get, '/imagen-laboratorio/por-padecimiento/{idPadecimiento}');
    private GET_DOCS_BY_PADECIMIENTO = new RequestConfig('getDocumentosPorPadecimiento', 'application/json', true, RequestMethod.Get, '/documentos/por-padecimiento/{idPadecimiento}');

    private GET_RESULTS_BYCONSULTA = new RequestConfig('Studies-GetResults', 'application/json', true, RequestMethod.Post, '/resultado-estudio/por-consulta/{idConsulta}');
    private POST_RESULTS = new RequestConfig('Studies-PostResults', 'application/json', true, RequestMethod.Post, '/resultado-estudio');

    private studiesJSON: ServicesSubject<StudyJSON[]> = new ServicesSubject();

    constructor(private http: CustomHttp,
                private jsonApiService: JsonApiService) {
    }

    filterFromJSON(search: string = ""): Observable<StudyJSON[]> {
        return Observable.create((observer: Subscriber<StudyJSON[]>) => {
            this.getFromJSON()
                .subscribe((studiesJSON) => {
                    if (!search) {
                        observer.next(studiesJSON);
                    } else {
                        observer.next(studiesJSON.filter((studyJson) => studyJson.Descripcion.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0));
                    }
                });
        });
    }

    getFromJSON(refresh?: boolean): Observable<StudyJSON[]> {
        if (this.studiesJSON.needRefresh || refresh) {
            this.studiesJSON.needRefresh = false;
            this.jsonApiService.fetch('/prescripciones.json')
                .subscribe((studiesJson: StudyJSON[]) => {
                    this.studiesJSON.subject.next(studiesJson);
                    this.studiesJSON.data = studiesJson;
                }, () => this.studiesJSON.needRefresh = !this.studiesJSON.data);
        }

        return this.studiesJSON.subject.asObservable();
    }

    getByConsulta(idConsulta: number): Observable<NuevoEstudioView[]> {
        return this.http.sendRequest(this.GET_BY_CONSULTA, null, idConsulta)
            .map((res: Response) => res.json())
            .catch((error) => Observable.throw(error));
    }
    getFormatoByIdEstudioView (idEstudio: string): Observable<NuevoEstudioView> {
        return this.http.sendRequest(this.GET_BY_ID_ESTUDIO_VIEW, null, idEstudio)
        .map((res: Response) => res.json())
        .catch((error) => Observable.throw(error));
    }

    /**
     * Plan terapéutico y tratamiento guardar Estudio
     * HTTP Method: POST
     * MS: 73.249.23.243:9119/
     * EP: /estudio
     * Body params: receta
     * @param estudio
     */
    save(estudio: NuevoEstudioView): Observable<NuevoEstudioView> {
        return this.http.sendRequest(this.POST_ESTUDIO, estudio)
            .map((res: Response) => res.json())
            .catch((error) => this.http.handleError(error));
    }
    updateFormatoEstudio (idEstudio: string, estudioView: NuevoEstudioView ): Observable<any> {
        return this.http.sendRequest(this.PUT_ESTUDIO, estudioView, idEstudio)
            .map((res: Response) => res.json())
            .catch((error) => this.http.handleError(error));
    }

    /**
     * Plan terapéutico y tratamiento BORRAR Estudio
     * HTTP Method: DELETE
     * MS: 73.249.23.243:9119/
     * EP: /estudio
     * Body params: receta
     * @param idEstudio
     */
    delete(idEstudio: string): Observable<Response> {
        return this.http.sendRequest(this.DELETE, null, idEstudio)
            .catch(error => this.http.handleError(error));
    }

    getResultsByConsulta(idConsulta: number): Observable<EstudiosYResultados> {
        return this.http.sendRequest(this.GET_RESULTS_BYCONSULTA, null, idConsulta)
            .map((res: Response) => res.json())
            .catch((error) => this.http.handleError(error));
    }

    saveResult(resultado: ResultadoEstudio): Observable<Response> {
        return this.http.sendRequest(this.POST_RESULTS, resultado)
            .catch((error) => this.http.handleError(error));
    }

    // OBTENER DOCUMENTOS, IMAGENES, ESTUDIOS
    getImagenesByPadecimiento (idPadecimiento: number): Observable<ImagenLaboratorioListView[]> {
        return this.http.sendRequest(this.GET_IMGS_BY_PADECIMIENTO, null, idPadecimiento)
        .map((res: Response) => res.json())
        .catch((error) => this.http.handleError(error));
    }

    getDocumentosByPadecimiento (idPadecimiento: number): Observable<DocumentosListView[]> {
        return this.http.sendRequest(this.GET_DOCS_BY_PADECIMIENTO, null, idPadecimiento)
        .map((res: Response) => res.json())
        .catch((error) => this.http.handleError(error));
    }
}
