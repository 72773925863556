import {Injectable} from '@angular/core';
import {CustomHttp} from '../../common/http/custom.http';
import {RequestConfig} from '../rest/rest.request';
import {RequestMethod, Response} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import {CluesRequest, PageRequest} from "../../common/http/page.request";
import {PageImpl} from "../../common/http/PageImpl";
import {
    CatCie10View, CatCie9FiltradoView, CatCie9View, CatCifView,
    CatCodigoPostalDomView, CatCuadroIoMedView, CatDolometroView,
    CatEntidadesDomView, CatEspecialidadesView,
    CatEstudiosView,
    CatFormacionView, CatGpoTerapeuticoMedView,
    CatLenguasIndigenasView,
    CatLocalidadesDomView,
    CatMedicamentosView,
    CatMotivosEnvioView,
    CatNacionalidadesView,
    CatReligionView, CatTipoInsumoMedView,
    CatTipoPlazaView,CatJornadaView,
    CatViaAdministracionView,
    UnidadMedicaView,
    CatActividadView,CatAreaView,CatInstitucionView,CatPersonalMedView,CatTipoContratView, CantEntidadesFedView, CatAnioResidenciaView, CatCluesView, RegionSanitariaView
} from "../classes/catalog.classes";
import { get } from 'https';
import { HttpClient } from '@angular/common/http';
import { Quiz, QuizQuestions } from '../classes/common.classes';

@Injectable()
export class CatalogosService {
    private cnfGeneral = new RequestConfig('GETCATALOGO', 'application/json', true, RequestMethod.Get, '/catalogo-estado-consulta/findAll');
    private GET_CP_PAGE = new RequestConfig('get-page-codigos', 'application/json', true, RequestMethod.Get, '/catalogo-codigo-postal/search');
    private GET_ONE_ESTADO = new RequestConfig('get-one-estado', 'application/json', true, RequestMethod.Get, '/catalogo-entidades/{idCatEntidadesDom}');
    private GET_ONE_MUNIC = new RequestConfig('get-one-municipio', 'application/json', true, RequestMethod.Get, '/catalogo-municipios/{idCatMunicipiosDom}');
    private GET_ONE_LOCALIDAD = new RequestConfig('get-one-municipio', 'application/json', true, RequestMethod.Get, '/catalogo-localidades/search');
    private GET_CATALOGO_RELIGION = new RequestConfig('get-all-religion', 'application/json', true, RequestMethod.Get, '/catalogo-religion/findAll');
    // private GET_CATALOGO_RELIGIONES_PAGE = new RequestConfig('get-page-religion', 'application/json', true, RequestMethod.Get, '/catalogo-religion/search');
    private GET_CATALOGO_RELIGIONES_PAGE = new RequestConfig('get-page-religion', 'application/json', true, RequestMethod.Get, '/catalogo-religion/search');
    private GET_CATALOGO_RELIGIONES_REQUEST = new RequestConfig('get-request-religion', 'application/json', true, RequestMethod.Get, '/catalogo-religion/{idCatReligion}');
    private POST_CATALOGO_RELIGIONES = new RequestConfig('post-religion', 'application/json', true, RequestMethod.Post, '/catalogo-religion');
    private PUT_CATALOGO_RELIGIONES = new RequestConfig('put-religion', 'application/json', true, RequestMethod.Put, '/catalogo-religion/{idCatReligion}');
    private GET_CATALOGO_MEDICAMENTOS_ALL = new RequestConfig('get-all-medicamentos', 'application/json', true, RequestMethod.Get, '/catalogo-medicamentos/findAll');
    private GET_CATALOGO_MEDICAMENTOS_PAGE = new RequestConfig('get-page-medicamentos', 'application/json', true, RequestMethod.Get, '/catalogo-medicamentos/search');
    private GET_CATALOGO_MEDICAMENTOS_REQUEST = new RequestConfig('get-request-medicamentos', 'application/json', true, RequestMethod.Get, '/catalogo-medicamentos/{idCatMedicamentos}');
    private POST_CATALOGO_MEDICAMENTOS_REQUEST = new RequestConfig('post-medicamentos-request', 'application/json', true, RequestMethod.Post, '/catalogo-medicamentos');
    private PUT_CATALOGO_MEDICAMENTOS_REQUEST = new RequestConfig('put-medicamento-request', 'application/json', true, RequestMethod.Put, '/catalogo-medicamentos/{idCatMedicamentos}');
    private GET_TIPO_INSUMO_PAGE = new RequestConfig('get-tipo-indumo-page', 'application/json', true, RequestMethod.Get, '/catalogo-tipo-insumo/search');
    private GET_GRUPO_TERAPEUTICO_PAGE = new RequestConfig('get-grupo-terapeutico-page', 'application/json', true, RequestMethod.Get, '/catalogo-grupo-terapeutico/search');
    private GET_CUADRO_IO_PAGE = new RequestConfig('get-cuadro-io-page', 'application/json', true, RequestMethod.Get, '/catalogo-cuadroIO/search');
    private GET_CATALOGO_LENGUAS_ALL = new RequestConfig('get-all-lenguas', 'application/json', true, RequestMethod.Get, '/catalogo-lenguas-indigenas/findAll');
    private GET_CATALOGO_LENGUAS_PAGE = new RequestConfig('get-page-lenguas', 'application/json', true, RequestMethod.Get, '/catalogo-lenguas-indigenas/search');
    private GET_CATALOGO_LENGUAS_REQUEST = new RequestConfig('get-request-lengua', 'application/json', true, RequestMethod.Get, '/catalogo-lenguas-indigenas/{idCatLenguasIndigenas}');
    private POST_CATALOGO_LENGUAS = new RequestConfig('post-lenguas', 'application/json', true, RequestMethod.Post, '/catalogo-lenguas-indigenas');
    private PUT_CATALOGO_LENGUAS = new RequestConfig('put-lenguas', 'application/json', true, RequestMethod.Put, '/catalogo-lenguas-indigenas/{idCatLenguasIndigenas}');
    private GET_CATALOGO_FORMACION_ALL = new RequestConfig('get-all-formacion', 'application/json', true, RequestMethod.Get, '/catalogo-formacion/findAll');
    private GET_CATALOGO_FORMACION_PAGE = new RequestConfig('get-page-formacion', 'application/json', true, RequestMethod.Get, '/catalogo-formacion/search');
    private GET_CATALOGO_FORMACION_REQUEST = new RequestConfig('get-request-formacion', 'application/json', true, RequestMethod.Get, '/catalogo-formacion/{idCatFormacion}');
    private POST_CATALOGO_FORMACION_REQUEST = new RequestConfig('get-request-formacion', 'application/json', true, RequestMethod.Post, '/catalogo-formacion');
    private PUT_CATALOGO_FORMACION_REQUEST = new RequestConfig('get-request-formacion', 'application/json', true, RequestMethod.Put, '/catalogo-formacion/{idCatFormacion}');
    private GET_CATALOGO_VIA_ADMINISTRACION_ALL = new RequestConfig('get-via-administracion-all', 'application/json', true, RequestMethod.Get, '/catalogo-via-administracion/findAll');
    private GET_CATALOGO_VIA_ADMINISTRACION_PAGE = new RequestConfig('get-via-administracion-page', 'application/json', true, RequestMethod.Get, '/catalogo-via-administracion/search');
    private GET_CATALOGO_VIA_ADMINISTRACION_REQUEST = new RequestConfig('get-via-administracion-request', 'application/json', true, RequestMethod.Get, '/catalogo-via-administracion/{idCatViaAdministracion}');
    private POST_CATALOGO_VIA_ADMINISTRACION = new RequestConfig('post-via-administracion-request', 'application/json', true, RequestMethod.Post, '/catalogo-via-administracion');
    private PUT_CATALOGO_VIA_ADMINISTRACION = new RequestConfig('put-via-administracion-request', 'application/json', true, RequestMethod.Put, '/catalogo-via-administracion/{idCatViaAdministracion}')
    private GET_CATALOGO_NACIONALIDADES_ALL = new RequestConfig('get-nacionalidades-all', 'application/json', true, RequestMethod.Get, '/catalogo-nacionalidades/findAlll');
    private GET_CATALOGO_NACIONALIDADES_PAGE = new RequestConfig('get-nacionalidades-page', 'application/json', true, RequestMethod.Get, '/catalogo-nacionalidades/search');
  
    //tutores
    private GET_CATALOGO_tutores = new RequestConfig('get-TUTORES', 'application/json', true, RequestMethod.Get, '/Tutores/findAll');
    private GET_CATALOGO_RELACION_tutores = new RequestConfig('get-RELACIONTUTORES', 'application/json', true, RequestMethod.Get, '/relacion-tutores/find?idPaciente={idPaciente}');

  
    //empieza nuevo de medico
    private GET_CATALOGO_TipoPlaza_PAGE = new RequestConfig('get-tipoplaza-page', 'application/json', true, RequestMethod.Get, '/catalogo-Tipoplaza/search');
    private GET_CATALOGO_Jornada_PAGE = new RequestConfig('get-tipoplaza-page', 'application/json', true, RequestMethod.Get, '/catalogo-Jornada/search');
    private GET_CATALOGO_AnioResidencia_PAGE = new RequestConfig('get-catAnioResidencia-page', 'application/json', true, RequestMethod.Get, '/catalogo-anio-Residencia/findAll');
    private GET_CATALOGO_CLUES = new RequestConfig('get-tipoplaza-page', 'application/json', true, RequestMethod.Get, '/catalogo-Clues/search');

    private GET_CATALOGO_Actividad_PAGE = new RequestConfig('get-activdad-page', 'application/json', true, RequestMethod.Get, '/catalogo-Actividadprincipal/search');
    private GET_CATALOGO_EntidadesDom_PAGE = new RequestConfig('get-tipoplaza-page', 'application/json', true, RequestMethod.Get, '/catalogo-entidades/search');
    private GET_CATALOGO_AreaTrab_PAGE = new RequestConfig('get-tipoplaza-page', 'application/json', true, RequestMethod.Get, '/catalogo-Areatrabajo/search');
    private GET_CATALOGO_Institucion_PAGE = new RequestConfig('get-tipoplaza-page', 'application/json', true, RequestMethod.Get, '/catalogo-Instituciones/search');
    private GET_CATALOGO_Personal_PAGE = new RequestConfig('get-tipoplaza-page', 'application/json', true, RequestMethod.Get, '/catalogo-Personalmedico/search');
    private GET_CATALOGO_TipoContrato_PAGE = new RequestConfig('get-tipoplaza-page', 'application/json', true, RequestMethod.Get, '/catalogo-Tipocontrato/search');
   
    
    //termina nuevo de medico
    private GET_CATALOGO_NACIONALIDADES_REQUEST = new RequestConfig('get-nacionalidades-request', 'application/json', true, RequestMethod.Get, '/catalogo-nacionalidades/{idCatNacionalidades}');
    private POST_CATALOGO_NACIONALIDADES = new RequestConfig('post-nacionalidades', 'application/json', true, RequestMethod.Post, '/catalogo-nacionalidades');
    private PUT_CATALOGO_NACIONALIDADES = new RequestConfig('put-nacionalidades', 'application/json', true, RequestMethod.Put, '/catalogo-nacionalidades/{idCatNacionalidades}');
    private GET_CATALOGO_UMEDICAS_PAGE = new RequestConfig('get-umedicas-page', 'application/json', true, RequestMethod.Get, '/unidad-medica/search');
    private GET_CATALOGO_UMEDICAS_EDO = new RequestConfig('get-umedicas-edo', 'application/json', true, RequestMethod.Get, '/unidad-medica/searchByEntidad?idEntidad={idEdo}');
    
    private GET_UNIDAD_MEDICA_REQUEST = new RequestConfig('get-unidad-medica-request', 'application/json', true, RequestMethod.Get, '/unidad-medica/{idUnidadMedica}');
    private POST_UNIDAD_MEDICA_REQUEST = new RequestConfig('post-unidad-medica-request', 'application/json', true, RequestMethod.Post, '/unidad-medica');
    private PUT_UNIDAD_MEDICA_REQUEST = new RequestConfig('put-unidad-medica-request', 'application/json', true, RequestMethod.Put, '/unidad-medica/{idUnidadMedica}');
    

    //regiones sanitarias
    private GET_CATALOGO_RESANI_PAGE = new RequestConfig('get-REGSANI-page', 'application/json', true, RequestMethod.Get, '/regionSanitaria/page');
    private GET_CATALOGO_RESANI_EDO = new RequestConfig('get-unidad-medica-edo', 'application/json', true, RequestMethod.Get, '/regionSanitaria/searchByEstado?idEstado={idEdo}');
                                                                                                                                            
    private GET_RESANI_REQUEST = new RequestConfig('get-unidad-medica-request', 'application/json', true, RequestMethod.Get, '/regionSanitaria/{idRegionSanitaria}');

    private POST_RESANI_REQUEST = new RequestConfig('post-RESANI-request', 'application/json', true, RequestMethod.Post, '/regionSanitaria');
    private PUT_RESANI_REQUEST = new RequestConfig('put-RESANI-request', 'application/json', true, RequestMethod.Put, '/regionSanitaria/{idRegionSanitaria}');

    
    private GET_CATALOGO_ESTUDIOS_PAGE = new RequestConfig('get-estudios-page', 'application/json', true, RequestMethod.Get, '/catalogo-estudios/search');
    private GET_CATALOGO_ESTUDIOS_REQUEST = new RequestConfig('get-estudios-request', 'application/json', true, RequestMethod.Get, '/catalogo-estudios/{idCatEstudio}');
    private POST_CATALOGO_ESTUDIOS_REQUEST = new RequestConfig('post-estudios-page', 'application/json', true, RequestMethod.Post, '/catalogo-estudios');
    private PUT_CATALOGO_ESTUDIOS_REQUEST = new RequestConfig('put-estudios-page', 'application/json', true, RequestMethod.Put, '/catalogo-estudios/{idCatEstudio}');



    private GET_CATALOGO_MOTIVOS_ENVIO = new RequestConfig('Catalog-GetMotivosEnvio', 'application/json', true, RequestMethod.Get, '/catalogo-motivos-envio/findAll');
    private GET_CATALOGO_MOTIVOS_ENVIO_PAGE = new RequestConfig('get-page-motivo-envio', 'application/json', true, RequestMethod.Get, '/catalogo-motivos-envio/search');
    private GET_CATALOGO_MOTIVOS_ENVIO_REQUEST = new RequestConfig('get-motivo-envio-request', 'application/json', true, RequestMethod.Get, '/catalogo-motivos-envio/{idCatMotivosEnvio}');
    private POST_CATALOGO_MOTIVOS_ENVIO_REQUEST = new RequestConfig('post-motivo-envio-request', 'application/json', true, RequestMethod.Post, '/catalogo-motivos-envio');
    private PUT_CATALOGO_MOTIVOS_ENVIO_REQUEST = new RequestConfig('put-motivo-envio-request', 'application/json', true, RequestMethod.Put, '/catalogo-motivos-envio/{idCatMotivosEnvio}');

    private GET_CATALOGO_DISCAPACIDADES_PAGE = new RequestConfig('get-page-discpacidades', 'application/json', true, RequestMethod.Get, '/catalogo-discapacidades/search');
    private GET_CATALOGO_DISCAPACIDADES_REQUEST = new RequestConfig('get-discapacidades-request', 'application/json', true, RequestMethod.Get, '/catalogo-discapacidades/{idCatCif}');
    private POST_CATALOGO_DISCAPACIDADES_REQUEST = new RequestConfig('post-discapacidades-request', 'application/json', true, RequestMethod.Post, '/catalogo-discapacidades');
    private PUT_CATALOGO_DISCAPACIDADES_REQUEST = new RequestConfig('put-discapacidades-request', 'application/json', true, RequestMethod.Put, '/catalogo-discapacidades/{idCatCif}');
    private GET_CATALOGO_ESPECIALIDADES_PAGE = new RequestConfig('get-page-especialidades', 'application/json', true, RequestMethod.Get, '/catalogo-especialidades/search');
    private GET_CATALOGO_ESPECIALIDADES_REQUEST = new RequestConfig('get-especialidades-request', 'application/json', true, RequestMethod.Get, '/catalogo-especialidades/{idCatEspecialidades}');
    private POST_CATALOGO_ESPECIALIDADES_REQUEST = new RequestConfig('post-especialidades-request', 'application/json', true, RequestMethod.Post, '/catalogo-especialidades');
    private PUT_CATALOGO_ESPECIALIDADES_REQUEST = new RequestConfig('put-especialidades-request', 'application/json', true, RequestMethod.Put, '/catalogo-especialidades/{idCatEspecialidades}');
    private GET_CATALOGO_CIE9_PAGE = new RequestConfig('get-page-cie9', 'application/json', true, RequestMethod.Get, '/cat-cie9/search');
    private GET_CATALOGO_CIE9_REQUEST = new RequestConfig('get-cie9-request', 'application/json', true, RequestMethod.Get, '/cat-cie9/{idCie9}');
    private POST_CATALOGO_CIE9_REQUEST = new RequestConfig('post-cie9-request', 'application/json', true, RequestMethod.Post, '/cat-cie9');
    private PUT_CATALOGO_CIE9_REQUEST = new RequestConfig('put-cie9-request', 'application/json', true, RequestMethod.Put, '/cat-cie9/{idCie9}');
    private GET_CATALOGO_CIE10_ALL = new RequestConfig('get-cie10-all', 'application/json', true, RequestMethod.Get, '/cat-cie10/findAll');
    private GET_CATALOGO_CIE10_PAGE = new RequestConfig('get-cie10-page', 'application/json', true, RequestMethod.Get, '/cat-cie10/search');
    private GET_CATALOGO_CIE10_REQUEST = new RequestConfig('get-cie10-request', 'application/json', true, RequestMethod.Get, '/cat-cie10/{idCie10}');
    private POST_CATALOGO_CIE10_REQUEST = new RequestConfig('post-cie10-request', 'application/json', true, RequestMethod.Post, '/cat-cie10');
    private PUT_CATALOGO_CIE10_REQUEST = new RequestConfig('put-cie10-request', 'application/json', true, RequestMethod.Put, '/cat-cie10/{idCie10}');


    //preguntas y cuestionario
    private GET_CATPREGUNTAS_PAGE = new RequestConfig('get-page-CUESTIONARIO', 'application/json', true, RequestMethod.Get, '/cat_preguntas/search');
    private GET_CATQUIZ_PAGE = new RequestConfig('get-page-QUIZ', 'application/json', true, RequestMethod.Get, '/cat_cuestionario/find');

    private POST_CATQUIZ = new RequestConfig('post-CATQUIZ-request', 'application/json', true, RequestMethod.Post, '/cat_cuestionario');
    private PUT_CATQUIZ = new RequestConfig('put-CATQUIZ-request', 'application/json', true, RequestMethod.Put, '/cat_cuestionario/{idCuestionario}');
  
    private POST_CATASK = new RequestConfig('post-CATQUIZ-request', 'application/json', true, RequestMethod.Post, '/cat_preguntas');
    private PUT_CATASK = new RequestConfig('put-CATQUIZ-request', 'application/json', true, RequestMethod.Put, '/cat_preguntas/{idPregunta}');


    private GET_ONE_ASK = new RequestConfig('get-CATQUIZ-request', 'application/json', true, RequestMethod.Get, '/cat_preguntas/{idPregunta}');
    private GET_ONE_QUIZ = new RequestConfig('get-CATQUIZ-request', 'application/json', true, RequestMethod.Get, '/cat_cuestionario/{idCuestionario}');

  
    

    


    //preguntas y cuestonario

    private GET_DOLOMETRO = new RequestConfig('Catalog-Dolo-Get', 'application/json', true, RequestMethod.Get, '​/catalogo-dolometro​/{idCatDolometro}');
    private GET_DOLOMETRO_ALL = new RequestConfig('Catalog-Dolo-GetAll', 'application/json', true, RequestMethod.Get, '/catalogo-dolometro/findAll');
    private GET_DOLOMETRO_PAGE = new RequestConfig('Catalog-Dolo-GetPage', 'application/json', true, RequestMethod.Get, '/catalogo-dolometro/search');

    constructor(private http: CustomHttp) {
    }

    /**
     * Hacer petición HTTP para obtener el estatus de la consulta ** new
     * {'id': 1,'descripcion': 'Nueva'}, **
     * {'id': 2,'descripcion': 'Consulta en Turno'},
     * {'id': 3,'descripcion': 'Cancelada'},
     * {'id': 4,'descripcion': 'Final izada'}
     * {'id': 5,'descripcion': 'Confirmada'}
     * {'id': 6,'descripcion': 'En enfermería'}
     * {'id': 7,'descripcion': 'Sala de Espera'}
     */
    getEstadoConsulta(): Observable<CatEstadoConsultaView[]> {
        this.cnfGeneral.uri = '/catalogo-estado-consulta/findAll';
        this.cnfGeneral.code = 'getEstadoConsulta';
        this.cnfGeneral.method = RequestMethod.Get;
        return this.http.sendRequest(this.cnfGeneral)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getCodigosPostalesPage(options?: PageRequest): Observable<PageImpl<CatCodigoPostalDomView>> {
        return this.http.sendRequest(this.GET_CP_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getEstadoElegido(idCatEntidad: number): Observable<CatEntidadesDomView> {
        return this.http.sendRequest(this.GET_ONE_ESTADO, null, idCatEntidad)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getMunicipioElegido(idCatMunicipio) {
        return this.http.sendRequest(this.GET_ONE_MUNIC, null, idCatMunicipio)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getLocalidadadElegida(options?: PageRequest): Observable<PageImpl<CatLocalidadesDomView>> {
        return this.http.sendRequest(this.GET_ONE_LOCALIDAD, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getCatalogoFormacion(): Observable<Array<CatFormacionView>> {
        return this.http.sendRequest(this.GET_CATALOGO_FORMACION_ALL)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }
    getCatalogoReligiones(): Observable<Array<CatReligionView>> {
        return this.http.sendRequest(this.GET_CATALOGO_RELIGION)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getCatalogoReligionesPage(options?: PageRequest): Observable<PageImpl<CatReligionView>> {
        return this.http.sendRequest(this.GET_CATALOGO_RELIGIONES_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getCatalogoReligionesRequest(idCatReligion: number): Observable<CatReligionView> {
        return this.http.sendRequest(this.GET_CATALOGO_RELIGIONES_REQUEST, null, idCatReligion)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postCatalogoReligiones(catReligionView): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_RELIGIONES, catReligionView)
            .catch(error => Observable.throw(error));
    }

    putCatalogoReligiones(idCatReligion: number, catReligionView): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_RELIGIONES, catReligionView, idCatReligion)
            .catch(error => Observable.throw(error));
    }

    getCatalogoMedicamentosAll(): Observable<Array<CatMedicamentosView>> {
        return this.http.sendRequest(this.GET_CATALOGO_MEDICAMENTOS_ALL)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getMedicamentosPage(options?: PageRequest): Observable<PageImpl<CatMedicamentosView>> {
        return this.http.sendRequest(this.GET_CATALOGO_MEDICAMENTOS_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getMedicamentosRequest(idCatMedicamentos: number): Observable<CatMedicamentosView> {
        return this.http.sendRequest(this.GET_CATALOGO_MEDICAMENTOS_REQUEST, null, idCatMedicamentos)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postMedicamentosRequest(catMedicamentosView): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_MEDICAMENTOS_REQUEST, catMedicamentosView)
            .catch(error => Observable.throw(error));
    }

    putMedicamentosRequest(idCatMedicamentos: number, catMedicamentosView: CatMedicamentosView): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_MEDICAMENTOS_REQUEST, catMedicamentosView, idCatMedicamentos)
            .catch(error => Observable.throw(error));
    }

    getTipoIsumoPage(options?: PageRequest): Observable<PageImpl<CatTipoInsumoMedView>> {
        return this.http.sendRequest(this.GET_TIPO_INSUMO_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getGrupoTerapeuticoPage(options?: PageRequest): Observable<PageImpl<CatGpoTerapeuticoMedView>> {
        return this.http.sendRequest(this.GET_GRUPO_TERAPEUTICO_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getCuadroIOPage(options?: PageRequest): Observable<PageImpl<CatCuadroIoMedView>> {
        return this.http.sendRequest(this.GET_CUADRO_IO_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getLenguasAll(): Observable<Array<CatLenguasIndigenasView>> {
        return this.http.sendRequest(this.GET_CATALOGO_LENGUAS_ALL)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getLenguasPage(options?: PageRequest): Observable<PageImpl<CatLenguasIndigenasView>> {
        return this.http.sendRequest(this.GET_CATALOGO_LENGUAS_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getLenguasRequest(idCatLenguasIndigenas: number): Observable<CatLenguasIndigenasView> {
        return this.http.sendRequest(this.GET_CATALOGO_LENGUAS_REQUEST, null, idCatLenguasIndigenas)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postLenguas(catLenguasIndigenasView): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_LENGUAS, catLenguasIndigenasView)
            .catch(error => Observable.throw(error));
    }

    putLenguas(idCatLenguasIndigenas: number, catLenguasIndigenasView): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_LENGUAS, catLenguasIndigenasView, idCatLenguasIndigenas)
            .catch(error => Observable.throw(error));
    }

    getFormacionAll(): Observable<Array<CatFormacionView>> {
        return this.http.sendRequest(this.GET_CATALOGO_FORMACION_ALL)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getFormacionPage(options?: PageRequest): Observable<PageImpl<CatFormacionView>> {
        return this.http.sendRequest(this.GET_CATALOGO_FORMACION_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getFormacionRequest(idCatFormacion: number): Observable<CatFormacionView> {
        return this.http.sendRequest(this.GET_CATALOGO_FORMACION_REQUEST, null, idCatFormacion)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postFormacionRequest(catFormacionView: CatFormacionView): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_FORMACION_REQUEST, catFormacionView)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    putFormacionRequest(idCatFormacion: number, catFormacionView: CatFormacionView): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_FORMACION_REQUEST, catFormacionView, idCatFormacion)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getViaAdministracionAll(): Observable<Array<CatViaAdministracionView>> {
        return this.http.sendRequest(this.GET_CATALOGO_VIA_ADMINISTRACION_ALL)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getViaAdministracionPage(options?: PageRequest): Observable<PageImpl<CatViaAdministracionView>> {
        return this.http.sendRequest(this.GET_CATALOGO_VIA_ADMINISTRACION_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getViaAdministracionRequest(idCatViaAdministracion: number): Observable<CatViaAdministracionView> {
        return this.http.sendRequest(this.GET_CATALOGO_VIA_ADMINISTRACION_REQUEST, null, idCatViaAdministracion)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postCatalogoViaAdministracion(catViaAdministracion): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_VIA_ADMINISTRACION, catViaAdministracion)
            .catch(error => Observable.throw(error));
    }

    putCatalogoViaAdministracion(idCatViaAdministracion: number, catViaAdministracion): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_VIA_ADMINISTRACION, catViaAdministracion, idCatViaAdministracion)
    }

    getCie10All(): Observable<Array<CatCie10View>> {
        return this.http.sendRequest(this.GET_CATALOGO_CIE10_ALL)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getCie10Page(options?: PageRequest): Observable<PageImpl<CatCie10View>> {
        return this.http.sendRequest(this.GET_CATALOGO_CIE10_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getCie10Request(idCatCie10: number): Observable<CatCie10View> {
        return this.http.sendRequest(this.GET_CATALOGO_CIE10_REQUEST, null, idCatCie10)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }
    postCatalogoCie10(catCie10View): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_CIE10_REQUEST, catCie10View)
            .catch(error => Observable.throw(error));
    }

    putCatalogoCie10(idCie10: number, catCie10View): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_CIE10_REQUEST, catCie10View, idCie10)
    }


    GETtutores(): Observable<any> {
        return this.http.sendRequest(this.GET_CATALOGO_tutores).map(res => res.json())
        .catch(error => Observable.throw(error));
    }
    
    GETRELtutores(idpac:string): Observable<any> {
        return this.http.sendRequest(this.GET_CATALOGO_RELACION_tutores, null, idpac).map(res => res.json())
        .catch(error => Observable.throw(error));
    }

    // REQUEST PARA CRUD DE NACIONALIDADES
    getNacionalidadesAll(): Observable<Array<CatNacionalidadesView>> {
        return this.http.sendRequest(this.GET_CATALOGO_NACIONALIDADES_ALL)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getNacionalidadesPage(options?: PageRequest): Observable<PageImpl<CatNacionalidadesView>> {
        return this.http.sendRequest(this.GET_CATALOGO_NACIONALIDADES_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

//empieza nuevo
// url de prueba: http://192.168.0.50:8080
// url de produc: http://bajacalifornia.telemedicina.lat:9228
    getCatCluesPage(options: CluesRequest): Observable<PageImpl<CatCluesView>> {
        return this.http.sendRequest(this.GET_CATALOGO_CLUES, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getAnioResidenciaPage(): Observable<CatAnioResidenciaView[]>{
        return this.http.sendRequest(this.GET_CATALOGO_AnioResidencia_PAGE)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getTipoPlazaPage(options?: PageRequest): Observable<PageImpl<CatTipoPlazaView>> {
        return this.http.sendRequest(this.GET_CATALOGO_TipoPlaza_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }
    getJornadaPage(options?: PageRequest): Observable<PageImpl<CatJornadaView>> {
        return this.http.sendRequest(this.GET_CATALOGO_Jornada_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }


    getActividadMedPage(options?: PageRequest): Observable<PageImpl<CatActividadView>> {
        return this.http.sendRequest(this.GET_CATALOGO_Actividad_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }
    getEntidadesMedPage(options?: PageRequest): Observable<PageImpl<CantEntidadesFedView>> {
        return this.http.sendRequest(this.GET_CATALOGO_EntidadesDom_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }
    getPersonalMedPage(options?: PageRequest): Observable<PageImpl<CatPersonalMedView>> {
        return this.http.sendRequest(this.GET_CATALOGO_Personal_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getAreaPage(options?: PageRequest): Observable<PageImpl<CatAreaView>> {
        return this.http.sendRequest(this.GET_CATALOGO_AreaTrab_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getTipocontratoPage(options?: PageRequest): Observable<PageImpl<CatTipoContratView>> {
        return this.http.sendRequest(this.GET_CATALOGO_TipoContrato_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getInstituPage(options?: PageRequest): Observable<PageImpl<CatInstitucionView>> {
        return this.http.sendRequest(this.GET_CATALOGO_Institucion_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }


    

//acaba nuevo

    getNacionalidadesRequest(idCatNacionalidades: number): Observable<CatNacionalidadesView> {
        return this.http.sendRequest(this.GET_CATALOGO_NACIONALIDADES_REQUEST, null, idCatNacionalidades)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postCatalogoNacionalidades(catNacionalidadesView): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_NACIONALIDADES, catNacionalidadesView)
            .catch(error => Observable.throw(error));
    }

    putCatalogoNacionalidades(idCatNacionalidades: number, catNacionalidadesView): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_NACIONALIDADES, catNacionalidadesView, idCatNacionalidades)
            .catch(error => Observable.throw(error));
    }
    //REQUEST REGIONES SANITARIAS
    
    getRegionesSanitariasPage(options?: PageRequest): Observable<PageImpl<RegionSanitariaView>> {
        return this.http.sendRequest(this.GET_CATALOGO_RESANI_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getRegionesSanitariasEDO(idEdo : any): Observable<RegionSanitariaView> {
        return this.http.sendRequest(this.GET_CATALOGO_RESANI_EDO, null,idEdo)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }
    

    getRegionSanitariaRequest(idRegionSanitaria: number): Observable<RegionSanitariaView> {
        return this.http.sendRequest(this.GET_RESANI_REQUEST, null, idRegionSanitaria)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postRegionSanitariaRequest(region: RegionSanitariaView): Observable<any> {
        return this.http.sendRequest(this.POST_RESANI_REQUEST, region)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    putRegionSanitariaRequest(idRegionSanitaria: number, region: RegionSanitariaView): Observable<any> {
        return this.http.sendRequest(this.PUT_RESANI_REQUEST, region, idRegionSanitaria)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }


    // REQUEST PARA CRUD DE  UNIDADES MEDICAS
    getUnidadesMedicasPage(options?: PageRequest): Observable<PageImpl<UnidadMedicaView>> {
        return this.http.sendRequest(this.GET_CATALOGO_UMEDICAS_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getUnidadesMedicasEDO(idEdo : any): Observable<UnidadMedicaView> {
        return this.http.sendRequest(this.GET_CATALOGO_UMEDICAS_EDO, null,idEdo)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getUnidadMedicaRequest(idUnidadMedica: number): Observable<UnidadMedicaView> {
        return this.http.sendRequest(this.GET_UNIDAD_MEDICA_REQUEST, null, idUnidadMedica)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postUnidadMedicaRequest(unidadMedicaView: UnidadMedicaView): Observable<any> {
        return this.http.sendRequest(this.POST_UNIDAD_MEDICA_REQUEST, unidadMedicaView)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    putUnidadMedicaRequest(idUnidadMedica: number, unidadMedicaView: UnidadMedicaView): Observable<any> {
        return this.http.sendRequest(this.PUT_UNIDAD_MEDICA_REQUEST, unidadMedicaView, idUnidadMedica)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getEstudiosPage(options?: PageRequest): Observable<PageImpl<CatEstudiosView>> {
        return this.http.sendRequest(this.GET_CATALOGO_ESTUDIOS_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getEstudiosRequest(idCatEstudio: number): Observable<CatEstudiosView> {
        return this.http.sendRequest(this.GET_CATALOGO_ESTUDIOS_REQUEST, null, idCatEstudio)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postEstudiosRequest(catEstudiosView): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_ESTUDIOS_REQUEST, catEstudiosView)
            .catch(error => Observable.throw(error));
    }

    putEstudiosRequest(idCatEstudio: number, catEstudiosView): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_ESTUDIOS_REQUEST, catEstudiosView, idCatEstudio)
    }

    getMotivosEnvio(): Observable<CatMotivosEnvioView[]> {
        return this.http.sendRequest(this.GET_CATALOGO_MOTIVOS_ENVIO)
            .map((res: Response) => res.json())
            .catch((error) => Observable.throw(error));
    }

    getMotivosEnvioPage(options?: PageRequest): Observable<PageImpl<CatMotivosEnvioView>> {
        return this.http.sendRequest(this.GET_CATALOGO_MOTIVOS_ENVIO_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getMotivosEnvioRequest(idCatMotivosEnvio: number): Observable<CatMotivosEnvioView> {
        return this.http.sendRequest(this.GET_CATALOGO_MOTIVOS_ENVIO_REQUEST, null, idCatMotivosEnvio)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postMotivosEnvioRequest(catMotivosEnvioView): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_MOTIVOS_ENVIO_REQUEST, catMotivosEnvioView)
            .catch(error => Observable.throw(error));
    }

    putMotivosEnvioRequest(idCatMotivosEnvio: number, catMotivosEnvioView): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_MOTIVOS_ENVIO_REQUEST, catMotivosEnvioView, idCatMotivosEnvio)
    }

    getDiscapacidadesPage(options?: PageRequest): Observable<PageImpl<CatCifView>> {
        return this.http.sendRequest(this.GET_CATALOGO_DISCAPACIDADES_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getDiscapacidadesRequest(idCatCif: number): Observable<CatCifView> {
        return this.http.sendRequest(this.GET_CATALOGO_DISCAPACIDADES_REQUEST, null, idCatCif)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postDiscapacidadesRequest(catCifView): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_DISCAPACIDADES_REQUEST, catCifView)
            .catch(error => Observable.throw(error));
    }

    putDiscapacidadesRequest(idCatCif: number, catCifView): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_DISCAPACIDADES_REQUEST, catCifView, idCatCif)
    }

    getEspecialidadesPage(options?: PageRequest): Observable<PageImpl<CatEspecialidadesView>> {
        return this.http.sendRequest(this.GET_CATALOGO_ESPECIALIDADES_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getEspecialidadesRequest(idCatEspecialidades: number): Observable<CatEspecialidadesView> {
        return this.http.sendRequest(this.GET_CATALOGO_ESPECIALIDADES_REQUEST, null, idCatEspecialidades)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postEspecialidadesRequest(idCatEspecialidades): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_ESPECIALIDADES_REQUEST, idCatEspecialidades)
            .catch(error => Observable.throw(error));
    }

    putEspecialidadesRequest(idCatEspecialidades: number, catEspecialidadesView): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_ESPECIALIDADES_REQUEST, catEspecialidadesView, idCatEspecialidades)
    }
    getCie9Page(options?: PageRequest): Observable<PageImpl<CatCie9FiltradoView>> {
        return this.http.sendRequest(this.GET_CATALOGO_CIE9_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }



    //cuestionarios y preguntas//

    getCatpreguntas(options?: PageRequest): Observable<PageImpl<QuizQuestions>> {
        return this.http.sendRequest(this.GET_CATPREGUNTAS_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }
    getCatQuiz(options?: PageRequest): Observable<PageImpl<Quiz>> {
        return this.http.sendRequest(this.GET_CATQUIZ_PAGE, options)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }


    postCatQuiz(quiz): Observable<any> {
        return this.http.sendRequest(this.POST_CATQUIZ, quiz)
            .catch(error => Observable.throw(error));
    }

    putCatQuiz(idCuestionario: number, quiz): Observable<any> {
        return this.http.sendRequest(this.PUT_CATQUIZ, quiz, idCuestionario)
    }


    postCatPregunta(qquiz): Observable<any> {
        return this.http.sendRequest(this.POST_CATASK, qquiz)
            .catch(error => Observable.throw(error));
    }

    putCatPregunta(idPregunta: number, qquiz): Observable<any> {
        return this.http.sendRequest(this.PUT_CATASK, qquiz, idPregunta)
    }


    getCatPregunta(idPregunta: number): Observable<QuizQuestions> {
        return this.http.sendRequest(this.GET_ONE_ASK, null, idPregunta)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    getCatoneQuiz(idCuestionario: number): Observable<Quiz> {
        return this.http.sendRequest(this.GET_ONE_QUIZ, null, idCuestionario)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    //fin cuestionario y preguntas//

    getCie9Request(idCie9: number): Observable<CatCie9View> {
        return this.http.sendRequest(this.GET_CATALOGO_CIE9_REQUEST, null, idCie9)
            .map(res => res.json())
            .catch(error => Observable.throw(error));
    }

    postCie9Request(idCie9): Observable<any> {
        return this.http.sendRequest(this.POST_CATALOGO_CIE9_REQUEST, idCie9)
            .catch(error => Observable.throw(error));
    }

    putCie9Request(idCie9: number, catCie9View): Observable<any> {
        return this.http.sendRequest(this.PUT_CATALOGO_CIE9_REQUEST, catCie9View, idCie9)
    }

    /* Dolometro functions */
    /**
     * Obtiene la informacion del dolometro requerido segun el id
     * @param idDolometro id numerico del dolometro a obtener
     */
    getDolometro(idDolometro: number): Observable<CatDolometroView> {
        return this.http.sendRequest(this.GET_DOLOMETRO, null, idDolometro)
            .map((res: Response) => res.json())
            .catch((error) => Observable.throw(error));
    }

    /**
     * Obtiene la lista completa del catalogo de Dolometros disponibles
     */
    getDolometroAll(): Observable<CatDolometroView[]> {
        return this.http.sendRequest(this.GET_DOLOMETRO_ALL)
            .map((res: Response) => res.json())
            .catch((error) => Observable.throw(error));
    }

    /**
     * Optiene una lista filtrada del catalogo de dolometros
     * @param options filtro para la obtencion de dolometros
     */
    getDolometroPage(options: PageRequest): Observable<PageImpl<CatDolometroView>> {
        return this.http.sendRequest(this.GET_DOLOMETRO_PAGE, options)
            .map((res: Response) => res.json())
            .catch((error) => Observable.throw(error));
    }

    /* ~Dolometro functions */
}

export class CatEstadoConsultaView {
    activo: boolean;
    color: string;
    descripcion: string;
    idEstadoConsulta: number;
}
