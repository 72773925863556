import {NgModule} from "@angular/core";
import {ConsultaLayoutSubheaderComponent} from "./consulta.layout.subheader.component";
import {ConsultaService} from "../../../shared/consulta.service";
import {ShareDataService} from "../../../../+paciente/+detalle/consulta-actual/share-data.service";
import {SmartadminModule} from "../../../../shared/smartadmin.module";
import {JqueryUiModule} from "../../../../shared/ui/jquery-ui/jquery-ui.module";

@NgModule({
    imports: [
        SmartadminModule,
        JqueryUiModule
    ],
    declarations: [ConsultaLayoutSubheaderComponent],
    exports: [ConsultaLayoutSubheaderComponent],
    providers: [
        ConsultaService,
        ShareDataService
    ]
})
export class ConsultaLayoutSubheaderModule {
}
