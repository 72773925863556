import {Injectable} from "@angular/core";
import {PadecimientoView} from "../classes/ailment.classes";
import {RequestConfig} from "../rest/rest.request";
import {CustomHttp} from "../../common/http/custom.http";
import {RequestMethod, Response} from "@angular/http";
import {Observable} from "rxjs/Observable";
import {PageImpl} from "../../common/http/PageImpl";
import {PageRequest} from "../../common/http/page.request";
import {ServicesSubject} from "../../common/http/services.subject";

@Injectable()
export class AilmentService {
    private GET = new RequestConfig('Ailment-Get', 'application/json', true, RequestMethod.Get, '/padecimiento/{idAilment}');
    private GET_BY_IDPATIENT = new RequestConfig('Ailment-GetByIdPatient', 'application/json', true, RequestMethod.Get, '/padecimiento/{idPatient}');
    private GET_PAGE = new RequestConfig('Ailment-GetPage ', 'application/json', true, RequestMethod.Get, '/padecimiento/page');
    private GET_ALL = new RequestConfig('Ailment-GetAll', 'application/json', true, RequestMethod.Get, '/padecimiento/findAll');
    private PUT = new RequestConfig('Ailment-Put', 'application/json', true, RequestMethod.Put, '/padecimiento/{idAilment}');
    private POST = new RequestConfig('Ailment-Post', 'application/json', true, RequestMethod.Post, '/padecimiento');

    private ailment: ServicesSubject<PadecimientoView> = new ServicesSubject();
    private allAilments: ServicesSubject<PadecimientoView[]> = new ServicesSubject();

    constructor(private http: CustomHttp) {
    }

    getById(idPadecimiento: number, refresh?: boolean): Observable<PadecimientoView> {
        if (this.ailment.needRefresh || refresh) {
            this.ailment.needRefresh = false;
            this.http.sendRequest(this.GET, null, idPadecimiento)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.ailment.needRefresh = !this.ailment.data;
                    return this.http.handleError(error);
                })
                .subscribe(ailment => {
                    this.ailment.subject.next(ailment);
                    this.ailment.data = ailment;
                });
        } else if (this.ailment.data) {
            if (this.ailment.data.idPadecimiento != idPadecimiento) {
                delete this.ailment.data;
                this.ailment.subject.next(null);
                this.getById(idPadecimiento, true);
            }
        }

        return this.ailment.subject.asObservable();
    }

    getAll(refresh?: boolean): Observable<PadecimientoView[]> {
        if (this.allAilments.needRefresh || refresh) {
            this.allAilments.needRefresh = false;
            this.http.sendRequest(this.GET_ALL)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.allAilments.needRefresh = !this.allAilments.data;
                    return this.http.handleError(error);
                })
                .subscribe(ailments => {
                    this.allAilments.subject.next(ailments);
                    this.allAilments.data = ailments;
                });
        } else if (!this.allAilments.data) {
            this.allAilments.subject.next(null);
            this.getAll(true);
        }

        return this.allAilments.subject.asObservable();
    }

    getByIdPaciente(idPaciente: string, refresh?: boolean): Observable<PadecimientoView> {
        if (this.ailment.needRefresh || refresh) {
            this.ailment.needRefresh = false;
            this.http.sendRequest(this.GET_BY_IDPATIENT, null, idPaciente)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.ailment.needRefresh = !this.ailment.data;
                    return this.http.handleError(error);
                })
                .subscribe(ailment => {
                    this.ailment.subject.next(ailment);
                    this.ailment.data = ailment;
                });
        } else if (this.ailment.data) {
            if (this.ailment.data.idPaciente != idPaciente) {
                delete this.ailment.data;
                this.ailment.subject.next(null);
                this.getByIdPaciente(idPaciente, true);
            }
        }

        return this.ailment.subject.asObservable();
    }

    getPage(idPaciente: string, filterOptions?: PageRequest): Observable<PageImpl<PadecimientoView>> {
        let options: PageRequest = new PageRequest();
        options.name = idPaciente;
        options = Object.assign(options, filterOptions);

        return this.http.sendRequest(this.GET_PAGE, options)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    save(ailment: PadecimientoView): Observable<Response> {
        return this.http.sendRequest(this.POST, ailment)
            .catch(error => this.http.handleError(error));
    }

    update(ailment: PadecimientoView): Observable<Response> {
        return this.http.sendRequest(this.PUT, ailment, ailment.idPadecimiento)
            .catch(error => this.http.handleError(error));
    }
}
