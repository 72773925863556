import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { loginNioComponent } from './login-nio.component';

const routes: Routes = [
//     {
//     path: '',
//     redirectTo: 'login',
//     pathMatch: 'full'
// },
    {
        path: 'login',
        component: loginNioComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginNioRoutingModule { }
