import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TooltipModule, BsDropdownModule, AccordionModule} from 'ngx-bootstrap'
import {HeaderModule} from './header';
import {FooterComponent} from './footer';
import {NavigationModule} from './navigation';
import {RibbonComponent} from './ribbon';
import {ShortcutComponent} from './shortcut';
import {LayoutSwitcherComponent} from "./layout-switcher.component";
import {MainLayoutComponent} from './app-layouts/main-layout.component';
import {EmptyLayoutComponent} from './app-layouts/empty-layout.component';
import {RouterModule} from '@angular/router';
import {AuthLayoutComponent} from './app-layouts/auth-layout.component';
import {RouteBreadcrumbsComponent} from './ribbon/route-breadcrumbs.component';
import {UtilsModule} from '../utils/utils.module';
import {LoginNioModule} from '../../+login-nio/login-nio.module';
import { NavigationPueblaComponent } from './navigation-puebla/navigation-puebla.component';

@NgModule({
    imports: [
        CommonModule,
        HeaderModule,
        NavigationModule,
        FormsModule,
        RouterModule,
        UtilsModule,
        TooltipModule,
        BsDropdownModule,
        LoginNioModule,
        AccordionModule.forRoot()
    ],
    declarations: [
        FooterComponent,
        RibbonComponent,
        ShortcutComponent,
        LayoutSwitcherComponent,
        MainLayoutComponent,
        EmptyLayoutComponent,
        AuthLayoutComponent,
        RouteBreadcrumbsComponent,
        NavigationPueblaComponent
    ],
    exports: [
        HeaderModule,
        NavigationModule,
        FooterComponent,
        RibbonComponent,
        ShortcutComponent,
    ]
})
export class NiomedicLayoutModule {
}
