/**
 * Created by gcuellar on 24/04/17.
 */
import {Injectable} from '@angular/core';
import {CanLoad, CanActivate, Route, ActivatedRouteSnapshot} from '@angular/router';
import {HasPermissionService} from '../haspermission/hasPermission.service';

@Injectable()
export class CanAccessService implements CanLoad, CanActivate {
    constructor (private hasPermission: HasPermissionService) {}

    canLoad(route: Route) {
        if (!!route.data['permission']) {
            return this.hasPermission.canAccess(route.data['permission'])
        } else {
            return this.hasPermission.isLogged()
        }
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (!!route.data['permission']) {
            return this.hasPermission.canAccess(route.data['permission'])
        } else {
            return this.hasPermission.isLogged()
        }
    }
}
