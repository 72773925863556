import {Component, ViewContainerRef} from '@angular/core';

declare const $: any;

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {
    public title = 'app works!';

    public constructor(private viewContainerRef: ViewContainerRef) {
        $.sound_on = false;
    }
}
