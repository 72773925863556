import { Component, OnInit } from '@angular/core';
import {FadeZoomInTop} from "../../animations/fade-zoom-in-top.decorator";
import {environment} from "../../../../environments/environment";
import {AuthNioService} from '../../../+login-nio/auth-nio.service';

@FadeZoomInTop()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styles: [],
})
export class MainLayoutComponent implements OnInit {
  navigation : boolean;
  navimage:string;
  constructor(private authService: AuthNioService) {
    if(environment.navigate == "NA" || this.authService.token.idTipoUsuario != 2){ // Si es NA o no es medico (admin no es medico)
      this.navigation= true;
    } else {
      this.navigation= false;
    }
  }

  ngOnInit() {
  }
  imagenBase(imagen){
    this.navimage=imagen;
    
  }
}
