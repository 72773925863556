import {NgModule} from '@angular/core';
import {PacienteFichaLayout} from './paciente.ficha.layout';
import {SmartadminModule} from '../../shared/smartadmin.module';

@NgModule({
    imports: [
        SmartadminModule
    ],
    declarations: [PacienteFichaLayout],
    exports: [PacienteFichaLayout]
})
export class PacienteFichaModule {
}
