import {Component, ViewChild, EventEmitter, Output, SimpleChanges,OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from "rxjs/Subject";
import {NuevaConsultaComponent} from '../../../+consulta/nueva-consulta/nueva-consulta.component';
import {environment} from "../../../../environments/environment";
import {AuthNioService} from '../../../+login-nio/auth-nio.service';
import { StorageServiceService } from 'app/shared/services/storage-service.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { LayoutService } from '..';
import { ShaService } from 'app/shared/jssha/jssha.service';
import { UserchangePassword, UserResetPassword } from 'app/+admin/+users/users.classes';
import { HeaderService } from './header.service';
import { UserIdleService } from 'angular-user-idle';
import { UsersService } from 'app/+admin/+users/users.service';


@Component({
    selector: 'sa-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    searchMobileActive = false;
    dialogContent1: any;
    public _oldpass:string="";
    public mail:string="";
    public tel:string="";
    public _newpass:string="";
    public _newpass2:string="";
    public _link:string="";
    readonly googlePlayLink: string;
    // paciente: PatientView = new PatientView();
    // medico: MedicoView = new MedicoView();

    isAlentha : boolean = false;
    _idgroups : string ="";

    espac:boolean=false;
  readonly appStoreLink: string;
    usrest:UserchangePassword = new UserchangePassword();
    @ViewChild(NuevaConsultaComponent) child: NuevaConsultaComponent;
    openedNewConsultaModal: Subject<void> = new Subject();
    @Output() imagen64 :EventEmitter <string> =  new EventEmitter();
    navi:boolean;
    constructor(private router: Router, private usersService: UsersService,private authService: AuthNioService,private userIdle: UserIdleService,
        private serviceStorage:StorageServiceService,private shaService: ShaService, private notificationService: NotificationService,private headerservices: HeaderService) {
        if(environment.navigate=="NA" || this.authService.token.idTipoUsuario != 2){ // Si es NA o no es medico (admin no es medico)
            this.navi= true;
        }
        else{
            this.navi=false;
        }
    }
    ngOnInit(): void {
        console.log("ON INIT");
        console.log(localStorage);
        console.log(this.authService);
       if(this.authService.token.idTipoUsuario ==3){this.espac=true;}
        var seletedgroupd = localStorage.getItem('id_select_group');
        if(environment.Instance_file=='ECE'){
            this._idgroups='24';
        }else{
            this._idgroups='14';
        }
        if(seletedgroupd == this._idgroups){
            this.isAlentha=true;
        }
        if(seletedgroupd == '14'){
            this.isAlentha=true;
        }

        this.usersService.get(this.authService.token.userId).subscribe((user) => {
            console.log(user)
            this.mail = user.email;
            this.tel=user.telefono;
        });


        //Start watching for user inactivity.
    this.userIdle.startWatching();
    
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
        console.log(count);
        console.log("cerro sesion por inactividad de 10 min");
        this.userIdle.stopWatching();
        this.userIdle.stopTimer();
            this.authService.logout();

    });
    
    // // Start watch when time is up.
    // this.userIdle.onTimeout().subscribe(() => console.log('Time is up!'));
    }


    stop() {
        console.log("STOP");

        this.userIdle.stopTimer();
      }
    
      stopWatching() {
        console.log("STOPW");

        this.userIdle.stopWatching();
      }
    
      startWatching() {
        console.log("STAR");
        this.userIdle.startWatching();

      }
    
      restart() {
        console.log("RESET");

        this.userIdle.resetTimer();
      }



    toggleSearchMobile() {
        this.searchMobileActive = !this.searchMobileActive;
        $('body').toggleClass('search-mobile', this.searchMobileActive);
    }

    onSubmit() {
        this.router.navigate(['/miscellaneous/search']);
    }
    resetPass():void{
        console.log("Entro");
        console.log(this._oldpass);
        console.log(this._newpass);
        console.log(this._newpass2);
        console.log(this.authService.token);
        console.log(this.authService.token.userId);
        console.log(this.authService.token.username);
        console.log(this.mail);
        console.log(this.tel);

        if(this._newpass.length>0 && this._newpass2.length>0){
        if(this._newpass==this._newpass2){
            var oldencryptpas = this.shaService.encrypt( `${this._oldpass}{${this.authService.token.username}}`,1000,'SHA-256','HEX');
            var newencryptpas = this.shaService.encrypt( `${this._newpass}{${this.authService.token.username}}`,1000,'SHA-256','HEX');
            var newencryptpas2 = this.shaService.encrypt( `${this._newpass}{${this.mail}}`,1000,'SHA-256','HEX');
            var newencryptpas3 = this.shaService.encrypt( `${this._newpass}{${this.tel}}`,1000,'SHA-256','HEX');
            this.usrest.username=this.authService.token.username;
            this.usrest.oldPassword=oldencryptpas;
            this.usrest.newPassword = newencryptpas;
            this.usrest.newPassword2 = newencryptpas2;
            this.usrest.newPassword3 = newencryptpas3;
            this.usrest.password=this._newpass;

            console.log(this.usrest);
                this.headerservices.pOSTChangePassword(this.usrest).subscribe(
                    () => {
                        this.notificationService.smallBox(
                            {
                                title: 'CONTRASEÑA MODIFICADA',
                                content: '<br>',
                                color: "#739E73",
                                icon: "fa fa-check",
                                timeout: 3000
                            }
                            );
                        }, (error) => {
                            this.notificationService.smallBox(
                                {
                                    title: 'OCURRIO UN ERROR, USUARIO Y CONTRASEÑA NO ENCONTRADA',
                                    content: '<br>',
                                    color: "#d9534f",
                                    icon: "fa fa-times",
                                    timeout: 3000
                                }
                              );
                              console.log(error);
                        });

                document.getElementById("cerrarmodal").click();
        }else{
            this.notificationService.smallBox(
                {
                    title: 'LAS CONTRASEÑAS NO COINCIDEN',
                    content: '<br>',
                    color: "#d9534f",
                    icon: "fa fa-times",
                    timeout: 3000
                }
              );
        }
    }else{
        this.notificationService.smallBox(
            {
                title: 'LOS CAMPOS NO PUEDEN ESTAR VACIOS',
                content: '<br>',
                color: "#d9534f",
                icon: "fa fa-times",
                timeout: 3000
            }
          );
    }

    }


    GENERAR(){
        console.log("Entro");
        var username = this.authService.token.username;
        console.log(this.authService.token.userId);
        var idselectedGroup = localStorage.getItem('id_select_group')
        let idgrupo = Number(idselectedGroup);
        var iduser = this.authService.token.userId;
        
        console.log(idgrupo);
        console.log(this.authService);
        //this.headerservices.getHASH();
        
        this.headerservices.getHASH(username,String(iduser),idgrupo).subscribe((hast) => {
            console.log(hast._body);
            var hashh= hast._body.split('=');
            console.log(hashh);
            var url = environment.url_np+'/#/TOKEN?hash='+hashh[1];
            console.log(url);
               this._link = url;
       });


    }

    COPIAR(inputElement):void{
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
    }

    showNewConsultat(type?) {
        console.log(type);
        this.openedNewConsultaModal.next();
        this.child.tipo_consulta_nueva = type || 1;
        this.child.pacientesList.length = 0;
        this.child.loadMorePatients(true);
    }
    comunicar(group_name){
        var selectimage=localStorage.getItem("image_group_selected");
        this.serviceStorage.setItem('group_name_selected',group_name);
        this.imagen64.emit(selectimage);
    }
}
