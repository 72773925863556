import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import {PatientView, DatosContactoView, DatosAdicionalesView, DatosClinicosView, EsquemaVacunacionView} from '../+paciente/paciente.classes';
import {Router} from '@angular/router';
import {NotificationService} from 'app/shared/utils/notification.service';
import {CamposTablaHeredoFamiliares, CamposTablaPersonalesPatologicos, DomicilioView, PadecimientoActual, PantallaHeredoFamiliares, PantallaPersonalesPatologicos
 ,AntecedentesGinecobstetricos,DiagnosticoHCG, ExploracionFisica
 ,EstudiosLaboratorio,
   PantallaInterrogatorioAparatos,
   PantallaPersonalesNoPatologicos,
  TerapeuticaEmpleada,
  PersonalesNoPatologicosVivienda,
  PersonalesNoPatologicosMascotas,
  PersonalesNoPatologicosAcDeportiva,
PersonalesNoPatologicosHabAlimenticios,
PersonalesNoPatologicosHabHigienicos,
PersonalesNoPatologicosHistoriaLaboral,
PersonalesNoPatologicosTablaLaboral,
PersonalesNoPatologicosTablaConsumo,
InterrogatorioOrganoSentidosVision,
InterrogatorioOrganoSentidosAudicion
,InterrogatorioOrganoSentidosOlfato
,InterrogatorioOrganoSentidosGusto
,InterrogatorioOrganoSentidosTacto
,InterrogatorioRespiratorio
,InterrogatorioCardiovascular
,InterrogatorioDigestivo
,InterrogatorioEndocrino
,InterrogatorioSistemaNervioso
,InterrogatorioPielyAnexos
,InterrogatorioPsiquiatrico
,InterrogatorioGenitourinario
,InterrogatorioUrinario
,InterrogatorioReproductor
,InterrogatorioHemolinfatico
,InterrogatorioMusculoEsqueletico
,InterrogatorioOncologico
,InterrogatorioHematologico
   
} from "../shared/classes/common.classes";
import {UserAppView} from "../shared/classes/user.classes";
import {ShaService} from "../shared/jssha/jssha.service";
import {NgxQrcodeElementTypes,NgxQrcodeErrorCorrectionLevels} from "@techiediaries/ngx-qrcode";
import {environment} from "../../environments/environment";
import {TokenpacService} from './tokenpac.service';
import { Login } from 'app/+login-nio/auth-nio.classes';
import { NzTabSetComponent } from 'ng-zorro-antd';
import { Console } from 'console';
import { PersonalesNoPatologicosComponent } from 'app/+paciente/+detalle/+cursos-clinicos/+paciente-historia-clinica/personales-no-patologicos/personales-no-patologicos.component';

@Component({
 selector: 'app-tokenpac',
 templateUrl: './tokenpac.component.html',
 styleUrls: ['./tokenpac.component.css']
})
export class TokenpacComponent implements OnInit {
 @ViewChild('ClinicHistoryTabs') tabset: NzTabSetComponent;
 @Input() wholeObject: any = {};
 @Output() wholeObjectChange = new EventEmitter<PantallaHeredoFamiliares>();
 @Input() private wholeObjectpp: any = {};
 @Output() private wholeObjectChangepp: EventEmitter<PantallaPersonalesPatologicos> = new EventEmitter<PantallaPersonalesPatologicos>();
 @Input() wholeObjectpa: any = {};
 @Output() wholeObjectChangepa = new EventEmitter<PadecimientoActual>();
 paciente: PatientView;
 domicilio: DomicilioView;
 datosContacto: DatosContactoView;
 wholeObjectrest: any = {};

 saludo = "DESCARGUE NUESTRA APLICACION ";
 tipoElemento = NgxQrcodeElementTypes.CANVAS;
 correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

 /* 
   app ece 
 // */
 //   valor: string = "https://play.google.com/store/apps/details?id=com.tmesalud.APPTELEMETRIA";
 //   valor3:string ="https://apps.apple.com/mx/app/cm-monitoreo-pacientes/id1585180778";
   // // CONSULTA EN LINEA APPS
    valor: string = "https://play.google.com/store/apps/details?id=com.tmesalud.APPTELEMETRIACL";
    valor3:string ="https://cct.telemedicina.lat/";//app apple


date: {year: number, month: number};


 objGeneral: any = {
   'idHistoriaClinicaGeneral': null,
   'idPaciente': null,
   'fechaCreacion': null,
   'version': 1.1,
   'vigencia': null,
   'ultimaModificacion': null,
   'activo': true,
   'creadoPor': 'sysAdmin', /// TODO: nombre de medico
   'hcg': {}
 };


 objSMS: any ={

 "message":"",
 "tpoa":"Sender",
 "recipient":
   [
     {
       "msisdn":""
     }
   ]
 }


 
 // "from": "TM TELEMEDICINA",
 // "to": "",
 // "contents": [
 //   {
 //     "type": "text",
 //     "text": ""
 //   }
 // ]

 EstadoNacimiento:any;

 


 Estados :any =[
   {
     "id": 1,
     "name": "AGUASCALIENTES"
   },
   {
     "id": 2,
     "name": "BAJA CALIFORNIA"
   },
   {
     "id": 3,
     "name": "BAJA CALIFORNIA SUR"
   },
   {
     "id": 4,
     "name": "CAMPECHE"
   },
   {
     "id": 5,
     "name": "COAHUILA"
   },
   {
     "id": 6,
     "name": "COLIMA"
   },
   {
     "id": 7,
     "name": "CHIAPAS"
   },
   {
     "id": 8,
     "name": "CHIHUAHUA"
   },
   {
     "id": 9,
     "name": "CDMX"
   },
   {
     "id": 10,
     "name": "DURANGO"
   },
   {
     "id": 11,
     "name": "GUANAJUATO"
   },
   {
     "id": 12,
     "name": "GUERRERO"
   },
   {
     "id": 13,
     "name": "HIDALGO"
   },
   {
     "id": 14,
     "name": "JALISCO"
   },
   {
     "id": 15,
     "name": "MÉXICO"
   },
   {
     "id": 16,
     "name": "MICHOACÁN"
   },
   {
     "id": 17,
     "name": "MORELOS"
   },
   {
     "id": 18,
     "name": "NAYARIT"
   },
   {
     "id": 19,
     "name": "NUEVO LEÓN"
   },
   {
     "id": 20,
     "name": "OAXACA"
   },
   {
     "id": 21,
     "name": "PUEBLA"
   },
   {
     "id": 22,
     "name": "QUERÉTARO"
   },
   {
     "id": 23,
     "name": "QUINTANA ROO"
   },
   {
     "id": 24,
     "name": "SAN LUIS POTOSÍ"
   },
   {
     "id": 25,
     "name": "SINALOA"
   },
   {
     "id": 26,
     "name": "SONORA"
   },
   {
     "id": 27,
     "name": "TABASCO"
   },
   {
     "id": 28,
     "name": "TAMAULIPAS"
   },
   {
     "id": 29,
     "name": "TLAXCALA"
   },
   {
     "id": 30,
     "name": "VERACRUZ"
   },
   {
     "id": 31,
     "name": "YUCATÁN"
   },
   {
     "id": 32,
     "name": "ZACATECAS"
   }];


 private modelUserAppView: UserAppView = new UserAppView();
 parentesco:any[];
 estadoCivil:any[];
 tipoSangre:any[];
 iduser:string="";
 nameuser : string="";
 IDPAC : string="";
 idgroup : number=0;
 TP :string;
 fechayear : string="";
 fechames : string="";
 fechadia : string="";
 public _username: string="";
 public _password: string="";
 public _mail: string;
 lugarnacimiento :string;
 selectedOcupacion: any = null;
 selectedOcupacionAnterior: any = null;
 isSavingPatient: boolean = true;
 isAdo: boolean = true;
 showClinicInfo: boolean = true;
 showClinicInfoH: boolean = true;
 showClinicInfoAC: boolean = true;
 showGeneralInfo: boolean = true;
 arrayCamposTHF: CamposTablaHeredoFamiliares[];
 pantallaHeredoFamiliares: PantallaHeredoFamiliares = new PantallaHeredoFamiliares();
 onlyConsultas: any[] = [];
 datosAdicionales: DatosAdicionalesView = new DatosAdicionalesView();
 padecimientoActual: PadecimientoActual;
 diagnosticoHCG :DiagnosticoHCG;
 esquemaVacunacion : EsquemaVacunacionView [];
 estudiosLaboratorio : EstudiosLaboratorio;
 exploracionFisica:ExploracionFisica;
 ginecobstetricos:AntecedentesGinecobstetricos;
 pantallaInterrogatorioAparatos:PantallaInterrogatorioAparatos;
 terapeuticaEmpleada:TerapeuticaEmpleada;
 pantallaPersonalesNoPatologicos : PantallaPersonalesNoPatologicos;
   facs :any;
 datosContactosss: DatosContactoView[] = [];
 HAST :string ="";
 arrayCamposTPP: CamposTablaPersonalesPatologicos[] = [];
 pantallaPersonalesPatologicos: PantallaPersonalesPatologicos = new PantallaPersonalesPatologicos();
 datosClinicos: DatosClinicosView = new DatosClinicosView();
 fechaNacimientoString: string;
 fechaNacimiento: Date;

 constructor(
   private tokepnacservice: TokenpacService,
   private router: Router,
   private notificationService: NotificationService,
   private shaService: ShaService
   ) { 

   this.paciente = new PatientView();
   this.domicilio = new DomicilioView();
   this.datosContacto = new DatosContactoView();
   this.padecimientoActual = new PadecimientoActual();

   this.diagnosticoHCG  = new DiagnosticoHCG();
   this.esquemaVacunacion  = [];
   this.estudiosLaboratorio  = new  EstudiosLaboratorio();
   this.exploracionFisica = new ExploracionFisica();
   this.ginecobstetricos = new AntecedentesGinecobstetricos();
   this.pantallaInterrogatorioAparatos = new PantallaInterrogatorioAparatos();

   this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosVision = new  InterrogatorioOrganoSentidosVision();
   this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosAudicion = new   InterrogatorioOrganoSentidosAudicion();
   this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosOlfato = new   InterrogatorioOrganoSentidosOlfato();
   this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosGusto = new   InterrogatorioOrganoSentidosGusto();
   this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosTacto = new   InterrogatorioOrganoSentidosTacto();
   this.pantallaInterrogatorioAparatos.interrogatorioRespiratorio = new   InterrogatorioRespiratorio();
   this.pantallaInterrogatorioAparatos.interrogatorioCardiovascular = new   InterrogatorioCardiovascular();
   this.pantallaInterrogatorioAparatos.interrogatorioDigestivo = new   InterrogatorioDigestivo();
   this.pantallaInterrogatorioAparatos.interrogatorioEndocrino = new   InterrogatorioEndocrino();
   this.pantallaInterrogatorioAparatos.interrogatorioSistemaNervioso = new   InterrogatorioSistemaNervioso();
   this.pantallaInterrogatorioAparatos.interrogatorioPielyAnexos = new   InterrogatorioPielyAnexos();
   this.pantallaInterrogatorioAparatos.interrogatorioPsiquiatrico = new   InterrogatorioPsiquiatrico();
   this.pantallaInterrogatorioAparatos.interrogatorioGenitourinario = new   InterrogatorioGenitourinario();
   this.pantallaInterrogatorioAparatos.interrogatorioUrinario = new   InterrogatorioUrinario();
   this.pantallaInterrogatorioAparatos.interrogatorioReproductor = new   InterrogatorioReproductor();
   this.pantallaInterrogatorioAparatos.interrogatorioHemolinfatico = new   InterrogatorioHemolinfatico();
   this.pantallaInterrogatorioAparatos.interrogatorioMusculoEsqueletico = new   InterrogatorioMusculoEsqueletico();
   this.pantallaInterrogatorioAparatos.interrogatorioOncologico = new   InterrogatorioOncologico();
   this.pantallaInterrogatorioAparatos.interrogatorioHematologico = new   InterrogatorioHematologico();


   this.terapeuticaEmpleada = new TerapeuticaEmpleada();
   this.pantallaPersonalesNoPatologicos = new PantallaPersonalesNoPatologicos();
   this.pantallaPersonalesNoPatologicos.personalesNoPatologicosVivienda = new PersonalesNoPatologicosVivienda();


   this.pantallaPersonalesNoPatologicos.personalesNoPatologicosMascotas = new PersonalesNoPatologicosMascotas();
   this.pantallaPersonalesNoPatologicos.personalesNoPatologicosAcDeportiva  = new PersonalesNoPatologicosAcDeportiva();
   this.pantallaPersonalesNoPatologicos.personalesNoPatologicosHabAlimenticios  = new PersonalesNoPatologicosHabAlimenticios();
   this.pantallaPersonalesNoPatologicos.personalesNoPatologicosHabHigienicos  = new PersonalesNoPatologicosHabHigienicos();
   this.pantallaPersonalesNoPatologicos.PersonalesNoPatologicosTablaConsumo  = [];
   this.pantallaPersonalesNoPatologicos.personalesNoPatologicosHistoriaLaboral  = new PersonalesNoPatologicosHistoriaLaboral();
   this.pantallaPersonalesNoPatologicos.personalesNoPatologicosTablaLaboral  = [];
 }


 ngOnInit() {
  
   this.padecimientoActual.motivoConsulta ="REGISTRO DE PACIENTE"
   this.fechaNacimiento = new Date();
   this.tokepnacservice.logout();
   var hashh= this.router.url.split('=');
   this.HAST = hashh[1];
   var url = environment.api_url+'/create-new-user/link?hash='+this.HAST;
   this.tokepnacservice.getHAST(url).subscribe((response) => {
     this.idgroup=response.idGroup;
    if(this.idgroup ===36){
      this.isAdo=true;
    }else{
      this.isAdo=false;
    }
     
     this.nameuser=response.nombre;
     this.iduser=response.idUsuario;
     },(error) => {
       this.notificationService.smallBox(
         {
             title: "OCURRIO UN ERROR EL TOKEN NO ES VALIDO",
             content: '<br>',
             color: "#d9534f",
             icon: "fa fa-times",
             timeout: 3000
         }
       );
       this.router.navigate(['home']);
     });

   this.estadoCivil = this.tokepnacservice.getEstadoCivil();
   this.tipoSangre = this.tokepnacservice.getTipoSangre();
   this.tokepnacservice.getParentesco().subscribe((parentesco) => {
     this.parentesco = parentesco;
     });

     this.arrayCamposTHF = [
       new CamposTablaHeredoFamiliares('Diabetes'),
       new CamposTablaHeredoFamiliares('Hipertensión arterial'),
       new CamposTablaHeredoFamiliares('Obesidad/Sobrepeso'),
       new CamposTablaHeredoFamiliares('Cardiovasculares'),
       new CamposTablaHeredoFamiliares('Oncológicos'),
   ];
   this.llenarObjeto();

   this.arrayCamposTPP = [
     new CamposTablaPersonalesPatologicos('Diabetes'),
     new CamposTablaPersonalesPatologicos('Hipertensión arterial'),
     new CamposTablaPersonalesPatologicos('Obesidad/Sobrepeso'),
     new CamposTablaPersonalesPatologicos('Cardiovasculares'),
     new CamposTablaPersonalesPatologicos('CANCER'),
 ];
 this.llenarObjetopp();

 }

 llenarObjetopp():void{
   this.pantallaPersonalesPatologicos.datosTabla = this.arrayCamposTPP;
   // Enviamos el valor
   this.wholeObjectpp.personalesPatologicos = this.pantallaPersonalesPatologicos;
   this.wholeObjectChangepp.emit(this.wholeObjectpp);
 }

 llenarObjeto(): void {
   this.pantallaHeredoFamiliares.datosTabla = this.arrayCamposTHF;
   // Enviamos el valor
   this.wholeObject.heredoFamiliares = this.pantallaHeredoFamiliares;
   this.wholeObjectChange.emit(this.wholeObject);
}

_keyUp(event: any) {
 const pattern = /^[a-zA-Z0-9@_.]*$/;  
 if (!pattern.test(event.target.value)) {
   event.target.value = event.target.value.replace(/[^a-zA-Z0-9@_.]/g, "");
   // invalid character, prevent input
 }
}




USERREGIST($event: any): void {

    var mes;
    var day;

 if(this.paciente.nombre && this.paciente.apellidoPaterno&&this.fechadia&&this.fechames&&this.fechayear){
  //  if(this.facs.month<10){
  //    mes = '0'+this.facs.month;
  //  }else{
  //   mes = this.facs.month;
  //  }
  //  if(this.facs.day<10){
  //   day = '0'+this.facs.day;
  // }else{
  //   day = this.facs.day;
  // }
   this.fechaNacimientoString =this.fechayear+'-'+this.fechames+'-'+this.fechadia;
   var st=this.fechaNacimientoString + 'T01:01:01';
   this.fechaNacimiento = new Date(st);

  var fechs =this.fechaNacimientoString.split('-')
  var digitos = fechs[2]+fechs[1];
   var nam = this.paciente.nombre.substr(0,3);
   var app = this.paciente.apellidoPaterno.substr(0,3);
   if(this.paciente.apellidoMaterno){
     nam = this.paciente.nombre.substr(0,2);
     app = this.paciente.apellidoPaterno.substr(0,2);
     var apm = this.paciente.apellidoMaterno.substr(0,2);
     this._username = nam+app+apm+digitos;
   }else{
     this._username = nam+app+digitos;
   }
 }

}

checkClaveElector(): boolean {
 var app1 = this.paciente.apellidoPaterno.substr(0,1);
 var apm1 = this.paciente.apellidoMaterno.substr(0,1);
 var nom1 = this.paciente.nombre.substr(0,1);

 var app2="";
 var apm2="";
 var nom2="";
 const vocales = ["A", "E", "I", "O", "U"];

 for(var x1 = 1;x1<this.paciente.apellidoPaterno.length;x1++){
     app2= this.paciente.apellidoPaterno.substr(x1,1);
     if (vocales.indexOf(app2) === -1) {
       break;
     }
 }

 for(var y1 = 1;y1<this.paciente.apellidoMaterno.length;y1++){
   apm2= this.paciente.apellidoMaterno.substr(y1,1);
   if (vocales.indexOf(apm2) === -1) {
     break;
   }
 }

 for(var z1 = 1;z1<this.paciente.nombre.length;z1++){
   nom2= this.paciente.nombre.substr(z1,1);
   if (vocales.indexOf(nom2) === -1) {
     break;
   }
 }
 var sex = "";

 if (this.paciente.sexo=='MUJER'){
   sex ="M";
 }else{
   sex ="H";
 }

 var fechs =this.fechaNacimientoString.split('-');
 var ans = fechs[0].substr(2,2);
 var digitos =ans+fechs[1]+fechs[2];
 var clave = app1+app2+apm1+apm2+nom1+nom2+digitos+this.EstadoNacimiento+sex;
 var calvesomo= this.paciente.clave_elector.substr(0,15);
 if(calvesomo == clave){return true}
 else{return false;}
}
//yPUZM2eDnDM

REGISTRO():void{
 
 var valid = false;
 if(!this.paciente.apellidoMaterno){
   this.paciente.apellidoMaterno="  ";
 }

 let newPaciente: PatientView = Object.assign({}, this.paciente);
 let userAppView: UserAppView = new UserAppView();
 newPaciente.apellidoMaterno = this.paciente.apellidoMaterno;

 if(this._username && this._password && newPaciente.nombre && newPaciente.apellidoPaterno && newPaciente.sexo && newPaciente.telefonoCelular && newPaciente.email && newPaciente.curp && this.fechaNacimientoString ){
   
   if(newPaciente.clave_elector){
     valid = this.checkClaveElector();
   }else{
     valid = true;
   }
  // valid=false;
   if(valid){
     userAppView.username = this._username;
     newPaciente.rfc="";
     newPaciente.userName=this._username;
    //  if (this.fechaNacimientoString) {
    //    var st=this.fechaNacimientoString + 'T23:59:59';
    //    this.fechaNacimiento = new Date(st);
    //  } else {
    //    this.fechaNacimiento = null;
    //  }
    if(!this.fechaNacimiento){this.fechaNacimiento=null;}

     newPaciente.fechaNacimiento = this.fechaNacimiento;
     newPaciente.activo = true;
     newPaciente.es_titular=false;
     newPaciente.esCanalizado=false;
     newPaciente.tipoSangre = this.TP;
     this.datosAdicionales.escolaridad =  null;
     this.datosAdicionales.ocupacion = this.selectedOcupacion;
     this.datosAdicionales.ocupacionAnterior = this.selectedOcupacionAnterior;
     this.datosAdicionales.origenEtnico = null;
     this.datosAdicionales.lenguaIndigena = null;

     newPaciente.datosAdicionalesView = this.datosAdicionales;
     this.domicilio.activo = true;
     newPaciente.domicilioViewList = [];
     newPaciente.domicilioViewList.push(this.domicilio);

     this.datosContactosss.push(this.datosContacto);

     newPaciente.datosContactoViewList=this.datosContactosss;
     newPaciente.alergias =this.pantallaPersonalesPatologicos.textAlergias;
     this.datosClinicos.alergias = this.pantallaPersonalesPatologicos.textAlergias;
     this.datosClinicos.discapacidad = null;
     this.datosClinicos.institucionSalud = null;
     newPaciente.afiliacion = null;
     newPaciente.clave_elector="";
     

     var urlpost =environment.api_url+'/create-new-user'
     //let idgrupo = 14;
     let idgrupo = this.idgroup;
     userAppView.password = this.shaService.encrypt(this._password + '{' + newPaciente.userName + '}', 1000, 'SHA-256', 'HEX');
     userAppView.name = (newPaciente.nombre + " " + newPaciente.apellidoPaterno + " " + newPaciente.apellidoMaterno).trim();
     userAppView.email = newPaciente.email;
     userAppView.groups.forEach((group) => userAppView.groupList.push(idgrupo));

     userAppView.password2 = this.shaService.encrypt(this._password + '{' + newPaciente.email + '}', 1000, 'SHA-256', 'HEX');
     userAppView.password3 = this.shaService.encrypt(this._password + '{' + newPaciente.telefonoCelular + '}', 1000, 'SHA-256', 'HEX');
     userAppView.telefono = newPaciente.telefonoCelular;

     this.modelUserAppView = Object.assign({}, this.modelUserAppView, userAppView);

     this.tokepnacservice.savePatient(urlpost,{
       pacienteVIew: newPaciente,
       userAppView: this.modelUserAppView,
       selectedGroup : idgrupo,
       code : this._password
       })
       .subscribe((patient) => {
         this.IDPAC=patient.idPaciente;
         //  this.doLogin(this.IDPAC); 

               var URLC = "https://telemedicina.com.mx/api/Aviso.php?INSTUSER=TRUE&NOM=" + this.paciente.nombre + "&AP=" + this.paciente.apellidoPaterno + "&AM=" + this.paciente.apellidoMaterno + "&TEL=" + this.paciente.telefonoCelular + "&MAIL=" + this.paciente.email;
               this.tokepnacservice.getTYC(URLC).subscribe((response) => {});

               var urs ="https://telemedicina.com.mx/SMS/sms.php?usuario="+newPaciente.userName+"&pass="+this._password+"&telefono=52"+this.paciente.telefonoCelular;
               this.tokepnacservice.getTYC(urs).subscribe((response) => {});
               //  this.ENVIOSMS(this.paciente.telefonoCelular);

               document.getElementById("OPENMODALFINSH").click();
       },(error) => {
         //BAAC911125HSLRRR04
         var objeto = JSON.parse(error._body);
         this.notificationService.smallBox(
           {
               title: "OCURRIO UN ERROR, "+objeto["message"]+" ,INTENTELO MAS TARDE",
               content: '<br>',
               color: "#d9534f",
               icon: "fa fa-times",
               timeout: 3000
           }
         );
       });
     }else{
       this.notificationService.smallBox(
         {
             title: "LA CLAVE DE REGISTRO ELECTORAL NO COINCIDE CON SUS DATOS",
             content: '<br>',
             color: "#d9534f",
             icon: "fa fa-times",
             timeout: 3000
         }
       );
     }

     }else{
       this.notificationService.smallBox(
         {
             title: "POR FAVOR,INGRESE TODOS LOS CAMPOS REQUERIDOS",
             content: '<br>',
             color: "#d9534f",
             icon: "fa fa-times",
             timeout: 3000
         }
       );
     }
         
}

ENVIOSMS(telefono:string){

//   var mensaje = "Bienvenido a AMI, Datos de la cuenta: Usuario: "+this._username+"  Contraseña: "+this._password+" .Más Información: https://ece.telemedicina.lat";
//   this.objSMS.message =mensaje;
//   this.objSMS.recipient[0].msisdn ="52"+telefono;

// //  this.objSMS.contents[0].text=mensaje;
//   console.log(this.objSMS);
 

//    this.tokepnacservice.setSMS2(this.objSMS);

 var URLC = "https://esalud.red/soporte/api/Aviso.php?INSTUSER=TRUE&NOM=" + this.paciente.nombre + "&AP=" + this.paciente.apellidoPaterno + "&AM=" + this.paciente.apellidoMaterno + "&TEL=" + this.paciente.telefonoCelular + "&MAIL=" + this.paciente.email;

 this.tokepnacservice.getTYC(URLC).subscribe((response) => {});


 var urs ="https://telemedicina.com.mx/SMS/sms.php?usuario="+this._username+"&pass="+this._password+"&telefono=52"+this.paciente.telefonoCelular;
 this.tokepnacservice.getTYC(urs).subscribe((response) => {});

}


doLogin(idpac :string): void {


this.wholeObjectrest.diagnosticoHCG=this.diagnosticoHCG;
 this.wholeObjectrest.esquemaVacunacion=this.esquemaVacunacion;
 this.wholeObjectrest.estudiosLaboratorio=this.estudiosLaboratorio;
 this.wholeObjectrest.exploracionFisica=this.exploracionFisica;
 this.wholeObjectrest.ginecobstetricos=this.ginecobstetricos;
 this.wholeObjectrest.heredoFamiliares=this.pantallaHeredoFamiliares;
 this.wholeObjectrest.padecimientoActual=this.padecimientoActual;
 this.wholeObjectrest.pantallaInterrogatorioAparatos=this.pantallaInterrogatorioAparatos;
 this.wholeObjectrest.pantallaPersonalesNoPatologicos=this.pantallaPersonalesNoPatologicos;

 this.wholeObjectrest.personalesPatologicos=this.pantallaPersonalesPatologicos;
 this.wholeObjectrest.terapeuticaEmpleada=this.terapeuticaEmpleada;
 this.objGeneral.hcg = JSON.parse(JSON.stringify(this.wholeObjectrest));
 this.objGeneral.creadoPor = "USER TOKEN";
 this.objGeneral.idPaciente = idpac;
 this.objGeneral.fechaCreacion = (new Date()).getTime();
 this.objGeneral.ultimaModificacion = (new Date()).getTime();
 this.objGeneral.vigencia = (new Date()).getTime();
 console.log(this.pantallaInterrogatorioAparatos);

 console.log(this.objGeneral);

 let LOGIN: Login = new Login();
 LOGIN.username = this._username;
 LOGIN.password = this.shaService.encrypt(`${this._password}{${LOGIN.username}}`,1000,'SHA-256','HEX');

 // // GET EMPTY TOKEN
 this.tokepnacservice.loadToken(null);
 // SUBSCRIBE AND MAKE THE REQUEST
 this.tokepnacservice.login(LOGIN).subscribe(() => {
        //esto no lo hace porque ocupa observable
         this.guardarHC(idpac);
     }, (error) => {
     });
}

FINIZAR(){
 document.getElementById("cerrarmodal3").click();
this.router.navigate(['#']);
}




guardarHC(idpac :string) {
 
 this.wholeObjectrest.diagnosticoHCG=this.diagnosticoHCG;
 this.wholeObjectrest.esquemaVacunacion=this.esquemaVacunacion;
 this.wholeObjectrest.estudiosLaboratorio=this.estudiosLaboratorio;
 this.wholeObjectrest.exploracionFisica=this.exploracionFisica;
 this.wholeObjectrest.ginecobstetricos=this.ginecobstetricos;
 this.wholeObjectrest.heredoFamiliares=this.pantallaHeredoFamiliares;
 this.wholeObjectrest.padecimientoActual=this.padecimientoActual;
 this.wholeObjectrest.pantallaInterrogatorioAparatos=this.pantallaInterrogatorioAparatos;
 this.wholeObjectrest.pantallaPersonalesNoPatologicos=this.pantallaPersonalesNoPatologicos;

 this.wholeObjectrest.personalesPatologicos=this.pantallaPersonalesPatologicos;
 this.wholeObjectrest.terapeuticaEmpleada=this.terapeuticaEmpleada;
 this.objGeneral.hcg = JSON.parse(JSON.stringify(this.wholeObjectrest));
 this.objGeneral.creadoPor = "USER TOKEN";
 this.objGeneral.idPaciente = idpac;
 this.objGeneral.fechaCreacion = (new Date()).getTime();
 this.objGeneral.ultimaModificacion = (new Date()).getTime();
 this.objGeneral.vigencia = (new Date()).getTime();

  this.tokepnacservice.postHistoriaCG(this.objGeneral)
              .subscribe(() => {
                  },
                  (error) => {
                  }
              );
}



}
