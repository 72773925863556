import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TokenpacComponent} from './tokenpac.component';
import {SmartadminModule} from "../shared/smartadmin.module";
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {ShaService} from "../shared/jssha/jssha.service";
import {JqueryUiModule} from "../shared/ui/jquery-ui/jquery-ui.module";
import {CatalogosService} from "../shared/services/catalogos.service";
import {ComunService} from "../shared/services/comun.service";
import {AuthNioService} from '../+login-nio/auth-nio.service';
import {UsersService} from '../+admin/+users/users.service';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [TokenpacComponent],
  exports: [TokenpacComponent],
    providers: [
        CatalogosService,
        ComunService,
        ShaService,
        AuthNioService,
        UsersService
    ],
  imports: [
    SmartadminModule,
    ReactiveFormsModule,
    JqueryUiModule,
    NgxQRCodeModule,
    NgbModule,
    NgSelectModule,
    NgbPaginationModule, NgbAlertModule,
    NgZorroAntdModule.forRoot()
  ] 
})   
export class TokenpacModule { }
