/**
 * Created by gcuellar on 20/04/17.
 */
import {Directive, ElementRef, OnInit, Input, AfterViewInit} from '@angular/core';
import {HasPermissionService} from './hasPermission.service';

@Directive({
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit, AfterViewInit {

    @Input() hasPermission: any;

    constructor(private el: ElementRef,
                private permissionService: HasPermissionService) {
    }

    ngOnInit() {
        if (typeof this.hasPermission !== 'string') {
            throw new Error('hasPermission value must be a string');
        }
        const Modules = this.hasPermission.trim();
        if (!this.permissionService.canAccess(Modules)) {
            this.el.nativeElement.remove()
        }
    }

    ngAfterViewInit(): void {
        this.el.nativeElement.removeAttribute('haspermission');
        this.el.nativeElement.removeAttribute('ng-reflect-has-permission');
    }
}
