import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '../shared/utils/notification.service';
import {ConsultaListaService} from '../+consulta/+lista/consulta.lista.service';
import {ConsultaService} from '../+consulta/shared/consulta.service';
import {isNullOrUndefined} from 'util';
import {ActivatedRoute} from '@angular/router';
import {CiesService} from '../shared/services/cies.service';
import {HasPermissionService} from '../shared/haspermission/hasPermission.service';
import {PageRequest} from '../common/http/page.request';
import {ConsultaView, TratamientoView, SignosVitales} from '../+consulta/shared/consulta.classes';
import {UserDetailsService} from '../shared/user/user.details.service';
import {DoctorService} from '../+doctor/doctor.service';
import {AilmentService} from '../shared/services/ailment.service';
import {Cie10, Cie9} from '../shared/classes/cies.classes';
import {PadecimientoView} from '../shared/classes/ailment.classes';
import {PatientView} from '../+paciente/paciente.classes';
import {Subscription} from 'rxjs/Subscription';
import {ExploracionFisica} from '../shared/classes/common.classes';
import {ShareDataService} from '../+paciente/+detalle/consulta-actual/share-data.service';
import {CatDolometroView, DolometroLevelIcons} from "../shared/classes/catalog.classes";
import {CatalogosService} from "../shared/services/catalogos.service";
import { environment } from 'environments/environment';


const createHost = require('cross-domain-storage/host');
const createGuest = require('cross-domain-storage/guest');
const storageHost = createHost([
  {
      origin: environment.api_url, // https://cct.telemedicina.lat:9211
      allowedMethods: ['get', 'set', 'remove'],
  },
  {
    origin: 'https://teleprueba.alenthatest.net',
    allowedMethods: ['get', 'set'],
  },
  {
    origin: 'https://sos.alentha.us',
    allowedMethods: ['get', 'set'],
  }, 
  {
    origin: 'https://sos.alentha.us:4344',
    allowedMethods: ['get', 'set'],
  },
  {
    origin: 'https://teleprueba.co:4343',
    allowedMethods: ['get', 'set'],
  },
]);


@Component({
  selector: 'app-iframe-consulta',
  templateUrl: './iframe-consulta.component.html',
  styleUrls: ['./iframe-consulta.component.css']
})
export class IframeConsultaComponent implements OnInit {

  @Input() private readonly consulta: number;
  analysis = {
      presumptive: true
  };
  private timerToSave: number = null;

  private searchCieTimeout: number;
  private optionsSearchCie: PageRequest = new PageRequest();
  searchCie9: string;
  selectedCie9: Cie9;
  mapCies9: Map<number, Cie9> = new Map();
  catCie9: Array<Cie9> = [];
  searchCie10: string;
  selectedCie10: Cie10;
  mapCies10: Map<number, Cie10> = new Map();
  catCie10: Array<Cie10> = [];
  private loadingData = false;
  idselectedGroup : number;
  private selectedPadecimiento: PadecimientoView;
  selectedConsulta: ConsultaView = new ConsultaView();
  selectedPaciente: PatientView = new PatientView();
  padecimientoDefault: PadecimientoView = new PadecimientoView();
  ultimaConsulta: ConsultaView;

  catEstatusConsulta: Map<string, number> = new Map();

  private isSubsecuente: boolean = false;
  private hasPadecimiento: boolean = false;
  readOnly: boolean = false;
  exploracionFisica: ExploracionFisica;

  private subscriptions: Subscription = new Subscription();

  dolometros: CatDolometroView[] = [];
  dolometroLevelIcons: {[level: string]: string} = DolometroLevelIcons;

  constructor(public consultaService: ConsultaService,
              private shareDataService: ShareDataService,
              private listaConsultaService: ConsultaListaService,
              private ailmentService: AilmentService,
              private ciesService: CiesService,
              private catalogosService: CatalogosService,
              private userService: UserDetailsService,
              private doctorService: DoctorService,
              private hasPermissionService: HasPermissionService,
              private notificationService: NotificationService,
              private activatedRoute: ActivatedRoute) {
      this.consulta = this.activatedRoute.snapshot.params.id;
      this.optionsSearchCie.page = 0;
      this.optionsSearchCie.size = 50;
      this.optionsSearchCie.orderType = 'desc';
  }

  ngOnInit(): void {

    var idgrupo = localStorage.getItem('id_select_group')
    this.idselectedGroup = Number(idgrupo);
    console.log(this.idselectedGroup);
    console.log(this.consulta);
    this.shareDataService.getParameter().subscribe((value) => {
        if (value.value && this.padecimientoDefault && this.padecimientoDefault.idPaciente) {
            this.selectedConsulta.listaPadecimiento = [];
            this.selectedConsulta.listaPadecimiento.push(this.padecimientoDefault);
        }
    });
    this.consultaService.insideMenu = false;

    this.exploracionFisica = new ExploracionFisica();

    if (this.consulta) {
        this.subscriptions.add(this.consultaService.get(this.consulta)
            .subscribe(consulta => {
                if (!isNullOrUndefined(consulta) && consulta.idConsulta == this.consulta) {
                    if (!consulta.exploracionFisica) {
                        consulta.exploracionFisica = new ExploracionFisica();
                    }
                    let vitalSigns: SignosVitales = new SignosVitales();
                    for (let property in consulta.signosVitales) {
                        vitalSigns[property] = consulta.signosVitales[property];
                    }

                    this.exploracionFisica = Object.assign({}, this.exploracionFisica, consulta.exploracionFisica);

                    this.selectedConsulta = consulta;
                    //ahora mando llamar funcion cies por default
                    this.obtenerPadecimientoPorDefault();
                    this.selectedConsulta.signosVitales = vitalSigns;
                    this.listaConsultaService.setConsultaSelected(this.selectedConsulta);
                    this.isSubsecuente = (this.selectedConsulta.tipoConsulta === 'Sub secuente');

                    if (isNullOrUndefined(this.selectedConsulta.nombreMedico) && this.selectedConsulta.idMedico) {
                        this.subscriptions.add(this.doctorService.getById(this.selectedConsulta.idMedico).subscribe(medico => {
                            this.selectedConsulta.nombreMedico = `${medico.nombre} ${medico.apellidoPaterno} ${medico.apellidoMaterno}`;
                            this.listaConsultaService.setConsultaSelected(this.selectedConsulta);
                        }));
                    }

                    this.loadDolometro();
                    this.loadCatEstatus();
                    this.loadCies();
                    this.getPadecimientoPorConsulta();
                    this.getUltimaConsulta();
                }
            }, error => console.error(error)));
    } else if (this.listaConsultaService.getConsultaSelected()) {
        this.selectedConsulta = this.listaConsultaService.getConsultaSelected();
        this.isSubsecuente = (this.selectedConsulta.tipoConsulta === 'Sub secuente');
        this.exploracionFisica = Object.assign({}, this.exploracionFisica, this.selectedConsulta.exploracionFisica);
        this.loadDolometro();
        this.loadCatEstatus();
        this.loadCies();
    }
}

ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    this.consultaService.activeTabSOAP = 0;
    if (this.timerToSave) {
        window.clearTimeout(this.timerToSave);
        this.saveData();
    }
}

private getUltimaConsulta(): void {
    this.consultaService.getLastByPatient(this.selectedConsulta.idPaciente,this.idselectedGroup)
        .subscribe((lastConsulta) => this.ultimaConsulta = lastConsulta);
}

private loadDolometro(): void {
    this.subscriptions.add(
        this.catalogosService.getDolometroAll()
            .subscribe((dolometros) => this.dolometros = dolometros.sort((a, b) => a.nivel - b.nivel))
    );
}

private loadCatEstatus(): void {
    this.subscriptions.add(
        this.consultaService.getMapEstatusConsulta()
            .subscribe(mapEstatusConsulta => this.catEstatusConsulta = mapEstatusConsulta)
    );
}

private loadCies(): void {
    /* CIE9 */
    this.selectedConsulta.listaTartamiento.forEach(treatment => {
        if (treatment) {
            if (!isNullOrUndefined(treatment.catalogKey) && !isNullOrUndefined(treatment.proNombre)) {
                let cie9: Cie9 = new Cie9();
                cie9.idCie9 = treatment.catCie9Id;
                cie9.catalogKey = treatment.catalogKey;
                cie9.proNombre = treatment.proNombre.toLocaleLowerCase();
                this.mapCies9.set(cie9.idCie9, cie9);
            } else {
                this.subscriptions.add(this.ciesService.getCie9(treatment.catCie9Id).subscribe(cie9 => {
                    cie9.proNombre = cie9.proNombre.toLocaleLowerCase();
                    this.mapCies9.set(cie9.idCie9, cie9);
                }));
            }
        }
    });
    /* ~ */
    /* CIE10 */
    this.selectedConsulta.listaPadecimiento.forEach(ailment => {
        if (ailment) {
            if (!isNullOrUndefined(ailment.catalogKey) && !isNullOrUndefined(ailment.nombrePadecimiento)) {
                let cie10: Cie10 = new Cie10();
                cie10.idCie10 = ailment.cie10Id;
                cie10.catalogKey = ailment.catalogKey;
                cie10.nombre = ailment.nombrePadecimiento.toLocaleLowerCase();
                cie10.presumptive = ailment.presuntivo;
                this.mapCies10.set(cie10.idCie10, cie10);
            } else {
                this.subscriptions.add(this.ciesService.getCie10(ailment.cie10Id).subscribe(cie10 => {
                    cie10.nombre = cie10.nombre.toLocaleLowerCase();
                    cie10.presumptive = ailment.presuntivo;
                    this.mapCies10.set(cie10.idCie10, cie10);
                }));
            }
        }
    });
    /* ~ */
}

autocompleteCie(ev: KeyboardEvent, type: string): void {
    if (this.searchCieTimeout) {
        clearTimeout(this.searchCieTimeout);
    }
    switch (type) {
        case '9':
        case 'cie9':
            if (ev.keyCode == 27 && this.selectedCie9) {
                this.catCie9 = [];
                if (this.selectedCie9.catalogKey && this.selectedCie9.proNombre) {
                    this.searchCie9 = `${this.selectedCie9.catalogKey} ${this.selectedCie9.proNombre}`;
                } else {
                    this.subscriptions.add(this.ciesService.getCie9(this.selectedCie9.idCie9).subscribe(cie => this.searchCie9 = `${cie.catalogKey} ${cie.proNombre}`));
                }
            } else {
                this.searchCieTimeout = window.setTimeout(() => {
                    let input: HTMLInputElement = <HTMLInputElement>ev.target;
                    input.onblur = null;
                    if (this.searchCie9) {
                        this.loadingData = true;
                        this.optionsSearchCie.orderColumn = 'proNombre';
                        this.optionsSearchCie.datosBusqueda = this.searchCie9;
                        this.subscriptions.add(this.ciesService.getPageCie9(this.optionsSearchCie)
                            .subscribe(data => {
                                this.loadingData = false;
                                this.catCie9 = data.content;
                                if (ev.keyCode == 13 && this.catCie9.length) {
                                    this.assignCie(this.catCie9[0], type);
                                }
                            }))
                    } else {
                        this.catCie9 = [];
                        if (this.selectedCie9) {
                            input.onblur = () => {
                                input.onblur = null;
                                if (this.selectedCie9.catalogKey && this.selectedCie9.proNombre) {
                                    this.searchCie9 = `${this.selectedCie9.catalogKey} ${this.selectedCie9.proNombre}`;
                                } else {
                                    this.subscriptions.add(this.ciesService.getCie9(this.selectedCie9.idCie9)
                                        .subscribe(cie => this.searchCie9 = `${cie.catalogKey} ${cie.proNombre}`));
                                }
                            };
                        }
                    }
                }, 300);
            }
            break;
        case '10':
        case 'cie10':
            if (ev.keyCode == 27 && this.selectedCie10) {
                this.catCie10 = [];
                if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                    this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                } else {
                    this.ciesService.getCie10(this.selectedCie10.idCie10).subscribe(cie => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`);
                }
            } else {
                this.searchCieTimeout = window. setTimeout(() => {
                    let input: HTMLInputElement = <HTMLInputElement>ev.target;
                    input.onblur = null;
                    if (this.searchCie10) {
                        this.loadingData = true;
                        this.optionsSearchCie.orderColumn = 'nombre';
                        this.optionsSearchCie.datosBusqueda = this.searchCie10;
                        this.subscriptions.add(this.ciesService.getPageCie10(this.optionsSearchCie)
                            .subscribe(pageCie10 => {
                                this.loadingData = false;
                                this.catCie10 = pageCie10.content;
                                if (this.catCie10.length) {
                                    if (ev.keyCode == 13) {
                                        this.assignCie(this.catCie10[0], type);
                                    }
                                } else if (this.selectedCie10) {
                                    input.onblur = () => {
                                        input.onblur = null;
                                        if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                                            this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                                        } else {
                                            this.subscriptions.add(this.ciesService.getCie10(this.selectedCie10.idCie10)
                                                .subscribe(cie => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`));
                                        }
                                    };
                                }
                            }));
                    } else {
                        this.catCie10 = [];
                        if (this.selectedCie10) {
                            input.onblur = () => {
                                input.onblur = null;
                                if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                                    this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                                } else {
                                    this.subscriptions.add(this.ciesService.getCie10(this.selectedCie10.idCie10)
                                        .subscribe(cie => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`));
                                }
                            };
                        }
                    }
                }, 300);
            }
            break;
    }
}

assignCie(cie: any, type?: string): void {
    if (cie instanceof Cie9 || cie.hasOwnProperty('idCie9') || type == 'cie9') {
        this.selectedCie9 = cie;
        this.searchCie9 = `${cie.catalogKey} ${cie.proNombre}`;
        this.catCie9 = [];
    } else if (cie instanceof Cie10 || cie.hasOwnProperty('idCie10') || type == 'cie10') {
        this.selectedCie10 = cie;
        this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`;
        this.catCie10 = [];
    }
}

addCie(type: string): void {
    switch (type) {
        case '9':
        case 'cie9':
            this.selectedCie9.proNombre = this.selectedCie9.proNombre.toLocaleLowerCase();
            // add to cies9 map
            this.mapCies9.set(this.selectedCie9.idCie9, this.selectedCie9);
            // add to consulta treatment list
            let treatment: TratamientoView = new TratamientoView();
            treatment.consultaId = this.selectedConsulta.idConsulta;
            treatment.catCie9Id = this.selectedCie9.idCie9;
            this.selectedConsulta.listaTartamiento.push(treatment);

            delete this.selectedCie9;
            delete this.searchCie9;

            this.dataChange();
            break;
        case '10':
        case 'cie10':
            this.selectedCie10.nombre = this.selectedCie10.nombre.toLocaleLowerCase();
            this.selectedCie10.presumptive = this.analysis.presumptive;
            // add to cies10 map
            this.mapCies10.set(this.selectedCie10.idCie10, this.selectedCie10);
            // add to consulta treatment list
            let ailment: PadecimientoView = new PadecimientoView();
            ailment.consultaId = this.selectedConsulta.idConsulta;
            ailment.cie10Id = this.selectedCie10.idCie10;
            ailment.idPaciente = this.selectedConsulta.idPaciente;
            ailment.idMedico = this.userService.usersDetails.idMedico;
            ailment.creadoPor = this.userService.fullName;
            ailment.presuntivo = this.selectedCie10.presumptive;
            //console.log(ailment);
            ailment.idMedicoTratante = this.selectedConsulta.idMedico;
            ailment.nombreMedicoTratante = this.selectedConsulta.nombreMedico;
            ailment.nombrePadecimiento = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`.replace(/\b\w/g, wordFirstChar => wordFirstChar.toLocaleUpperCase());
            ailment.estatus = true;
            this.selectedConsulta.listaPadecimiento.push(ailment);

            delete this.selectedCie10;
            delete this.searchCie10;
            this.analysis.presumptive = true;
            break;
    }
}

removeCie(idCie: number, type: string): void {
    if (this.selectedConsulta.idEstadoConsulta == this.catEstatusConsulta.get('ConsultaEnTurno')) {
        let index: number;
        switch (type) {
            case '9':
            case 'cie9':
                // remove from cies9 map
                this.mapCies9.delete(idCie);
                // remove from consulta treatment list
                index = this.selectedConsulta.listaTartamiento.findIndex(treatment => treatment.catCie9Id == idCie);
                if (index >= 0) {
                    this.selectedConsulta.listaTartamiento.splice(index, 1);
                }
                // update consulta data
                this.dataChange();
                break;
            case '10':
            case 'cie10':
                this.mapCies10.delete(idCie);
                // remove from consulta treatment list
                index = this.selectedConsulta.listaPadecimiento.findIndex(ailment => ailment.cie10Id == idCie);
                if (index >= 0) {
                    this.selectedConsulta.listaPadecimiento.splice(index, 1);
                }
                break;
        }
    }
}

trackByIndex(i: number, obj: any): number {
    return obj.id;
}

forceSelectCie(ev: MouseEvent): void {
    let input: HTMLInputElement = <HTMLInputElement>ev.target;
    setTimeout(() => input.setSelectionRange(0, input.value.length), 50);
}

dataChange(): void {
    if (this.hasPermissionService.canAccess('ROLE_UPDATE_CONSULTA')) {
        if (this.timerToSave) {
            window.clearTimeout(this.timerToSave);
        }
        this.timerToSave = window.setTimeout(() => this.saveData(), 5000);
    }
}

private getPadecimientoPorConsulta(): boolean {
    let idPadecimiento: number;

    if (this.hasPadecimiento) {
        return true;
    }

    if (!isNullOrUndefined(this.selectedPadecimiento)) {
        idPadecimiento = this.selectedPadecimiento.idPadecimiento;
    }

    try {
        if (!isNullOrUndefined(idPadecimiento) && idPadecimiento > 0 && idPadecimiento) {
            this.subscriptions.add(this.ailmentService.getById(idPadecimiento).subscribe(padecimiento => {
                this.selectedPadecimiento = padecimiento;
                this.hasPadecimiento = true;
            }));
        }
    } catch (e) {

    }
}

savePhysicalExploration(): void {
    this.selectedConsulta.exploracionFisica = this.exploracionFisica;
    this.saveData();
}

saveData(): void {
    this.selectedConsulta.exploracionFisica = this.exploracionFisica;
    if (this.hasPermissionService.canAccess('ROLE_UPDATE_CONSULTA') && this.selectedConsulta.idEstadoConsulta == this.catEstatusConsulta.get('ConsultaEnTurno')) {
        //   Actualizar consulta
        this.consultaService.update(this.selectedConsulta)
            .subscribe(() => {
                this.timerToSave = null;
                this.listaConsultaService.setConsultaSelected(this.selectedConsulta);
            }, error => {
                this.notificationService.bigBox({
                    title: 'Error al actualizar la consulta',
                    content: `No se pudo actualizar la consulta`,
                    color: '#C46A69',
                    icon: 'fa fa-warning shake animated',
                    timeout: 6000
                });
                console.error('Error al Actualizar Estatus de  Consulta', error);
            });
    }
}

updateConsulta(consulta: ConsultaView): void {
    this.selectedConsulta = consulta;
    this.listaConsultaService.setConsultaSelected(consulta);
}

private obtenerPadecimientoPorDefault(): void {
    this.optionsSearchCie.orderColumn = 'nombre';
    this.optionsSearchCie.datosBusqueda = 'Z71 PERSONAS EN CONTACTO';
    this.ciesService.getPageCie10(this.optionsSearchCie).subscribe((arrayCie10) => {
        if (arrayCie10.content.length) {
            this.padecimientoDefault.consultaId = this.selectedConsulta.idConsulta;
            this.padecimientoDefault.cie10Id = arrayCie10.content[0].idCie10;
            this.padecimientoDefault.idPaciente = this.selectedConsulta.idPaciente;
            this.padecimientoDefault.idMedico = this.userService.usersDetails.idMedico;
            this.padecimientoDefault.creadoPor = this.userService.fullName;
            this.padecimientoDefault.presuntivo = true;
            this.padecimientoDefault.idMedicoTratante = this.selectedConsulta.idMedico;
            this.padecimientoDefault.nombreMedicoTratante = this.selectedConsulta.nombreMedico;
            this.padecimientoDefault.nombrePadecimiento = `${arrayCie10.content[0].catalogKey} ${arrayCie10.content[0].nombre}`.replace(/\b\w/g, wordFirstChar => wordFirstChar.toLocaleUpperCase());
            this.padecimientoDefault.estatus = true;
        }
    });
}

} 
