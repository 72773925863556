import { Injectable } from '@angular/core';
import { Headers, Http, RequestMethod, RequestOptions, URLSearchParams,Response } from '@angular/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Login, TokenManager } from 'app/+login-nio/auth-nio.classes';
import { PatientView } from 'app/+paciente/paciente.classes';
import { CustomHttp } from 'app/common/http/custom.http';
import { UserAppView } from 'app/shared/classes/user.classes';
import { RequestConfig } from 'app/shared/rest/rest.request';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CronService {

  private GET_HASHMEDICO = new RequestConfig('GET-HASHMEDICO', 'application/json', true, RequestMethod.Get, '/create-new-user/link?hash=')
  private _token: TokenManager;
  private reqGeneric = new RequestConfig('CR-PostPatologico', 'application/json', true, RequestMethod.Get, '/personal-patologico');
  private LOG_OUT: RequestConfig = new RequestConfig('AC003', 'application/json', true, RequestMethod.Post, '/users/secure/logout');


  constructor(private _http: Http) { }

  postNotification(value: any) {
    let options: RequestOptions = new RequestOptions();
    options.headers = new Headers();
    options.method = RequestMethod.Post;
    options.headers.append('Content-Type', 'application/json');
    options.headers.append('Authorization', 'Bearer ' + environment.OSTOKENAPI);
    options.body = value;
     
    var urls = 'https://onesignal.com/api/v1/notifications'

    return this._http.request(urls,options).map((res: Response) => res.json())
  }

  
  postCANCELNotification(idNotification:string) {
    let options: RequestOptions = new RequestOptions();
    options.headers = new Headers();
    options.method = RequestMethod.Delete;
    options.headers.append('Content-Type', 'application/json');
    options.headers.append('Authorization', 'Bearer ' + environment.OSTOKENAPI);
   // options.body = value;
     
    var urls = 'https://onesignal.com/api/v1/notifications/'+idNotification+'?app_id='+environment.OSAPPID

    return this._http.request(urls,options).map((res: Response) => res.ok)
  }

  postNotiPacientes(value: any) {
    var url =environment.api_url+"/notificaciones-paciente";
    return this._http.post(url, value)
        .map((res: Response) => res.ok)
        //.catch(error => this.http.handleError(error));
  }

  DELETEALL(){
    var url = environment.api_url+"/notificaciones-paciente/deleteAll";
    return this._http.delete(url)
        .map((res: Response) => res.ok);
        //.catch(error => this.http.handleError(error));
  }


  GETALL(){
    var url =environment.api_url+"/notificaciones-paciente";
    return this._http.get(url)
        .map((res: Response) => res.json())
        //.catch(error => this.http.handleError(error));
  }
  
}
