import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import {NotificationService} from '../../shared/utils/notification.service';
import {ConsultaService} from '../shared/consulta.service';
import {UserDetailsService} from 'app/shared/user/user.details.service';
import {isNullOrUndefined} from 'util';
import {Router} from '@angular/router';
import {HasPermissionService} from "../../shared/haspermission/hasPermission.service";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PacienteService} from '../../+paciente/paciente.service';
import {PageRequest, PageRequestCanalizado} from '../../common/http/page.request';
import {Observable} from 'rxjs/Observable';
import {ConsultaView, CanalConsulta, TipoConsulta, SignosVitales, ConsultTypes, ReferenciaView, ContraReferenciaView} from '../shared/consulta.classes';
import {PadecimientoView} from '../../shared/classes/ailment.classes';
import {PatientPageView, PatientView} from '../../+paciente/paciente.classes';
import {NiomedicPageRequest} from '../../common/http/niomedic.page.request';
import {PageImpl} from '../../common/http/PageImpl';
import {CatalogosService, CatEstadoConsultaView} from '../../shared/services/catalogos.service';
import {AuthNioService} from '../../+login-nio/auth-nio.service';
import {DoctorService} from '../../+doctor/doctor.service';
import {MedicoView} from '../../+doctor/doctor.classes';
import {ReportsService} from "../../shared/services/reports.service";
import {CapitalizePipe} from "../../shared/utils/capitalize.pipe";

import {CatDolometroView, CatMotivosEnvioView, DolometroLevelIcons, RegionSanitariaView, UnidadMedicaView} from "../../shared/classes/catalog.classes";
import {isThisMinute} from 'date-fns';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { Cie10 } from 'app/shared/classes/cies.classes';
import { CiesService } from 'app/shared/services/cies.service';
import { DiagnosticoHCG } from 'app/shared/classes/common.classes';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Evento } from 'app/+agenda/evento.classes';
import { EventosService } from 'app/+agenda/eventos.service';

@Component({
    selector: 'app-nueva-consulta',
    templateUrl: './nueva-consulta.component.html',
    styleUrls: ['./nueva-consulta.component.css']
})
export class NuevaConsultaComponent implements OnInit, AfterViewInit, OnDestroy {
    cssExtra: boolean = false;
    @Input() private idPaciente: string;
    @Input() private fechaConsulta: Date;
    @Input() private loadData: Observable<void>;
    @Input() idModal: string = "modalDisponibilidad";
    @Input() injected: boolean = false;  // para visualizar el modal en linea ver agenda.component

    @Output() private onConsultaAdded: EventEmitter<any> = new EventEmitter(); // cuando la consulta haya terminado, exitoso o fracaso
    @Output() availabilityOpened: EventEmitter<void> = new EventEmitter();

    availabilityModalStyles: CSSStyleDeclaration = document.createElement('span').style;
    descargaNota: boolean = false;
    private tiposConsulta: TipoConsulta[] = ConsultTypes;
    private _tipo_consulta_nueva: TipoConsulta;
    private pageSearch: NiomedicPageRequest = new NiomedicPageRequest;
    private guardando: boolean = false;
    private refreshing: boolean = false;
    cargando: boolean = false;
    trySave: boolean = false;
    REF : ReferenciaView = new ReferenciaView();
    CREF : ContraReferenciaView = new ContraReferenciaView();
    onlyConsultas: ConsultaView[] = [];
    private savedConsulta: ConsultaView;
    modelo: ConsultaView = new ConsultaView();
    consu: ConsultaView = new ConsultaView();
    EVENTOLOCAL :Evento = new Evento();
    fechasFiltro: Array<Date> = [new Date(), new Date()];
    pageSearchCanalizado: PageRequestCanalizado = new PageRequestCanalizado();
    idselectedGroup:number;
    frmNuevaConsulta: FormGroup;
    canalesConsulta: CanalConsulta[];
    selectedCanal: CanalConsulta;
    motivosEnvio: CatMotivosEnvioView[] = [];
    selectedMotivo: CatMotivosEnvioView;
    dolometros: CatDolometroView[] = [];
    dolometroLevelIcons: { [level: string]: string } = DolometroLevelIcons;
    selectedEstado: any;
    selectedRS: any
    selectedEspecialidad: any
    selectedUnidad: any
    selectedType: TipoConsulta;
    ailmentSelected: PadecimientoView;
    ailmentsSelected: PadecimientoView[] = [];
    ailmentsList: PadecimientoView[] = [];
    catEspecialidades: any[];
    diagnosticoHCG: DiagnosticoHCG = new DiagnosticoHCG();
    model = {
        motivoConsulta: '',
        tipoConsultaId: 'Inicia',
        diagnostico: '',
        tratamiento: '',
        especialidad: null,
        samu: null,
        urgencia: null,
        fechaConsulta: null,
        fechaConsultaFin: null
    };

    Estados :any =[
        {
          "id": 1,
          "name": "AGUASCALIENTES"
        },
        {
          "id": 2,
          "name": "BAJA CALIFORNIA"
        },
        {
          "id": 3,
          "name": "BAJA CALIFORNIA SUR"
        },
        {
          "id": 4,
          "name": "CAMPECHE"
        },
        {
          "id": 5,
          "name": "COAHUILA"
        },
        {
          "id": 6,
          "name": "COLIMA"
        },
        {
          "id": 7,
          "name": "CHIAPAS"
        },
        {
          "id": 8,
          "name": "CHIHUAHUA"
        },
        {
          "id": 9,
          "name": "CDMX"
        },
        {
          "id": 10,
          "name": "DURANGO"
        },
        {
          "id": 11,
          "name": "GUANAJUATO"
        },
        {
          "id": 12,
          "name": "GUERRERO"
        },
        {
          "id": 13,
          "name": "HIDALGO"
        },
        {
          "id": 14,
          "name": "JALISCO"
        },
        {
          "id": 15,
          "name": "MÉXICO"
        },
        {
          "id": 16,
          "name": "MICHOACÁN"
        },
        {
          "id": 17,
          "name": "MORELOS"
        },
        {
          "id": 18,
          "name": "NAYARIT"
        },
        {
          "id": 19,
          "name": "NUEVO LEÓN"
        },
        {
          "id": 20,
          "name": "OAXACA"
        },
        {
          "id": 21,
          "name": "PUEBLA"
        },
        {
          "id": 22,
          "name": "QUERÉTARO"
        },
        {
          "id": 23,
          "name": "QUINTANA ROO"
        },
        {
          "id": 24,
          "name": "SAN LUIS POTOSÍ"
        },
        {
          "id": 25,
          "name": "SINALOA"
        },
        {
          "id": 26,
          "name": "SONORA"
        },
        {
          "id": 27,
          "name": "TABASCO"
        },
        {
          "id": 28,
          "name": "TAMAULIPAS"
        },
        {
          "id": 29,
          "name": "TLAXCALA"
        },
        {
          "id": 30,
          "name": "VERACRUZ"
        },
        {
          "id": 31,
          "name": "YUCATÁN"
        },
        {
          "id": 32,
          "name": "ZACATECAS"
        }];

    @ViewChild('dialogSearchMedic') private dialogSearchMedicEl: ElementRef;
    @ViewChild('dialogSearchPatient') private dialogSearchPatientEl: ElementRef;

    private pageSearchMedic: PageRequest = new PageRequest();
    private medicosPage: PageImpl<MedicoView> = new PageImpl();
    private timeoutFilterMedic: number;
    isLoadingMedic: boolean = false;
    private searchCieTimeout: number;
    private optionsSearchCie: PageRequest = new PageRequest();
    private selectedCie10: Cie10;
    cargandoCie: boolean = false;
    medicosList: MedicoView[] = [];
    medicFilter: string;
    medicoLogueado: MedicoView = new MedicoView();
    selectedMedic: MedicoView = new MedicoView();
    dlgOptMedicSearch = {
        draggable: false,
        autoOpen: false,
        width: 320,
        resizable: false,
        modal: true,
        closeText: '',
        position: {my: 'left top', at: 'left top', of: <any>$('#medicoName1')},
        title: ``
    };
    searchCie10: string;
    catCie10: Array<Cie10> = [];
    private pageSearchPatient: PageRequest = new PageRequest();
    private pagesPatients: PageImpl<PatientPageView> = new PageImpl();
    loadingPatients: boolean = false;
    private timeoutFilterPatient: number;
    pacientesList: PatientView[] = [];
    arrayRegiones: RegionSanitariaView = new RegionSanitariaView;
    arrayUnidades: UnidadMedicaView =new UnidadMedicaView;
    lstCie10: Cie10[];
    private nombre: string;
    patientFilter: string;
    selectedPaciente: PatientView = new PatientView();
    dlgOptPatientSearch = {
        draggable: false,
        autoOpen: false,
        width: 320,
        resizable: false,
        modal: true,
        closeText: '',
        position: {my: 'left top', at: 'left top', of: <any>$('#itemPaciente')},
        title: ``
    };

    signosVitales: SignosVitales = new SignosVitales();
    canEditSignosVitales = true;

    @ViewChild("popLaboralInhability") private popLaboralInhability;

    constructor(private notifications: NotificationService,
                private consultaService: ConsultaService,
                private reportsService: ReportsService,
                private usuarioServicio: UserDetailsService,
                private pacienteService: PacienteService,
                private router: Router,
                private eventosService: EventosService,
                private formBuilder: FormBuilder,
                private doctorService: DoctorService,
                private authservice: AuthNioService,
                private catalogosService: CatalogosService,
                private notificationService: NotificationService,
                private hasPermissionService: HasPermissionService,private capitalizePipe: CapitalizePipe) {
    }

    ngOnInit(): void {
        this.pageSearchPatient.orderColumn = 'nombre';
        this.pageSearchPatient.orderType = 'asc';
        this.pageSearchPatient.size = 10;
        this.pageSearchPatient.page = 0;
        this.pageSearchCanalizado.size = 5;
        this.pageSearchCanalizado.page = 0;
        this.pageSearchCanalizado.orderColumn = 'nombre';
        this.pageSearchCanalizado.orderType = 'asc';
        this.pageSearchCanalizado.idUser=this.authservice.token.userId;
        let idgrupo = localStorage.getItem('id_select_group')
        this.idselectedGroup = Number(idgrupo);
        if (this.fechaConsulta) {
            this.modelo.fechaConsulta = this.fechaConsulta;
        }

        this.frmNuevaConsulta =
            this.formBuilder.group({
                canalConsulta: [''],
                tipoConsulta: [''],
                padecimiento: new FormControl({value: '', disabled: false}),
                cie10: [''],
                medico: [''],
                fecha: [this.fechaConsulta],
                especialidad: [null],
                dolencia: [''],
                motivo: ['', Validators.required],
                samu: [null],
                urgencia: [null],
                motivoEnvio: [null],
                EstadoRgs: [null],
                Rgs: [null],
                Und: [null],
                esp_Rgs: [null],
                servicio: ['']
            });

           
            this.pageSearch.page = (1 - 1);
            this.pageSearch.size = 100000;
            this.catalogosService.getEspecialidadesPage(this.pageSearch)
            .subscribe((pageLenguas) => {
                this.catEspecialidades = pageLenguas.content;
                 });

        this.catalogosService.getRegionesSanitariasEDO(14)
         .subscribe((REG) => {
           console.log(REG);
             this.arrayRegiones = REG;

         });
            
         this.catalogosService.getUnidadesMedicasEDO(14)
         .subscribe((UMD) => {
            console.log(UMD);
              this.arrayUnidades = UMD;
 
          });

        this.loadData.subscribe(() => this.dataFromServices());

        if (!this.usuarioServicio.usersDetails.idUsuario) {
            this.usuarioServicio.loadUsersDetails();
        }

        this.getMedicoLogueado();

     

        this.pageSearchMedic = {
            active: true,
            page: 0,
            size: 6,
            orderColumn: 'nombre',
            orderType: 'desc',
            name: ''
        };

        if (this.injected) {
            this.cssExtra = true;
            this.dataFromServices();
            this.selectedType = this.tiposConsulta[0];

            this.availabilityModalStyles.height = "auto";
            this.availabilityModalStyles.width = "1010px";
            this.availabilityModalStyles.marginLeft = "-100px";
            this.availabilityModalStyles.top = "0";
        }

        this.catalogosService.getDolometroAll()
            .subscribe((dolometros) => {
                if (dolometros.length) {
                    this.dolometros = dolometros
                        .sort((a, b) => a.nivel - b.nivel);
                    this.modelo.catDolometroView = this.dolometros[0];
                }
            });
        this.catalogosService.getMotivosEnvio()
            .subscribe((motivosEnvio) => this.motivosEnvio = motivosEnvio.filter((motivoEnvio) => motivoEnvio.activo));
            
    }


    forceSelectCie(ev: MouseEvent): void {
        let input: HTMLInputElement = <HTMLInputElement>ev.target;
        setTimeout(() => input.setSelectionRange(0, input.value.length), 50);
    }
    
    autocompleteCie(ev: KeyboardEvent, type: string): void {
        if (this.searchCieTimeout) clearTimeout(this.searchCieTimeout);
        if (ev.keyCode === 27 && this.selectedCie10) {
            this.catCie10 = [];
            if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
            } else {
                this.cargandoCie = true;
                this.consultaService.getCie10(this.selectedCie10.idCie10)
                    .subscribe((cie) => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                        () => this.cargandoCie = false,
                        () => this.cargandoCie = false);
            }
        } else {
            this.searchCieTimeout = window.setTimeout(() => {
                let input: HTMLInputElement = <HTMLInputElement>ev.target;
                input.onblur = null;
                if (this.searchCie10) {
                    this.optionsSearchCie.orderColumn = "nombre";
                    this.cargandoCie = true;
                    this.optionsSearchCie.datosBusqueda = this.searchCie10;
                    this.consultaService.getPageCie10(this.optionsSearchCie)
                        .subscribe((pageCie10) => {
                                this.catCie10 = pageCie10.content;
                                if (this.catCie10.length) {
                                    if (ev.keyCode === 13) this.assignCie(this.catCie10[0], type);
                                } else if (this.selectedCie10) {
                                    input.onblur = () => {
                                        input.onblur = null;
                                        if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                                            this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                                        } else {
                                            this.consultaService.getCie10(this.selectedCie10.idCie10)
                                                .subscribe((cie) => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                                                    () => this.cargandoCie = false,
                                                    () => this.cargandoCie = false);
                                        }
                                    };
                                }
                            },
                            () => this.cargandoCie = false,
                            () => this.cargandoCie = false);
                } else {
                    this.catCie10 = [];
                    if (this.selectedCie10) {
                        input.onblur = () => {
                            input.onblur = null;
                            if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                                this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                            } else {
                                this.consultaService.getCie10(this.selectedCie10.idCie10).subscribe(
                                    cie => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                                    () => {
                                        this.cargandoCie = false
                                    },
                                    () => this.cargandoCie = false);
                            }
                        };
                    }
                }
            }, 300);
        }
    }

    assignCie(cie: any, type?: string): void {
        this.selectedCie10 = cie;
        this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`;
        this.catCie10 = [];
    }

    borrarCie10(index): void {
        this.diagnosticoHCG.lstCie10.splice(index,1);
    }

    agregarEnfermedad(): void {
         if (this.selectedCie10 && this.selectedCie10.nombre) {
            this.selectedCie10.nombre = this.capitalizePipe.transform(this.selectedCie10.nombre);
            if (this.diagnosticoHCG.lstCie10.findIndex((cie10) => cie10.nombre.toLowerCase() == this.selectedCie10.nombre.toLowerCase()) < 0) {
                this.diagnosticoHCG.lstCie10 = [...this.diagnosticoHCG.lstCie10, this.selectedCie10];
                this.selectedCie10 = new Cie10();
                this.selectedCie10 = null;
                this.searchCie10 = null;

                //setTimeout(() => this.sendData(), 300);
            }
        }
    }
    getMedicoLogueado() {
        this.doctorService.getByIdUser(this.authservice.token.userId)
            .subscribe((medico) => {
                this.medicoLogueado = JSON.parse(JSON.stringify(medico));
                // this.medicoLogueado = medico;
                this.nombre = `${medico.nombre} ${medico.apellidoPaterno || ''} ${medico.apellidoMaterno || ''}`;
                this.refreshing = false;
            }, () => {
                this.refreshing = false;
            }, () => {
                this.refreshing = false;
            });
    }

    getDataOnceAgain() {
        if (!this.refreshing && (!this.medicoLogueado || this.medicoLogueado && !this.medicoLogueado.idMedico)) {
            this.refreshing = true;
            this.getMedicoLogueado();
        }
    }

    ngAfterViewInit(): void {
        this.searchMedicDlg.dialog('close');
    }

    ngOnDestroy(): void {
        this.searchMedicDlg.on('dialogopen', () => this.searchMedicDlg.dialog('moveToTop'));
    }

    showAgenda(): void {
        this.availabilityOpened.emit();
    }

    private dataFromServices(): void {
        this.fechaConsulta && this.fechaConsulta.setMinutes((this.fechaConsulta.getMinutes() - this.fechaConsulta.getMinutes() % 5) + 5);

        if (isNullOrUndefined(this.canalesConsulta)) {
            // cargar y seleccionar el canal de consulta, por default es presencial
            this.consultaService.getCanalConsulta()
                .subscribe((canalesConsulta) => {
                    this.canalesConsulta = canalesConsulta;
                    this.selectedCanal = this.canalesConsulta[0];
                });
        }

        if (isNullOrUndefined(this.tiposConsulta)) {
            this.tiposConsulta = [
                {id: 1, descripcion: 'Nueva'},
                {id: 2, descripcion: 'Interconsulta'},
                {id: 3, descripcion: 'Referencia'},
                {id: 4, descripcion: 'Subsecuente'}
            ];
        }

        this.clearData();

        this.loadMedicos(true);
    }

    /**
     * Cargar padecimientos
     */
    private loadAilments(): void {
        console.log("LOADAILMENTS");
        console.log(this.selectedPaciente);
        if (this.selectedPaciente) {
            if (this.selectedType && this.selectedType.id > 1) {
                const requestOptions: NiomedicPageRequest = new NiomedicPageRequest();
                requestOptions.idPaciente = this.selectedPaciente.idPaciente;
                this.pacienteService.getClinicCourse(requestOptions)
                    .subscribe((clinicCoursePage) => {
                        console.log("dentro Evento getclinick");
                        console.log(clinicCoursePage);
                        this.ailmentsList = clinicCoursePage.content;
                        if (this.ailmentsList.length) {
                            this.frmNuevaConsulta.get('padecimiento').enable();
                        }
                    });
            } else {
                console.log("else getclinick");
                delete this.ailmentSelected;
                this.ailmentsList = [];
                this.ailmentsSelected = [];
                this.frmNuevaConsulta.get('padecimiento').disable();
            }
        }
    }

    addAilment(): void {
        if (this.ailmentSelected) {
            let index: number;
            index = this.ailmentsList.indexOf(this.ailmentSelected);
            if (index >= 0) {
                this.ailmentsSelected = this.ailmentsSelected.concat(this.ailmentsList.splice(index, 1));
                this.ailmentsList = this.ailmentsList.slice();
                if (!this.ailmentsList.length) {
                    this.frmNuevaConsulta.get('padecimiento').disable();
                }
                delete this.ailmentSelected;
            }
        }
    }

    removeAilment(index: number): void {
        this.ailmentsList = this.ailmentsList.concat(this.ailmentsSelected.splice(index, 1));
        this.frmNuevaConsulta.get('padecimiento').enable();
    }

    nuevaConsulta({imprimirReferencia = false}: { imprimirReferencia: boolean }): void {
        this.trySave = true;

        if (this.cargando) {
            return;
        }

        Object.keys(this.frmNuevaConsulta.controls).forEach(key => {
            this.frmNuevaConsulta.get(key).markAsDirty();
            this.frmNuevaConsulta.get(key).updateValueAndValidity();
        });

        if (this.selectedType && this.selectedType.id > 1) {
            if (!isNullOrUndefined(this.ailmentSelected)) {
                this.addAilment();
            }

            if (!this.ailmentsSelected.length) {
                this.frmNuevaConsulta.get('padecimiento').setErrors({'empty': true});
            } else {
                this.frmNuevaConsulta.get('padecimiento').setErrors(null);
            }
        }

        if (this.selectedType && this.selectedType.id == 3) {
            if (!this.selectedMotivo) {
                this.frmNuevaConsulta.get('motivoEnvio').setErrors({'required': true});
            } else {
                this.frmNuevaConsulta.get('motivoEnvio').setErrors(null);
            }

            if (!this.modelo.servicio) {
                this.frmNuevaConsulta.get('servicio').setErrors({'required': true});
            } else {
                this.frmNuevaConsulta.get('servicio').setErrors(null);
            }
        }

        console.log(this.frmNuevaConsulta);
        console.log(this.modelo.fechaConsulta);

        // if(this.modelo.fechaConsulta === undefined){
            
        //     this.modelo.fechaConsulta = new Date();
        //     this.modelo.fechaConsultaFin = new Date();
        // }

        // // if ( modelo con fecha afuerzas
        // //     this.frmNuevaConsulta.valid
        // //     && this.selectedPaciente
        // //     && this.modelo.fechaConsulta
        // // )
        if (
            this.frmNuevaConsulta.valid
            && this.selectedPaciente
            && this.modelo.fechaConsulta
        ) {
            this.modelo.creadoPor = this.usuarioServicio.fullName;
            this.modelo.tipoConsulta = this.selectedType.descripcion;
            this.modelo.fechaCrecion = new Date();
            this.modelo.idPaciente = this.selectedPaciente.idPaciente;
            this.modelo.nombrePaciente = `${this.selectedPaciente.nombre ? this.selectedPaciente.nombre : ''} ${this.selectedPaciente.apellidoPaterno ? this.selectedPaciente.apellidoPaterno : ''} ${this.selectedPaciente.apellidoMaterno ? this.selectedPaciente.apellidoMaterno : ''}`;
            this.modelo.fechaInicio = null;
            this.modelo.feachaFin = null;
            this.modelo.numeroConsulta = '' + Math.floor(Math.random() * 900000000);
            this.modelo.canal = this.selectedCanal.descripcion;

            this.modelo.motivoConsulta = this.model.motivoConsulta;
            this.modelo.idMedico = this.usuarioServicio.usersDetails.idMedico;
            this.modelo.especialidad = this.medicoLogueado.especialidadViewList[0].especialidad;
            this.modelo.listaPadecimiento = this.ailmentsSelected;
            this.modelo.idTipoConsulta = this.selectedType.id;
            this.modelo.nombreMedico = this.usuarioServicio.usersDetails.fullName ? this.usuarioServicio.usersDetails.fullName : `${this.usuarioServicio.usersDetails.nombre ? this.usuarioServicio.usersDetails.nombre : ''} ${this.usuarioServicio.usersDetails.apellidoPaterno ? this.usuarioServicio.usersDetails.apellidoPaterno : ''} ${this.usuarioServicio.usersDetails.apellidoMaterno ? this.usuarioServicio.usersDetails.apellidoMaterno : ''}`;
            if (this.selectedMedic.idMedico) {
                this.modelo.idMedicoSolicitado = this.selectedMedic.idMedico;
                this.modelo.nombreMedicoSolicitado = this.selectedMedic.fullName ? this.selectedMedic.fullName : this.selectedMedic.nombre;
                if (this.selectedMedic.especialidadViewList.length) {
                    this.modelo.especialidadMedicoSolicitado = this.selectedMedic.especialidadViewList[0].especialidad;
                } else {
                    this.modelo.especialidadMedicoSolicitado = 'MEDICINA GENERAL';
                }

            }
            if (this.selectedType.id === 2 || this.selectedType.id === 3) {
                this.modelo.nombreMedico = this.modelo.nombreMedicoSolicitado;
            }
            if (this.model.urgencia !== null) {
                this.modelo.urgente = this.model.urgencia;
            }

            if (this.model.samu !== null) {
                this.modelo.samu = this.model.samu;
            }

            this.modelo.signosVitales = this.signosVitales;

            if (this.modelo.motivoEnvio) {
                this.modelo.idMotivoEnvio = 1;
            }

            if (this.modelo.servicio) {
                this.modelo.idServicio = 1;
            }

            this.cargando = true;
            console.log(this.modelo);
            this.consultaService.getEstadoConsultaActual(1)
                .subscribe((estadoConsultaActual: CatEstadoConsultaView) => {
                    this.modelo.estadoConsulta = estadoConsultaActual.descripcion;
                    this.modelo.idEstadoConsulta = estadoConsultaActual.idEstadoConsulta;
                    this.modelo.idGroup = this.idselectedGroup;
                    this.consultaService.save(this.modelo)
                        .subscribe((savedConsultaEvent) => {
                            setTimeout(() => {
                                console.log(savedConsultaEvent);
                                this.EVENTOLOCAL.tipoEventoId=2;
                                this.EVENTOLOCAL.titulo = "CONSULTA-"+savedConsultaEvent.numeroConsulta.toString();
                                this.EVENTOLOCAL.descripcion = "CONSULTA PARA "+savedConsultaEvent.nombrePaciente;
                                this.EVENTOLOCAL._eventoColor="#346FF1";
                                this.EVENTOLOCAL.idUsuario = savedConsultaEvent.idUsuario;
                                this.EVENTOLOCAL.idUsuarioRecibe = savedConsultaEvent.idUsuario;
                                this.EVENTOLOCAL.idPaciente = savedConsultaEvent.idPaciente;
                                this.EVENTOLOCAL.idConsulta = savedConsultaEvent.idConsulta;
                                this.EVENTOLOCAL.especialidad = "MEDICINA GENERAL";
                                this.EVENTOLOCAL.status = 0;
                                this.EVENTOLOCAL.alerta= savedConsultaEvent.fechaConsulta
                                this.EVENTOLOCAL.inicio= savedConsultaEvent.fechaConsulta
                                this.EVENTOLOCAL.fin= savedConsultaEvent.fechaConsultaFin
                                this.EVENTOLOCAL.regionSanitaria = Number(localStorage.getItem('id_select_group'));
                                this.EVENTOLOCAL.unidadMedica =localStorage.getItem('group_name_selected');
                                const evento = Object.assign({}, this.EVENTOLOCAL);

                                this.eventosService.create(evento)
                                .subscribe((eventAdded) => {console.log(eventAdded)});
                                this.cargando = false;
                                if (imprimirReferencia && savedConsultaEvent && savedConsultaEvent.idConsulta) {
                                    this.printReference(savedConsultaEvent.idConsulta);
                                    (<any>$('#newConsulta')).modal('hide');
                                    this.refreshTable();
                                    this.resetlista(); // GGR 20200518
                                    this.notificationService.smallBoxSuccess({
                                           title: 'Exito',
                                            content: 'Consulta creada',
                                            color: '#22bb33',
                                            icon: 'fa fa-check',
                                            timeout: 3000
                                    });
                                   
                                    this.onConsultaAdded.emit({error: false});
                                } else {
                                    this.resetlista(); // GGR 20200518
                                    this.notificationService.smallBoxSuccess({
                                            title: 'Exito',
                                             content: 'Consulta creada',
                                             color: '#22bb33',
                                             icon: 'fa fa-check',
                                             timeout: 3000
                                     });
                                    (<any>$('#newConsulta')).modal('hide');
                                    this.refreshTable();
                                    this.cargando = false;
                                    this.onConsultaAdded.emit({error: false});
                                }
                            });

                         

                        }, (error) => {
                            console.log("EROR");
                            this.cargando = false;
                            this.onConsultaAdded.emit({error: true});
                            this.notificationService.bigBox({
                                title: 'Error al guardar la consulta',
                                content: `No se pudo guardar la consulta ${JSON.stringify(error)}`,
                                color: '#C46A69',
                                icon: 'fa fa-warning',
                                timeout: 6000
                            });
                        }, () => {
                            this.cargando = false;
                        });
                });
        }
    }


    nuevaReferencia() : void {
        console.log("nuevaReferencia");
        
        if(this.selectedMedic && this.usuarioServicio && this.selectedPaciente && this.selectedEstado && this.selectedRS && this.selectedEspecialidad && this.selectedUnidad){

            if(this.selectedType.id==2){this.REF.tipo=2;}
            if(this.model.urgencia == null) {this.model.urgencia = false;}
            if(this.model.samu == null) {this.model.samu = false;}

            var idselectedGroupN = localStorage.getItem('group_name_selected');
            var idselectedGroup = localStorage.getItem('id_select_group');
            this.REF.umRefClues = idselectedGroupN;
            this.REF.regionSanitaria = Number(idselectedGroup);
            this.REF.idPaciente=this.selectedPaciente.idPaciente;
            this.REF.dx1=0;
            this.REF.dx2=0;
            this.REF.dx3=0;
            this.REF.dx4=0;
            this.REF.dx5=0;
            this.REF.dx6=0;
            if(!this.model.urgencia){this.REF.urgente=0;}
            this.REF.refSamu=this.model.samu;
            this.REF.unidadMedica = this.selectedUnidad;
            this.REF.espReq = this.selectedEspecialidad;
            this.REF.idMedicoRecibe = this.selectedMedic.idMedico;
            this.REF.refMotivo =this.selectedMotivo.motivosEnvioDescripcion;
            this.REF.refResClin = this.model.motivoConsulta;
            this.REF.refSvTaSys = this.signosVitales.sistolica;
            this.REF.refSvTaDia =this.signosVitales.diastolica;
            this.REF.refSvTemp = Number(this.signosVitales.temperatura);
            this.REF.refSvFr =this.signosVitales.frecRespiratoria;
            this.REF.refSvFc =this.signosVitales.frecCardiaca;
            this.REF.refSvPeso =this.signosVitales.peso;
            this.REF.refSvTalla =this.signosVitales.talla;
            this.REF.idMedicoCrea = this.usuarioServicio.usersDetails.idMedico;
            this.REF.estado =1;
            this.REF.activo=1;

            if(this.diagnosticoHCG.lstCie10.length>0){
                for(var x =0;x<this.diagnosticoHCG.lstCie10.length;x++){
                    if(x>5){break;}
                    switch(x){
                        case 0:
                            this.REF.dx1=this.diagnosticoHCG.lstCie10[x].idCie10;
                        break;
                        case 1:
                            this.REF.dx2=this.diagnosticoHCG.lstCie10[x].idCie10;
                        break;
                        case 2:
                            this.REF.dx3=this.diagnosticoHCG.lstCie10[x].idCie10;
                        break;
                        case 3:
                            this.REF.dx4=this.diagnosticoHCG.lstCie10[x].idCie10;
                        break;
                        case 4:
                            this.REF.dx5=this.diagnosticoHCG.lstCie10[x].idCie10;
                        break;
                        case 5:
                            this.REF.dx6=this.diagnosticoHCG.lstCie10[x].idCie10;
                        break;
                    }

                }
            }

            this.consultaService.saveREF(this.REF).subscribe((succesful) => {
                console.log("GUARDAR"+succesful);
                this.notificationService.smallBox(
                    {
                        title: "REFERENCIA CREADA",
                        content: '<br>',
                        color: "#739E73",
                        icon: "fa fa-check",
                        timeout: 3000
                    }
                  );
                            if(this.selectedType.id==2){
                                this.EVENTOLOCAL.tipoEventoId=3;
                                this.EVENTOLOCAL.titulo = "INTERCONSULTA-"+succesful.folio;
                                this.EVENTOLOCAL.descripcion = "INTERCONSULTA- "+succesful.nombre;
                                this.EVENTOLOCAL._eventoColor="#EFAD1C";
                            }else{
                                this.EVENTOLOCAL.tipoEventoId=4;
                                this.EVENTOLOCAL.titulo = "REFERENCIA-"+succesful.folio;
                                this.EVENTOLOCAL.descripcion = "REFERENCIA- "+succesful.nombre;
                                this.EVENTOLOCAL._eventoColor="#009966";
                            }
                            
                            this.EVENTOLOCAL.idUsuario = this.usuarioServicio.usersDetails.idUsuario;
                            this.EVENTOLOCAL.idUsuarioRecibe = this.selectedMedic.idUsuario;
                            this.EVENTOLOCAL.idPaciente = this.REF.idPaciente;
                            this.EVENTOLOCAL.especialidad = this.selectedEspecialidad;
                            this.EVENTOLOCAL.status = 0;
                            this.EVENTOLOCAL.regionSanitaria = this.REF.regionSanitaria;
                            this.EVENTOLOCAL.unidadMedica =this.REF.umRefClues;


                            const evento = Object.assign({}, this.EVENTOLOCAL);

                            this.eventosService.create(evento)
                            .subscribe((eventAdded) => {console.log(eventAdded)});

                },(error) => {
                    console.log("ERROR AL GUARDAR notificacion :"+error)
                });
            
            (<any>$('#newConsulta')).modal('hide');
        }else{
            this.notificationService.smallBox(
                {
                    title: "FAVOR DE LLENAR LOS CAMPOS NECESARIOS",
                    content: '<br>',
                    color: "#d9534f",
                    icon: "fa fa-times",
                    timeout: 3000
                }
              );
        }

        console.log(this.REF);
        
        console.log("fin nuevaReferencia");
        

    }

    nuevaConsultaRAPIDA({imprimirReferencia = false}: { imprimirReferencia: boolean }): void {
        this.trySave = true;

        if (this.cargando) {
            return;
        }

        Object.keys(this.frmNuevaConsulta.controls).forEach(key => {
            this.frmNuevaConsulta.get(key).markAsDirty();
            this.frmNuevaConsulta.get(key).updateValueAndValidity();
        });

       
        console.log(this.frmNuevaConsulta);
        console.log(this.modelo.fechaConsulta);

        if(this.modelo.fechaConsulta === undefined){
            
            this.modelo.fechaConsulta = new Date();
            this.modelo.fechaConsultaFin = new Date();
        }

        if (
            this.selectedPaciente.idPaciente !==undefined
        ) {
            this.modelo.creadoPor = this.usuarioServicio.fullName;
            this.modelo.tipoConsulta = this.selectedType.descripcion;
            this.modelo.fechaCrecion = new Date();
            this.modelo.idPaciente = this.selectedPaciente.idPaciente;
            this.modelo.nombrePaciente = `${this.selectedPaciente.nombre ? this.selectedPaciente.nombre : ''} ${this.selectedPaciente.apellidoPaterno ? this.selectedPaciente.apellidoPaterno : ''} ${this.selectedPaciente.apellidoMaterno ? this.selectedPaciente.apellidoMaterno : ''}`;
            this.modelo.fechaInicio = null;
            this.modelo.feachaFin = null;
            this.modelo.numeroConsulta = '' + Math.floor(Math.random() * 900000000);
            this.modelo.canal = this.selectedCanal.descripcion;

            this.modelo.motivoConsulta = this.model.motivoConsulta;
            this.modelo.idMedico = this.usuarioServicio.usersDetails.idMedico;
            this.modelo.especialidad = this.medicoLogueado.especialidadViewList[0].especialidad;
            this.modelo.listaPadecimiento = this.ailmentsSelected;
            this.modelo.idTipoConsulta = this.selectedType.id;
            this.modelo.nombreMedico = this.usuarioServicio.usersDetails.fullName ? this.usuarioServicio.usersDetails.fullName : `${this.usuarioServicio.usersDetails.nombre ? this.usuarioServicio.usersDetails.nombre : ''} ${this.usuarioServicio.usersDetails.apellidoPaterno ? this.usuarioServicio.usersDetails.apellidoPaterno : ''} ${this.usuarioServicio.usersDetails.apellidoMaterno ? this.usuarioServicio.usersDetails.apellidoMaterno : ''}`;
            if (this.selectedMedic.idMedico) {
                this.modelo.idMedicoSolicitado = this.selectedMedic.idMedico;
                this.modelo.nombreMedicoSolicitado = this.selectedMedic.fullName ? this.selectedMedic.fullName : this.selectedMedic.nombre;
                if (this.selectedMedic.especialidadViewList.length) {
                    this.modelo.especialidadMedicoSolicitado = this.selectedMedic.especialidadViewList[0].especialidad;
                } else {
                    this.modelo.especialidadMedicoSolicitado = 'MEDICINA GENERAL';
                }

            }
           

            this.modelo.signosVitales = this.signosVitales;


            this.cargando = true;
            console.log(this.modelo);
            this.consultaService.getEstadoConsultaActual(1)
                .subscribe((estadoConsultaActual: CatEstadoConsultaView) => {
                    this.modelo.estadoConsulta = estadoConsultaActual.descripcion;
                    this.modelo.idEstadoConsulta = estadoConsultaActual.idEstadoConsulta;
                    this.modelo.idGroup = this.idselectedGroup;
                    this.consultaService.save(this.modelo)
                        .subscribe((savedConsultaEvent) => {
                            setTimeout(() => {
                                if (imprimirReferencia && savedConsultaEvent && savedConsultaEvent.idConsulta) {
                                    this.printReference(savedConsultaEvent.idConsulta);
                                    (<any>$('#newConsulta')).modal('hide');
                                    this.refreshTable();
                                    this.resetlista(); // GGR 20200518
                                    this.notificationService.smallBoxSuccess({
                                           title: 'Exito',
                                            content: 'Consulta creada',
                                            color: '#22bb33',
                                            icon: 'fa fa-check',
                                            timeout: 3000
                                    });
                                    this.cargando = false;
                                    this.onConsultaAdded.emit({error: false});
                                } else {
                                    this.resetlista(); // GGR 20200518
                                    this.notificationService.smallBoxSuccess({
                                            title: 'Exito',
                                             content: 'Consulta creada',
                                             color: '#22bb33',
                                             icon: 'fa fa-check',
                                             timeout: 3000
                                     });
                                    (<any>$('#newConsulta')).modal('hide');
                                    this.refreshTable();
                                    this.cargando = false;
                                    this.onConsultaAdded.emit({error: false});
                                    let startD: Date, endD: Date;
                                    startD = this.fechasFiltro[0];
                                    startD.setHours(0, 0, 0, 0);
                                    endD = this.fechasFiltro[1];
                                    endD.setHours(23, 59, 59, 99);
                                    let options: NiomedicPageRequest = new NiomedicPageRequest;
                                        options.startDate = startD.getTime();
                                        options.endDate = endD.getTime();
                                        options.page = 0;
                                        options.size = 1000;
                                        options.orderColumn = 'fechaConsulta';
                                        options.orderType = 'desc';
                                        options.idMedico = this.modelo.idMedico;
                                        options.idPaciente = this.modelo.idPaciente;

                                    this.consultaService.getPage(options)
                                    .subscribe((pageConsultaView) => {
                                        this.onlyConsultas = pageConsultaView.content;
                                        console.log("entro only");
                                        console.log(this.onlyConsultas);
                                        for(var x=0;x<=this.onlyConsultas.length;x++){
                                            console.log(this.onlyConsultas[x]);
                                            if(this.onlyConsultas[x]['idEstadoConsulta']==1){
                                                this.consu = this.onlyConsultas[x];
                                                break;
                                            }
                                        }
                                       
                                        this.consultaStartAndGo(this.consu);

                                    });


                                }
                            });
                        }, (error) => {
                            console.log("EROR");
                            this.cargando = false;
                            this.onConsultaAdded.emit({error: true});
                            this.notificationService.bigBox({
                                title: 'Error al guardar la consulta',
                                content: `No se pudo guardar la consulta ${JSON.stringify(error)}`,
                                color: '#C46A69',
                                icon: 'fa fa-warning',
                                timeout: 6000
                            });
                        }, () => {
                            this.cargando = false;
                        });
                });
        }
    }

    consultaStartAndGo(consulta: ConsultaView): void {
        if (this.hasPermissionService.canAccess('start.consulta, update.consulta')) {
            if (consulta.idEstadoConsulta == 1 || consulta.idEstadoConsulta == 5 || consulta.idEstadoConsulta == 7) {
                this.descargaNota = true;
                this.consultaService.iniciarConsulta(consulta)
                    .subscribe(() => {
                        this.onRowClicked(null, consulta)
                    });
            }
        }
    }

    onRowClicked(params, consulta: ConsultaView): void {
        this.router.navigate(['consulta/detalle', consulta.idConsulta]).then(() => this.descargaNota = false);
    }
    private clearData(): void {
        this.model = {
            motivoConsulta: '',
            tipoConsultaId: 'Inicial',
            diagnostico: '',
            tratamiento: '',
            especialidad: null,
            samu: null,
            urgencia: null,
            fechaConsulta: null,
            fechaConsultaFin: null
        };

        this.modelo = new ConsultaView();
        delete this.modelo.motivoEnvio;
        delete this.modelo.servicio;
        if (this.dolometros.length) {
            this.modelo.catDolometroView = this.dolometros[0];
        }

        this.selectedMedic = new MedicoView();
        delete this.selectedPaciente;
        if (this.canalesConsulta && this.canalesConsulta.length) {
            this.selectedCanal = this.canalesConsulta[0];
        } else {
            delete this.selectedCanal;
        }

        delete this.selectedType;
        delete this.ailmentSelected;

        this.ailmentsList = [];
        this.ailmentsSelected = [];
        this.frmNuevaConsulta.get('padecimiento').disable();
        Object.keys(this.frmNuevaConsulta.controls)
            .forEach((key) => {
                this.frmNuevaConsulta.get(key).markAsPristine();
                this.frmNuevaConsulta.get(key).markAsUntouched();
            });

        this.signosVitales = new SignosVitales();

        this.trySave = false;
    }

    private refreshTable(): void {
        if (this.router.url === '/consulta/lista') {
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
                return false;
            };
            this.router.navigated = false;
            this.router.navigate([this.router.url]);
        }
    }
    resetlista(){
        this.model.motivoConsulta='';
        this.model.fechaConsulta=null;
        this.model.fechaConsultaFin=null;
        delete this.selectedPaciente;
    }
    loadMorePatients(reset = false): void {
        console.log("servicio de pacientes "+this.pacientesList);
        console.log(this.pacientesList);
        console.log(this.pagesPatients);

        if (this.pagesPatients.last && !reset) {
            console.log("pimer reset" + reset);
            return;
        }

        if (reset) {
            console.log("segundo reset" + reset);
            this.pageSearchPatient.page = 0;
            this.loadingPatients = true;
        } else if (this.pageSearchPatient.page < this.pagesPatients.totalPages) {
            console.log("else reset" + reset);
            this.pageSearchPatient.page = this.pageSearchPatient.page + 1;
            this.loadingPatients = true;
        }

        // this.loadingPatients = true;
        if(this.authservice.token.idTipoUsuario===2){
        this.pageSearchPatient.selectGroup=Number(localStorage.getItem('id_select_group'));}
        this.pacienteService.getPage(this.pageSearchPatient)
            .subscribe((patientPage) => {
                console.log("engo getpage");
                console.log(patientPage);
                console.log(this.pageSearchPatient);
                console.log("salio");

                this.loadingPatients = false;
                if(this.pageSearchPatient.name !=""){
                    this.pacientesList=[];
                }
                this.pacientesList = [].concat(this.pacientesList, patientPage.content);
                this.pagesPatients = patientPage;
                //  this.loadingPatients = false;
            }, () => {
                console.log("engo getpage1");
                this.loadingPatients = false;
            }, () => this.loadingPatients = false);
        this.pacienteService.getPageCanalizado(this.pageSearchCanalizado).subscribe((patientPageView) => {
            this.pacientesList = [].concat(this.pacientesList, patientPageView.content);
        });
        // console.log(pacientelistCanalizado);
        // this.pacientesList=this.pacientesList.concat(this.pacientesList,pacientelistCanalizado);
        
        
    }

    filterPatient(): void {
        clearTimeout(this.timeoutFilterPatient);

        this.timeoutFilterPatient = window.setTimeout(() => {
            if (this.pageSearchPatient.name != this.patientFilter) {
                this.pageSearchPatient.name = this.patientFilter;
                this.loadMorePatients(true);
            }
        }, 200);

    }

    filterMedic(): void {
        clearTimeout(this.timeoutFilterMedic);

        this.timeoutFilterMedic = window.setTimeout(() => {
            if (this.pageSearchMedic.name != this.medicFilter) {
                this.pageSearchMedic.name = this.medicFilter;
                this.loadMedicos(true);
            }
        }, 500);
    }

    set tipo_consulta_nueva(id: number) {
        console.log("id tipo consulta " + id);
        this.tiposConsulta.map((el) => {
            if (el.id === id) {
                this._tipo_consulta_nueva = el;
                this.selectedType = el;
                console.log(this.selectedType);
            }
        });
        this.loadAilments();
    }

    get searchMedicDlg(): any {
        return $(this.dialogSearchMedicEl.nativeElement);
    }

    get searchPatientDialog(): any {
        return $(this.dialogSearchPatientEl.nativeElement);
    }

    openDlgSearch(): void {
        //agregado para limpiar la busqueda
        this.medicFilter = '';

        this.searchMedicDlg.dialog('option', 'position', {my: 'left top', at: 'left top', of: <any>$('#medicoName1')});
        this.searchMedicDlg.dialog('open');
    }

    openDialogPacientes(): void {
        //agregado para limpiar la busqueda
        this.pacientesList = [];
        setTimeout(() => {
            this.patientFilter = '';
            this.pageSearchPatient.name = '';
            this.loadMorePatients(true);  // deberia
        }, 100);

        let idItemPaciente: string = '#itemPaciente' + (this.injected ? 'Injected' : '');
        this.searchPatientDialog.dialog('option', 'position', {
            my: 'left top',
            at: 'left top',
            of: <any>$(idItemPaciente)
        });
        this.searchPatientDialog.dialog('open');
    }

    private loadMedicos(reset = false): void {
        if (this.medicosPage.last && !reset) {
            return;
        }

        if (reset) {
            this.pageSearchMedic.page = 0;
            this.isLoadingMedic = true;
        } else if (this.pageSearchMedic.page < this.medicosPage.totalPages) {
            this.pageSearchMedic.page++;
            this.isLoadingMedic = true;
        }

        this.isLoadingMedic = true;
        this.doctorService.getPage(this.pageSearchMedic)
            .subscribe((pageMedico) => {
                this.isLoadingMedic = false;
                if (reset) {
                    this.medicosList = [];
                }
                this.medicosList = [];
                this.medicosList = [].concat(this.medicosList, pageMedico.content);
                this.medicosList = this.medicosList.filter((medico) => medico.idMedico != this.medicoLogueado.idMedico);
                this.medicosPage = pageMedico;
                this.isLoadingMedic = false;
            }, () => this.isLoadingMedic = false, () => this.isLoadingMedic = false);
    }

    onListaMedicoScroll(event: any): void {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadMedicos();
        }
    }

    onListaPacienteScroll(event: any): void {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadMorePatients();
        }
    }

    setSelectMedic(medico: MedicoView): void {
        //agregado para limpiar la busqueda
        this.medicosList = [];
        let Medico = medico;
        if (this.medicoLogueado && this.medicoLogueado.idMedico) {
            if (this.medicoLogueado.idMedico !== medico.idMedico) {
                this.searchMedicDlg.dialog('close');
                this.medicFilter = "";
                this.selectedMedic = medico;
            } else {
                this.notifications.smallBox({
                    title: 'Atención',
                    content: 'No puede elegir al medico en sesión.',
                    color: '#df295f',
                    icon: 'fa fa-warning shake',
                    timeout: 4000
                });
            }
        } else {
            if (this.authservice.token && this.authservice.token.userId) {
                this.doctorService.getByIdUser(this.authservice.token.userId)
                    .subscribe((medico) => {
                        this.medicoLogueado = JSON.parse(JSON.stringify(medico));
                        this.nombre = `${medico.nombre} ${medico.apellidoPaterno || ''} ${medico.apellidoMaterno || ''}`;
                        this.setSelectMedic(Medico);
                    });
            }
        }
    }

    setSelectPatient(patient: PatientView): void {
        this.searchPatientDialog.dialog('close');
        this.patientFilter = "";
        this.selectedPaciente = patient;
        if ( this.selectedPaciente && !this.selectedPaciente.numeroExpediente  ) {
            this.pacienteService.getById(this.selectedPaciente.idPaciente).subscribe((patient) => this.selectedPaciente = patient);
        }
        this.loadAilments();
    }

    onDisponibilidad($event: Evento): void {
        if ($event.inicio || $event.fin) {
            this.modelo.fechaConsulta = $event.inicio;
            this.modelo.fechaConsultaFin = $event.fin && $event.fin || null;
        }
        this.EVENTOLOCAL = $event;

            console.log(this.selectedType);
    }

    areaPatientMedics(internal: boolean = false): string {
        if (this.selectedType) {
            switch (this.selectedType.id) {
                case 1:
                case 4:
                    if (!internal) {
                        return "col-sm-4";
                    } else {
                        return "col-sm-12";
                    }
                case 2:
                case 3:
                    if (!internal) {
                        return "col-sm-12";
                    } else {
                        return "col-sm-4 mb-1";
                    }
                case 10:
                    return "col-sm-12";
                default:
                    return "hidden";
            }
        }

        return "hidden";
    }

    clickNewConsulta(): void {
        if (this.popLaboralInhability && this.popLaboralInhability.isOpen) {
            $("#openLaboralInhability").trigger('click');
        }
    }

    private printReference(idConsulta: number): void {
        this.reportsService.getNoteReference(idConsulta)
            .take(1)
            .subscribe((pdfBase64File) => {
                this.downloadDocument(pdfBase64File, `Referencia-${this.modelo.nombrePaciente}pdf`);

                delete this.savedConsulta;
                this.cargando = false;
                (<any>$('#newConsulta')).modal('hide');
            }, () => {
                this.cargando = false;
            }, () => {
                this.cargando = false;
            });
    }

    downloadDocument(pdfBase64File: string, name?: string) {
        this.cargando = true;
        let url = `data:application/pdf;base64,${pdfBase64File}`;
        fetch(url)
            .then(res => res.blob())
            .then(function (blob) {
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, name || "referencia.pdf");
                } else {
                    var elem = window.document.createElement('a');
                    elem.href = window.URL.createObjectURL(blob);
                    elem.download = name || "referencia.pdf";
                    document.body.appendChild(elem);
                    elem.click();
                    document.body.removeChild(elem);
                }
            });
    }
}
