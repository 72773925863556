import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NuevaConsultaComponent} from './nueva-consulta.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {UtilsModule} from 'app/shared/utils/utils.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AvatarUrlPipe} from "../../shared/utils/avatar-image-pipe";
import {AilmentService} from "../../shared/services/ailment.service";
import {I18nModule} from "../../shared/i18n/i18n.module";
import {PopoverModule, AccordionModule} from "ngx-bootstrap";
import {NgZorroAntdModule} from "ng-zorro-antd";
import {ConsultaService} from '../shared/consulta.service';
import {JqueryUiModule} from '../../shared/ui/jquery-ui/jquery-ui.module';
import {DisponibilidadComponent} from './disponibilidad/disponibilidad.component';
import {SharedModule} from "../../shared/shared.module";
import {FullCalendarModule} from "@fullcalendar/angular";
import {SmartadminInputModule} from '../../shared/forms/input/smartadmin-input.module';
import {EventosService} from '../../+agenda/eventos.service';
import {ReportsService} from "../../shared/services/reports.service";
import {CatalogosService} from "../../shared/services/catalogos.service";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        I18nModule,
        ReactiveFormsModule,
        NgSelectModule,
        UtilsModule,
        PopoverModule.forRoot(),
        NgZorroAntdModule,
        FullCalendarModule,
        SharedModule,
        JqueryUiModule,
        SmartadminInputModule,
        AccordionModule.forRoot()
    ],
    declarations: [
        NuevaConsultaComponent,
        DisponibilidadComponent]
    ,
    exports: [
        NuevaConsultaComponent,
        DisponibilidadComponent
    ],
    providers: [
        AvatarUrlPipe,
        AilmentService,
        ConsultaService,
        EventosService,
        ReportsService,
        CatalogosService
    ]
})
export class NuevaConsultaModule {
}
