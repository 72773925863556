export class ShaService {
    constructor() {
    }

    /**
     *
     * @param wordToEncrypt
     *      string to be encrypted
     * @param rounds
     *      number of times wordToEncrypt would be processed, default 1000 (high numbers may affect time response)
     * @param shaType
     *      the algorithm type for encryption, also changes the length for the result string
     *      @values SHA-1, SHA-224, SHA3-224, SHA-256, SHA3-256, SHA-384, SHA3-384, SHA-512, SHA3-512
     *      @default SHA3-256
     * @param outType
     *      output string type, can be Hexadecimal or Base-64
     *      @values HEX, B64
     *      @default B64
     * @returns {any}
     *      Encrypted word
     */
    encrypt(wordToEncrypt: string, rounds?: number, shaType?: string, outType?: string) {
        rounds = rounds > 0 ? rounds : 1000;
        switch (shaType) {
            case 'SHA-1':
            case 'SHA-224':
            case 'SHA3-224':
            case 'SHA-256':
            case 'SHA-384':
            case 'SHA3-384':
            case 'SHA-512':
            case 'SHA3-512':
                break;
            default:
                shaType = 'SHA3-256';
                break;
        }
        outType = outType === 'HEX' ? outType : 'B64';

        if (!!require('jssha')) {
            var jsSHA = require('jssha');
            let encrypter = new jsSHA(shaType, 'TEXT', {numRounds: rounds});
            encrypter.update(wordToEncrypt);

            return encrypter.getHash(outType);
        } else {
            require('script-loader!jssha/src/sha.js').then(() => {
                var jsSHA = require('jssha');
                let encrypter = new jsSHA(shaType, 'TEXT', {numRounds: rounds});
                encrypter.update(wordToEncrypt);

                return encrypter.getHash(outType);
            }).catch(error => {
                console.debug('Error: ', error);
                return undefined;
            })
        }
    }
}
