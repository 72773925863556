import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageServiceService {
  private storageSub=new Subject<String>();
  constructor() { }
  watchStorage(): Observable<any>{
    return this.storageSub.asObservable();
  }
  setItem(key:string,data:any){
    localStorage.setItem(key,data)
    this.storageSub.next('changed');
  }
  removeItem(key){
    localStorage.removeItem(key);
    this.storageSub.next('changed');
  }
}
