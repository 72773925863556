import {Component, OnInit,Input, SimpleChange, SimpleChanges} from '@angular/core';
import {VariablesService} from '../app-layouts/variables.service';
import {LayoutService} from "../layout.service";
import {environment} from "../../../../environments/environment";
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import { from, Observable, Observer } from 'rxjs';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import {AuthNioService} from '../../../+login-nio/auth-nio.service';
@Component({
    selector: 'sa-navigation',
    templateUrl: './navigation.component.html',
    providers: [VariablesService]
})
export class NavigationComponent {
    url:any;
    name = "Angular";
    base64TrimmedURL: string;
    base64DefaultURL: string;
    url_stadisticas="";
    generatedImage: string;
    windowOPen: boolean;
    urs:string ="paciente/detalle/03e667a9-5d63-42fc-81bc-a53cf2d45b7e/perfil";
    isAlentha:boolean=false;
    _idgroups : string ="";
    hasconsulta="";
    hashc="38d847d87a8f513006035011acb7f378=";
    espac:boolean=false;
    constructor(public vars: VariablesService,
                public layout: LayoutService,
                private domSanitizer: DomSanitizer,
                private authService: AuthNioService) {
                    this.windowOPen = false; 
    }
    img:string;
    esbaja_:boolean;
    @Input() imagen64 :string;
    ngOnInit(): void {

      this.url_stadisticas = environment.url_np;
      console.log(this.url_stadisticas);
      

        if (environment.navlogos=="ES_BC") {
            this.esbaja_=true;
            this.img=this.authService.token.imagen_grupo_principal;
        }
        
        var seletedgroupd = localStorage.getItem('id_select_group');
        this.hashc+=seletedgroupd;
 if(this.authService.token.idTipoUsuario ==3){this.espac=true;}
       if(environment.Instance_file=='ECE'){
            this._idgroups='24';
        }else{
            this._idgroups='14';
        }
        if(seletedgroupd == this._idgroups){
            this.isAlentha=true;
        }

        this.getImageWithoutWindowOpen(this.img);   
    }
    ngOnChanges(changes:SimpleChanges){
      var url = changes.imagen64.currentValue
      this.img=localStorage.getItem('image_group_selected');
      
      if(this.img!='null' ){
        this.getImageWithoutWindowOpen(url);
      }
      else{
       this.img=null;
      }
    }
    sanatizeUrl(generatedImageUrl ): SafeResourceUrl {
        
        if(this.img == null || this.img == undefined){
          generatedImageUrl="/assets/img/theme/nm-dark.png" }             
        return this.domSanitizer.bypassSecurityTrustResourceUrl(generatedImageUrl);
    }
    dataUritoBlob(dataUri){
        const byteString = dataUri;
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for( let i=0 ; i< byteString.length;i++){
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array],{type:'image/jpeg'});
        return blob;
    }
    getImageWithoutWindowOpen(imageUrl: string) {
        this.windowOPen = false;
        this.getBase64ImageFromURL(imageUrl).subscribe((base64Data: string) => {
          this.base64TrimmedURL = base64Data;
          this.createBlobImageFileAndShow();
        });
      }
      createBlobImageFileAndShow(): void {
        this.dataURItoBlob(this.base64TrimmedURL).subscribe((blob: Blob) => {
          const imageBlob: Blob = blob;
          const imageName: string = this.generateName();
          const imageFile: File = new File([imageBlob], imageName, {
            type: "image/jpeg"
          });
          this.generatedImage = window.URL.createObjectURL(imageFile);
          // on demo image not open window
          if(this.windowOPen) {
            window.open(this.generatedImage);
          }
        });
      }
      generateName(): string {
        const date: number = new Date().valueOf();
        let text: string = "";
        const possibleText: string =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
          text += possibleText.charAt(
            Math.floor(Math.random() * possibleText.length)
          );
        }
        // Replace extension according to your media type like this
        return date + "." + text + ".jpeg";
      }
      dataURItoBlob(dataURI: string): Observable<Blob> {
        return Observable.create((observer: Observer<Blob>) => {
          const byteString: string = window.atob(dataURI);
          const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
          const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([int8Array], { type: "image/jpeg" });
          observer.next(blob);
          observer.complete();
        });
      }
      getBase64ImageFromURL(url: string): Observable<string> {
        return Observable.create((observer: Observer<string>) => {
          // create an image object
          let img = new Image();
          img.crossOrigin = "Anonymous";
          img.src = url;
          if (!img.complete) {
            // This will call another method that will create image from url
            img.onload = () => {
              observer.next(this.getBase64Image(img));
              observer.complete();
            };
            img.onerror = err => {
              observer.error(err);
            };
          } else {
            observer.next(this.getBase64Image(img));
            observer.complete();
          }
        });
      }
      getBase64Image(img: HTMLImageElement): string {
        // We create a HTML canvas object that will create a 2d image
        var canvas: HTMLCanvasElement = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
        // This will draw image
        ctx.drawImage(img, 0, 0);
        // Convert the drawn image to Data URL
        let dataURL: string = canvas.toDataURL("image/png");
        this.base64DefaultURL = dataURL;
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
      }
}
