import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Cie9} from '../../../../../shared/classes/cies.classes';
import {CiesService} from '../../../../../shared/services/cies.service';
import {PageRequest} from '../../../../../common/http/page.request';
import {TerapeuticaEmpleada} from '../../../../../shared/classes/common.classes';
import {CapitalizePipe} from "../../../../../shared/utils/capitalize.pipe";

@Component({
    selector: 'app-terapeutica-empleada',
    templateUrl: './terapeutica-empleada.component.html',
    styleUrls: ['../paciente.historia-clinica.component.css']
})
export class TerapeuticaEmpleadaComponent implements OnChanges {
    @Input() private wholeObject: any = {};
    @Output() private wholeObjectChange: EventEmitter<TerapeuticaEmpleada> = new EventEmitter<TerapeuticaEmpleada>();

    private searchCieTimeout: number;
    private optionsSearchCie: PageRequest = new PageRequest();

    private selectedCie9: Cie9;
    searchCie9: string;
    catCie9: Array<Cie9> = [];
    cargandoCie: boolean = false;
    terapeuticaEmpleada: TerapeuticaEmpleada = new TerapeuticaEmpleada();
    readOnly: boolean = false;
    notas: boolean = false;

    constructor(private ciesService: CiesService,
                private capitalizePipe: CapitalizePipe) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('wholeObject')) {
            const value = changes['wholeObject'].currentValue;
            if (value && value.hasOwnProperty('terapeuticaEmpleada')) {
                this.terapeuticaEmpleada = value['terapeuticaEmpleada'];
            } else {
                this.wholeObject.terapeuticaEmpleada = this.terapeuticaEmpleada;
            }
        }
        
    }

    sendData(): void {
        this.wholeObject.terapeuticaEmpleada = this.terapeuticaEmpleada;
        this.wholeObjectChange.emit(this.wholeObject);
    }

    autocompleteCie(ev: KeyboardEvent, type: string): void {
        if (this.searchCieTimeout) {
            clearTimeout(this.searchCieTimeout);
        }

        if (ev.keyCode === 27 && this.selectedCie9) {
            this.catCie9 = [];

            if (this.selectedCie9.catalogKey && this.selectedCie9.proNombre) {
                this.searchCie9 = `${this.selectedCie9.catalogKey} ${this.selectedCie9.proNombre}`
            } else {
                this.cargandoCie = true;
                this.ciesService.getCie9(this.selectedCie9.idCie9)
                    .subscribe((cie: Cie9) => this.searchCie9 = `${cie.catalogKey} ${cie.proNombre}`,
                        () => this.cargandoCie = false,
                        () => this.cargandoCie = false);
            }
        } else {
            this.searchCieTimeout = window.setTimeout(() => {
                let input: HTMLInputElement = <HTMLInputElement>ev.target;
                input.onblur = null;
                if (this.searchCie9) {
                    this.optionsSearchCie.orderColumn = 'proNombre';
                    this.optionsSearchCie.orderType = 'asc';
                    this.optionsSearchCie.datosBusqueda = this.searchCie9;

                    this.cargandoCie = true;
                    this.ciesService.getPageCie9(this.optionsSearchCie)
                        .subscribe((pageCie9) => {
                                this.catCie9 = pageCie9.content;
                                if (this.catCie9.length) {
                                    if (ev.keyCode === 13) {
                                        this.assignCie(this.catCie9[0], type);
                                    }
                                } else if (this.selectedCie9) {
                                    input.onblur = () => {
                                        input.onblur = null;
                                        if (this.selectedCie9.catalogKey && this.selectedCie9.proNombre) {
                                            this.searchCie9 = `${this.selectedCie9.catalogKey} ${this.selectedCie9.proNombre}`
                                        } else {
                                            this.ciesService.getCie9(this.selectedCie9.idCie9).subscribe(
                                                (cie: Cie9) => this.searchCie9 = `${cie.catalogKey} ${cie.proNombre}`,
                                                () => this.cargandoCie = false,
                                                () => this.cargandoCie = false);
                                        }
                                    };
                                }
                            },
                            () => this.cargandoCie = false,
                            () => this.cargandoCie = false);
                } else {
                    this.catCie9 = [];
                    if (this.selectedCie9) {
                        input.onblur = () => {
                            input.onblur = null;
                            if (this.selectedCie9.catalogKey && this.selectedCie9.proNombre) {
                                this.searchCie9 = `${this.selectedCie9.catalogKey} ${this.selectedCie9.proNombre}`
                            } else {
                                this.ciesService.getCie9(this.selectedCie9.idCie9)
                                    .subscribe((cie) => this.searchCie9 = `${cie.catalogKey} ${cie.proNombre}`,
                                        () => this.cargandoCie = false,
                                        () => this.cargandoCie = false);
                            }
                        };
                    }
                }
            }, 300);
        }
    }

    assignCie(cie: any, type?: string): void {
        this.selectedCie9 = cie;
        this.searchCie9 = `${cie.catalogKey} ${cie.proNombre}`;
        this.catCie9 = [];
    }


    forceSelectCie(ev: MouseEvent): void {
        let input: HTMLInputElement = <HTMLInputElement>ev.target;
        setTimeout(() => input.setSelectionRange(0, input.value.length), 50);
    }


    borrarCie9(cie9: Cie9): void {
        this.terapeuticaEmpleada.lstCie9 = this.terapeuticaEmpleada.lstCie9
            .filter((cie9) => cie9.catalogKey !== cie9.catalogKey);
    }

    addCie9(): void {
        if (this.searchCie9 && this.selectedCie9.catalogKey) {
            this.selectedCie9.proNombre = this.capitalizePipe.transform(this.selectedCie9.proNombre);
            if (this.terapeuticaEmpleada.lstCie9.findIndex((cie9) => cie9.proNombre.toLowerCase() == this.selectedCie9.proNombre.toLowerCase()) < 0) {
                this.terapeuticaEmpleada.lstCie9 = [...this.terapeuticaEmpleada.lstCie9, this.selectedCie9];
                this.selectedCie9 = null;
                this.searchCie9 = null;
                this.sendData();
            }
        }
    }
}
