import { Component, OnInit } from '@angular/core';
import {UserService} from "../user.service";
import {LayoutService} from "../../layout/layout.service";

@Component({
  selector: 'app-nm-login-info',
  templateUrl: './nm-login-info.component.html',
  styleUrls: ['./nm-login-info.component.css']
})
export class NmLoginInfoComponent implements OnInit {

  user:any;

  constructor(
    private userService: UserService,
              private layoutService: LayoutService) {
  }

  ngOnInit() {
    this.userService.getLoginInfo().subscribe(user => {
      this.user = user
    })

  }

  toggleShortcut() {
    this.layoutService.onShortcutToggle()
  }
}
