import { Injectable } from '@angular/core';
import { Headers, Http, RequestMethod, RequestOptions, URLSearchParams,Response } from '@angular/http';
import { Login, TokenManager } from 'app/+login-nio/auth-nio.classes';
import { PatientView } from 'app/+paciente/paciente.classes';
import { UserAppView } from 'app/shared/classes/user.classes';
import { RequestConfig } from 'app/shared/rest/rest.request';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenpacService {

  private GET_HASHMEDICO = new RequestConfig('GET-HASHMEDICO', 'application/json', true, RequestMethod.Get, '/create-new-user/link?hash=')
  private _token: TokenManager;
  private reqGeneric = new RequestConfig('CR-PostPatologico', 'application/json', true, RequestMethod.Get, '/personal-patologico');
  private LOG_OUT: RequestConfig = new RequestConfig('AC003', 'application/json', true, RequestMethod.Post, '/users/secure/logout');

  constructor(private _http: Http) { }

  getEstadoCivil() {
    return [
        {id: 1, nombre: 'Casado(a)'},
        {id: 2, nombre: 'Divorciado(a)'},
        {id: 3, nombre: 'Soltero(a)'},
        {id: 4, nombre: 'Union Libre(a)'},
        {id: 5, nombre: 'Viudo(a)'}
    ];}

  getTipoSangre() {
    return [
        {id: 4, nombre: 'O+'},
        {id: 8, nombre: 'O-'},
        {id: 1, nombre: 'A+'},
        {id: 5, nombre: 'A-'},
        {id: 2, nombre: 'B+'},
        {id: 6, nombre: 'B-'},
        {id: 3, nombre: 'AB+'},
        {id: 7, nombre: 'AB-'},
        {id: 8, nombre: 'Desconoce tipo de sangre'},
    ];
  }

  getParentesco() {
    return this._http.get('/assets/api/parentesco.json').map((res) => res.json());
  }


  getHAST(url: string){
    return this._http.get(url)
        .map((res: Response) => res.json())
        //.catch(error => this.http.handleError(error));
}



getTYC(url: string){
  return this._http.get(url)
      .map((res: Response) => res.json())
      //.catch(error => this.http.handleError(error));
}

SetSmS(value: any){
  let options: RequestOptions = new RequestOptions();
  options.headers = new Headers();
  options.method = RequestMethod.Post;
  options.headers.append('Content-Type', 'application/json');
  options.headers.append('X-API-Token', '0hln3Ctw-cUplt1WR1k5kvboZYYKuPzNCIq7');
  options.body = value;
    var urls = 'https://api.zenvia.com/v2/channels/sms/messages';
  return this._http.request(urls,options).map((res: Response) => res.json())
     
}

savePatient(URL:string,pacienteUsuario: {pacienteVIew: PatientView, userAppView: UserAppView,selectedGroup : number,code:string}) {
  return this._http.post(URL, pacienteUsuario)
      .map((res: Response) => res.json())
      //.catch(error => this.http.handleError(error));
}


loadToken(value: TokenManager): TokenManager {
  this._token = value;
  if (value === null) {
      localStorage.removeItem("token");
      localStorage.removeItem('auth_token');
      localStorage.removeItem("nio-userdetail");
      localStorage.removeItem("id_select_group");
      localStorage.removeItem("group_name_selected");
      localStorage.removeItem("image_group_selected");
      localStorage.removeItem("idprincipalrol");
  } else {
      this._token.expires_in = (new Date().getTime() + ((this._token.expires_in - 1) * 1000));
      localStorage.setItem('auth_token', JSON.stringify(this._token));
      if(this._token.id_grupo_principal !=null && this._token.nombre_grupo_principal!=null)
      {
      localStorage.setItem("group_name_selected",this._token.nombre_grupo_principal);
      localStorage.setItem("id_select_group",''+this._token.id_grupo_principal);
      localStorage.setItem("image_group_selected",this._token.imagen_grupo_principal);
      }
      localStorage.setItem('idprincipalrol',''+this._token.idTipoUsuario);
  }

  return this._token;
}
login(login: Login) {
  let body: URLSearchParams = new URLSearchParams();
  body.append('grant_type', 'password');
  body.append('client_id', environment.client_id);
  body.append('client_secret', environment.client_secret);
  body.append('username', login.username);
  body.append('password', login.password);
  let options: RequestOptions = new RequestOptions();
  options.headers = new Headers();
  options.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  // sre print
  //alert('Contacto login a ' + environment.oauth_server);
  return this._http.post(environment.oauth_server, body, options).map(res => this.loadToken(res.json()));
}




postHistoriaCG(value: any) {
  console.log(this._token);
  let options: RequestOptions = new RequestOptions();
  options.headers = new Headers();
  options.method = RequestMethod.Post;
  options.headers.append('Content-Type', 'application/json');
  options.headers.append('Authorization', this._token.token_type + ' ' + this._token.access_token);
  options.body = value;
    var urls = environment.api_url+'/historia-clinica'
  // return this._http.post(urls,"" ,options)
  // .map((res: Response) => res.json())

  return this._http.request(urls,options).map((res: Response) => res.json())
     

}



logout(replaceURL: boolean = false) {
  console.log("ek token"+this._token);
  if (!this._token) {
      this.loadToken(null);
     
  } else {
    console.log("else");
      let options: RequestOptions = new RequestOptions();
      options.headers = new Headers();
      options.headers.append('Content-Type', this.LOG_OUT.contentType);
      options.headers.append('Authorization', this._token.token_type + ' ' + this._token.access_token);

      this._http.post(environment.api_url + this.LOG_OUT.uri, {}, options)
          .subscribe(() => {
              this.loadToken(null);
              
          }, () => {
              this.loadToken(null);
          });
  }
}


}
