import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Boca, ExploracionFisica, Ojo, Pulsos} from '../../../../../shared/classes/common.classes';

@Component({
    selector: 'app-exploracion-fisica',
    templateUrl: './exploracion-fisica.component.html',
    styleUrls: ['../paciente.historia-clinica.component.css', './exploracion-fisica.component.css']
})
export class ExploracionFisicaComponent implements OnChanges {
    @Input() private wholeObject: any = {};
    @Output() private wholeObjectChange = new EventEmitter<ExploracionFisica>();
    exploracionFisica: ExploracionFisica = new ExploracionFisica();
    notas: boolean = false;

    constructor() {
    }

    calcIMC(): { imc: number, dx: string } {
        let IMC = 0;
        const peso = this.exploracionFisica.peso;
        const talla = this.exploracionFisica.talla;
        if (peso && peso > 0 && talla && talla > 0) {
            const TALLA_CUAD = (talla / 100) * (talla / 100);
            IMC = Math.round(((peso) / (TALLA_CUAD)) * 100) / 100;
            this.exploracionFisica.imc = IMC;
            switch (true) {
                case (IMC === 0 || IMC === null):
                    return {imc: IMC, dx: ''};
                case (IMC < 18):
                    return {imc: IMC, dx: `Peso bajo. Necesario valorar signos de desnutrición`};
                case (IMC < 25):
                    return {imc: IMC, dx: `Normal`};
                case (IMC < 27):
                    return {imc: IMC, dx: `Sobrepeso`};
                case (IMC < 30):
                    return {
                        imc: IMC,
                        dx: `Obesidad grado I. Riesgo relativo alto para desarrollar enfermedades cardiovasculares`
                    };
                case (IMC < 40):
                    return {
                        imc: IMC,
                        dx: `Obesidad grado II. Riesgo relativo muy alto para el desarrollo de enfermedades cardiovasculares`
                    };
                case (IMC >= 40):
                    return {
                        imc: IMC,
                        dx: `Obesidad grado III Extrema o Mórbida. Riesgo relativo extremadamente alto para el desarrollo de enfermedades cardiovasculares`
                    };
            }
            return {imc: 0, dx: ``}
        } else {
            IMC = 0;
            return {imc: IMC, dx: ``}
        }

    }

    sendData() {
        //    Enviar el objeto
        this.wholeObject.exploracionFisica = this.exploracionFisica;
        this.wholeObjectChange.emit(this.wholeObject);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('wholeObject')) {
            const value = changes['wholeObject'].currentValue;
            if (value && value.hasOwnProperty('exploracionFisica')) {
                this.exploracionFisica = Object.assign(this.exploracionFisica, value['exploracionFisica']);

                if (!this.exploracionFisica.cabeza.ojoIzquierdo) this.exploracionFisica.cabeza.ojoIzquierdo = new Ojo();
                if (!this.exploracionFisica.cabeza.ojoDerecho) this.exploracionFisica.cabeza.ojoDerecho = new Ojo();
                if (!this.exploracionFisica.cabeza.boca) this.exploracionFisica.cabeza.boca = new Boca();

                if (!this.exploracionFisica.extremidades.pulsos || typeof this.exploracionFisica.extremidades.pulsos == "boolean") this.exploracionFisica.extremidades.pulsos = new Pulsos();
            } else {
                this.wholeObject.exploracionFisica = this.exploracionFisica;
            }
        }
    }


}
