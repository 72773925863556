import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    InterrogatorioCardiovascular,
    InterrogatorioDigestivo,
    InterrogatorioEndocrino,
    InterrogatorioGenitourinario,
    InterrogatorioHematologico,
    InterrogatorioHemolinfatico,
    InterrogatorioMusculoEsqueletico,
    InterrogatorioOncologico,
    InterrogatorioOrganoSentidosAudicion,
    InterrogatorioOrganoSentidosGusto,
    InterrogatorioOrganoSentidosOlfato,
    InterrogatorioOrganoSentidosTacto,
    InterrogatorioOrganoSentidosVision,
    InterrogatorioPielyAnexos,
    InterrogatorioPsiquiatrico,
    InterrogatorioReproductor,
    InterrogatorioRespiratorio,
    InterrogatorioSistemaNervioso,
    InterrogatorioUrinario,
    PantallaInterrogatorioAparatos
} from '../../../../../shared/classes/common.classes';
import {PatientView} from '../../../../paciente.classes';

@Component({
    selector: 'app-interrogatorio',
    templateUrl: './interrogatorio.component.html',
    styleUrls: ['../paciente.historia-clinica.component.css']
})
export class InterrogatorioComponent implements OnInit, OnChanges {
    // Organo de los sentidos
    interrogatorioOrganoSentidosVision: InterrogatorioOrganoSentidosVision = new InterrogatorioOrganoSentidosVision();
    interrogatorioOrganoSentidosAudicion: InterrogatorioOrganoSentidosAudicion = new InterrogatorioOrganoSentidosAudicion();
    interrogatorioOrganoSentidosOlfato: InterrogatorioOrganoSentidosOlfato = new InterrogatorioOrganoSentidosOlfato();
    interrogatorioOrganoSentidosGusto: InterrogatorioOrganoSentidosGusto = new InterrogatorioOrganoSentidosGusto();
    interrogatorioOrganoSentidosTacto: InterrogatorioOrganoSentidosTacto = new InterrogatorioOrganoSentidosTacto();
    // Interrogatorio respiratorio
    interrogatorioRespiratorio: InterrogatorioRespiratorio = new InterrogatorioRespiratorio();
    // Cardiovascular
    interrogatorioCardiovascular: InterrogatorioCardiovascular = new InterrogatorioCardiovascular();
    // Interrogatorio digestivo
    interrogatorioDigestivo: InterrogatorioDigestivo = new InterrogatorioDigestivo();
    // Interrogatorio endocrino
    interrogatorioEndocrino: InterrogatorioEndocrino = new InterrogatorioEndocrino();
    // Interrogatorio Sistema Nervioso
    interrogatorioSistemaNervioso: InterrogatorioSistemaNervioso = new InterrogatorioSistemaNervioso();
    // Interrogatorio piel
    interrogatorioPielyAnexos: InterrogatorioPielyAnexos = new InterrogatorioPielyAnexos();
    interrogatorioPsiquiatrico: InterrogatorioPsiquiatrico = new InterrogatorioPsiquiatrico();
    interrogatorioGenitourinario: InterrogatorioGenitourinario = new InterrogatorioGenitourinario();
    interrogatorioUrinario: InterrogatorioUrinario = new InterrogatorioUrinario();
    interrogatorioReproductor: InterrogatorioReproductor = new InterrogatorioReproductor();
    interrogatorioHemolinfatico: InterrogatorioHemolinfatico = new InterrogatorioHemolinfatico();
    interrogatorioMusculoEsqueletico: InterrogatorioMusculoEsqueletico = new InterrogatorioMusculoEsqueletico();
    interrogatorioOncologico: InterrogatorioOncologico = new InterrogatorioOncologico();
    interrogatorioHematologico: InterrogatorioHematologico = new InterrogatorioHematologico();
    // Objeto general del componente interrogatorio
    pantallaInterrogatorioAparatos: PantallaInterrogatorioAparatos = new PantallaInterrogatorioAparatos();
    notas: boolean = false;

    @Input() wholeObject: any = {};
    @Output() wholeObjectChange = new EventEmitter<PantallaInterrogatorioAparatos>();
    dateFormat = 'dd/MM/yyyy';

    @Input() paciente: PatientView = new PatientView();

    constructor() {
    }

    ngOnInit() {
        this.llenarObjeto();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('wholeObject')) {
            const value = changes['wholeObject'].currentValue;
            if (value && value.hasOwnProperty('pantallaInterrogatorioAparatos')) {
                this.pantallaInterrogatorioAparatos = value['pantallaInterrogatorioAparatos'];
                this.interrogatorioOrganoSentidosVision = this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosVision;
                this.interrogatorioOrganoSentidosAudicion = this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosAudicion;
                this.interrogatorioOrganoSentidosOlfato = this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosOlfato;
                this.interrogatorioOrganoSentidosGusto = this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosGusto;
                this.interrogatorioOrganoSentidosTacto = this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosTacto;
                this.interrogatorioRespiratorio = this.pantallaInterrogatorioAparatos.interrogatorioRespiratorio;
                this.interrogatorioCardiovascular = this.pantallaInterrogatorioAparatos.interrogatorioCardiovascular;
                this.interrogatorioDigestivo = this.pantallaInterrogatorioAparatos.interrogatorioDigestivo;
                this.interrogatorioEndocrino = this.pantallaInterrogatorioAparatos.interrogatorioEndocrino;
                this.interrogatorioSistemaNervioso = this.pantallaInterrogatorioAparatos.interrogatorioSistemaNervioso;
                this.interrogatorioPielyAnexos = this.pantallaInterrogatorioAparatos.interrogatorioPielyAnexos;
                this.interrogatorioPsiquiatrico = this.pantallaInterrogatorioAparatos.interrogatorioPsiquiatrico;
                this.interrogatorioGenitourinario = this.pantallaInterrogatorioAparatos.interrogatorioGenitourinario;
                this.interrogatorioUrinario = this.pantallaInterrogatorioAparatos.interrogatorioUrinario;
                this.interrogatorioReproductor = this.pantallaInterrogatorioAparatos.interrogatorioReproductor;
                this.interrogatorioHemolinfatico = this.pantallaInterrogatorioAparatos.interrogatorioHemolinfatico;
                this.interrogatorioMusculoEsqueletico = this.pantallaInterrogatorioAparatos.interrogatorioMusculoEsqueletico;
                this.interrogatorioOncologico = this.pantallaInterrogatorioAparatos.interrogatorioOncologico;
                this.interrogatorioHematologico = this.pantallaInterrogatorioAparatos.interrogatorioHematologico;
            } else {
                this.wholeObject.pantallaInterrogatorioAparatos = this.pantallaInterrogatorioAparatos;
            }
        }
        if (changes.hasOwnProperty('paciente')) {
            const value = changes['paciente'].currentValue;
            if (value && value.hasOwnProperty('sexo') && value['sexo'].length) {
                this.paciente = value;
            }
        }
    }

    llenarObjeto() {
        this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosVision = this.interrogatorioOrganoSentidosVision;
        this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosAudicion = this.interrogatorioOrganoSentidosAudicion;
        this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosOlfato = this.interrogatorioOrganoSentidosOlfato;
        this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosGusto = this.interrogatorioOrganoSentidosGusto;
        this.pantallaInterrogatorioAparatos.interrogatorioOrganoSentidosTacto = this.interrogatorioOrganoSentidosTacto;
        this.pantallaInterrogatorioAparatos.interrogatorioRespiratorio = this.interrogatorioRespiratorio;
        this.pantallaInterrogatorioAparatos.interrogatorioCardiovascular = this.interrogatorioCardiovascular;
        this.pantallaInterrogatorioAparatos.interrogatorioDigestivo = this.interrogatorioDigestivo;
        this.pantallaInterrogatorioAparatos.interrogatorioEndocrino = this.interrogatorioEndocrino;
        this.pantallaInterrogatorioAparatos.interrogatorioSistemaNervioso = this.interrogatorioSistemaNervioso;
        this.pantallaInterrogatorioAparatos.interrogatorioPielyAnexos = this.interrogatorioPielyAnexos;
        this.pantallaInterrogatorioAparatos.interrogatorioPsiquiatrico = this.interrogatorioPsiquiatrico;
        this.pantallaInterrogatorioAparatos.interrogatorioGenitourinario = this.interrogatorioGenitourinario;
        this.pantallaInterrogatorioAparatos.interrogatorioUrinario = this.interrogatorioUrinario;
        this.pantallaInterrogatorioAparatos.interrogatorioReproductor = this.interrogatorioReproductor;
        this.pantallaInterrogatorioAparatos.interrogatorioHemolinfatico = this.interrogatorioHemolinfatico;
        this.pantallaInterrogatorioAparatos.interrogatorioMusculoEsqueletico = this.interrogatorioMusculoEsqueletico;
        this.pantallaInterrogatorioAparatos.interrogatorioOncologico = this.interrogatorioOncologico;
        this.pantallaInterrogatorioAparatos.interrogatorioHematologico = this.interrogatorioHematologico;
        //    Enviar el objeto
        this.wholeObject.pantallaInterrogatorioAparatos = this.pantallaInterrogatorioAparatos;
        this.wholeObjectChange.emit(this.wholeObject);
        
    }


}
