import { Component, Input, OnInit, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import {isNullOrUndefined} from 'util';
import {ConsultaService} from '../shared/consulta.service';
import {CiesService} from '../../shared/services/cies.service';
import {ConsultaView, TratamientoView} from "../shared/consulta.classes";
import {Cie10, Cie9} from "../../shared/classes/cies.classes";
import {PadecimientoView} from "../../shared/classes/ailment.classes";
import {Subscription} from "rxjs/Subscription";
import { DocumentoView, ImagenLaboratorioView, EstudioLaboratorioView } from '../../shared/classes/common.classes';
import {PageImpl} from "../../common/http/PageImpl";
import {PageRequest} from "../../common/http/page.request";
import {NiomedicPageRequest} from "../../common/http/niomedic.page.request";
import {DomSanitizer} from "@angular/platform-browser";
import { StudiesService } from '../../shared/services/studies.service';
import { NuevoEstudioView } from '../../shared/classes/studies.classes';
import { ReportsService } from '../../shared/services/reports.service';
import { RecipesService } from '../../shared/services/recipes.service';
import { Recipe } from '../../shared/classes/recipe.classes';
import { FormatoService } from '../../+formato/formato.service';
import { FormatResult } from 'app/+formato/formato.classes';
import { Format } from '../../+formato/formato.classes';
import { DoctorService } from 'app/+doctor/doctor.service';

@Component({
    selector: 'app-detalle-consulta',
    templateUrl: 'detalle-consulta.component.html',
    styleUrls: ['detalle-consulta.component.css']
})
export class DetalleConsultaComponent implements OnInit, OnChanges {
    @ViewChild('modalFormatResult') private modalFormatResultEl: ElementRef;
    @Input() consultaElegida: ConsultaView | number;
    @Input() numeroConsulta: string;
    @Input() ultimaConsulta: boolean;
    @Input() cie10: string | number;
    @Input() cie9: string | number;
    @Input() defaultOpened: boolean = false;

    tabActivo: number = 0;
    showMore: boolean = false;
    cargando: boolean = false;

    _consulta: ConsultaView = new ConsultaView();
    mapCies9: Map<number, Cie9> = new Map();
    mapCies10: Map<number, Cie10> = new Map();

    imagePageRequest: NiomedicPageRequest;
    imagesPage: PageImpl<ImagenLaboratorioView> = new PageImpl();
    imageIndex: number;

    documentPageRequest: NiomedicPageRequest;
    documentsPage: PageImpl<DocumentoView> = new PageImpl();

    // ESTUDIOS
    estudiosLaboratorio: NuevoEstudioView[] = [];
    // RECETAS
    receta: Recipe = new Recipe();
    // FORMATOS
    dataSet: FormatResult[] = [];
    formats: Format[];
    loadedFormats: boolean = false;
    selectedFormatResult: FormatResult;
    populateFormatResult: any = {
        data: null
    };

    subscriptions: Subscription = new Subscription();

    constructor(private consultaService: ConsultaService,
                private ciesService: CiesService,
                private sanitizer: DomSanitizer,
                private studiesService: StudiesService,
                private reportsService: ReportsService,
                private recipesService: RecipesService,
                private formatoService: FormatoService,
                private doctorService: DoctorService) {
    }

    ngOnInit() {
        if (!isNullOrUndefined(this.consultaElegida)) {
            if (typeof this.consultaElegida === 'number') {
                this.subscriptions.add(
                    this.consultaService.get(this.consultaElegida)
                        .subscribe(consulta => {
                            if (consulta.idConsulta == this.consultaElegida) {
                                this._consulta = consulta;
                                this.loadCies();
                            }
                        })
                );
            } else {
                this._consulta = this.consultaElegida;
                this.loadCies();
            }  
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (let propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                if (propName === 'consultaElegida') {
                    if (isNullOrUndefined(this._consulta.idConsulta)) {
                        this._consulta = (changes['consultaElegida'].currentValue);
                    }
                }

                if (propName === 'cie10') {
                    if (!isNullOrUndefined(changes['cie10'].currentValue) && typeof changes['cie10'].currentValue === 'number') {
                        this.ciesService.getCie10(changes['cie10'].currentValue).subscribe(cie10data => {
                            this.cie10 = `${cie10data.catalogKey} ${cie10data.nombre}`;
                        });
                    }
                }

                if (propName === 'cie9') {
                    if (!isNullOrUndefined(changes['cie9'].currentValue) && typeof changes['cie9'].currentValue === 'number') {
                        this.ciesService.getCie9(changes['cie9'].currentValue).subscribe(cie9data => {
                            this.cie9 = `${cie9data.catalogKey} ${cie9data.proNombre}`;
                        });
                    }
                }
            }
        }
    }
    trackByFn(index, item) {
        return index; // or item.id
      }

    loadCies() {
        this.mapCies10.clear();
        this.mapCies9.clear();
        if (!this._consulta.listaTartamiento) this._consulta.listaTartamiento = [];
        this._consulta.listaTartamiento.forEach(treatment => {
            if (treatment && treatment.catCie9Id) {
                this.subscriptions.add(
                    this.ciesService.getCie9(treatment.catCie9Id).subscribe(cie9 => {
                        cie9.proNombre = cie9.proNombre.toLocaleLowerCase();
                        this.mapCies9.set(cie9.idCie9, cie9);
                    })
                );
            }
        });
       /* if (this._consulta.listaCatCie9) {
            this._consulta.listaTartamiento = this._consulta.listaTartamiento.concat(
                this._consulta.listaCatCie9.map(cie9 => {
                    if (this._consulta.listaTartamiento.findIndex(treatment => treatment.catCie9Id == cie9) < 0) {
                        let treatment: TratamientoView = new TratamientoView();
                        treatment.catCie9Id = cie9;
                        return treatment;
                    }
                })
            );
        }*/
        /* ~ */
        /* CIE10 */
        if (!this._consulta.listaPadecimiento) this._consulta.listaPadecimiento = [];
        this._consulta.listaPadecimiento.forEach((ailment, idx) => {
            if (ailment && ailment.cie10Id ) {
                this.ciesService.getCie10(ailment.cie10Id).subscribe(cie10 => {
                    cie10.nombre = cie10.nombre.toLocaleLowerCase();
                    cie10.presumptive = ailment.presuntivo;
                    this.mapCies10.set(cie10.idCie10, cie10);
                });
            }
        });
        //console.log(this._consulta,'s');
        /*if (this._consulta.listaCatCie10) {
           // console.log(this._consulta.listaCatCie10);
            this._consulta.listaPadecimiento = this._consulta.listaPadecimiento.concat(
                this._consulta.listaCatCie10.map(cie10 => {
                    if (this._consulta.listaPadecimiento.findIndex(ailemnt => ailemnt.cie10Id == cie10) < 0) {
                        let ailment: PadecimientoView = new PadecimientoView();
                        ailment.cie10Id = cie10;
                        ailment.presuntivo = true;
                        return ailment;
                    }
                }),
            );
        }*/
        /* ~ */
    }

    showImages(imageIndex?: number) {
        if (!isNullOrUndefined(imageIndex)) {
            if (imageIndex < 0) delete this.imageIndex;
            else this.imageIndex = imageIndex;
        } else if (!this.imagePageRequest) {
            this.imagePageRequest = new NiomedicPageRequest;
            this.imagePageRequest.idPaciente = this._consulta.idPaciente;
            this.imagePageRequest.idConsulta = this._consulta.idConsulta;
            this.imagePageRequest.orderColumn = 'fechaCreacion';
            this.imagePageRequest.size = 2;
            this.cargando = true;
            this.imagePageRequest.orderType = 'asc';
            this.subscriptions.add(
                this.consultaService.getImagenesPage(this._consulta, this.imagePageRequest).subscribe(pageImages => {
                    this.imagesPage = pageImages;
                    this.cargando = false;
                }, (err) => {this.cargando = false}));
        }
    }
    showEstudios() {
        this.cargando = true;
        this.studiesService.getByConsulta(this._consulta.idConsulta)
        .subscribe((arrayEstudioView) => {
            this.estudiosLaboratorio = arrayEstudioView;
            this.cargando = false;
        }, () => this.cargando = false);
    }
    
    showRecetas() {
        this.cargando = true;
        this.recipesService.getByConsulta(this._consulta.idConsulta)
        .subscribe((recipe) => {
            //En algun momento sera un array de recetas
            this.receta = recipe;
            this.cargando = false;
        }, () => this.cargando = false);
    }
    getImage(data: ImagenLaboratorioView) {
        return `data:${data.contentType};base64,${data.imageName}`;
    }
    showFormatos () {
        if (!this.loadedFormats) {
            this.cargando = true;
            this.doctorService.getById(this._consulta.idMedico)
                .subscribe((medic) => {
                    if (medic && medic.idUsuario && medic.idMedico == this._consulta.idMedico) {
                        this.formatoService.getByUser(medic.idUsuario)
                            .subscribe((formats) => {
                                this.formats = formats;
                                this.loadedFormats = true;

                                this.formatoService.getResults({idConsulta: this._consulta.idConsulta})
                                    .subscribe((formatResults) => {
                                        this.dataSet = formatResults.map((formatResult) => {
                                            if (!formatResult.formatView && formatResult.idFormat) {
                                                formatResult.formatView = JSON.parse(JSON.stringify(
                                                    this.formats.find((format) => format.idFormat == formatResult.idFormat)
                                                ));
                                            }
                                            return formatResult;
                                        });
                                        this.cargando = false;
                                    }, () => this.cargando = false);
                            }, (err) => {
                                this.formats = [];
                                this.loadedFormats = true;
                                this.cargando = false;
                            });
                    }
                });
        }
    }
    private get modalFormatResult(): any {
        if (!this.modalFormatResultEl) {
            return null;
        }

        return $(this.modalFormatResultEl.nativeElement);
    }
    openModalFormatResult(formatResult: FormatResult): void {
        if (this.modalFormatResult) {
            this.modalFormatResult.modal('show');

            delete this.selectedFormatResult;
            this.populateFormatResult.data = {};

            // setTimeout(() => {
                this.selectedFormatResult = formatResult;
                this.populateFormatResult.data = this.selectedFormatResult.jsonAnswer;
            // }, 50);
        }
        console.log(this.dataSet);
    }

    changeImagesPage(pagination: PageRequest) {
        this.cargando = true;
        this.imagePageRequest = Object.assign(this.imagePageRequest, pagination);
        this.subscriptions.add(
            this.consultaService.getImagenesPage(this._consulta, this.imagePageRequest).subscribe(pageImages => {
                this.imagesPage = pageImages;
                this.cargando = false;
            }, (err) => {this.cargando = false}));
    }

    showDocuments() {
        if (!this.documentPageRequest) {
            this.documentPageRequest = new NiomedicPageRequest;
            this.documentPageRequest.idPaciente = this._consulta.idPaciente;
            this.documentPageRequest.idConsulta = this._consulta.idConsulta;
            this.documentPageRequest.orderColumn = 'fechaCreacion';
            this.documentPageRequest.orderType = 'asc';
            this.documentPageRequest.size = 2;
            this.cargando = true;
            this.subscriptions.add(
                this.consultaService.getDocumentosPage(this._consulta, this.documentPageRequest).subscribe(pageDocuments => {
                    this.documentsPage = pageDocuments;
                    this.cargando = false;
                }));
        }
    }

    changeDocumentsPage(pagination: PageRequest) {
        this.documentPageRequest = Object.assign(this.documentPageRequest, pagination);

        this.subscriptions.add(
            this.consultaService.getDocumentosPage(this._consulta, this.documentPageRequest).subscribe(pageDocuments => this.documentsPage = pageDocuments)
        );
    }

    sanitize(data: DocumentoView) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`data:${data.contentType};base64,${data.documentoName}`);
    }


    getB64(estudioView?: NuevoEstudioView) {
        if (estudioView && estudioView.idConsulta) {
            this.cargando = true;
            var idGroup = Number(localStorage.getItem('id_select_group')); //GGR20200803 agrego idGrupo seleccionado
            this.reportsService.getFormatoEstudios(estudioView.idConsulta, estudioView.folio, idGroup).subscribe((base64File) => {
                this.cargando = false;
                let fileName = `reporteEstudios-Folio:${estudioView.folio}${this._consulta.nombrePaciente}`;
            let url = `data:application/pdf;base64,${base64File}`;
            this.getBlobDownload(fileName, url);
            }, (err) => {
                this.cargando = false;
            }, () => {
                this.cargando = false;
            });
        } else if ( this.receta && this.receta.idReceta) {
            this.cargando = true;
            var idGroup = Number(localStorage.getItem('id_select_group')); //GGR20200622 agrego idGrupo seleccionado
            this.reportsService.getRecipeNote(this.receta.consultaId, idGroup)
            .take(1)
            .subscribe((base64File) => {
                this.cargando = false;
                let fileName = `reporteReceta-Folio:${this.receta.numeroFolio}${this._consulta.nombrePaciente}`;
                let url = `data:application/pdf;base64,${base64File}`;
                this.getBlobDownload(fileName, url);
        }, (err) => {
                    this.cargando = false;
                }, () => {
                    this.cargando = false;
                });
        }
    }
    getBlobDownload(fileName, url) {
        fetch(url)
        .then(res => res.blob())
        .then(function(blob) {
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, fileName);
            } else {
                var elem = window.document.createElement('a');
                elem.href = window.URL.createObjectURL(blob);
                elem.download = fileName;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            }
        });
    }
}
