import {Subscription} from "rxjs/Subscription";
import {
    Component, OnInit, OnDestroy, ElementRef,
    Renderer, AfterViewInit, AfterContentInit
} from '@angular/core';
import {Router} from "@angular/router";

import {LayoutService} from "../layout.service";

import {
    trigger,
    state,
    style,
    transition,
    animate
} from '@angular/animations'
import {AuthNioService} from "app/+login-nio/auth-nio.service";
import {MedicoView} from "../../../+doctor/doctor.classes";
import {UserDetailsService} from "../../user/user.details.service";

@Component({
    selector: 'sa-shortcut',
    templateUrl: './shortcut.component.html',
    animations: [
        trigger('shortcutState', [
            state('out', style({
                height: 0,
            })),
            state('in', style({
                height: '*',
            })),
            transition('out => in', animate('250ms ease-out')),
            transition('in => out', animate('250ms 300ms ease-out'))
        ])
    ]
})
export class ShortcutComponent implements AfterContentInit, OnDestroy {
    [x: string]: any;

    public state: string = 'out';
    private layoutSub: Subscription;
    private documentSub: any;

    constructor(private layoutService: LayoutService,
                private router: Router,
                private renderer: Renderer,
                private el: ElementRef,
                private authService: AuthNioService,
                private userDetailsService: UserDetailsService) {
    }

    ngAfterContentInit(): void {
        this.listen()
    }

    ngOnDestroy(): void {
        if (this.layoutSub)
            this.layoutSub.unsubscribe();
    }

    shortcutTo(route): void {
        this.router.navigate(route);
        this.layoutService.onShortcutToggle(false);
    }

    listen(): void {
        this.layoutSub = this.layoutService.subscribe((store) => {
            this.state = store.shortcutOpen ? 'in' : 'out'

            if (store.shortcutOpen) {
                this.documentSub = this.renderer.listenGlobal('document', 'mouseup', (event) => {
                    if (!this.el.nativeElement.contains(event.target)) {
                        this.layoutService.onShortcutToggle(false);
                        this.documentUnsub()
                    }
                });
            } else {
                this.documentUnsub()
            }
        })
    }

    documentUnsub(): void {
        this.documentSub && this.documentSub();
        this.documentSub = null
    }

    logout(): void {
        this.userDetailsService.usersDetails = new MedicoView();
        delete this.userDetailsService.fullName;
        this.authService.logout();
    }
}
