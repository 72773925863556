import {Injectable} from '@angular/core';
import {AuthNioService} from 'app/+login-nio/auth-nio.service';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {isNullOrUndefined} from 'util';
import {DoctorService} from "../../+doctor/doctor.service";
import {MedicoView} from "../../+doctor/doctor.classes";
import {UserAppView} from "../classes/user.classes";
import { CustomHttp } from 'app/common/http/custom.http';
import { RequestConfig } from '../rest/rest.request';
import { RequestMethod,Response } from '@angular/http';

@Injectable()
export class UserDetailsService {
    private lastTimeChecked: number = null;
    private userDetailsObserver: Subject<any> = new Subject<any>();

    public usersDetails: MedicoView = new MedicoView();
    private reqGeneric = new RequestConfig('CR-PostPatologico', 'application/json', true, RequestMethod.Get, '/personal-patologico');

    //RECOVERY PASSWORD
    private GET_RECPASS = new RequestConfig('USUARIOS-RECOVERY', 'application/json', true, RequestMethod.Get, '/users/recoverPassword?email={email}')

    public fullName: string;

    constructor(private authservice: AuthNioService,
                private doctorService: DoctorService,private http: CustomHttp) {
    }

    loadUsersDetails() {
        this.lastTimeChecked = 0;
        if (this.authservice.token) {
            if (this.authservice.token.idTipoUsuario === 1 || this.authservice.token.idTipoUsuario === 2){
                this.infoDocAdmin();
            }
        }
    }

    infoDocAdmin (){
        this.doctorService.getByIdUser(this.authservice.token.userId)
            .subscribe(medico => {
                this.usersDetails = medico;
                localStorage.setItem("nio-userdetail", JSON.stringify(this.usersDetails));
                this.fullName = `${medico.nombre || ''} ${medico.apellidoPaterno || ''} ${medico.apellidoMaterno || ''}`;
                this.lastTimeChecked = new Date().getTime();
                this.userDetailsObserver.next();
            }, () => {
                this.lastTimeChecked = new Date().getTime();
                this.userDetailsObserver.next();
            });
    }

    get infoReady(): Observable<void> {
        return this.userDetailsObserver.asObservable();
    }

    stringToColour(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color;
        color = Math.floor(Math.abs((Math.sin(hash) * 10000) % 1 * 16777216)).toString(16);
        return '#' + Array(6 - color.length + 1).join('0') + color;
    }

    /**
     * Crear un Elemento HTML para representar la imagen, si en userDetail hay alguna cargada y tambien si hay alguna imagen de menor tamaño
     * La coloca de background en un DIV
     * @param username
     */
    generarImagenAvatar(username: string): HTMLElement {
        let user: MedicoView = this.usersDetails;

        /*
        let imgEl: HTMLElement = $('<div>').addClass('circle-profile');

        if (!isNullOrUndefined(user) && !!user.image.contentType && !!user.image.encodedImageContentSmall) {
            const imgBase64: string = 'data:' + user.image.contentType + ';base64,' + user.image.encodedImageContentSmall;
            $(imgEl).css('background-image', 'url(' + imgBase64 + ')');
        } else {
            $(imgEl).text(username.slice(0, 2).toUpperCase());
        }*/
        // $(imgEl).text(user.nombre.slice(0, 1).toUpperCase() + user.apellidoPaterno.slice(0, 1).toUpperCase());
        let iniciales = username.slice(0, 2).toUpperCase();

        let color = this.stringToColour(iniciales);

        let sElement = `<div class="circle-profile" style="background-color: ${color}">${iniciales}</div>`;
        return $(sElement)[0];
    }

    drawImage(username: string, force = false): Observable<HTMLElement> {
        return Observable.create(observer => {
            if (!!username && force) {
                observer.next(this.generarImagenAvatar(username));
            } else {
                if (!!username) {
                    if (this.lastTimeChecked < new Date().getTime() - (10 * 60 * 1000/*10 minutes*/)) {
                        if (isNullOrUndefined(this.lastTimeChecked) || this.lastTimeChecked > 0) {
                            this.loadUsersDetails();
                        }
                        this.infoReady.subscribe(() => {
                            observer.next(this.generarImagenAvatar(username))
                        });
                    } else {
                        observer.next(this.generarImagenAvatar(username));
                    }


                } else {
                    observer.next($('<i>')
                        .addClass('menu-icon-user_b')
                        .css({fontSize: '2.5em', color: 'gray'})[0]);
                }
            }
        });
    }

    udputResetPassword(value: any,iduser:number) {
        this.reqGeneric.method = RequestMethod.Put;
        this.reqGeneric.code = 'RESETPASSWORD';
        this.reqGeneric.uri = '/users/'+iduser+'/reset';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    udpostResetPassword(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'POSTRESETPASSWORD';
        this.reqGeneric.uri = '/users/recoverPassword';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    
    
    udgetRecoverypass(MAIL: string): Observable<any> {
        return this.http.sendRequest(this.GET_RECPASS, null, MAIL)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }

}
