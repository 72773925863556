import {NgModule} from '@angular/core';
import {DetalleConsultaComponent} from './detalle-consulta.component';
import {CollapseModule} from 'ngx-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {SmartadminModule} from '../../shared/smartadmin.module';
import {NgZorroAntdModule} from "ng-zorro-antd";
import {CiesService} from '../../shared/services/cies.service';
import { RecipesService } from '../../shared/services/recipes.service';
import { FormioModule } from 'angular-formio';
import { FormatoService } from '../../+formato/formato.service';
import { DoctorService } from 'app/+doctor/doctor.service';

@NgModule({
    imports: [
        SmartadminModule,
        ReactiveFormsModule,
        CollapseModule.forRoot(),
        NgZorroAntdModule.forRoot(),
        FormioModule
    ],
    declarations: [DetalleConsultaComponent],
    exports: [DetalleConsultaComponent],
    providers: [CiesService, RecipesService, FormatoService, DoctorService]
})
export class DetalleConsultaModule {
}
