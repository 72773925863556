import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Console } from 'console';
import { environment } from 'environments/environment';
import {NotiPacientes, OSNotification} from "../shared/classes/common.classes";
import {CronService} from "./cron.service";
@Component({
  selector: 'app-cron',
  templateUrl: './cron.component.html',
  styleUrls: ['./cron.component.css']
})
export class CronComponent implements OnInit {

  constructor( private cronservices: CronService,
    private datePipe: DatePipe,
    ) { }
  private onNoti:OSNotification = new OSNotification();
  private Not:NotiPacientes = new NotiPacientes();
  ngOnInit() {
    
    console.log("entro init ");

    var date = new Date();
    var diahoy =this.datePipe.transform(date,"yyyy-MM-dd");
    var idUserG ="";
    var idDeviG ="";
    var idUserP ="";
    var idDeviP ="";
    var idUserPE ="";
    var idDeviPE="";
    var idUserC ="";
    var idDeviC ="";
    console.log(diahoy); //output : 2018-02-13


    this.cronservices.DELETEALL().subscribe((event) => {
            if(event){
                this.cronservices.GETALL().subscribe((NOTI) => {
                    console.log(NOTI);
                    
                    console.log(NOTI['glucosa'][0]['idUserApp']);
                    this.Not.idUser =NOTI['glucosa'][0]['idUserApp'];
                    console.log(this.Not.idUser);
                    //GLUCOSA
                    for(var w=0;w<NOTI['glucosa'].length;w++){
                      var horag =NOTI['glucosa'][w]['hora'].substr(0,5);
                      this.onNoti.app_id=environment.OSAPPID;
                      this.onNoti.data={"ID": "1","PERD":NOTI['glucosa'][w]['periodo']}; 
                      this.onNoti.include_player_ids=[NOTI['glucosa'][w]['idDevice']];
                      this.onNoti.template_id=environment.OSTEMPLATEGL;
                      this.onNoti.send_after=diahoy+" "+horag+":00 GMT-5";

                      idUserG =NOTI['glucosa'][w]['idUserApp'];
                      idDeviG = NOTI['glucosa'][w]['idDevice'];

                       this.cronservices.postNotification(this.onNoti).subscribe((succesful) => {
                                this.Not.idUser =idUserG;
                                this.Not.idDevice =idDeviG;
                                this.Not.idNotification =succesful.id;
                                this.Not.tipoNotificacion=1;
                                this.cronservices.postNotiPacientes(this.Not).subscribe((succcsees) => {console.log(succcsees);},(error) => {
                                  console.log("ERROR AL GUARDAR notificacion en server :"+error)
                              });
                        },(error) => {
                            console.log("ERROR AL GUARDAR notificacion :"+error)
                        });
                    }

                     //PRESION ARTERIAL
                     for(var y=0;y<NOTI['presionArt'].length;y++){
                      var horag =NOTI['presionArt'][y]['hora'].substr(0,5);
                      this.onNoti.app_id=environment.OSAPPID;
                      this.onNoti.data={"ID": "2","PERD":NOTI['presionArt'][y]['periodo']}; 
                      this.onNoti.include_player_ids=[NOTI['presionArt'][y]['idDevice']];
                      this.onNoti.template_id=environment.OSTEMPLATEGL;
                      this.onNoti.send_after=diahoy+" "+horag+":00 GMT-5";

                      idUserP =NOTI['presionArt'][y]['idUserApp'];
                      idDeviP = NOTI['presionArt'][y]['idDevice'];

                       this.cronservices.postNotification(this.onNoti).subscribe((succesful) => {
                                this.Not.idUser =idUserP;
                                this.Not.idDevice =idDeviP;
                                this.Not.idNotification =succesful.id;
                                this.Not.tipoNotificacion=2;
                                this.cronservices.postNotiPacientes(this.Not).subscribe((succcsees) => {console.log(succcsees);},(error) => {
                                  console.log("ERROR AL GUARDAR notificacion en server :"+error)
                              });
                        },(error) => {
                            console.log("ERROR AL GUARDAR notificacion :"+error)
                        });
                    }

                     //PESO
                     for(var x=0;x<NOTI['nutricion'].length;x++){
                      var horag =NOTI['nutricion'][x]['hora'].substr(0,5);
                      this.onNoti.app_id=environment.OSAPPID;
                      this.onNoti.data={"ID": "3","PERD":NOTI['nutricion'][x]['periodo']}; 
                      this.onNoti.include_player_ids=[NOTI['nutricion'][x]['idDevice']];
                      this.onNoti.template_id=environment.OSTEMPLATEGL;
                      this.onNoti.send_after=diahoy+" "+horag+":00 GMT-5";

                      idUserPE =NOTI['nutricion'][x]['idUserApp'];
                      idDeviPE = NOTI['nutricion'][x]['idDevice'];

                       this.cronservices.postNotification(this.onNoti).subscribe((succesful) => {
                                this.Not.idUser =idUserPE;
                                this.Not.idDevice =idDeviPE;
                                this.Not.idNotification =succesful.id;
                                this.Not.tipoNotificacion=3;
                                this.cronservices.postNotiPacientes(this.Not).subscribe((succcsees) => {console.log(succcsees);},(error) => {
                                  console.log("ERROR AL GUARDAR notificacion en server :"+error)
                              });
                        },(error) => {
                            console.log("ERROR AL GUARDAR notificacion :"+error)
                        });
                    }


                     //COVID
                     for(var z=0;z<NOTI['covid'].length;z++){
                      var horag =NOTI['covid'][z]['hora'].substr(0,5);
                      this.onNoti.app_id=environment.OSAPPID;
                      this.onNoti.data={"ID": "1","PERD":NOTI['covid'][z]['periodo']}; 
                      this.onNoti.include_player_ids=[NOTI['covid'][z]['idDevice']];
                      this.onNoti.template_id=environment.OSTEMPLATEGL;
                      this.onNoti.send_after=diahoy+" "+horag+":00 GMT-5";

                      idUserC =NOTI['covid'][z]['idUserApp'];
                      idDeviC = NOTI['covid'][z]['idDevice'];

                       this.cronservices.postNotification(this.onNoti).subscribe((succesful) => {
                                this.Not.idUser =idUserC;
                                this.Not.idDevice =idDeviC;
                                this.Not.idNotification =succesful.id;
                                this.Not.tipoNotificacion=4;
                                this.cronservices.postNotiPacientes(this.Not).subscribe((succcsees) => {console.log(succcsees);},(error) => {
                                  console.log("ERROR AL GUARDAR notificacion en server :"+error)
                              });
                        },(error) => {
                            console.log("ERROR AL GUARDAR notificacion :"+error)
                        });
                    }

                },(error) => {
                   console.log("ERROR AL OBTENER notificaciones :"+error)
                });
            }
        },(error) => {
            console.log("ERROR AL eliminar las notificaciones :"+error)
        });

  }

}
