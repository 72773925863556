import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RequestMethod, Response} from "@angular/http";
import {RequestConfig} from "../../shared/rest/rest.request";
import {CustomHttp} from "../../common/http/custom.http";
import {PageImpl} from "../../common/http/PageImpl";
import {ByProfile, User, UserRequestView} from "./users.classes";
import {TypeUser} from "../../shared/classes/user.classes";
import {UserAppView} from "../../shared/classes/user.classes"; // Sre22052020 nuevo

@Injectable()
export class UsersService {
    private CREATE_CODE = new RequestConfig("USR", "application/json", true, RequestMethod.Post, "/users");
    private EDIT_CODE = new RequestConfig("USR", "application/json", true, RequestMethod.Put, "/users/{userId}");
    private DELETE_CODE = new RequestConfig("USR", "application/json", true, RequestMethod.Delete, "/users/{userId}");
    private GET_CODE = new RequestConfig("USR", "application/json", true, RequestMethod.Get, "/users/{userId}");
    private GET_PAGE = new RequestConfig("USR", "application/json", true, RequestMethod.Post, "/users/list");
    private GET_BY_GROUP = new RequestConfig("USR", "application/json", true, RequestMethod.Post, "/users/findByGroups");
    private GET_SEARCH = new RequestConfig("USR", "application/json", true, RequestMethod.Post, "/users/search");
    private GET_USERS_BY_PROFILE = new RequestConfig("USR", "application/json", true, RequestMethod.Post, "/users/assigned");
    private GET_USERS_TYPE = new RequestConfig("USR", "application/json", true, RequestMethod.Get, "/tipo-usuario/findAll");
    // Sre22052020 Agrego método para buscar un usuario por username:
    private GET_USER_VIEW = new RequestConfig("USR", "application/json", true, RequestMethod.Get, "/users/username/{userId}");
    private reqGeneric = new RequestConfig('CR-PostPatologico', 'application/json', true, RequestMethod.Get, '/personal-patologico');

    //RECOVERY PASSWORD
    private GET_RECPASS = new RequestConfig('USUARIOS-RECOVERY', 'application/json', true, RequestMethod.Get, '/users/recoverPassword/{email}')


    constructor(private http: CustomHttp) {
    }

    getUsersType(filter: {visible: boolean}): Observable <Array<TypeUser>> {
        return this.http.sendRequest(this.GET_USERS_TYPE, filter)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia una petición a WS para guardar usuario
     */
    save(user: User): Observable<Response> {
        return this.http.sendRequest(this.CREATE_CODE, user)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener detalles de un ususario
     */
    get(idUserApp: number, image?: boolean): Observable<User> {
        return this.http.sendRequest(this.GET_CODE, {image: image}, idUserApp)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error)).timeout(6000);
    }

    /**
     * Envia petición a WS para obtener detalles de un ususario por username
     * Sre22052020 Nuevo metodo
     */
    getByUsername(username: string): Observable<UserAppView> {
        return this.http.sendRequest(this.GET_USER_VIEW, {}, username)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envía petición a WS para editar datos de usuario
     */
    update(user: User): Observable<Response> {
        return this.http.sendRequest(this.EDIT_CODE, user, user.idUserApp)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición al servidor para eliminar datos de usuario
     */
    delete(idUserApp: number) {
        return this.http.sendRequest(this.DELETE_CODE, {}, idUserApp)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición al servidor para desactivar usuario
     */
    deactivateUser(idUserApp: number) {
        return this.http.sendRequest(this.DELETE_CODE, {}, idUserApp)
            .catch(error => this.http.handleError(error));
    }

    getPage(options: any): Observable<PageImpl<User>> {
        return this.http.sendRequest(this.GET_PAGE, options)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * buscar usuarios
     */
    search(name: any, group?: any): Observable<PageImpl<User>> {
        let options = {
            name: name,
            page: 0,
            size: 100
        };
        return this.http.sendRequest(this.GET_PAGE, options)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    findByGroups(groups: Array<number>): Observable<Array<User>> {
        return this.http.sendRequest(this.GET_BY_GROUP, groups)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));

    }

    findByProfileAssigned(byProfile: ByProfile): Observable<User> {
        return this.http.sendRequest(this.GET_USERS_BY_PROFILE, byProfile)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));

    }

    getSearchPageUsers(searchUsers: UserRequestView): Observable<PageImpl<User>> {
        return this.http.sendRequest(this.GET_SEARCH, searchUsers)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }
    putResetPassword(value: any,iduser:number) {
        this.reqGeneric.method = RequestMethod.Put;
        this.reqGeneric.code = 'RESETPASSWORD';
        this.reqGeneric.uri = '/users/'+iduser+'/reset';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    

    
    getRecoverypass(MAIL: string): Observable<any> {
        return this.http.sendRequest(this.GET_RECPASS, null, MAIL)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }

}
