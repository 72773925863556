import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    OnChanges,
    SimpleChanges,
    AfterViewInit,
    AfterViewChecked
} from '@angular/core';
import {CamposTablaHeredoFamiliares, PantallaHeredoFamiliares} from '../../../../../shared/classes/common.classes';
import {Cie10} from '../../../../../shared/classes/cies.classes';
import {PageRequest} from '../../../../../common/http/page.request';
import {CiesService} from '../../../../../shared/services/cies.service';
import {CapitalizePipe} from "../../../../../shared/utils/capitalize.pipe";

declare var $: any;

@Component({
    selector: 'app-heredo-familiares',
    templateUrl: './heredo-familiares.component.html',
    styleUrls: ['../paciente.historia-clinica.component.css']
})

export class HeredoFamiliaresComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked{
    @Input() wholeObject: any = {};
    @Output() wholeObjectChange = new EventEmitter<PantallaHeredoFamiliares>();

    private searchCieTimeout: number;
    private optionsSearchCie: PageRequest = new PageRequest();
    //    Objeto que contendra toda la info en general
    arrayCamposTHF: CamposTablaHeredoFamiliares[];
    pantallaHeredoFamiliares: PantallaHeredoFamiliares = new PantallaHeredoFamiliares();

    private selectedCie10: Cie10;
    cargandoCie: boolean = false;
    searchCie10: string;
    catCie10: Array<Cie10> = [];
    onlyConsultas: any[] = [];
    loadingDataTable: boolean = false;
    total: number = 0;
    pageIndex: number = 0;
    pageSize: number = 5;
    readOnly: boolean = false;
    notas: boolean = false;
    

    constructor(private ciesService: CiesService,
                private capitalizePipe: CapitalizePipe) {
    }

    ngOnInit(): void {
        this.optionsSearchCie.page = 0;
        this.optionsSearchCie.size = 10;
        this.optionsSearchCie.orderType = "desc";

        this.arrayCamposTHF = [
            new CamposTablaHeredoFamiliares('Diabetes'),
            new CamposTablaHeredoFamiliares('Hipertension arterial'),
            new CamposTablaHeredoFamiliares('Obesidad/Sobrepeso'),
            new CamposTablaHeredoFamiliares('Cardiovasculares'),
            new CamposTablaHeredoFamiliares('Endocrinos'),
            new CamposTablaHeredoFamiliares('Renales'),
            new CamposTablaHeredoFamiliares('Hepaticos'),
            new CamposTablaHeredoFamiliares('Cerebrovasculares'),
            new CamposTablaHeredoFamiliares('Psiquiatricas/Neuro'),
            new CamposTablaHeredoFamiliares('Oncologicos'),
            new CamposTablaHeredoFamiliares('Transmision sexual')

        ];
        this.llenarObjeto();
        console.log(this.arrayCamposTHF);
    }

    ngAfterViewInit(): void {
        this.cancelarComillas();
    }

    ngAfterViewChecked(): void {
        this.cancelarComillas();
    }

    private cancelarComillas(): void {
        $('input[type="text"], textarea').on('keypress', function (e) {
            if (e.which === 34) {
                e.preventDefault();
            }
        });
    }

    agregarEnfermedad(): void {
        if (this.selectedCie10 && this.selectedCie10.nombre) {
            this.selectedCie10.nombre = this.capitalizePipe.transform(this.selectedCie10.nombre);
            if (this.arrayCamposTHF.findIndex((campoTHF) => campoTHF.enfermedad.toLowerCase() == this.selectedCie10.nombre.toLowerCase()) < 0) {
                const nuevo = new CamposTablaHeredoFamiliares(this.selectedCie10.nombre);
                nuevo.tieneEnfermedad = true;
                this.arrayCamposTHF.push(nuevo);
                // Limpiamos el input
                this.selectedCie10 = null;
                this.searchCie10 = null;
                setTimeout(() => this.llenarObjeto(), 300);
            }
        }
    }

    llenarObjeto(): void {
        this.pantallaHeredoFamiliares.datosTabla = this.arrayCamposTHF;
        // Enviamos el valor
        this.wholeObject.heredoFamiliares = this.pantallaHeredoFamiliares;
        this.wholeObjectChange.emit(this.wholeObject);

    }

    autocompleteCie(ev: KeyboardEvent, type: string): void {
        if (this.searchCieTimeout) clearTimeout(this.searchCieTimeout);
        if (ev.keyCode === 27 && this.selectedCie10) {
            this.catCie10 = [];
            if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
            } else {
                this.cargandoCie = true;
                this.ciesService.getCie10(this.selectedCie10.idCie10)
                    .subscribe((cie) => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                        () => this.cargandoCie = false,
                        () => this.cargandoCie = false);
            }
        } else {
            this.searchCieTimeout = window.setTimeout(() => {
                let input: HTMLInputElement = <HTMLInputElement>ev.target;
                input.onblur = null;
                if (this.searchCie10) {
                    this.optionsSearchCie.orderColumn = "nombre";
                    this.cargandoCie = true;
                    this.optionsSearchCie.datosBusqueda = this.searchCie10;
                    this.ciesService.getPageCie10(this.optionsSearchCie)
                        .subscribe((pageCie10) => {
                                this.catCie10 = pageCie10.content;
                                if (this.catCie10.length) {
                                    if (ev.keyCode === 13) this.assignCie(this.catCie10[0], type);
                                } else if (this.selectedCie10) {
                                    input.onblur = () => {
                                        input.onblur = null;
                                        if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                                            this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                                        } else {
                                            this.ciesService.getCie10(this.selectedCie10.idCie10)
                                                .subscribe((cie) => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                                                    () => this.cargandoCie = false,
                                                    () => this.cargandoCie = false);
                                        }
                                    };
                                }
                            },
                            () => this.cargandoCie = false,
                            () => this.cargandoCie = false);
                } else {
                    this.catCie10 = [];
                    if (this.selectedCie10) {
                        input.onblur = () => {
                            input.onblur = null;
                            if (this.selectedCie10.catalogKey && this.selectedCie10.nombre) {
                                this.searchCie10 = `${this.selectedCie10.catalogKey} ${this.selectedCie10.nombre}`
                            } else {
                                this.ciesService.getCie10(this.selectedCie10.idCie10).subscribe(
                                    cie => this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`,
                                    () => {
                                        this.cargandoCie = false
                                    },
                                    () => this.cargandoCie = false);
                            }
                        };
                    }
                }
            }, 300);
        }
    }

    assignCie(cie: any, type?: string): void {
        this.selectedCie10 = cie;
        this.searchCie10 = `${cie.catalogKey} ${cie.nombre}`;
        this.catCie10 = [];
    }

    forceSelectCie(ev: MouseEvent): void {
        let input: HTMLInputElement = <HTMLInputElement>ev.target;
        setTimeout(() => input.setSelectionRange(0, input.value.length), 50);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('wholeObject')) {
            const value = changes['wholeObject'].currentValue;
            if (value && value.hasOwnProperty('heredoFamiliares')) {
                this.pantallaHeredoFamiliares = value['heredoFamiliares'];
                this.arrayCamposTHF = this.pantallaHeredoFamiliares.datosTabla;
            } else {
                this.wholeObject.heredoFamiliares = this.pantallaHeredoFamiliares;
            }
        }
        this.cancelarComillas();
    }
}
