import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {EstudiosLaboratorio, EstudiosLaboratorioDocumento, EstudiosLaboratorioEstudio} from '../../../../../shared/classes/common.classes';
import {VacunaView} from '../../../../paciente.classes';
import {CatTipoInsumoMedView} from "../../../../../shared/classes/catalog.classes";
import {StudyJSON} from "../../../../../shared/classes/studies.classes";
import {PageRequest} from "../../../../../common/http/page.request";
import {StudiesService} from "../../../../../shared/services/studies.service";

@Component({
    selector: 'app-estudios-laboratorio',
    templateUrl: './estudios-laboratorio.component.html',
    styleUrls: ['../paciente.historia-clinica.component.css']
})
export class EstudiosLaboratorioComponent implements OnChanges {
    @ViewChild('dialogStudiesFilterList') private dialogStudiesFilterListEl: ElementRef;//cuadro de busqueda de estudio
    @Input() wholeObject: any = {};
    @Output() wholeObjectChange = new EventEmitter<VacunaView[]>();

    estudiosLaboratorio: EstudiosLaboratorio = new EstudiosLaboratorio();
    estudioDescripcion: EstudiosLaboratorioEstudio = new EstudiosLaboratorioEstudio();
    isEditingDescripcion = false;
    @ViewChild('fileDocInput') private fileDocInput: ElementRef;
    notas: boolean = false;

    isExtensionValid = true;
    //para el cuadro de busqueda de estudio
    studiesFilterList: StudyJSON[] = [];
    private timeoutFitlerList: number;
    private pageSearchFilterList: PageRequest = new PageRequest();
    private isLastFromFilterList = false;
    searchStudiesFilterList: string;
    loadingStudiesFilterList = false;
    private selectedStudy: StudyJSON;
    dialogStudiesConfig = {
        autoOpen: false,
        closeText: '',
        draggable: false,
        resizable: false,
        modal: true,
        width: 400,
        classes: {
            'ui-dialog-titlebar': 'no-border',
        },
        position: {my: 'left top', at: 'left top', of: $('#txtEstudio')}
    };

    constructor(private studiesService: StudiesService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('wholeObject')) {
            const value = changes['wholeObject'].currentValue;
            console.log(value);
            if (value  && value.hasOwnProperty('estudiosLaboratorio')) {
                this.estudiosLaboratorio = value['estudiosLaboratorio'];
            } else {
                this.wholeObject.estudiosLaboratorio = this.estudiosLaboratorio;
            }
        }
        
    }

    validExtensionFile(nameFile: string) {
        if ( /\.(jp[e]?g|png|pdf)/g.test(nameFile) ) {
            this.isExtensionValid = true;
            return true;
        }  else {
            this.isExtensionValid = false;
            setTimeout(_ => { this.isExtensionValid = true }, 1500 );
            return false;
        }
    }

    addEstudio() {
        console.log(this.estudioDescripcion);
        if (this.estudioDescripcion.tipo.trim().length && this.estudioDescripcion.descripcion.trim().length && !this.isEditingDescripcion) {
            const obj: EstudiosLaboratorioEstudio = Object.assign({}, this.estudioDescripcion);

            this.estudioDescripcion = new EstudiosLaboratorioEstudio();
            this.estudiosLaboratorio.listaEstudios = [...this.estudiosLaboratorio.listaEstudios, obj];
            this.sendData();
            this.estudioDescripcion.descripcion = '';
            this.estudioDescripcion.tipo = '';
        }

        if (this.isEditingDescripcion) {
            this.isEditingDescripcion = false;
            this.estudioDescripcion = new EstudiosLaboratorioEstudio();
        }
    }

    deleteEstudio(item: EstudiosLaboratorioEstudio) {
        this.estudiosLaboratorio.listaEstudios = this.estudiosLaboratorio.listaEstudios.filter((data) => {
            return data.descripcion + data.tipo !== item.descripcion + data.tipo;
        });
        this.sendData();
    }

    editarEstudio(item: EstudiosLaboratorioEstudio) {
        this.estudioDescripcion = item;
        this.isEditingDescripcion = true;
        this.sendData();
    }

    onFileChangeDoc($event) {
        for (let i = 0; i < $event.target.files.length; i++) {
            let reader: FileReader = new FileReader();
            reader.readAsDataURL($event.target.files[i]);
            if (this.validExtensionFile($event.target.files[i].name)) {
                reader.onload = () => {
                    this.estudiosLaboratorio.listaDocumentos = [...this.estudiosLaboratorio.listaDocumentos, {
                        contentType: $event.target.files[i].type,
                        nameFile: $event.target.files[i].name,
                        fileBase64: reader.result.toString()
                    }];
                    this.sendData();
                }
            }
        }
    }

    sendDoc() {
        this.isExtensionValid = true;
        this.fileDocInput.nativeElement.click();
    }

    verDocumento(item: EstudiosLaboratorioDocumento) {
        console.debug(item);
    }

    deleteDocumento(doc: EstudiosLaboratorioDocumento) {
        this.estudiosLaboratorio.listaDocumentos = this.estudiosLaboratorio.listaDocumentos.filter((data) => {
            return data.nameFile !== doc.nameFile;
        });
        this.sendData();
    }

    sendData() {
        this.wholeObject.estudiosLaboratorio = this.estudiosLaboratorio;
        this.wholeObjectChange.emit(this.wholeObject);
    }

    //cuadros y busquedas Estudios
    private get dialogStudiesFilterList(): any {
        if (!this.dialogStudiesFilterListEl) {
            return null;
        }

        return $(this.dialogStudiesFilterListEl.nativeElement);
    }

    openDialogStudiesFilterList(event: MouseEvent): void {
        if (this.dialogStudiesFilterList) {
            this.studiesFilterList = [];

            if (event) {
                this.dialogStudiesFilterList.dialog('option', 'position', {
                    my: 'left top',
                    at: 'left top',
                    of: event.target
                });
            }

            this.dialogStudiesFilterList.dialog('open');
        }
    }

    loadFilterList(reset: boolean = false, more: boolean = false): void {
        clearTimeout(this.timeoutFitlerList);

        if (more) {
            this.pageSearchFilterList.page++;
        }

        if (reset) {
            this.pageSearchFilterList.page = 0;
            this.studiesFilterList = [];
            this.isLastFromFilterList = false;
        }

        if (this.searchStudiesFilterList && this.searchStudiesFilterList.length > 2) {
            this.loadingStudiesFilterList = true;
            this.timeoutFitlerList = window.setTimeout(() => {
                this.pageSearchFilterList.datosBusqueda = this.searchStudiesFilterList;
                this.studiesService.filterFromJSON(this.searchStudiesFilterList)
                    .subscribe((studiesJSON) => {
                        this.studiesFilterList = [];
                        this.studiesFilterList.push(...studiesJSON);
                        this.isLastFromFilterList = true;
                        this.loadingStudiesFilterList = false;
                    }, () => {
                        this.loadingStudiesFilterList = false;
                    }, () => {
                        this.loadingStudiesFilterList = false;
                    });
            }, 500);
        }
    }

    onFilterListScroll(event: MouseEvent): void {
        if (!this.isLastFromFilterList) {
            if ((<HTMLElement>event.target).offsetHeight + (<HTMLElement>event.target).scrollTop >= (<HTMLElement>event.target).scrollHeight) {
                this.loadFilterList(false, true);
            }
        }
    }

    selectStudyFilterList(study: StudyJSON): void {
        this.selectedStudy = study;
        this.estudioDescripcion.tipo = this.selectedStudy.Estudio;
        this.estudioDescripcion.descripcion = this.selectedStudy.Descripcion;
        this.estudioDescripcion.id = this.selectedStudy.Id;
        this.estudioDescripcion.comentarios = this.selectedStudy.Preparacion;
        this.searchStudiesFilterList = '';

        if (this.dialogStudiesFilterList) {
            this.dialogStudiesFilterList.dialog('close');
        }
    }

}
