import { Component, OnInit, ViewChild  } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {IframeDashComponent} from '../../../+dashboard/iframe-dash/iframe-dash.component';

@Component({
  selector: 'app-navigation-puebla',
  templateUrl: './navigation-puebla.component.html',
  styleUrls: ['./navigation-puebla.component.css']
})
export class NavigationPueblaComponent implements OnInit {

  @ViewChild(IframeDashComponent) hijo: IframeDashComponent;

  constructor(private router: Router,) { }

  ngOnInit() {
  }
  behaviour( mensaje){
    this.router.navigate(['/home/iframe'], { queryParams: { Mensaje: mensaje}});
  }

}
