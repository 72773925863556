export class EstudioView {
    activo: boolean;
    descripcionEstudio: string;
    emailEnvio: string;
    fechaCreacion: Date;
    idEstudio?: string;
    medicoId: string;
    nombreMedico: string;
    nombrePaciente: string;
    pacienteId: string;
    tipoEstudio: string;
    consultaId: number;
    preparacion: string;
}
export class NuevoEstudioView {
    activo:	boolean;
    cedulaMedico:	string;
    correoPaciente:	string;
    curpPaciente:	string;
    detallesEstudioList: DetalleEstudioView[];
    edadPaciente:	number;
    especialidadMedico:	string;
    fechaCreacion:	Date;
    folio:	number;
    idConsulta:	number;
    idEstudio:	string;
    idMedico:	string;
    idPaciente:	string;
    nombreMedico:	string;
    nombrePaciente:	string;
    numeroExpediente: number;
    sexoPaciente:	string;
    universidadMedico:	string;
}
export class DetalleEstudioView {
    descripcionEstudio:	string;
    idDetalleEstudio:	number;
    idEstudio:	string;
    preparacion:	string;
    tipoEstudio: string;

    constructor (descripcionEstudio?: string, tipoEstudio?: string) {
        this.descripcionEstudio = descripcionEstudio;
        this.tipoEstudio = tipoEstudio;
    }
}

export class StudyJSON {
    Id: number;
    Descripcion: string;
    Preparacion: string;
    Estudio: string;
}

export class ResultadoEstudio {
    base64: string;
    fechaCreacion: Date;
    idConsulta: number;
    idPaciente: string;
    idResultadoEstudio: number;
    mimeType: string;
}

export class EstudiosYResultados {
    formatoEstudioView: FormatoEstudio;
    resultadoEstudioViewList: ResultadoEstudio[];
}

export  class FormatoEstudio {
    base64: string;
    idConsulta: number;
    mimeType: string;
}

