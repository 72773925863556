import {Injectable} from '@angular/core';
import {
    ConnectionBackend, Headers, Http, Request, RequestMethod, RequestOptions, RequestOptionsArgs,
    Response, URLSearchParams
} from '@angular/http';
import { Observable } from 'rxjs/Rx'
import {isNullOrUndefined} from 'util';
import {RequestConfig} from '../../shared/rest/rest.request';
import {environment} from '../../../environments/environment';
import {ErrorResponse} from './error.response';
import {Subject} from 'rxjs/Subject';
import {NotificationService} from '../../shared/utils/notification.service';
import {AuthNioService} from '../../+login-nio/auth-nio.service';
import {TokenManager} from "../../+login-nio/auth-nio.classes";
import { Console } from 'console';

@Injectable()
export class CustomHttp extends Http {
    private customApiUrl: string;
    private urlProxy = ''; // 'https://cors-anywhere.herokuapp.com/';
    constructor(_backend: ConnectionBackend, _defaultOptions: RequestOptions,
                public oauth: AuthNioService, public notifications: NotificationService) {
        super(_backend, _defaultOptions);
    }

    request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
        if (this.isSecure(options)) {
            if (this.oauth.isTokenValid()) {
                return super.request(url, options).catch(error => Observable.throw(error));
            }
            return this.oauth.refreshToken()
                .flatMap((success: TokenManager) => {
                    options.headers.set('Authorization', success.token_type + ' ' + success.access_token);
                    return super.request(url, options);
                }).catch(error => Observable.throw(error));
        } else {
            return super.request(url, options).catch(error => Observable.throw(error));
        }
    }

    get(url: string, options?: RequestOptionsArgs): Observable<Response> {
        if (this.isSecure(options)) {
            if (this.oauth.isTokenValid()) {
                return super.get(url, options).catch(error => Observable.throw(error));
            }
            return this.oauth.refreshToken()
                .flatMap((success: TokenManager) => {
                    options.headers.set('Authorization', success.token_type + ' ' + success.access_token);
                    return super.get(url, options);
                }).catch(error => Observable.throw(error));
        }
        return super.get(url, options).catch(error => Observable.throw(error));
    }

    /**
     * Performs a request with `post` http method.
     */
    post(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        if (this.isSecure(options)) {
            if (this.oauth.isTokenValid()) {
                return super.post(url, options).catch(error => Observable.throw(error));
            }
            return this.oauth.refreshToken()
                .flatMap((success: TokenManager) => {
                    options.headers.set('Authorization', success.token_type + ' ' + success.access_token);
                    return super.post(url, options);
                }).catch(error => Observable.throw(error));
        }
        return super.post(url, options).catch(error => Observable.throw(error));
    }

    /**
     * Performs a request with `put` http method.
     */
    put(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        if (this.isSecure(options)) {
            if (this.oauth.isTokenValid()) {
                return super.put(url, options).catch(error => Observable.throw(error));
            }
            return this.oauth.refreshToken()
                .flatMap((success: TokenManager) => {
                    options.headers.set('Authorization', success.token_type + ' ' + success.access_token);
                    return super.put(url, options);
                }).catch(error => Observable.throw(error));
        }
        return super.put(url, options).catch(error => Observable.throw(error));
    }

    /**
     * Performs a request with `delete` http method.
     */
    delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
        if (this.isSecure(options)) {
            if (this.oauth.isTokenValid()) {
                return super.delete(url, options).catch(error => Observable.throw(error));
            }
            return this.oauth.refreshToken()
                .flatMap((success: TokenManager) => {
                    options.headers.set('Authorization', success.token_type + ' ' + success.access_token);
                    return super.delete(url, options);
                }).catch(error => Observable.throw(error));
        }
        return super.delete(url, options).catch(error => Observable.throw(error));
    }

    sendRequestFilePost(config: RequestConfig, body?: any,  ...pathVariables): Observable<Response> {
        let options: RequestOptions = new RequestOptions();
        let url;

        options.method = config.method;
        // configuración de headers de request
        options.headers = new Headers();

        // options.headers.append('Content-Type', config.contentType);
        if (config.secure && !isNullOrUndefined(this.oauth.token)) {
            options.headers.append('Authorization', this.oauth.token.token_type + ' ' + this.oauth.token.access_token);
            options.headers.append('hier_token', this.oauth.token.hier_token);
        }

        if (!this.customApiUrl) {
            url = this.getFormattedUrl(environment.api_url + config.uri, pathVariables);
        } else {
            url = this.getFormattedUrl(this.customApiUrl + config.uri, pathVariables);
            this.customApiUrl = '';
        }

        return super.post(this.urlProxy + url, body, options).catch(error => Observable.throw(error));
    }

    sendRequestFilePosttoURL(CustURL: string, config: RequestConfig, body?: any,  ...pathVariables): Observable<Response> {
        this.customApiUrl = CustURL;
        return this.sendRequestFilePost(config, body, pathVariables);
    }

    /**
     * Método para enviar peticiones al servidor
     * @param config configuración necesaria para enviar la petición
     * @param token OAUTH2 para enviar peticiones seguras
     * @param body información a enviar el servidor
     * @param pathVariables valores que se reemplazaran en la URI
     * @return  Observable<Response>
     */
    sendRequest(config: RequestConfig, body?: any, ...pathVariables): Observable<Response> {
        let options: RequestOptions = new RequestOptions();
        // agregar los parametros que se necesitan al request
        if (!isNullOrUndefined(body)) {
            if (config.method !== RequestMethod.Get) {
                options.body = body;
            } else {
                options.search = new URLSearchParams();
                for (let prop in body) {
                    if (prop != "constructor")
                        options.search.append(prop, body[prop]);
                }
            }
        }

        options.method = config.method;
        // configuración de headers de request
        options.headers = new Headers();
        if (config.contentType.length) {
            options.headers.append('Content-Type', config.contentType);
        }
        // seguridad oauth si es necesario
        if (config.secure && !isNullOrUndefined(this.oauth.token)) {
            // options.headers.append('Authorization', 'Bearer' + ' ' + this.oauth.token.access_token);
            options.headers.append('Authorization', this.oauth.token.token_type + ' ' + this.oauth.token.access_token);
            options.headers.append('hier_token', this.oauth.token.hier_token);
            // this.oauth.token.token_type + ' ' + this.oauth.token.access_token);
        }

        let url;
        if (!this.customApiUrl) {
            url = this.getFormattedUrl(environment.api_url + config.uri, pathVariables);
        } else {
            url = this.getFormattedUrl(this.customApiUrl + config.uri, pathVariables);
            this.customApiUrl = '';
        }

        return this.request(this.urlProxy + url, options);
    }

    sendRequestToUrl(apiUrl: string, config: RequestConfig, body?: any, ...pathVariables): Observable<Response> {              
        this.customApiUrl = apiUrl;    
        return this.sendRequest(config, body, pathVariables);
    }

    isSecure(options?: RequestOptionsArgs) {
        return !isNullOrUndefined(options) && !isNullOrUndefined(options.headers) &&
            !isNullOrUndefined(options.headers.get('Authorization'));
    }

    private getFormattedUrl(url: string, ...pathVariables) {
        let size = url.indexOf('{');
        if (!isNullOrUndefined(pathVariables) && pathVariables.length > 0 && size > 0) {
            pathVariables.forEach(variable => {
                variable.forEach(path => {
                    let index = url.indexOf('{');
                    let limit = url.indexOf('}') + 1;
                    url = url.substr(0, index) + path + url.substr(limit, url.length);
                });
            });
        }
        return url;
    }

    handleError(error: Response | any) {
        let errorResponse: ErrorResponse;
        let errorMessage: string;
        let errorTitle: string;

        let ignoreNotification: boolean = false;
        if (error instanceof Response) {
            //alert('Error : ' + error.status);
            errorResponse = error.json();
            
        errorTitle = errorResponse.message || errorResponse['GW-message'] || "";
        errorMessage = this.extractErrorList(errorResponse);
        if (!!errorTitle && !errorMessage) {
            errorMessage = errorTitle;
            errorTitle = "";
        }

            this.notifications.smallBox(
                {
                    title:  "ERROR: "+ errorMessage,
                    content: '<br>',
                    color: "#d9534f",
                    icon: "fa fa-times",
                    timeout: 3000
                });
                console.log(error);
            if (error.status === 401) {
                this.oauth._refreshing = new Subject();
                this.oauth.logout();
                ignoreNotification = true;
            }
        } else {
            this.notifications.smallBox(
                {
                    title:  "ERROR NR handle: "+ error.error,
                    content: '<br>',
                    color: "#d9534f",
                    icon: "fa fa-times",
                    timeout: 3000
                });
                console.log(error);
            if (error.error && (error.error === 'invalid_token' || error.error === 'invalid_grant')) {
                this.oauth._refreshing = new Subject();
                this.oauth.logout();
                ignoreNotification = true;
            }
            errorResponse = new ErrorResponse('ERRDESC', error.status, 'Error al enviar la petición', []);
        }


        switch (errorResponse.status) {
            case 401:
                if (!ignoreNotification) {
                    this.notifications.smallBox({
                        title: 'ERROR DE AUTORIZACIÓN',
                        content: 'SIN PERMISOS PARA LA PETICIÓN.',
                        color: '#df295f',
                        icon: 'fa fa-warning shake',
                        timeout: 5000
                    });
                }
                break;
            default:
                if (!ignoreNotification && errorMessage) {
                console.log("ERROR "+errorTitle);
                console.log("ERROR "+errorMessage);
                }
                break;
        }

        return Observable.throw(errorResponse);
    }

    private extractErrorList(error: ErrorResponse): string {
        let errorString: string = "";

        if (error.errorList || error['GW-errorList']) {
            try {
                errorString = error.errorList[0] || error['GW-errorList'][0];

                if (errorString.indexOf("{") >= 0) {
                    let objsErrorList: string[] = errorString.split("{");
                    errorString = objsErrorList.shift();
                    if (objsErrorList.length) {
                        let stringErrorList: string = objsErrorList[0].replace("}", "");
                        objsErrorList = stringErrorList.split(",");
                        objsErrorList.forEach((objErrorList) => {
                            if (objErrorList.indexOf("errorList") >= 0) {
                                errorString += " " + objErrorList.replace("errorList", "").replace(/[:\[\]]/g, "").trim();
                            }
                        });
                    }
                }
            } catch (e) {
            }
        }

        return errorString;
    }
}
