import { Injectable } from '@angular/core';
import { RequestMethod,Response } from '@angular/http';
import { MedicoView } from 'app/+doctor/doctor.classes';
import { CustomHttp } from 'app/common/http/custom.http';
import { ServicesSubject } from 'app/common/http/services.subject';
import { RequestConfig } from 'app/shared/rest/rest.request';
import { Observable } from 'rxjs-compat';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
   private medico: ServicesSubject<MedicoView> = new ServicesSubject();
  private reqGeneric = new RequestConfig('CR-PostPatologico', 'application/json', true, RequestMethod.Get, '/personal-patologico');
  private GET_hast = new RequestConfig('GETHAST', 'application/json', true, RequestMethod.Get, '/new-user/get-link?username={username}&idUsuario={iduser}&idGroup={idgroup}')
  private GET_ID_USUARIO = new RequestConfig('Doctor-GetByIdUsr', 'application/json', true, RequestMethod.Get, '/medicos/obtenerPorIdUsuario/{idUsuario}');

  constructor(private http: CustomHttp) { }
  
  pOSTChangePassword(value: any) {
    this.reqGeneric.method = RequestMethod.Post;
    this.reqGeneric.code = 'CHANGEPASSWORD';
    this.reqGeneric.uri = '/users/changePassword';
    return this.http.sendRequest(this.reqGeneric, value)
        .catch(error => this.http.handleError(error));
}

getHASH(username: string,iduser: string,idgroup: number):any {
  return this.http.sendRequest(this.GET_hast, null, username,iduser,idgroup)
    .map((res: Response) => res);
      
//            .catch(error => this.http.handleError(error));
}




}
